import * as Store from "@actions/index";
import { useDispatch } from "@helpers/Store";

export default class Alert {
  /**
   * Открываем уведомление
   *
   * @return function(Dispatch)->Alert
   */
  public static open(alert: string, data: any = {}) {
    useDispatch(Store.Ui.Alert.openAlert({ alert, data }));
  }

  /**
   * Закрываем уведомление
   *
   * @return function(Dispatch)->Alert
   */
  public static close(index: number) {
    useDispatch(Store.Ui.Alert.close(index));
  }

  /**
   * Закрываем все уведомления
   *
   * @return function(Dispatch)->Alert
   */
  public static closeAll() {
    useDispatch(Store.Ui.Alert.closeAll());
  }
}
