import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { useHistory } from "react-router-dom";

const SignUpPage: React.FC = () => {
  const [login, setLogin] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.title = "Signup" + " | " + process.env.SITE_NAME;
    dispatch(Middleware.Ui.Footer.hide());
    return () => {
      dispatch(Middleware.Ui.Footer.show());
    };
  }, [dispatch]);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      Middleware.Auth.passwordRestore({ login }, () => {
        history.push("/static/registration-complete");
      })
    );
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  };

  return (
    <div className="auth auth--restore">
      <div className="auth__wrapper auth__wrapper--big">
        <div>
          <h1>Забыли пароль?</h1>
          <div className="form__group--margin">
            <p className="auth__restore-description">
              Обновить пароль просто. Напишите email, с которого вы
              регистрировались на онлайн-выставку, и мы отправим Вам письмо с
              ссылкой для сброса старого пароля.
            </p>
          </div>
        </div>
        <div>
          <form onSubmit={onSubmitHandler}>
            <div className="form__group form__group--margin">
              <input
                className="input"
                type="email"
                name="login"
                id="login"
                value={login}
                onChange={onChangeHandler}
                placeholder="Е-mail"
                required
              />
            </div>
            <button
              type="submit"
              className="auth__btn btn btn--red"
              aria-label="Сбросить пароль"
            >
              Сбросить пароль
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
