import * as Action from "@actions/index";
import { useDispatch } from "@helpers/Store";

export default class Modal {
  /**
   * Открываем модалку
   *
   * @return function(Dispatch)->Modal
   */
  public static open(modal: string, data: any = {}) {
    useDispatch(Action.Ui.Modal.openModal({ modal, data }));
  }

  /**
   * Закрываем модалку
   *
   * @return function(Dispatch)->Modal
   */
  public static close() {
    useDispatch(Action.Ui.Modal.close());
  }

  /**
   * Закрываем все модалки
   *
   * @return function(Dispatch)->Modal
   */
  public static closeAll() {
    useDispatch(Action.Ui.Modal.closeAll());
  }
}
