import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import Jaas from "@components/jaas/Player";
// import Chat from "./components/Chat";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  avatar: string;
  moderator: boolean;
  ready: boolean;
}

class EventShowPage extends React.Component<PropsInterface, StateInterface> {
  constructor(props: PropsInterface) {
    super(props);
    this.state = {
      avatar:
        "https://sun9-50.userapi.com/impf/c847220/v847220738/9ff4a/2rdvf7dOlVc.jpg?size=615x338&quality=96&proxy=1&sign=f908b5f9e1537c6a91f4c7a88657dc32&type=album",
      moderator: true,
      ready: false,
    };
  }

  public componentDidMount() {
    Middleware.Ui.Sidebar.hide();
    function linkRequester(event: any) {
      if (event.target.closest("a")) {
        const userResponse = Boolean(confirm("Вы желаете покинуть страницу?"));
        if (!userResponse) {
          event.preventDefault();
        } else {
          document
            .getElementsByTagName("body")[0]
            .removeEventListener("click", linkRequester, true);
        }

        return userResponse;
      }
    }
    document.getElementsByTagName("body")[0].addEventListener("click", linkRequester, true);
  }

  public componentWillUnmount() {
    Middleware.Ui.Sidebar.show();
  }

  public render() {
    return (
      <>
        <div className="page-event-show">
          <h1>Событие {this.props.match.params.event_id}</h1>
          <div className="row">
            {this.props.Store.Auth.data && this.props.Store.Auth.status === "authorized" ? (
              <>
                <Jaas
                  User={{
                    id: this.props.Store.Auth.data.id,
                    name:
                      String(this.props.Store.Auth.data.firstName) +
                      " " +
                      String(this.props.Store.Auth.data.lastName),
                    email: this.props.Store.Auth.data.email,
                    avatar: this.props.Store.Auth.data.userImage || "",
                    moderator: false,
                  }}
                />
                {/* <Chat
                  user_id={this.props.Store.Auth.data.id}
                  user_name={
                    this.props.Store.Auth.data.firstName + " " + this.props.Store.Auth.data.lastName
                  }
                /> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(EventShowPage)
);
