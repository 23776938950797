import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, useHistory } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

const SignInPage: React.FC<PropsInterface> = (Store: any) => {
  const [signin, setSignin] = useState({
    csrf: "",
    login: Store?.Store?.Auth?.email || "",
    password: "",
    remember_me: "",
  });
  const [isPwdShowed, setIsPwdShowed] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state: any) => state.Profile);

  // const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();

  //   dispatch(
  //     Middleware.Auth.signin(signin, () => {
  //       Middleware.Profile.load().then((response: any) => {
  //         console.log("response", response);

  //         console.log("profile", profile);

  //         if (response.tariff === null) {
  //           history.push("/tariffs-and-payment");
  //         } else {
  //           history.push(Store.Store.Auth.preurl);
  //         }
  //       });
  //     })
  //   );
  // };
  let isAuthorized = false;

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isAuthorized) {
      return;
    }

    dispatch(
      Middleware.Auth.signin(signin, async () => {
        try {
          isAuthorized = true;

          const response = await Middleware.Profile.load();

          if (response.tariff === null) {
            history.push("/tariffs-and-payment");
          } else {
            const preurl = Store.Store.Auth.preurl || "/";
            history.push(preurl);
          }
        } catch (error) {
          console.error("Error loading profile:", error);
        } finally {
          isAuthorized = false;
        }
      })
    );
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSignin((prevSignin) => ({
      ...prevSignin,
      [name]: value,
    }));
  };

  useEffect(() => {
    document.title = `Login | ${process.env.SITE_NAME}`;
    dispatch(Middleware.Ui.Footer.hide());
    return () => {
      dispatch(Middleware.Ui.Footer.show());
    };
  }, [dispatch]);

  return (
    <div className="auth auth--signin">
      <div className="auth__wrapper auth__wrapper--big">
        <div className="auth__header">
          <h1>Авторизация</h1>
        </div>
        <form onSubmit={onSubmitHandler}>
          <div className="form__group form__group--margin">
            <div className="auth__pwd">
              <label className="visually-hidden" htmlFor="login">
                Ваш email*
              </label>
              <div className="auth__pwd-input">
                <input
                  className="input"
                  type="email"
                  name="login"
                  id="login"
                  value={signin.login}
                  onChange={onChangeHandler}
                  placeholder="Е-mail"
                  required={true}
                  autoComplete="username"
                />
              </div>
              <label />
            </div>
            <div className="auth__pwd">
              <label className="visually-hidden" htmlFor="password">
                Ваш пароль*
              </label>
              <div className="auth__pwd-input">
                <input
                  className="input"
                  type={isPwdShowed ? "text" : "password"}
                  name="password"
                  id="password"
                  value={signin.password}
                  onChange={onChangeHandler}
                  placeholder="Введите пароль"
                  required={true}
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  aria-label={isPwdShowed ? "Скрыть пароль" : "Показать пароль"}
                  className={`auth__pwd-btn ${
                    isPwdShowed ? "" : "auth__pwd-btn--closed"
                  }`}
                  onClick={() => {
                    setIsPwdShowed(!isPwdShowed);
                  }}
                />
              </div>
              <label />
            </div>
          </div>

          <div className="auth__footer">
            <button className="auth__btn btn btn--red" type="submit">
              Войти на сайт
            </button>
            <div className="auth__pwd-restore">
              <div>Забыли пароль?&nbsp;</div>
              <Link to="/auth/password/restore">Восстановить</Link>
            </div>
            <div className="auth__pwd-restore">
              <div>Нет еще аккаунта? &nbsp;</div>
              <Link to="/auth/signup">Зарегистрируйтесь</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(SignInPage);
