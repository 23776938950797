import React from "react";

const Logo: React.FC = () => {
  return (
    <>
      <picture>
        <img
          src={require("@public/images/logo.svg")}
          alt="ОТМ"
          width="57"
          height="28"
          className="pointer-events-none"
        />
      </picture>
      <div className="main-nav__logo-description">
        <p className="main-nav__logo-description-top">Intensive</p>
        <p className="main-nav__logo-description-bottom">by Profi Travel</p>
      </div>
    </>
  );
};

export default Logo;
