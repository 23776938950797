import React from "react";

interface PropsInterface {
  hash: number;
  onChange: (item: { name: string; type: string; value: string }) => void;
}

interface StateInterface {
  name: string;
  type: string;
  value: string;
  hash: number;
}

class TestInputComponent extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      name: "",
      type: "number",
      value: "",
      hash: this.props.hash,
    };
    console.log(this.props.hash);
  }

  public componentDidMount(): void {
    this.props.onChange(this.state);
  }

  public render() {
    return (
      <>
        <input
          type="text"
          name="name"
          value={this.state.name}
          onChange={(e) => {
            this.setState({ name: e.target.value }, () => {
              this.props.onChange(this.state);
            });
          }}
          required={true}
        />
        <select
          name="type"
          defaultValue={"number"}
          onChange={(e) => {
            this.setState({ type: e.target.value, value: "" }, () => {
              this.props.onChange(this.state);
            });
          }}
        >
          <option value={"number"}>number</option>
          <option value={"string"}>string</option>
        </select>
        <input
          type={this.state.type === "number" ? "number" : "text"}
          name="value"
          value={this.state.value}
          onChange={(e) => {
            this.setState({ value: e.target.value }, () => {
              this.props.onChange(this.state);
            });
          }}
          placeholder="value"
          required={true}
        />
      </>
    );
  }
}

export default TestInputComponent;
