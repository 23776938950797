import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class ChatsPage extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="page-chat">
          <div className="row flex-grow">
            <div className="left">
              <div className="filters">
                <ul>
                  <li>
                    <Link to="#" className="text-red">
                      <b>Все сообщения</b>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Ответы компаний</Link>
                  </li>
                  <li>
                    <Link to="#">Ответы в трансляциях</Link>
                  </li>
                </ul>
              </div>
              <div className="chats">
                <h2>Мои контакты</h2>
                <h3>Новые</h3>
                <ul>
                  <li>
                    <Link to="#" className="selected">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="name">
                        <b>Дарья Ассовская</b>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="name">
                        <b>Дарья Ассовская</b>
                      </div>
                    </Link>
                  </li>
                </ul>
                <h3>Прочитанные</h3>
                <ul>
                  <li>
                    <Link to="#">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="name">Дарья Ассовская</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="name">Дарья Ассовская</div>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="name">Дарья Ассовская</div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="chat"></div>
              <div className="message-form">
                <form className="row">
                  <textarea rows={4} />
                  <button type="submit">Отправить</button>
                </form>
                <div className="row mt-1">
                  <input type="file" name="attach" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(ChatsPage)
);
