import React, { ReactElement } from "react";
import { Provider } from "react-redux";
import { Store } from "@helpers/Store";

// import "@helpers/TimeIncrement";
/*
  Отключено обновление таймера глобально (замедляет работу)
  Нужно переделать без сохранения в стейте
  Используется для:
    - времени в динамическом блоке
    - создания встреч в нетворкинге
    - динамического обновления в списке встреч в лк
    - так же есть экшен в файле src/models/ModelPrototype.ts
*/

import "@public/sass/index.sass";
import PreloaderPage from "@components/preloader/PreloaderPage";

import Ping from "./Ping";
import Router from "./Router";

const Modal = React.lazy(
  () => import(/* webpackChunkName: 'ContainerModal' */ "./Modal")
);

const Alert = React.lazy(
  () => import(/* webpackChunkName: 'ContainerAlert' */ "./Alert")
);

const App = (): ReactElement => (
  <Provider store={Store}>
    <Ping>
      <>
        <React.Suspense fallback={<PreloaderPage />}>
          <Router />
          <Modal />
          <React.Suspense fallback={<PreloaderPage />}>
            <Alert />
          </React.Suspense>
        </React.Suspense>
      </>
    </Ping>
  </Provider>
);

export default App;
