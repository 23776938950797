import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Action from "@actions/index";

interface SignProps {
  onClose?: () => void;
}

const Sign = ({ onClose }: SignProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ul className="main-nav__user-nav main-nav__user-nav--unlogged">
      <li className="main-nav__user-nav-item main-nav__user-nav-item--reg">
        <Link
          to="#"
          onClick={() => {
            if (onClose) {
              onClose();
            }
            if (window.location.pathname.startsWith("/auth")) {
              dispatch(Action.Auth.setPreurl("/"));
            } else {
              dispatch(Action.Auth.setPreurl(window.location.pathname));
            }
            history.push("/auth/signup");
          }}
        >
          Регистрация
        </Link>
      </li>
      <li className="main-nav__user-nav-item main-nav__user-nav-item--login">
        <Link
          to="#"
          onClick={() => {
            if (onClose) {
              onClose();
            }
            if (window.location.pathname.startsWith("/auth")) {
              dispatch(Action.Auth.setPreurl("/"));
            } else {
              dispatch(Action.Auth.setPreurl(window.location.pathname));
            }
            history.push("/auth/signin");
          }}
        >
          Войти
        </Link>
      </li>
    </ul>
  );
};

export default Sign;
