import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import moment from "moment-timezone";

import * as Middleware from "@middlewares/index";
import * as Model from "@models/index";

import Style from "./Networking.module.scss";

// TODO Вынести правую колонку в отдельный компонент Status (src/pages/cabinet/networking/profile/components/Status.tsx)

interface IStep {
  id: number;
  title: string;
  description: string;
}

const AuthNetworking: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const networkingFillStatus = useSelector(
    (state: any) => state.Profile.data.networkingFillStatus
  );
  const title: string = useSelector(
    (state: any) => state.Main.Workshop.data.title
  );
  const profileUpdatedAt = moment(
    useSelector((state: any) => state.Profile.data.updatedAt)
  ).tz("Europe/Moscow");
  const workshopRegistrationDate = moment(
    useSelector((state: any) => state.Main.Workshop.data.registerStartAt)
  ).tz("Europe/Moscow");
  const preurl = useSelector((state: any) => state.Auth.preurl);
  const netwDays = useSelector((state) => state.Networking?.Days.data);

  const steps = [
    {
      id: 1,
      title: "Подключите опцию",
      description:
        "Система сформирует вашу онлайн-визитку на основе полученных данных из анкеты.",
    },
    {
      id: 2,
      title: "Находите партнеров",
      description:
        "С помощью фильтров или поисковой строки найдите человека или компанию и изучите информацию о них в каталоге Нетворкинга.",
    },
    {
      id: 3,
      title: "Обменивайтесь визитками",
      description:
        "Нажмите «Отправить визитку». Когда собеседник подтвердит запрос, его контакты появятся в Визитках в папке Сохраненные. ",
    },
  ];

  if (netwDays?.length) {
    steps.push({
      id: 4,
      title: "Назначайте видеовстречи",
      description: "Обсуждайте детали сотрудничества и организацию поездок",
    });
  }

  const setNetworkingStatus = async (status: boolean) => {
    let ProgressAlert: any;

    try {
      await new Model.Profile().setNetworkingStatus(status);
      const response = await Middleware.Profile.load();

      if (status && workshopRegistrationDate > profileUpdatedAt) {
        history.push({ pathname: "/auth/confirm/profile" });
      } else if (
        preurl !== "/" &&
        status &&
        response.networkingFillStatus === 3
      ) {
        Middleware.Ui.Alert.open("Process", {
          text: "Вы успешно присоединились к Нетворкингу",
          component: (c: any) => (ProgressAlert = c),
        });
        ProgressAlert.setStatus("success");
        ProgressAlert.startTimer();
        history.push({
          pathname: preurl,
        });
      } else if (status && response.networkingFillStatus === 3) {
        history.push({
          pathname: "/cabinet/networking/profile",
        });
      } else if (status && response.networkingFillStatus < 3) {
        history.push({
          pathname: "/cabinet/networking/reg",
        });
      } else {
        history.push({
          pathname: "/service/networking/disabled",
        });
      }
    } catch (Exception) {
      throw Exception;
    }
  };

  return (
    <>
      <div className="cabinet__layout-col">
        <div className={`${Style.block} cabinet__block`}>
          <div className={Style.header}>
            <div className={Style.headerContent}>
              <div className={Style.subtitle}>
                Ищете партнеров в турбизнесе?
              </div>
              <div className={Style.title}>
                <span>Нетворкинг</span> – это раздел, где можно
                <br />
                обменяться визитками, чтобы найти
                <br />
                партнеров
              </div>
              <div className={Style.text}>
                Работать в системе Вы сможете сразу после подключения опции.
              </div>
            </div>
            <img
              className={Style.headerImg}
              src={require("@public/images/computer.png")}
              alt=""
            />
          </div>
          <div className={Style.cards}>
            {steps.map((step: IStep, index: number) => {
              return (
                <div className={Style.card} key={step.id}>
                  <div className={Style.cardNumber}>{index + 1}</div>
                  <div className={Style.cardContent}>
                    <div className={Style.cardTitle}>{step.title}</div>
                    <div className={Style.cardText}>{step.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="cabinet__layout-col">
        <div className={`${Style.block} cabinet__block`}>
          <div className={Style.networkingTitle}>
            Ваш профиль не участвует в <span>Нетворкинге</span>
          </div>
          <div className={Style.networkingDescription}>
            Вы не можете {netwDays?.length ? "назначать встречи, " : null}
            обмениваться визитками с другими участниками системы Делового
            общения.
          </div>
          <div className={Style.networkingFooter}>
            <Link
              to="#"
              onClick={(event) => {
                event?.preventDefault();
                setNetworkingStatus(true);
              }}
              className="btn btn--medium btn--red"
            >
              Присоединиться сейчас
            </Link>
            <div className={Style.networkingNote}>
              Это бесплатно. Ваш доступ к вебинарам, стендам и рейтингу Loyalty
              сохранится.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthNetworking;
