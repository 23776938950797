import React, { ReactElement } from "react";
import { InputAutocomplete } from "@components/form";
import { useState } from "@helpers/Store";
import { Link } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface IProps {
  currentStep: number;
  maxStep: number;
  setCurrentStep: (value: number) => void;
  companyDirectionsList: number[];
  networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  required?: boolean;
  updateNetworkingProfile: any;
}

const RegStep3 = ({
  currentStep,
  maxStep,
  setCurrentStep,
  companyDirectionsList,
  networkingProfile,
  required,
  updateNetworkingProfile,
}: IProps): ReactElement => {
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (networkingProfile?.directions?.length > 0) {
      updateNetworkingProfile(networkingProfile);
    } else if (!required) {
      setCurrentStep(currentStep + 1);
    } else {
      let ProgressAlert: any;
      await Middleware.Ui.Alert.open("Process", {
        text: "Выберите хотя бы одно значение",
        component: (c: any) => {
          ProgressAlert = c;
        },
      });
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  };

  return (
    <>
      <section className="cabinet__block">
        <form onSubmit={onSubmitHandler} className="profile-netw-step">
          <div className="profile-netw-step__header">
            <span> Шаг {currentStep}</span> / {maxStep}
          </div>
          <div className="profile-netw-step__body">
            <div className="profile-netw-step__body-header">
              <p className="profile-netw-step__title">
                {required ? <span>* </span> : <></>}Продукт какой дестинации
                представляете или реализуете?
              </p>
              <p className="profile-netw-step__required">
                {required
                  ? "Обязательный пункт"
                  : "Вы можете заполнить этот пункт потом"}
              </p>
            </div>
            <p className="profile-netw-step__desc profile-netw-step__desc--small">
              Отметьте один или несколько пунктов, если вы специализируетесь на
              определенных направлениях. Если вы работаете со всеми
              направлениями, введите «Все направления» в окне поиска
            </p>
            <div className="profile-netw-step__main">
              {networkingProfile?.directions?.length > 0 ? (
                <div className="profile-netw-step__autocomplete-tags">
                  {companyDirectionsList?.map((Item: any, index: number) => {
                    if (networkingProfile?.directions?.includes(Item.id)) {
                      return (
                        <Link
                          key={index}
                          to="#"
                          className="profile-netw-step__autocomplete-tag"
                          onClick={() => {
                            const id = networkingProfile?.directions?.indexOf(
                              Item.id
                            );
                            if (id >= 0) {
                              networkingProfile?.directions?.splice(id, 1);
                            }
                          }}
                        >
                          {Item.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              ) : (
                <></>
              )}

              <InputAutocomplete
                name="directions"
                type="custom"
                list={companyDirectionsList}
                placeholder="Выберите из списка одно или несколько значений"
                clearOnSelect={true}
                onSelect={(event: any) => {
                  if (
                    !networkingProfile?.directions?.includes(
                      event.target.value
                    ) &&
                    event.target.value !== null
                  ) {
                    networkingProfile?.directions?.push(event.target.value);
                  }
                }}
              />
            </div>
          </div>

          <div className="profile-netw-step__footer">
            <button type="submit" className="btn btn--red">
              Дальше
            </button>
            {required ? (
              <></>
            ) : (
              <button
                className="btn btn--transparent"
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                }}
              >
                Пропустить
              </button>
            )}
          </div>
        </form>
      </section>
    </>
  );
};

export default RegStep3;
