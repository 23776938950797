import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class CutawaysPage extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <h1>Визитка #{this.props.match.params.cutaway_id}</h1>
        <main className="main">
          <section className="expertblock">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <div className="expertblock__profile">
                    <div className="expertblock__profile-img">
                      <img src={require("@public/images/expert_img.jpg")} />
                    </div>
                    <div className="expertblock__profile-desc">
                      <div className="expertblock__profile-name">Мария Митохина</div>
                      <div className="expertblock__profile-position">
                        Менеджер по продажам рижского бюро по развитию туризма — LIVE RĪGA
                      </div>
                      <a
                        className="btn expertblock__profile-btn expertblock__profile-btn--red"
                        href="#"
                        onClick={() => {
                          this.props.Dispatch(Middleware.Ui.Modal.open("Event-InviteMeeting", {}));
                        }}
                      >
                        Назначить встречу
                      </a>
                      <a
                        className="btn expertblock__profile-btn expertblock__profile-btn--gray"
                        href="#"
                      >
                        #Обменяться визитками
                      </a>
                    </div>
                  </div>
                  <div className="expertblock__about">
                    <h2>Обо мне</h2>
                    <p>
                      Москва сегодня — это уникальный, динамично развивающийся мегаполис с
                      872-летней историей. Город меняется в соответствии с принципами создания
                      комфортной и безопасной среды и условий для жизни, работы, спорта, отдыха с
                      семьей и друзьями. Совершенствуя город для его жителей, Москва стала
                      привлекательной и дружелюбной для туристов.
                    </p>
                    <p>
                      Подробнее обо всех туристических возможностях — на портале{" "}
                      <a href="#" target="_blank">
                        discover.moscow
                      </a>
                    </p>
                    <h3>Посетить страницу компании:</h3>
                    <a className="expertblock__company" href="#">
                      <div className="expertblock__company-img">
                        <img src={require("@public/images/company_img.png")} />
                      </div>
                      <div className="expertblock__company-txt">
                        Рижское бюро по развитию туризма — LIVE RĪGA
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CutawaysPage)
);
