import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Event extends ModelPrototype {
  public async calendar(filters?: { dateStart: any; dateEnd: any }): Promise<any> {
    try {
      return this.withUrl(`user/calendar/list`).setMethod(Method.GET).request(filters);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async list(filters?: { dateStart: any; dateEnd: any }): Promise<any> {
    try {
      return this.withUrl(`user/event/list`).setMethod(Method.GET).request(filters);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async subscribed(): Promise<any> {
    try {
      return this.withUrl(`user/event/subscribed`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async available(): Promise<any> {
    try {
      return this.withUrl(`user/event/available`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async get(event_id: number): Promise<any> {
    try {
      return this.withUrl(`user/event/${event_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async create(user_id: number, data: {}): Promise<any> {
    try {
      return this.withUrl(`user/${user_id}/event`)
        .setMethod(Method.POST)
        .request({ user_event_form: data });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async invite(user_id: number, event_id: number): Promise<any> {
    try {
      return this.withUrl(`user/${user_id}/event/${event_id}/invite`)
        .setMethod(Method.POST)
        .request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async remove(event_id: number, data: { description: string }): Promise<any> {
    try {
      return this.withUrl(`user/event/${event_id}`)
        .setMethod(Method.PUT)
        .request({
          event_form: {
            canceledDescription: data.description,
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async reject(event_id: number, data: { description: string }): Promise<any> {
    try {
      return this.withUrl(`user/event/${event_id}/reject`)
        .setMethod(Method.PUT)
        .request({
          event_form: {
            statusDescription: data.description,
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async accept(event_id: number): Promise<any> {
    try {
      return this.withUrl(`user/event/${event_id}/accept`).setMethod(Method.PUT).request({
        event_form: {},
      });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async counter(type: string): Promise<any> {
    try {
      return this.withUrl(`user/event/count/${type}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
