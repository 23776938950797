import * as Store from "@actions/index";
import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";
import { useDispatch } from "@helpers/Store";

export interface SignupData {
  email: string;
  profile: {
    firstName: string;
    lastName: string;
    position: string;
    phone: string;
    workName?: string;
  };
  networkingProfile: {
    country: number | null;
    city: number | null;
    employmentType: string;
  };
  companyTypes?: number[];
  plainPassword: {
    first: string;
    second: string;
  };
  agreeTerms: boolean;
}

export default class Auth {
  public static async jwt(data: {
    id: number;
    firstname: string;
    lastname: string;
    avatar: string;
    email: string;
    phone: string;
    position: string;
    company: string;
    company_id: number;
    city: string;
    country_id: number;
    country: string;
  }): Promise<any> {
    try {
      const request = await new Model.AuthJWT().signin(data);

      useDispatch(Store.Auth.setJWT(request));

      return request;
    } catch (Exception) {}
  }

  public static me() {
    return (Dispatch: any) => {
      new Model.Auth()
        .me()
        .then((request: any) => {
          Dispatch(Store.Auth.setData(request.data));
          Dispatch(Store.Auth.setStatus("authorized"));

          Auth.jwt({
            id: request.data?.id,
            firstname: request.data?.firstName,
            lastname: request.data?.lastName,
            avatar: request.data?.userImage,
            email: request.data?.email,
            phone: request.data?.phone,
            position: request.data?.position,
            company: request.data?.id,
            company_id: request.data?.id,
            city: request.data?.networkingProfile?.city,
            country_id: request.data?.networkingProfile?.country,
            country: request.data?.networkingProfile?.country,
          });
        })
        .catch((Exception) => {
          Dispatch(Store.Auth.setStatus("unauthorized"));
        });
    };
  }

  public static checkEmail(
    data: {
      login: string;
    },
    callback: (data: any) => void = (data) => {}
  ) {
    return (Dispatch: any) => {
      new Model.Auth()
        .checkEmail(data)
        .then((request: any) => {
          Dispatch(Store.Auth.setEmail(data.login));
          callback(request.data);
        })
        .catch((Exception) => {
          Dispatch(Store.Auth.setStatus("unauthorized"));
        });
    };
  }

  public static signin(
    data: {
      login: string;
      password: string;
    },
    callback: () => void = () => {}
  ) {
    return (Dispatch: any) => {
      new Model.Auth()
        .signin(data)
        .then((request: any) => {
          new Model.Auth().signinConfirm();
          Dispatch(Store.Auth.setStatus("loading"));
          callback();
          Dispatch(Auth.me());
        })
        .catch((Exception) => {
          Dispatch(Store.Auth.setStatus("unauthorized"));
          let ProgressAlert: any;
          Middleware.Ui.Alert.open("Process", {
            text: "Email или пароль введены неверно",
            component: (c: any) => (ProgressAlert = c),
          });
          ProgressAlert.setStatus("danger");
          ProgressAlert.startTimer();
        });
    };
  }

  public static passwordRestore(
    data: {
      login: string;
    },
    callback: () => void = () => {}
  ) {
    return (Dispatch: any) => {
      new Model.Auth()
        .passwordRestore(data)
        .then((request: any) => {
          callback();
        })
        .catch((Exception) => {});
    };
  }

  public static passwordReset(
    data: {
      password: string;
      token: string;
    },
    callback: () => void = () => {}
  ) {
    return (Dispatch: any) => {
      new Model.Auth().passwordReset(data).then((request: any) => {
        callback();
      });
    };
  }

  public static async signup(
    data: SignupData,
    captcha: string
  ): Promise<boolean> {
    let ProgressAlert: any;

    const handleException = (errorText: string): void => {
      let errorMessage = "An error occurred";

      if (errorText === "user already exist") {
        errorMessage = "Этот имейл уже зарегистрирован";
      } else {
        errorMessage = errorText;
      }
      ProgressAlert.setText(errorMessage);
    };

    Middleware.Ui.Alert.open("Process", {
      text: "Отправляется запрос на регистрацию...",
      component: (c: any) => (ProgressAlert = c),
    });
    try {
      await new Model.Auth().signup({ registration_form: data, captcha });
      ProgressAlert.setText("Регистрация прошла успешно. Проверьте почту.");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      new Model.Auth().signinConfirm().then(() => {
        useDispatch(Auth.me());
      });
      return true;
    } catch (Exception) {
      if (typeof Exception.data?.error === "string") {
        handleException(Exception.data?.error);
      } else {
        ProgressAlert.setText(
          "Не удалось зарегистрироваться. Попробуйте позже."
        );
      }
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      throw Exception;
    }
  }

  public static async session() {
    try {
      const Token = await new Model.Auth().session();
      return Token.data;
    } catch (Exception) {}
  }

  public static signout(callback: () => void = () => {}) {
    return (Dispatch: any) => {
      let ProgressAlert: any;
      Middleware.Ui.Alert.open("Process", {
        text: "Завершаем сессию...",
        component: (c: any) => (ProgressAlert = c),
      });
      new Model.Auth()
        .signout()
        .then((request: any) => {
          callback();
          Dispatch(Store.Auth.setStatus("unauthorized"));
          Dispatch(Store.Auth.setData(null));
          ProgressAlert.setText("Вы успешно вышли");
          ProgressAlert.setStatus("success");
          ProgressAlert.startTimer();
        })
        .catch((Exception) => {
          ProgressAlert.setText("Что то пошло не так!");
          ProgressAlert.setStatus("danger");
          ProgressAlert.startTimer();
        });
    };
  }
}
