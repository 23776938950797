import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, Switch, withRouter } from "react-router-dom";
import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";
import Sidebar from "@components/company/Sidebar";

import * as Pages from ".";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class RouterComponent extends React.Component<PropsInterface, StateInterface, ComponentInterface> {
  public componentDidMount() {
    Middleware.Company.Employee.load(Number(this.props.match.params.company_id));
  }

  public render() {
    return (
      <>
        <Sidebar>
          <div className="company-employees">
            <h1 className="company-cabinet__title">Сотрудники компании</h1>
            {this.props.Store.Company.Employee.list?.filter(
              (Employee: any) => Employee.userRequestStatus === 0
            ).length ? (
              <div className="company-employees__tabs-block">
                <div className="company-employees__tabs-nav">
                  <Link
                    className={`company-employees__tabs-nav-item ${
                      this.props.location.pathname.endsWith("inbox")
                        ? "company-employees__tabs-nav-item--active"
                        : ""
                    }`}
                    to={`/company/${Number(this.props.match.params.company_id)}/employees/inbox`}
                  >
                    Новые запросы
                  </Link>
                  {/* <Link
                  className={`company-employees__tabs-nav-item ${
                    this.props.location.pathname.endsWith("declined")
                      ? "company-employees__tabs-nav-item--active"
                      : ""
                  }`}
                  to={`/company/${Number(this.props.match.params.company_id)}/employees/declined`}
                >
                  Отклоненные
                </Link> */}
                </div>
                <div className="company-cabinet__block company-employees__tabs-body">
                  <Switch>
                    <Router
                      path="/company/:company_id/employees/inbox"
                      exact={true}
                      component={Pages.Inbox}
                    />
                    <Router
                      path="/company/:company_id/employees/declined"
                      exact={true}
                      component={Pages.Declined}
                    />
                  </Switch>
                </div>
              </div>
            ) : null}
            <div className="company-cabinet__block">
              <div className="company-employees__approved">
                <div className="company-employees__approved-title">Подтвержденные</div>
                <div className="company-employees__approved-list">
                  {this.props.Store.Company.Employee.list
                    ?.filter((Employee: any) => Employee.userRequestStatus === 1)
                    .map((Employee: any, index: number) => {
                      return (
                        <>
                          {index !== 0 ? <hr /> : <></>}
                          <div className="company-employees__approved-item">
                            <div className="company-employees__approved-item-body">
                              <div className="company-employees__approved-item-img">
                                <img src={Employee.userImage} alt="" />
                              </div>
                              <div className="company-employees__approved-item-name">
                                {Employee.firstName + " " + Employee.lastName}{" "}
                              </div>
                              <div className="company-employees__approved-item-position">
                                {Employee.position}
                              </div>
                              {Employee.isAdmin ? (
                                <div className="company-employees__approved-item-role">
                                  Администратор
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="company-employees__approved-item-buttons">
                              {/* <Link
                                to="#"
                                className="company-employees__approved-item-btn company-employees__approved-item-btn--visibility"
                              >
                                <img
                                  src={require("@public/images/employee-eye-closed.svg")}
                                  alt=""
                                />
                                Скрыть на стенде
                              </Link> */}
                              <Link
                                to="#"
                                className="company-employees__approved-item-btn company-employees__approved-item-btn--role"
                                onClick={() => {
                                  Middleware.Company.Employee.setIsAdmin(
                                    Number(this.props.match.params.company_id),
                                    Employee.id,
                                    !Employee.isAdmin
                                  );
                                }}
                              >
                                {Employee.isAdmin ? (
                                  <>Разжаловать администратора</>
                                ) : (
                                  <>Сделать администратором</>
                                )}
                              </Link>
                              <Link
                                to="#"
                                className="company-employees__approved-item-btn company-employees__approved-item-btn--delete"
                                onClick={() => {
                                  Middleware.Company.Employee.remove(
                                    Number(this.props.match.params.company_id),
                                    Employee.id
                                  );
                                }}
                              >
                                Удалить
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* //{" "}
                  <div className="company-employees__approved-item">
                    //{" "}
                    <div className="company-employees__approved-item-body">
                      //{" "}
                      <div className="company-employees__approved-item-img">
                        // <img src={require("@public/images/avatar.png")} alt="" />
                        //{" "}
                      </div>
                      //{" "}
                      <div className="company-employees__approved-item-name">
                        // Евгения Скакун //{" "}
                        <img src={require("@public/images/employee-eye-closed.svg")} alt="" />
                        //{" "}
                      </div>
                      //{" "}
                      <div className="company-employees__approved-item-position">
                        // Куратор отдела производства //{" "}
                      </div>
                      // <div className="company-employees__approved-item-role"></div>
                      //{" "}
                    </div>
                    //{" "}
                    <div className="company-employees__approved-item-buttons">
                      //{" "}
                      <Link to="#" className="company-employees__approved-item-btn company-employees__approved-item-btn--visibility">
                        // <img src={require("@public/images/employee-eye.svg")} alt="" />
                        // Отобразить на стенде //{" "}
                      </Link>
                      //{" "}
                      <Link to="#" className="company-employees__approved-item-btn company-employees__approved-item-btn--role">
                        // Назначить администратора //{" "}
                      </Link>
                      //{" "}
                      <Link to="#" className="company-employees__approved-item-btn company-employees__approved-item-btn--delete">
                        // Удалить //{" "}
                      </Link>
                      //{" "}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Sidebar>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
