import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hashtag from "./Hashtag";
import Partners from "./Partners";
import styles from "./Header.module.scss";
import { useSelector } from "react-redux";

const Header: React.FC = () => {
  const currentTariff = useSelector((state: any) => state.Profile?.data.tariff);
  const authStatus = useSelector((state: any) => state.Auth.status);
  const [isAuth, setIsAuth] = useState(false);
  const [hasTariff, setHasTariff] = useState(false);

  useEffect(() => {
    if (currentTariff === null) {
      setHasTariff(false);
    } else {
      setHasTariff(true);
    }
  }, [currentTariff]);

  useEffect(() => {
    if (authStatus === "authorized") {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [authStatus]);

  return (
    <div className="w-full lg:min-h-[650px] h-calc-vh-full-70 flex flex-col justify-between m-auto bg-grey-f5">
      <header className="h-full">
        <div className={`cont ${styles.header}`}>
          <div className=" flex flex-col gap-6 md:gap-8 lg:gap-10">
            <div className="flex justify-between items-center">
              <p className="font-bold text-14 md:text-24 lg:text-32 xl:text-36 tracking-tighter-15 md:tracking-normal">
                10 – 28 февраля
              </p>
              <div className="flex items-center border-[2px] bg-white text-14 py-[6px] px-[15px] content-center md:text-24 border-solid border-red-500 md:px-[32px] h-[30px] sm:h-[50px] rounded-full">
                <p className="tracking-tighter-15 md:tracking-normal">
                  ОТМ: SUMMER 2025 
                </p>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 md:gap-6 lg:gap-[29px] xl:gap-[34px] 2xl:gap-[36.5px] 3xl:gap-8 2xl:flex-nowrap">
              <Hashtag text="ПРОДУКТ" />
              <Hashtag text="ПРОДАЖИ" />
              <Hashtag text="ПРОДВИЖЕНИЕ" />
            </div>
            <div className="flex justify-between">
              <p className="xl:text-24 md:text-18">
                Онлайн-интенсив для турагентов <br /> по зарубежному турпродукту
                <br />
                <span className="text-red-500">в сезоне лето-2025</span>
              </p>

              {hasTariff && isAuth ? (
                <></>
              ) : (
                <img
                  className="hidden md:flex w-[89px] h-[88px]"
                  src={require("@public/images/header/arrow.svg")}
                  alt="Стрелочка"
                />
              )}
            </div>
          </div>

          {hasTariff && isAuth ? (
            <></>
          ) : (
            <Link
              className="btn btn--reg btn--large md:text-24 w-full"
              to={"?anchor=price-anchor"}
            >
              Участвовать
            </Link>
          )}
        </div>
      </header>
      <div className="w-full bg-white">
        <Partners />
      </div>
    </div>
  );
};

export default Header;
