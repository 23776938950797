import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import { Input, InputAutocomplete, Modificator, Select } from "@components/form/index";

interface PropsInterface extends Type.ComponentInterface {
  hash: string;
  onChange: (Office: StateInterface) => void;
}

interface StateInterface {
  hash: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  country_id: number | null;
  city_id: number | null;
  username: string;
  userposition: string;
  required: boolean;
}

class RatingPage extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      hash: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      country_id: 1,
      city_id: null,
      username: "",
      userposition: "",
      required: false,
    };
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidMount() {
    this.setState({ hash: this.props.hash });
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.props.onChange(this.state);
      if (
        this.state.name.length ||
        this.state.email.length ||
        this.state.phone.length ||
        this.state.address.length ||
        this.state.username.length ||
        this.state.userposition.length
      ) {
        this.setState({ required: true });
      } else {
        this.setState({ required: false });
      }
    });
  }

  public render() {
    return (
      <>
        <div className="form__group form__group--address">
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Название офиса"
            value={this.state.name}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-mail офиса"
            value={this.state.email}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
          <Input
            type="text"
            name="phone"
            id="phone"
            value={this.state.phone}
            onChange={this.onChangeHandler}
            placeholder="Телефон офиса"
            modificator={Modificator.PhoneWithPrefix}
            required={this.state.required}
          />
        </div>
        <hr />
        <div className="form__group ">
          <Select
            name="country_id"
            value={this.state.country_id}
            options={this.props.Store.Location.Country.list}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
          <InputAutocomplete
            name="city_id"
            type="city"
            country_id={this.state.country_id}
            placeholder="Город"
            onSelect={this.onChangeHandler}
            required={this.state.required}
          />
          <Input
            type="text"
            name="address"
            placeholder="Адрес офиса"
            value={this.state.address}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
        </div>

        <hr />
        <div className="form__group">
          <div className="company-create__step-5-subtitle">Контактное лицо</div>
        </div>
        <div className="form__group form__group--span-2">
          <Input
            type="text"
            name="username"
            placeholder="Имя"
            value={this.state.username}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
          <Input
            type="text"
            name="userposition"
            placeholder="Должность"
            value={this.state.userposition}
            onChange={this.onChangeHandler}
            required={this.state.required}
          />
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RatingPage)
);
