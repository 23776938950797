import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Model from "@models/index";
import { Method } from "@models/ModelPrototype";
import InputComponent from "./components/input";
import { Link } from "react-router-dom";

import Jaas from "@components/jaas/Player";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class TestPage extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount(): void {
    document.title = "Test Video" + " | " + process.env.SITE_NAME;
  }

  public render() {
    return (
      <>
        <div className="page-videotest">
          <Jaas
            User={{
              id: 1,
              name: String("Test User"),
              email: "test@test.local",
              avatar: "",
              moderator: true,
            }}
          />
        </div>
      </>
    );
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(TestPage);
