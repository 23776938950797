/* eslint-disable @typescript-eslint/indent */
import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class NetworkingMember extends ModelPrototype {
  public async page(
    page: number,
    filters?: { specializations?: number[]; types?: number[]; direction?: number; city?: number[] },
    options: { onlyLinkedToWorkshop?: number } = {}
  ): Promise<{ data: any }> {
    try {
      if (!filters) {
        filters = {};
      }

      const validatedDataRequest: any = {
        page,
      };

      if (filters.specializations) {
        validatedDataRequest.specializationList = filters.specializations;
      }

      if (filters.types) {
        validatedDataRequest.typeList = filters.types;
      }

      if (filters.direction) {
        validatedDataRequest.direction = filters.direction;
      }

      if (filters.city) {
        validatedDataRequest.cityId = filters.city;
      }

      if (
        options.onlyLinkedToWorkshop !== undefined &&
        +options.onlyLinkedToWorkshop === 1
      ) {
        validatedDataRequest.onlyLinkedToWorkshop = 1;
      }

      return this.withUrl(`networking/member/list`)
        .setMethod(Method.GET)
        .request(validatedDataRequest);
    } catch (Exception) {
      throw Exception;
    }
  }
}
