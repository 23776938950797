import React, { ReactElement } from "react";
import * as Type from "@interface/index";
import ScheduleTableDayLayout from "./ScheduleTableDayLayout";
import ScheduleTableEvent from "./ScheduleTableEvent";
import moment from "moment-timezone";

const ScheduleTableDay = (props: any): ReactElement => {
  const currentDate = props.currentDate;

  return (
    <div
      id={"date_" + moment(currentDate).tz("Europe/Moscow").format("DDMM")}
      className={`schedule__table-day `}
    >
      <div
        className={`schedule__table-header ${
          props.events.length > 0
            ? props.events[0]?.networkingDay
              ? "schedule__table-header--networking-day"
              : ""
            : ""
        }`}
      >
        <div>
          <span>
            {
              moment(props.currentDay)
                .tz("Europe/Moscow")
                .format("DD MMMM, dd")
                .split(",")[0]
            }
            {", "}
          </span>
          <span className="uppercase">
            {moment(props.currentDay).tz("Europe/Moscow").format("dd")}
          </span>
        </div>
      </div>
      <ScheduleTableDayLayout
        scheduleWizard={props.scheduleWizard}
        currentDate={currentDate}
      />

      <div className="schedule__table-item-wrapper">
        {props.events.length > 0
          ? props.events.map((event: any, index: number) => {
              return (
                <ScheduleTableEvent
                  key={index}
                  event={event}
                  scheduleWizard={props.scheduleWizard}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ScheduleTableDay;
