import React, { useEffect, useState } from "react";
import MailAndPhoneForm from "./MailAndPhoneForm";
import NameAndAddressForm from "./NameAndAddressForm";
import CompanyForm from "./CompanyForm";
import PasswordForm from "./PasswordForm";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";
import * as Model from "@models/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignupData } from "@store/middlewares/Auth";

export interface RegistrationProps {
  Store?: any;
  data: {
    [key: string]: string | undefined;
  };
  onChange: (field: string, value: string) => void;
}

const Registration: React.FC = (Store: any) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [captchaSelected, setCaptchaSelected] = useState(null);
  const [captcha, setCaptcha] = useState({ message: "", itemList: [] });
  const [loadingCaptcha, setLoadingCaptcha] = useState<boolean>(false); // Идет ли загрузка новой капчи
  const dispatch = useDispatch();
  const [profileTariff, setProfileTariff] = useState(null);
  const authStatus = useSelector((state: any) => state.Auth.status);

  useEffect(() => {
    dispatch(Middleware.Ui.Footer.hide());
    document.title = `Signup | ${process.env.SITE_NAME}`;
    return () => {
      dispatch(Middleware.Ui.Footer.show());
    };
  }, [dispatch]);

  const defaultEmptyFormData = {
    personalInfo: { email: Store?.Store?.Auth?.email || "", phone: "" },
    userInfo: {
      firstName: "",
      lastName: "",
      countryId: "",
      cityId: "",
      city: "",
    },
    companyInfo: {
      nameCompany: "",
      typeCompany: [],
      companyPosition: "",
      position: "",
      employmentType: "1", // 1- работает в компании, 2 - работает на себя
    },
    security: { password: "", confirmedPassword: "" },
  };

  const [formData, setFormData] = useState(defaultEmptyFormData);

  const steps = [
    {
      id: "personalInfo",
      label: "Регистрация",
      component: MailAndPhoneForm,
      data: formData.personalInfo,
    },
    {
      id: "userInfo",
      label: "Ваши данные",
      component: NameAndAddressForm,
      data: formData.userInfo,
    },
    {
      id: "companyInfo",
      label: "О компании",
      component: CompanyForm,
      data: formData.companyInfo,
    },
    {
      id: "security",
      label: "Пароль",
      component: PasswordForm,
      data: formData.security,
    },
  ];

  const CurrentForm = steps[currentStep].component;

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const prepareData = () => {
    return {
      email: formData.personalInfo.email,
      profile: {
        firstName: formData.userInfo.firstName,
        lastName: formData.userInfo.lastName,
        position: formData.companyInfo.position,
        phone: formData.personalInfo.phone.replace(/[^+\d]/g, ""),
        ...(formData.companyInfo.employmentType !== "2" && {
          workName: formData.companyInfo.nameCompany,
        }),
      },
      networkingProfile: {
        country: Number(formData.userInfo.countryId),
        city: Number(formData.userInfo.cityId),
        employmentType: formData.companyInfo.employmentType,
      },
      ...(formData.companyInfo.employmentType !== "2" && {
        companyTypes: formData.companyInfo.typeCompany,
      }),
      plainPassword: {
        first: formData.security.password,
        second: formData.security.confirmedPassword,
      },
      agreeTerms: true,
    };
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    nextStep();

    if (currentStep === steps.length - 1) {
      const data: SignupData = prepareData();
      const submit = async () => {
        try {
          await Middleware.Auth.signup(data, String(captchaSelected));

          setFormData(defaultEmptyFormData);

          const profile = await Middleware.Profile.load();
          setProfileTariff(profile.tariff);
        } catch (Exception: any) {
          console.log("Ошибка: ", Exception);
          if (Exception.data?.error === "Вы выбрали неправильную фигуру.") {
            setCaptchaSelected(null);
            setCaptcha({ message: "", itemList: [] });
            loadCaptcha();
          }
        }
      };
      submit();
    }
  };

  const loadCaptcha = async () => {
    setLoadingCaptcha(true);
    try {
      const captchaResponse = await new Model.Captcha().get();
      setCaptcha(captchaResponse.data);
    } catch (error) {
      console.error("Ошибка при загрузке капчи:", error);
    } finally {
      setLoadingCaptcha(false);
    }
  };

  const onChangeHandle = (field: string, value: string) => {
    const currentSection = steps[currentStep].id as keyof typeof formData;

    setFormData((prev) => ({
      ...prev,
      [currentSection]: {
        ...prev[currentSection],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    if (authStatus === "authorized") {
      if (profileTariff === null || profileTariff === undefined) {
        history.push("/tariffs-and-payment");
      } else {
        history.push(Store.Auth.preurl);
      }
    }
  }, [authStatus, profileTariff]);

  return (
    <div className="auth auth--signup">
      <div className="auth__wrapper auth__wrapper--big">
        <form onSubmit={onSubmitHandler}>
          <div className="form__header-with-steps">
            <h1>{steps[currentStep].label}</h1>
            <div className="auth__steps">
              <span className="auth__steps--red">Шаг {currentStep + 1}</span>
              <span>/ {steps.length}</span>
            </div>
          </div>
          <CurrentForm
            data={steps[currentStep].data}
            onChange={onChangeHandle}
            {...(steps[currentStep].id === "security" && {
              captchaSelected,
              setCaptchaSelected,
              captcha,
              loadCaptcha,
              loadingCaptcha,
            })}
          />
          <div className="auth__footer--gap">
            {currentStep !== 0 && (
              <button
                type="button"
                className="btn btn--grey btn--grey--bold auth__btn"
                onClick={prevStep}
              >
                Назад
              </button>
            )}
            {currentStep !== steps.length - 1 ? (
              <button type="submit" className="btn btn--red auth__btn">
                Далее
              </button>
            ) : (
              <button type="submit" className="btn btn--red auth__btn">
                Зарегистрироваться
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Connector(Registration);
