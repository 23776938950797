import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Captcha extends ModelPrototype {
  public async get(): Promise<any> {
    try {
      return this.withUrl(`captcha/request`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
