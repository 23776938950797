import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";
import { IUser } from "@interface/entities";
import { ITariff } from "@pages/dashboard/components/Price";

// TODO: вынести интерфейсы, много где нужны
// TODO: вынести проверку, которая определяет disabled для кнопки "Купить"
// TODO: поправить плашку "Ваш тариф" в адаптивах, после правки дизайна

interface Description {
  text: string;
  description: string;
}

interface IProps {
  promoDate: string;
  tariffs: ITariff[];
  changeStep: (size: number) => void;
}

const Step1: React.FC<IProps> = ({ promoDate, tariffs, changeStep }) => {
  const currentUserData: IUser | null | undefined = useSelector(
    (state: any) => state.Auth?.data
  );

  const now = moment().tz("Europe/Moscow").format();
  const userTariff =
    typeof currentUserData?.tariff !== "number" ? 0 : currentUserData?.tariff;

  return (
    <div className="payment__wrapper">
      <div className="payment__header">
        <h1 className="payment__title">Оплата онлайн-интенсива</h1>
        <div className="payment__desc">
          <p>
            Чтобы получить полный доступ к онлайн-интенсиву «Продукт. Продажи.
            Продвижение» – оплатите тариф рублями или баллами Loyalty.
          </p>
          <Link
            to="#"
            className="payment__header-link btn btn--red-underline"
            onClick={(event) => {
              event.preventDefault();
              Middleware.Ui.Modal.open("LoyaltyPointsInTariffs", {
                callback: "",
              });
            }}
          >
            Что такое баллы Loyalty и как их получить?
          </Link>
        </div>
      </div>
      <form action="" className="payment__form payment__form--horizontal">
        {tariffs?.map((item: any) => {
          return (
            <div className="payment__tariff tariff" key={item.id}>
              {/*setTariff(item.id); */}
              {userTariff === item.id ? (
                <div className="tariff__tag">Ваш тариф</div>
              ) : null}
              <div className="tariff__header">
                <h3 className="tariff__title">{item.name}</h3>
                <div className="tariff__old-price">
                  <span>
                    {now < promoDate ? item.price.rub : item.newPrice.rub}
                    &nbsp;₽
                  </span>
                  или{" "}
                  {now < promoDate
                    ? item.price.loyalty
                    : item.newPrice.loyalty}{" "}
                  Loyalty
                </div>
              </div>
              <div className="tariff__divider" />
              <div className="tariff__description">
                <ul className="tariff__features">
                  {item.description.map((item: Description, index: number) => {
                    return (
                      <li className="tariff__features-item" key={index}>
                        {item.text}
                      </li>
                    );
                  })}
                </ul>
                <Link
                  to="#"
                  className="tariff__description-link btn btn--red-underline"
                  onClick={(event) => {
                    event.preventDefault();
                    Middleware.Ui.Modal.open("TariffInfo", {
                      callback: changeStep,
                      item,
                      userTariff,
                    });
                  }}
                >
                  Подробнее о тарифе
                </Link>
              </div>
              <button
                className={`btn btn--red btn--medium tariff__btn ${
                  0 < userTariff && userTariff >= item.id ? "disabled" : ""
                }`}
                onClick={(event) => {
                  if (0 < userTariff && userTariff >= item.id) {
                    event.preventDefault();
                  } else {
                    Middleware.Payment.tariff(item.id);
                    changeStep(1);
                    window.scrollTo(0, 0);
                  }
                }}
              >
                Купить
              </button>
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default Step1;
