import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import * as Components from "./components";
import * as Middleware from "@middlewares/index";
import Sidebar from "@components/company/Sidebar";
import { Helmet } from "react-helmet";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class FreeCompanyPage extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount() {
    Middleware.Company.Current.load(Number(this.props.match.params.company_id), true);
    Middleware.Company.Direction.load();
    Middleware.Company.Type.load();
    window.scrollTo(0, 0);
  }

  public render() {
    return (
      <>
        <Helmet
          title={`${
            this.props.Store.Company.Current.data?.title
              ? this.props.Store.Company.Current.data?.title
              : "Компания"
          } | OTM: SUMMER 2021`}
          meta={[
            {
              name: "description",
              content: `Компания «${this.props.Store.Company.Current.data?.title}» в нетворкинге OTM: SUMMER 2021`,
            },
          ]}
        />
        <div className="stand stand--free">
          <div className="stand__grid">
            <div className="stand__header">
              <Components.Title />
            </div>
            <div className="stand__body">
              <div className="stand__left-col">
                <Components.About />
                <Components.Contacts />
              </div>
              <div className="stand__right-col">
                <Components.Members />
                <Components.Specializations />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(FreeCompanyPage)
);
