import React from "react";

interface Hotels {
  name: string;
  description: string;
}

interface PrizeItemProps {
  index: number;
  image: string;
  tariffs: string[];
  name: string;
  title: string;
  hotels: Hotels[];
  imageWebp: string;
}

const PrizeItem = ({
  index,
  image,
  imageWebp,
  tariffs,
  name,
  title,
  hotels,
}: PrizeItemProps): JSX.Element => {
  return (
    <div className="flex flex-col lg:flex-row bg-white rounded-xl lg:flex-row">
      <picture
        className="object-cover shrink-0 w-auto h-[250px] rounded-xl md:h-[340px] lg:h-[443px] lg:w-[456px] xl:h-[430px] xl:w-[544px] 2xl:w-[624px] 3xl:h-[400px] 3xl:w-[704px] 4xl:w-[744px]"
        key={index}
      >
        <source
          srcSet={require(`@public/images/prizes/${imageWebp}`)}
          type="image/webp"
        />
        <img
          className="object-cover shrink-0 w-auto h-[250px] rounded-xl md:h-[340px] lg:h-[443px] lg:w-[456px] xl:h-[430px] xl:w-[544px] 2xl:w-[624px] 3xl:h-[400px] 3xl:w-[704px] 4xl:w-[744px]"
          src={require(`@public/images/prizes/${image}`)}
          alt="Курортный пейзаж"
        />
      </picture>
      <div className="p-5 md:p-10 w-full">
        <div className="flex flex-col gap-2 md:flex-row justify-between">
          <p className="text-24 md:text-36 font-bold text-nowrap">
            {title}
          </p>
          <div className="flex flex-wrap gap-2 md:justify-end">
            {tariffs.map((tariff, index) => (
              <div
                key={index}
                className="flex items-center text-sm border-[1px] border-solid border-red-500 rounded-full px-3 md:px-6 lg:px-5 2xl:px-6 h-[26px] md:h-8 lg:h-6 2xl:h-8"
              >
                <p className="leading-[18.2px]">{tariff}</p>
              </div>
            ))}
          </div>
        </div>
        <p className="text-neutral-gray text-18 md:text-24 !mt-4 md:!mt-3">
          {name}
        </p>
        <hr className="border-gray border-solid border-[1px] !my-4 md:!my-8" />
        <div className="flex flex-col gap-4 md:gap-8">
          {hotels.map((hotel, index) => (
            <div key={index} className="flex flex-col gap-2 text-18">
              <p className="text-red-500 font-bold md:text-24">{hotel.name}</p>
              <p>{hotel.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrizeItem;
