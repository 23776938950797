import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import Clickoutside from "@helpers/Clickoutside";
import Logo from "./header/Logo";
import Sign from "./header/Sign";
import { PreloaderComponent } from "./preloader";
import { useCurrentTariff } from "@hooks/useCurrentTariff";
import { TariffBanner } from "./header/TariffBanner";

const Header: React.FC = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   Middleware.Networking.Days.get();
  // }, []);

  const authData = useSelector((state: any) => state.Auth?.data);
  const profileData = useSelector((state: any) => state.Profile?.data);
  const currentTariff = useCurrentTariff();
  const userEvent = useSelector((state: any) => state.User?.Event);
  const userCutaway = useSelector((state: any) => state.User?.Cutaway);
  const userCount = useSelector((state: any) => state.Main.User?.count);
  const userRatingData = useSelector((state: any) => state.UserRating);
  const authStatus = useSelector((state: any) => state.Auth?.status);
  const headerDisplay = useSelector((state: any) => state.Ui.Header.display);
  const userbarDisplay = useSelector((state: any) => state.Ui.Userbar.display);
  const netwDays = useSelector((state) => state.Networking?.Days.data);
  const userNameRef = useRef<HTMLAnchorElement>(null);

  const userRating =
    userRatingData.status === "loaded"
      ? userRatingData.data?.thisYearRating
      : null;

  useEffect(() => {
    if (authStatus === "authorized") {
      Middleware.Profile.load();
      Middleware.UserRating.load();
      Middleware.User.Event.counter("incoming");
      Middleware.User.Cutaway.counter("incoming");
    }
  }, [authStatus]);

  if (!headerDisplay) {
    return null;
  }

  const shortedNameOrEmail =
    authData?.firstName || authData?.lastName
      ? `${authData?.firstName ?? ""} ${authData?.lastName?.[0] ?? ""}${
          authData?.lastName?.length > 1 ? "." : ""
        }`
      : authData?.email || null;

  const tariffBannerText = currentTariff
    ? `Тариф «${currentTariff.name}»`
    : "Баллы Loyalty";

  return (
    <>
      <nav className="header__nav main-nav main-nav--gray">
        <div className="cont">
          <div className="main-nav__left">
            <Link className="main-nav__logo" to="/">
              <Logo />
            </Link>
            <hr />
            <ul className="main-nav__menu">
              <li className="main-nav__menu-item">
                <Link to="/?anchor=about">Об интенсиве</Link>
              </li>
              <li className="main-nav__menu-item">
                <Link to="/?anchor=schedule-anchor">Расписание</Link>
              </li>
              <li className="main-nav__menu-item">
                <Link to="/?anchor=stands-anchor">Стенды</Link>{" "}
              </li>
              <li className="main-nav__menu-item">
                <Link to="/?anchor=prizes-anchor">Призы</Link>{" "}
              </li>
              <li className="main-nav__menu-item">
                <Link to="/?anchor=price-anchor">Тарифы</Link>{" "}
              </li>
              <li className="main-nav__menu-item">
                <Link to="/networking">Нетворкинг</Link>{" "}
              </li>
            </ul>
          </div>

          <div className="hidden xl:block main-nav__counter">
            <span>{userCount}</span> коллег на выставке
          </div>

          {authStatus === "loading" ? (
            <div className="main-nav__user-nav main-nav__user-nav--loading">
              <PreloaderComponent />
            </div>
          ) : authStatus === "authorized" ? (
            <ul className="main-nav__user-nav main-nav__user-nav--logged !mr-5">
              <li className="hidden md:flex main-nav__user-nav-item">
                <TariffBanner text={tariffBannerText} rating={userRating} />
              </li>
              <li className="hidden lg:flex main-nav__user-nav-item main-nav__user-nav-item--name">
                <Link
                  to="#"
                  onClick={() => {
                    Middleware.Ui.Userbar.show();
                  }}
                  ref={userNameRef}
                >
                  {shortedNameOrEmail && (
                    <span className="text-black">{shortedNameOrEmail}</span>
                  )}
                  <img
                    src={require("@public/images/header/user-icon.svg")}
                    alt="Иконка пользователя"
                  />
                </Link>
              </li>
            </ul>
          ) : (
            <div className="hidden lg:flex">
              <Sign />
            </div>
          )}

          {/* hamburger */}
          <Link
            className="main-nav__menu-hamburger dropdown-menu-trigger w-8 h-8"
            to="#"
            onClick={() => {
              Middleware.Ui.Userbar.show();
            }}
          >
            <img
              className="pointer-events-none "
              src={require("@public/images/header/burger-menu.svg")}
              alt="Меню"
            />
          </Link>
        </div>

        <Clickoutside
          exceptionRef={userNameRef}
          clickOutside={() => Middleware.Ui.Userbar.hide()}
        >
          <div
            className={
              "main-nav__dropdown " +
              (userbarDisplay ? "main-nav__dropdown--open" : "")
            }
          >
            <div className="flex gap-4 !mb-5">
              <Logo />
              <div className="relative w-8 h-8 flex justify-center items-center ml-auto">
                <Link
                  className="main-nav__dropdown-close dropdown-menu-trigger"
                  to="#"
                  onClick={() => {
                    Middleware.Ui.Userbar.hide();
                  }}
                />
              </div>
            </div>

            {authStatus !== "authorized" ? (
              <div
                className="!mt-5"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                <Sign onClose={() => Middleware.Ui.Userbar.hide()} />
              </div>
            ) : (
              <>
                <hr className="!my-3" />
                <div className="main-nav__dropdown-list">
                  <Link
                    to={`/user/${authData?.id}`}
                    onClick={() => {
                      Middleware.Ui.Userbar.hide();
                    }}
                    className="main-nav__dropdown-name flex"
                  >
                    <div className="flex gap-2 items-center">
                      <img
                        className="pointer-events-none"
                        src={require("@public/images/header/user-icon.svg")}
                        alt="Иконка пользователя"
                      />
                      {authData?.firstName
                        ? authData?.firstName + " " + authData?.lastName
                        : authData?.email}
                    </div>
                  </Link>
                  <TariffBanner text={tariffBannerText} rating={userRating} />
                </div>
              </>
            )}

            <div className="flex items-center gap-2.5 h-[14px] !my-3">
              <span className="text-sm text-neutral-gray">Главная</span>
              <hr />
            </div>

            <div className="main-nav__dropdown-list">
              <Link
                className="main-nav__dropdown-item"
                to="/?anchor=about"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Об интенсиве
              </Link>

              <Link
                className="main-nav__dropdown-item"
                to="/?anchor=schedule-anchor"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Расписание
              </Link>

              <Link
                className="main-nav__dropdown-item"
                to="/?anchor=stands-anchor"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Стенды
              </Link>

              <Link
                className="main-nav__dropdown-item"
                to="/?anchor=prizes-anchor"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Призы
              </Link>

              <Link
                className="main-nav__dropdown-item"
                to="/?anchor=price-anchor"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Тарифы
              </Link>

              <Link
                className="main-nav__dropdown-item"
                to="/networking"
                onClick={() => {
                  Middleware.Ui.Userbar.hide();
                }}
              >
                Нетворкинг
              </Link>
            </div>

            {authStatus === "authorized" && (
              <>
                <div className="flex items-center gap-2.5 h-[14px] !my-3">
                  <span className="text-sm text-neutral-gray">
                    Личный&nbsp;кабинет
                  </span>
                  <hr />
                </div>

                <div className="main-nav__dropdown-list">
                  {profileData?.useNetworking &&
                    profileData?.networkingFillStatus === 3 && (
                      <>
                        {netwDays?.length > 0 && (
                          <Link
                            className="main-nav__dropdown-item"
                            to="/cabinet/events"
                            onClick={() => {
                              Middleware.Ui.Userbar.hide();
                            }}
                          >
                            Мои встречи
                            {+userEvent.incomingCounter > 0 && (
                              <span className="main-nav__dropdown-item-counter">
                                + {userEvent.incomingCounter}
                              </span>
                            )}
                          </Link>
                        )}
                        <Link
                          className="main-nav__dropdown-item"
                          to="/cabinet/cutaways"
                          onClick={(e) => {
                            Middleware.Ui.Userbar.hide();
                            // e.preventDefault();
                            // Middleware.Ui.Modal.open("RegistrationClosed");
                          }}
                        >
                          Визитки
                          {+userCutaway?.incomingCounter > 0 ? (
                            <span className="main-nav__dropdown-item-counter">
                              {userCutaway?.incomingCounter}
                            </span>
                          ) : null}
                        </Link>
                      </>
                    )}

                  <Link
                    className="main-nav__dropdown-item"
                    to="/cabinet/rating"
                    onClick={() => {
                      Middleware.Ui.Userbar.hide();
                    }}
                  >
                    Баллы и рейтинг
                    {userRating !== null && (
                      <span className="main-nav__dropdown-item-counter">
                        {userRating}
                      </span>
                    )}
                  </Link>
                  <Link
                    className="main-nav__dropdown-item"
                    to="/cabinet/profile"
                    onClick={() => {
                      Middleware.Ui.Userbar.hide();
                    }}
                  >
                    Контактные данные
                  </Link>
                  <Link
                    className="main-nav__dropdown-item"
                    to={`${
                      profileData?.useNetworking &&
                      profileData?.networkingFillStatus === 3
                        ? "/cabinet/networking/profile"
                        : profileData?.useNetworking &&
                          profileData?.networkingFillStatus < 3
                        ? "/cabinet/networking/reg"
                        : "/cabinet/networking"
                    }`}
                    onClick={() => {
                      Middleware.Ui.Userbar.hide();
                    }}
                  >
                    Профиль в нетворкинге
                  </Link>
                  {profileData?.useNetworking &&
                  profileData?.networkingFillStatus === 3 ? null : (
                    <Link
                      className="main-nav__dropdown-item"
                      to="/cabinet/networking"
                    >
                      Визитки
                    </Link>
                  )}
                </div>
              </>
            )}

            {/* Блок "Моя компания". Скрыто, т.к. не влезает на мобилке */}
            {/* {authData?.company && (
              <>
                <div className="flex items-center gap-2.5 h-[14px] !my-3">
                  <span className="text-sm text-neutral-gray">
                    Моя&nbsp;компания
                  </span>
                  <hr />
                </div>

                <div className="main-nav__dropdown-company main-nav__dropdown-company--added">
                  <Link
                    to={
                      authData?.isAdmin
                        ? `/company/${authData?.company?.id}/edit`
                        : profileData?.companyStand
                        ? `/company/stand/${profileData?.companyStand}`
                        : `/company/${profileData?.company?.id}`
                    }
                    className=" main-nav__dropdown-company-logos flex flex-col items-start"
                    onClick={() => {
                      Middleware.Ui.Userbar.hide();
                    }}
                  >
                    <div className="text-sm underline">
                      {authData?.company?.title}
                    </div>
                    <div className="!mt-3 main-nav__dropdown-company-item p-0 rounded w-[185px] h-[80px]">
                      <img
                        className="rounded border-solid border-[1px] border-light-gray"
                        src={
                          authData?.company?.companyImage ||
                          require("@public/images/defaultAvatarCompany.svg")
                        }
                        alt="Логотип компании"
                      />
                    </div>
                  </Link>
                </div>
              </>
            )} */}

            <hr className="!my-3" />

            <div className="flex items-center gap-8">
              <a
                className="flex items-center"
                href="https://profi.travel/"
                target="_blank"
              >
                <img
                  src={require("@public/images/header/logo-black.svg")}
                  alt="Логотип Profi Travel"
                />
              </a>
              <a
                className="flex items-center"
                href="https://t.me/otm_tourism"
                target="_blank"
              >
                <img
                  src={require("@public/images/header/tg-logo.svg")}
                  alt="Логотип Телеграм"
                />
              </a>
            </div>

            {authStatus === "authorized" && (
              <button className="!mt-6 btn btn--red ">
                <Link
                  className="main-nav__dropdown-exit"
                  to="#"
                  onClick={() => {
                    dispatch(Middleware.Auth.signout());
                    Middleware.Ui.Userbar.hide();
                  }}
                >
                  <p className="text-sm text-white">Выйти из аккаунта</p>
                </Link>
              </button>
            )}
          </div>
        </Clickoutside>
      </nav>
    </>
  );
};

export default Header;
