import React, { ReactElement } from "react";
import ScheduleTableWeek from "./ScheduleTableWeek";
import moment from "moment-timezone";

const ScheduleTableView = (props: any): ReactElement => {
  return (
    <div
      className={`schedule__table ${
        props.isShowed ? "" : "schedule__table--hidden"
      }`}
      data-period-count={props.scheduleWizard.dayPeriodCount}
      data-period-length={props.scheduleWizard.dayPeriodLength}
      data-flow="1"
      data-flow-view="table"
    >
      <div className="schedule__table-body">
        {Object.keys(props.weeks).map((week: any, index: number) => {
          if (Number(week) === props.activeWeek) {
            return (
              <ScheduleTableWeek
                key={index}
                scheduleWizard={props.scheduleWizard}
                events={props.weeks[week]}
                weekNum={week}
                weekStartDay={moment(
                  props.weeks[Object.keys(props.weeks)[index]][0].date
                )
                  .tz("Europe/Moscow")
                  .startOf("week")}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default ScheduleTableView;
