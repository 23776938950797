import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Rating extends ModelPrototype {
  public async page(page?: number): Promise<any> {
    try {
      return this.withUrl(`main/rating/group?gapSize=400`)
        .setMethod(Method.GET)
        .request({ page });
    } catch (Exception) {
      throw Exception;
    }
  }
  public async pageBase(page?: number): Promise<any> {
    try {
      return this.withUrl(`main/rating/group?gapSize=400&tariffId=1`)
        .setMethod(Method.GET)
        .request({ page });
    } catch (Exception) {
      throw Exception;
    }
  }
  public async pageFull(page?: number): Promise<any> {
    try {
      return this.withUrl(`main/rating/group?gapSize=400&tariffId=2,3`)
        .setMethod(Method.GET)
        .request({ page });
    } catch (Exception) {
      throw Exception;
    }
  }
  public async pageAll(page?: number): Promise<any> {
    try {
      return this.withUrl(`main/getLeaderboardTop?gapSize=400`)
        .setMethod(Method.GET)
        .request({ page });
    } catch (Exception) {
      throw Exception;
    }
  }
}
