import React, { useEffect, useState } from "react";
import Input from "@components/form/input";
import { Link } from "react-router-dom";
import { PreloaderComponent } from "@components/preloader";

export interface PasswordProps {
  data: {
    [key: string]: string | undefined;
  };
  onChange: (field: string, value: string) => void;
  captchaSelected: string | null;
  setCaptchaSelected: React.Dispatch<React.SetStateAction<string | null>>;
  captcha: { message: string; itemList: [] };
  loadCaptcha: () => void;
  loadingCaptcha: boolean;
}

const PasswordForm: React.FC<PasswordProps> = ({
  data,
  onChange,
  captchaSelected,
  setCaptchaSelected,
  captcha,
  loadCaptcha,
  loadingCaptcha,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmedPasswordVisible, setIsConfirmedPasswordVisible] =
    useState(false);
  const [checked, setChecked] = React.useState(false);

  const switchCaptchaItemName = (code: string) => {
    switch (code) {
      case "0BJc":
        return "square";
      case "kstA":
        return "triangle";
      case "XDYg":
        return "circle";
      case "vDzr":
        return "star";
      case "x3z9":
        return "hexagon";
    }
  };

  useEffect(() => {
    loadCaptcha();
  }, []);

  return (
    <div className="form__group--margin">
      <div className="form__group">
        <label className="visually-hidden" htmlFor="password">
          *Придумайте пароль
        </label>
        <div className="auth__pwd">
          <div className="auth__pwd-input">
            <Input
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              id="password"
              value={data.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange("password", e.target.value)
              }
              minLength={8}
              placeholder="Пароль | не менее 8 символов"
              required={true}
            />
            <button
              type="button"
              className={`auth__pwd-btn ${
                isPasswordVisible ? "auth__pwd-btn--closed" : ""
              }`}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
          </div>
        </div>

        <label className="visually-hidden" htmlFor="password_confirm">
          *Повторите пароль
        </label>
        <div className="auth__pwd">
          <div className="auth__pwd-input">
            <Input
              type={isConfirmedPasswordVisible ? "text" : "password"}
              name="passwordConfirm"
              id="password_confirm"
              value={data.confirmedPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange("confirmedPassword", e.target.value)
              }
              minLength={8}
              pattern={data.password?.replace(
                /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
                "\\$&"
              )}
              placeholder="Повторить Пароль | не менее 8 символов"
              required={true}
            />
            <button
              type="button"
              className={`auth__pwd-btn ${
                isConfirmedPasswordVisible ? "auth__pwd-btn--closed" : ""
              }`}
              onClick={() =>
                setIsConfirmedPasswordVisible(!isConfirmedPasswordVisible)
              }
            />
          </div>
        </div>
      </div>

      <div className="form__group--margin">
        {loadingCaptcha ? (
          <PreloaderComponent />
        ) : (
          <>
            <div className="auth__captcha">
              <div className="auth__captcha-left">
                <div className="auth__captcha-title">Captcha</div>
                <div className="auth__captcha-desc">{captcha?.message}</div>
              </div>
              <div className="auth__captcha-right">
                {captcha?.itemList?.map((item: string) => {
                  return (
                    <div
                      key={item}
                      className={`auth__captcha-item auth__captcha-item--${switchCaptchaItemName(
                        item
                      )} ${item === captchaSelected ? "selected" : ""}`}
                      onClick={() => {
                        if (item === captchaSelected) {
                          setCaptchaSelected(null);
                        } else {
                          setCaptchaSelected(item);
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <Link
              className="auth__captcha-description"
              to="#"
              onClick={() => {
                setCaptchaSelected(null);
                loadCaptcha();
              }}
            >
              Перезагрузить капчу
            </Link>
          </>
        )}
      </div>
      <div className="form__footer">
        <div className="auth__privacy">
          <label htmlFor="privacy">
            <input
              className="visually-hidden"
              type="checkbox"
              id="privacy"
              required={true}
              onChange={() => setChecked(!checked)}
            />
            <div className="auth__privacy-checkbox">
              {checked && (
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3L4 6L9 1"
                    stroke="#FF0011"
                    stroke-width="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <span>
              Соглашаюсь с{" "}
              <a target="_blank" href="https://profi.travel/legal">
                политикой обработки персональных данных
              </a>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PasswordForm;
