import { Main } from "@actions/index";
import { Reducer } from "redux";
import { WorkshopNews } from "@interface/entities/workshopNews";
import { BaseState, BaseAction } from "@interface/common";

const InitialState: BaseState<WorkshopNews[]> = {
  data: [],
};

const reducer: Reducer<
  BaseState<WorkshopNews[]>,
  BaseAction<WorkshopNews[]>
> = (state = InitialState, action) => {
  switch (action.type) {
    case Main.WorkshopNews.SET_DATA:
      return {
        ...state,
        data: [...(action.data ? action.data : [])],
      };
    default:
      return state;
  }
};

export default reducer;
