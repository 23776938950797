import React from "react";
import ScheduleListWeek from "./ScheduleListWeek";

interface ScheduleListViewProps {
  weeks: Record<string, any>;
  activeWeek: number;
  scheduleWizard: any;
  isShowed: boolean;
}

const ScheduleListView = ({
  weeks,
  activeWeek,
  scheduleWizard,
  isShowed,
}: ScheduleListViewProps) => {
  return (
    <div
      className={`schedule__list ${isShowed ? "" : "schedule__list--hidden"}`}
    >
      {activeWeek > 0 ? (
        Object.keys(weeks).map((week: any, index: number) => {
          if (Number(week) === activeWeek) {
            return (
              <ScheduleListWeek
                key={index}
                scheduleWizard={scheduleWizard}
                events={weeks[week]}
              />
            );
          }
          return null;
        })
      ) : (
        <>
          {Object.keys(weeks).map((week: any, index: number) => (
            <ScheduleListWeek
              key={index}
              scheduleWizard={scheduleWizard}
              events={weeks[week]}
              isShowArchiveDays={false}
            />
          ))}

          {Object.keys(weeks).map((week: any, index: number) => (
            <ScheduleListWeek
              key={index}
              scheduleWizard={scheduleWizard}
              events={weeks[week]}
              isShowActiveDays={false}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ScheduleListView;
