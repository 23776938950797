import * as Action from "@actions/index";
import { useDispatch } from "@helpers/Store";

export default class Userbar {
  public static show() {
    useDispatch(Action.Ui.Userbar.show());
  }

  public static hide() {
    useDispatch(Action.Ui.Userbar.hide());
  }
}
