import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class City {
  /**
   * @return City.suggest
   */
  public static async suggest(country_id: number, text) {
    try {
      const response = await new Model.Location.City().suggest(country_id, { text });
      return response.data;
    } catch (Exception) {}
  }

  /**
   * @return City.suggest
   */
  public static async get(city_id: number): Promise<{ id: number; name: string } | undefined> {
    try {
      const response = await new Model.Location.City().get(city_id);
      return response.data;
    } catch (Exception) {}
  }
}
