import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, withRouter } from "react-router-dom";
import { Input, InputCompanyType, Checkbox, InputAutocomplete } from "@components/form/index";
import Office from "./components/Office";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  name: string;
  types: any[];
  about: string;
  // * step 2
  directions: any[];
  // * step 3
  specializations: any[];
  // * step 4
  avatar: string | null;
  // * step 5
  offices: any;
  website: string;
  facebook: string;
  instagram: string;
  vkontakte: string;
  youtube: string;
  tiktok: string;
  telegram: string;
}

class CompanyEdit extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      name: "",
      types: [],
      about: "",
      directions: [],
      specializations: [],
      avatar: null,
      offices: {
        sdfg: {
          officename: "",
          officeemail: "",
          officephone: "",
          officeaddress: "",
          officecountry_id: null,
          officecity_id: null,
          contactname: "",
          contactposition: "",
        },
      },
      website: "",
      facebook: "",
      instagram: "",
      vkontakte: "",
      youtube: "",
      tiktok: "",
      telegram: "",
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onCheckHandler = this.onCheckHandler.bind(this);
    this.onFileSelectHandler = this.onFileSelectHandler.bind(this);
    this.onOfficeChange = this.onOfficeChange.bind(this);
  }

  public componentDidMount() {
    Middleware.Location.Country.load();
    Middleware.Company.Type.load();
    Middleware.Company.Direction.load();
    Middleware.Company.Specialization.load();
    Middleware.Company.Current.load(this.props.Store.Auth.data?.company?.id).then((Company) => {
      const offices = this.state.offices;
      Company.offices.forEach((Office: any) => {
        offices[Math.random().toString(36).substr(2, 5)] = {
          name: Office.title,
          email: Office.email,
          phone: Office.phone,
          address: Office.address,
          country_id: Office.networkingCountry,
          city_id: Office.networkingCity,
          username: Office.contactPersonName,
          userposition: Office.contactPersonDescription,
        };
      });

      this.setState({
        name: Company.title || "",
        types: Company.types || "",
        about: Company.description || [],
        directions: Company.directions || [],
        specializations: Company.specializations || [],
        // --
        website: Company.website || "",
        facebook: Company.networkingProfile?.facebook || "",
        instagram: Company.networkingProfile?.instagram || "",
        vkontakte: Company.networkingProfile?.vkontakte || "",
        youtube: Company.networkingProfile?.youtube || "",
        tiktok: Company.networkingProfile?.tiktok || "",
        telegram: Company.networkingProfile?.telegram || "",
      });
    });
  }

  private onOfficeChange(Office: any) {
    const offices = this.state.offices;
    offices[Office.hash] = {
      name: Office.name,
      email: Office.email,
      phone: Office.phone,
      address: Office.address,
      country_id: Office.country_id,
      city_id: Office.city_id,
      username: Office.username,
      userposition: Office.userposition,
    };
    this.setState({ offices });
  }

  private onSubmitHandler(event?: any): void {
    const data: any = {
      name: this.state.name,
      types: this.state.types,
      about: this.state.about,
      creator: this.props.Store.Auth.data.id,
      socials: {
        facebook: this.state.facebook,
        instagram: this.state.instagram,
        vkontakte: this.state.vkontakte,
        youtube: this.state.youtube,
        tiktok: this.state.tiktok,
        telegram: this.state.telegram,
      },
    };

    if (this.state.directions.length) {
      data.directions = this.state.directions;
    }
    if (this.state.specializations.length) {
      data.specializations = this.state.specializations;
    }
    if (this.state.avatar) {
      data.avatar = this.state.avatar;
    }
    if (this.state.offices.length) {
      const offices: any[] = [];
      Object.keys(this.state.offices).map((hash: any) => {
        offices.push(this.state.offices[hash]);
      });
      data.offices = offices;
    }

    Middleware.Company.Current.update(Number(this.props.match.params.company_id), data).then(() =>
      this.props.history.push("/cabinet/profile")
    );
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    this.setState({ [event.target.name]: event.target.value });
  }

  private onCheckHandler(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ): void {
    const list = this.state[event.target.name];
    if (list.includes(event.target.value)) {
      list.splice(list.indexOf(event.target.value), 1);
    } else {
      list.push(event.target.value);
    }
    this.setState({ [event.target.name]: list });
  }

  private onFileSelectHandler(event: any) {
    const selectedFile = event.target.files[0];
    const fr = new FileReader();

    fr.onload = (event) => {
      this.setState({ avatar: event.target.result });
    };

    fr.readAsDataURL(selectedFile);
  }

  public render() {
    return (
      <>
        <div className="company-edit">
          <h1 className="company-cabinet__title">Редактировать информацию о компании</h1>
          <div className="company-edit__col company-edit__col--left">
            <div className="company-cabinet__block">
              <div className="company-edit__form">
                <div className="form">
                  <div className="form__header">
                    <div className="company-edit__title">Основная информация</div>
                  </div>
                  <div className="form__body">
                    <div className="form__group">
                      <div className="company-edit__company-name">
                        {this.props.Store.Company.Current.data.title}
                      </div>
                      <InputCompanyType
                        name="types"
                        value={this.state.types}
                        onChange={this.onChangeHandler}
                        required={true}
                      />
                      <div className="textarea">
                        <textarea
                          className="textarea__input"
                          name="about"
                          id=""
                          placeholder="О компании"
                          onChange={this.onChangeHandler}
                          value={this.state.about}
                          required={true}
                          maxLength={3000}
                        />
                        <div className="textarea__counter">
                          {3000 - this.state.about?.length} знаков
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="company-cabinet__block">
              <div className="form">
                <div className="form__header">
                  <div className="company-edit__title">Направления</div>
                </div>
                <div className="form__body">
                  <div className="form__group">
                    <div className="form__autocomplete-tags">
                      {this.props.Store.Company.Direction.list.map((Item: any, index: number) => {
                        if (this.state.directions.includes(Item.id)) {
                          return (
                            <Link
                              to="#"
                              className="form__autocomplete-tag"
                              onClick={() => {
                                const directions = this.state.directions;
                                const id = this.state.directions.indexOf(Item.id);
                                if (id >= 0) {
                                  directions.splice(id, 1);
                                }
                                this.setState({ directions });
                              }}
                            >
                              {Item.name}
                            </Link>
                          );
                        }
                      })}
                    </div>
                    <InputAutocomplete
                      name="directions"
                      type="custom"
                      list={this.props.Store.Company.Direction.list}
                      placeholder="Выберите из списка одно или несколько значений"
                      clearOnSelect={true}
                      onSelect={(event: any) => {
                        const directions = this.state.directions;
                        if (
                          !directions.includes(event.target.value) &&
                          event.target.value !== null
                        ) {
                          directions.push(event.target.value);
                        }
                        this.setState({ directions });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="company-cabinet__block">
              <div className="company-edit__form company-edit__form--contacts">
                <div className="form">
                  <div className="form__header">
                    <div className="company-edit__title">Контактные данные</div>
                  </div>
                  <div className="form__body">
                    {/* <div className="form__group form__group--address">
                      <Input
                        type="text"
                        name="name"
                        placeholder="Название офиса"
                        value={this.state.name}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="email"
                        name="email"
                        placeholder="E-mail офиса"
                        value={this.state.email}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        value={this.state.phone}
                        onChange={this.onChangeHandler}
                        placeholder="Телефон офиса"
                        modificator={Modificator.PhoneWithoutPrefix}
                      />
                    </div>
                    <hr />
                    <div className="form__group ">
                      <Select
                        name="country_id"
                        value={this.props.Store.Profile.networkingProfile?.country || 1}
                        options={this.props.Store.Location.Country.list}
                        required={true}
                      />
                      <InputAutocomplete
                        name="city_id"
                        type="city"
                        country_id={this.state.country_id}
                        placeholder="Город"
                        onSelect={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="address"
                        placeholder="Адрес офиса"
                        value={this.state.address}
                        onChange={this.onChangeHandler}
                      />
                    </div>

                    <hr />
                    <div className="form__group">
                      <div className="company-edit__title">Контактное лицо</div>
                    </div>
                    <div className="form__group form__group--span-2">
                      <Input
                        type="text"
                        name="firstname"
                        placeholder="Имя"
                        value={this.state.firstname}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="position"
                        placeholder="Должность"
                        value={this.state.position}
                        onChange={this.onChangeHandler}
                      />
                    </div> */}

                    {Object.keys(this.state.offices).map((hash: string) => {
                      return <Office hash={hash} key={hash} onChange={this.onOfficeChange} />;
                    })}
                    <div className="form__group form__group--span-2">
                      <button
                        className="btn btn--new-field"
                        type="button"
                        onClick={() => {
                          const offices = this.state.offices;
                          offices[Math.random().toString(36).substr(2, 5)] = {
                            officename: "",
                            officeemail: "",
                            officephone: "",
                            officeaddress: "",
                            officecountry_id: null,
                            officecity_id: null,
                            contactname: "",
                            contactposition: "",
                          };
                          this.setState({ offices });
                        }}
                      >
                        {/* <span /> */}
                        Добавить офис
                      </button>
                    </div>
                    <div className="form__group form__group--span-2">
                      Укажите ссылки на официальные аккаунты компании в соцсетях
                    </div>
                    <div className="form__group form__group--links">
                      <Input
                        type="text"
                        name="website"
                        placeholder="Веб-сайт"
                        value={this.state.website}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="facebook"
                        placeholder="Facebook"
                        value={this.state.facebook}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="instagram"
                        placeholder="Instagram"
                        value={this.state.instagram}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="vkontakte"
                        placeholder="Vk.com"
                        value={this.state.vkontakte}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="youtube"
                        placeholder="Youtube"
                        value={this.state.youtube}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="tiktok"
                        placeholder="Tik-tok"
                        value={this.state.tiktok}
                        onChange={this.onChangeHandler}
                      />
                      <Input
                        type="text"
                        name="telegram"
                        placeholder="Telegram"
                        value={this.state.telegram}
                        onChange={this.onChangeHandler}
                      />
                    </div>
                  </div>

                  <div className="form__footer">
                    <button className="btn btn--red" type="submit">
                      Сохранить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="company-edit__col company-edit__col--right">
            {this.props.Store.Company.Current.data.companyImage || this.state.avatar ? (
              <>
                <div className="company-cabinet__block">
                  <div className="company-edit__avatar company-edit__avatar--uploaded">
                    <img
                      src={this.state.avatar || this.props.Store.Company.Current.data.companyImage}
                      alt=""
                    />
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      onChange={this.onFileSelectHandler}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="company-cabinet__block">
                  <div className="company-edit__avatar tac">
                    Загрузить логотип{" "}
                    <span>
                      Не&nbsp;меньше 250 пикселей
                      <br /> по&nbsp;одной стороне
                    </span>
                    <input
                      type="file"
                      name="avatar"
                      id="avatar"
                      onChange={this.onFileSelectHandler}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="company-cabinet__block">
              <div className="company-edit__spec">
                <div className="company-edit__title">Специализация компании</div>
                <div className="company-edit__spec-desc">Можете выбрать несколько пунктов</div>
                <div className="company-edit__spec-inputs checkbox-list">
                  {this.props.Store.Company.Specialization.list.map((Item: any, index: number) => {
                    return (
                      <Checkbox
                        key={index}
                        name="specializations"
                        placeholder={Item.name}
                        value={Item.id}
                        hiddenInput={true}
                        checked={this.state.specializations.includes(Item.id)}
                        onChange={this.onCheckHandler}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className="company-cabinet__block">
              <div className="company-edit__invite">
                <div className="form">
                  <div className="form__header">
                    <div className="company-edit__title">Пригласите коллег в Нетворкинг!</div>
                  </div>
                  <div className="form__body">
                    <div className="form__group">
                      <Input
                        type="email"
                        name="invite-email"
                        placeholder="E-mail сотрудника"
                        required={true}
                      />
                      <button className="btn btn--new-field" type="button">
                        <span />
                        Добавить сотрудника
                      </button>
                    </div>
                  </div>
                  <div className="form__footer">
                    <button className="btn btn--red company-edit__invite-submit" type="submit">
                      Пригласить
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CompanyEdit)
);
