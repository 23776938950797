import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment, { Moment } from "moment-timezone";

export interface PropsInterface {
  onSubmit: any;
  Store: any;
}

export interface StateInterface {
  calendar: Moment[] | null;
  selectedDate: Moment | null;
  month: Moment;
}

class CalendarComponent extends React.Component<
  PropsInterface,
  StateInterface
> {
  public constructor(props: PropsInterface) {
    super(props);

    this.state = {
      calendar: [],
      selectedDate: null,
      month: moment(new Date(this.props.Store.Main.Workshop.data.startAt))
        .tz("Europe/Moscow")
        .startOf("month"),
    };
  }

  public componentDidMount() {
    const now = moment().tz("Europe/Moscow");
    const networkingDays = this.props.Store.Networking.Days.data;

    if (networkingDays.includes(now.format("DD.MM.YYYY"))) {
      this.setState({ selectedDate: now });
    }

    this.props.onSubmit(this.state.selectedDate);
  }

  private days() {
    const daysArray: JSX.Element[] = [];
    const networkingDays = this.props.Store.Networking.Days.data;

    for (let i = 1; i < this.state.month.isoWeekday(); i++) {
      daysArray.push(<Link to="#" style={{ pointerEvents: "none" }} />);
    }

    for (let i: number = 1; i <= this.state.month.daysInMonth(); i++) {
      const tmpDate = this.state.month.clone().set("date", i);

      daysArray.push(
        <Link
          to="#"
          className={`event-calendar__item event-calendar__item--day ${
            tmpDate.format("DD:MM:YYYY") ===
            moment().tz("Europe/Moscow").format("DD:MM:YYYY")
              ? "event-calendar__item--today"
              : ""
          } ${
            this.state.selectedDate?.format("DD:MM:YYYY") ===
            tmpDate.format("DD:MM:YYYY")
              ? "event-calendar__item--active"
              : ""
          } ${
            !networkingDays.includes(tmpDate.format("DD.MM.YYYY"))
              ? "event-calendar__item--disabled"
              : ""
          }`}
          onClick={() => {
            console.log("тык");
            if (!networkingDays.includes(tmpDate.format("DD.MM.YYYY"))) {
              return;
            }
            this.setState({ selectedDate: tmpDate }, () =>
              this.props.onSubmit(this.state.selectedDate)
            );
          }}
        >
          {i}
        </Link>
        // className={`event-calendar__item event-calendar__item--day ${
        //   i === 3 ? "event-calendar__item--today" : ""
        // } ${i === 18 ? "event-calendar__item--active" : ""}`}
      );
    }

    return daysArray;
  }

  public render() {
    return (
      <>
        <div className="event-calendar">
          <div className="event-calendar__header">
            <div className="event-calendar__title">
              {this.state.month.format("MMMM YYYY")}
            </div>
            <div className="event-calendar__controls">
              <Link
                to="#"
                onClick={() =>
                  this.setState({
                    month: this.state.month.subtract(1, "months"),
                  })
                }
                className="event-calendar__arrow event-calendar__arrow--prev"
              />
              <Link
                to="#"
                onClick={() =>
                  this.setState({ month: this.state.month.add(1, "months") })
                }
                className="event-calendar__arrow event-calendar__arrow--next"
              />
            </div>
          </div>
          <div className="event-calendar__grid">
            {["пн", "вт", "ср", "чт", "пт", "сб", "вс"].map(
              (item, index: number) => {
                return (
                  <div
                    key={index}
                    className="event-calendar__item event-calendar__item--header"
                  >
                    {item}
                  </div>
                );
              }
            )}
            {this.days()}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CalendarComponent)
);
