import React from "react";
import { Switch } from "react-router-dom";
import Router, { Validator } from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Pages from ".";
import Connector from "@helpers/Connector";

export default Connector(
  class RouterComponent extends React.Component<ComponentInterface> {
    public render() {
      return (
        <>
          <Switch>
            {/* <Router path="/company/free" exact={true} component={Pages._show_free} />
            <Router path="/company/chats" exact={true} component={Pages.Chats} /> */}
            <Router
              path="/company/create"
              exact={true}
              validator={Validator.Authorized}
              component={Pages.Create}
            />
            <Router
              path="/company/create/:company_id"
              exact={true}
              validator={Validator.Authorized}
              component={Pages.Create}
            />
            <Router path="/company/stand" component={Pages.Stand} />
            <Router
              path="/company/:company_id"
              exact={true}
              render={(props: any) => (
                <Pages._show_free {...props} key={"company-" + props.match.params.stand_id} />
              )}
            />
            <Router
              path="/company/:company_id/edit"
              exact={true}
              validator={Validator.Authorized}
              component={Pages.Edit}
            />
            <Router
              path="/company/:company_id/employees"
              validator={Validator.Authorized}
              component={Pages.Employees}
            />
            <Router path="/company/cabinet" component={Pages.Cabinet} />
          </Switch>
        </>
      );
    }
  }
);
