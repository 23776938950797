import React, { ReactElement, useState } from "react";
import moment, { Moment } from "moment-timezone";
import ScheduleListEvent from "./ScheduleListEvent";
import clsx from "clsx";
import { specialAirtime } from "@pages/dashboard/components2.0/Schedule/Schedule";

interface IProps {
  dayEndTime: Moment;
  events: any[];
  isArchived: boolean;
}

// const customClasses = new Map()
//   .set("26 августа", "schedule__list-day-header--mald")
//   .set("27 августа", "schedule__list-day-header--mald")
//   .set("30 августа", "schedule__list-day-header--sey")
//   .set("4 сентября", "schedule__list-day-header--arab-lux")
//   .set("5 сентября", "schedule__list-day-header--arab-lux")
//   .set("6 сентября", "schedule__list-day-header--arab-lux")
//   .set("9 сентября", "schedule__list-day-header--cuba")
//   .set("10 сентября", "schedule__list-day-header--bel");

const archivedClasses = new Map()
  .set(true, "schedule__list-day-header--archived")
  .set(false, "");

// const networkingClasses = new Map()
//   .set(true, "schedule__list-day-header--networking-day")
//   .set(false, "");

const customDays = new Map()
  .set("10 февраля", {
    name: "День ОАЭ",
    flag: "Flag_of_the_United_Arab_Emirates.png",
  })
  .set("11 февраля", {
    name: "День ОАЭ",
    flag: "Flag_of_the_United_Arab_Emirates.png",
  })
  .set("12 февраля", {
    name: "День ОАЭ",
    flag: "Flag_of_the_United_Arab_Emirates.png",
  })
  .set("14 февраля", {
    name: "День Турции",
    flag: "Turkey.png",
  })
  .set("20 февраля", {
    name: "День Мальдивских островов",
    flag: "Flag_of_Maldives.png",
  })
  // .set("21 февраля", {
  //   name: "День Мальдивских островов",
  //   flag: "Flag_of_Maldives.png",
  // })
  .set("24 февраля", {
    name: "День Мальдивских островов",
    flag: "Flag_of_Maldives.png",
  })
  .set("26 февраля", {
    name: "День Республики Беларусь",
    flag: "Flag_of_Belarus.png",
  });

const networkingText = new Map()
  .set(true, " — День Нетворкинга")
  .set(false, "");

const ScheduleListDay = ({
  dayEndTime,
  events,
  isArchived,
}: IProps): ReactElement => {
  const [isShowed, setIsShowed] = useState(!isArchived);

  if (events.length < 1) {
    return <></>;
  }

  const formattedEventDate = moment(events[0]?.date)
    .tz("Europe/Moscow")
    .format("D MMMM");

  const formattedEventDateShort = moment(events[0]?.date)
    .locale("ru")
    .tz("Europe/Moscow")
    .format("D MMM");

  const isNetworkingDay = !!events.find((item) => item.networkingDay);

  // const currentClass = customClasses.get(formattedEventDate)
  //   ? customClasses.get(formattedEventDate)
  //   : networkingClasses.get(isNetworkingDay)
  //   ? networkingClasses.get(isNetworkingDay)
  //   : "";

  const currentCustomDay =
    customDays.get(formattedEventDate) ||
    networkingText.get(isNetworkingDay) ||
    "";

  const isSpecialAirtime = specialAirtime.includes(formattedEventDate);

  return (
    <div
      id={"date_" + moment(dayEndTime).tz("Europe/Moscow").format("DDMM")}
      className={`schedule__list-day ${
        !isShowed ? "schedule__list-day--hidden" : ""
      }`}
    >
      <div
        className={`schedule__list-day-header ${archivedClasses.get(
          isArchived
        )}`}
        onClick={() => setIsShowed(!isShowed)}
      >
        <div className="schedule__list-day-date-wrapper">
          <div className="schedule__list-day-date">
            {isArchived && (
              <>
                <span>Архив</span>
                <hr />
              </>
            )}
            <span className="hidden md:block">{formattedEventDate}</span>
            <span className="md:hidden">{formattedEventDateShort}</span>
            <hr />
            <span className="shedule__list-day-week">
              {moment(events[0]?.date).tz("Europe/Moscow").format("dddd")}
            </span>
            <span className="shedule__list-day-week-short md:hidden">
              {moment(events[0]?.date)
                .tz("Europe/Moscow")
                .format("ddd")
                .toUpperCase()}
            </span>
            {currentCustomDay && (
              <>
                <hr className="shedule__list-day-date-name" />
                <span className="shedule__list-day-date-name">
                  {currentCustomDay.name}
                </span>
              </>
            )}
          </div>
          {(currentCustomDay.flag || isSpecialAirtime) && (
            <div
              className={clsx("flex gap-3", {
                "hidden md:flex gap-3": isArchived,
              })}
            >
              {currentCustomDay.flag && (
                <div className="flex">
                  <img
                    height={30}
                    src={require(`@public/images/flags/${currentCustomDay.flag}`)}
                    alt="Флаг"
                  />
                </div>
              )}
              {isSpecialAirtime && (
                <div className="flex rounded bg-white justify-center items-center py-[6px] gap-2 px-[10px]">
                  <p className="hidden md:flex text-14 font-bold text-black">
                    Спецэфир
                  </p>
                  <img
                    className="shrink-0"
                    width={18}
                    height={18}
                    src={require("@public/images/fire.png")}
                    alt="Огонь"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={`schedule__list-day-body`}>
        {events.map((event: any, index: number) => {
          return event.networkingDay ? (
            <ScheduleListEvent key={index} event={event} networkingDay={true} />
          ) : (
            <ScheduleListEvent
              key={index}
              event={event}
              networkingDay={false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleListDay;
