import React from "react";

interface ReviewCard {
  imageSrc: string;
  name: string;
  subtitle: string;
  description: string;
}

const ReviewCard = ({
  imageSrc,
  name,
  subtitle,
  description,
}: ReviewCard): JSX.Element => {
  return (
    <div className="reviews__item review">
      <div className="hidden 2xl:flex review__user-photo">
        <img src={require(`@public/images/reviews/${imageSrc}`)} alt={name} />
      </div>
      <div className="review__user 2xl:hidden">
        <div className="review__user-photo">
          <img src={require(`@public/images/reviews/${imageSrc}`)} alt={name} />
        </div>
        <div className="review__user-info">
          <div className="review__user-name">{name}</div>
          <div className="review__user-position">{subtitle}</div>
        </div>
      </div>
      <div className="review__text-wrapper">
        <div className="hidden 2xl:flex review__user-info">
          <div className="review__user-name">{name}</div>
          <div className="review__user-position">{subtitle}</div>
        </div>
        <p className="review__text">{description}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
