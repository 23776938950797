import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Specialization {
  public static async load() {
    useDispatch(Action.Company.Direction.setStatus("loading"));
    try {
      const response = await new Model.Company().directions();
      useDispatch(Action.Company.Direction.setList(response.data));
      useDispatch(Action.Company.Direction.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }
}
