import React, { ReactElement, useEffect, useRef } from "react";

interface PropsInterface {
  children?:
    | React.ReactChild
    | React.ReactChildren
    | React.ReactChild[]
    | React.ReactChildren[];
  clickOutside: () => any;
  exceptionRef?: React.RefObject<HTMLElement>;
}

const ClickOutside: React.FC<PropsInterface> = ({
  children,
  exceptionRef,
  clickOutside,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node) &&
      (!exceptionRef?.current ||
        !exceptionRef.current.contains(event.target as Node))
    ) {
      clickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutside;
