import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import StoreInterface from "@interface/Redux";

export default (Component: any): any => {
  return withRouter(
    connect(
      (Store: StoreInterface) => {
        return { Store };
      },
      (Dispatch: any) => {
        return { Dispatch: (action: any) => Dispatch(action) };
      }
    )(Component)
  );
};
