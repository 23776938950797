import React from "react";
import { Switch } from "react-router-dom";
import Router, { Validator } from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Pages from ".";
import Connector from "@helpers/Connector";

export default Connector(
  class RouterComponent extends React.Component<ComponentInterface> {
    public render() {
      return (
        <>
          <Switch>
            <Router path="/user/:user_id" exact={true} component={Pages.Show} />
            <Router
              path={["/user/:user_id/event", "/user/:user_id/events"]}
              component={Pages.Event}
            />
          </Switch>
        </>
      );
    }
  }
);
