import React, { ReactElement } from "react";
import * as Middleware from "@middlewares/index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

interface IProps {
  id: number;
  type: number;
  bgImage: string;
  logo: string;
  title: string;
  description?: string;
  users: any[];
}

const StandsPartnersItem = ({
  type,
  id,
  bgImage,
  logo,
  title,
  description,
  users,
}: IProps): ReactElement => {
  const workshopData = useSelector((state: any) => state.Main.Workshop.data);
  const registerEndAt = moment(workshopData.registerEndAt)
    .tz("Europe/Moscow")
    .format();
  const now = moment().tz("Europe/Moscow").format();

  return (
    <div
      className={`stands-item-partner stands-item-partner--${
        type === 4
          ? "general"
          : type === 3
          ? "platinum"
          : type === 2
          ? "gold"
          : type === 1
          ? "silver"
          : type === 0
          ? "bronze"
          : ""
      }`}
      data-type="11"
    >
      {type > 1 && (
        <Link
          to={`/company/stand/${id}`}
          className="stands-item-partner__bg"
          target="_blank"
        >
          {bgImage && (
            <picture>
              <img src={bgImage} alt={title} />
            </picture>
          )}

          {type === 3 && (
            <div className="stands-item-partner__logo">
              <picture>
                <img src={logo} alt={`Логотип ${title}`} />
              </picture>
            </div>
          )}
        </Link>
      )}

      <div className="stands-item-partner__content">
        {type !== 3 && (
          <Link
            to={type === 0 ? `/company/${id}` : `/company/stand/${id}`}
            className="stands-item-partner__logo"
            target="_blank"
          >
            <div className="stands-item-partner__logo-wrapper">
              <picture>
                <img src={logo} alt={`Логотип ${title}`} />
              </picture>
            </div>
          </Link>
        )}

        <div className="flex flex-col gap-3">
          <Link
            to={type === 0 ? `/company/${id}` : `/company/stand/${id}`}
            className="stands-item-partner__title"
            target="_blank"
          >
            {title}
          </Link>
          {(type === 4 || type === 3) && description !== null ? (
            <div className="stands-item-partner__desc">{description}</div>
          ) : null}
        </div>

        {type > 0 && (
          <div className="stands-item-partner__footer">
            <Link
              className="btn btn--medium btn--red tariff__btn"
              to={`/company/stand/${id}`}
              target="_blank"
            >
              Изучить стенд
            </Link>
          </div>
        )}
        <a className="stands-item-partner__bookmarks" href="#">
          &nbsp;
        </a>
      </div>
    </div>
  );
};

export default StandsPartnersItem;
