import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Payment {
  public static async tariff(id: number) {
    try {
      useDispatch(Action.Payment.setTariff(id));
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async payment(name: string) {
    try {
      useDispatch(Action.Payment.setPayment(name));
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async payLoyalty(tariffId: number, tags: string) {
    try {
      const response = await new Model.Payment().payLoyalty({ tariffId, tags });
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async payMoney(tariffId: number, tags: string) {
    try {
      const response = await new Model.Payment().payMoney({ tariffId, tags });
      useDispatch(Action.Payment.setPaymentLink(response.data.paymentLink));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
