import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";

import * as Middleware from "@middlewares/index";
import PreloaderComponent from "@components/preloader/PreloaderComponent";
import StandsGroup from "./StandsGroup";

interface IProps {
  filter?: { direction?: number|null; type?: number|null; specialization?: number|null };
}

const reload = (filter?: {
  direction?: number|null;
  types?: number|null;
  specializations?: number|null;
}) => {
  Middleware.Networking.Exponent.General.page(1, filter);
  Middleware.Networking.Exponent.Platinum.page(1, filter);
  Middleware.Networking.Exponent.Gold.page(1, filter);
  Middleware.Networking.Exponent.Silver.page(1, filter);
  Middleware.Networking.Member.page(1, filter, { onlyLinkedToWorkshop: 1 });
};

const StandsGroups: React.FC<IProps> = ({ filter }) => {

  const filterRef = useRef<any>();

  const generalStands: any = useSelector(
    (state: any) => state.Networking.Exponent.General.data
  );
  const platinumStands: any = useSelector(
    (state: any) => state.Networking.Exponent.Platinum.data
  );
  const goldStands: any = useSelector(
    (state: any) => state.Networking.Exponent.Gold.data
  );
  const silverStands: any = useSelector(
    (state: any) => state.Networking.Exponent.Silver.data
  );
  const bronzeStands: any = useSelector(
    (state: any) => state.Networking.Member.list
  );
  const workshopTitle: any = useSelector(
    (state: any) => state.Main.Workshop.data.title
  );
  const generalStandsStatus = useSelector(
    (state: any) => state.Networking.Exponent.General.status
  );
  const platinumStandsStatus = useSelector(
    (state: any) => state.Networking.Exponent.Platinum.status
  );
  const goldStandsStatus = useSelector(
    (state: any) => state.Networking.Exponent.Gold.status
  );
  const silverStandsStatus = useSelector(
    (state: any) => state.Networking.Exponent.Silver.status
  );
  const bronzeStandsStatus = useSelector(
    (state: any) => state.Networking.Member.status
  );

  const breakpoint = window.matchMedia("(min-width: 768px)");

  useEffect(() => {
    if(filterRef.current === undefined || isEqual(filterRef.current, filter) === false) {
      reload(filter);
    }

    filterRef.current = { ...filter };
  }, [filter]);

  const allStands = [
    ...(goldStands?.map((stand) => ({ ...stand, type: 2 })) || []),
    ...(silverStands?.map((stand) => ({ ...stand, type: 1 })) || []),
    ...(bronzeStands?.map((stand) => ({ ...stand, type: 0 })) || []),
  ];

  return (
    <div className="stands__groups">
      {generalStandsStatus === "loading" ||
      platinumStandsStatus === "loading" ||
      goldStandsStatus === "loading" ||
      silverStandsStatus === "loading" ||
      bronzeStandsStatus === "loading" ? (
        <div className="stands__preloader">
          <PreloaderComponent />
        </div>
      ) : null}

      {generalStands?.length > 0 && (
        <div className="stands__general">
          <div className="stands__group stands__group--general">
            <div className="cont">
              <h3 className="stands__group-title">
                Генеральный партнер <span>{workshopTitle}</span>
              </h3>
              <StandsGroup stands={generalStands} type={4} />
            </div>
          </div>
        </div>
      )}

      {platinumStands?.length > 0 ||
      goldStands?.length > 0 ||
      silverStands?.length > 0 ||
      bronzeStands?.length > 0 ? (
        <div className="stands__not-general">
          <div className="cont">
            <div className="stands__not-general-bg" />
            {platinumStands?.length > 0 && (
              <div className="stands__group stands__group--partners">
                <h3 className="stands__group-title">Партнеры</h3>
                <StandsGroup
                  stands={platinumStands}
                  type={3}
                  isPartner={true}
                />
              </div>
            )}

            {(goldStands?.length > 0 ||
              silverStands?.length > 0 ||
              bronzeStands?.length > 0) && (
              <div className="stands__group stands__group--participants">
                <h3 className="stands__group-title">Участники</h3>
                {allStands.length > 0 && (
                  <StandsGroup stands={allStands} type={0} />
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StandsGroups;
