import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import { throws } from "assert";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any[];
}

interface StateInterface {
  isAllShowed: boolean;
  title: string;
  data: any;
}

class Schedule extends React.Component<PropsInterface, StateInterface> {
  constructor(Props: PropsInterface) {
    super(Props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
      isAllShowed: false,
    };
  }

  public componentDidMount() {
    if (this.props.data.length <= 3) {
      this.setState({ isAllShowed: true });
    }
  }

  public scheduleDate(datestring: string, format: string) {
    const date = moment(datestring);

    switch (format) {
      case "dayMonth":
        return date.tz("Europe/Moscow").format("D MMMM");
        break;

      case "time":
        return date.tz("Europe/Moscow").format("H") + ":" + date.tz("Europe/Moscow").format("mm");
        break;

      case "dayOfWeek":
        return date.tz("Europe/Moscow").format("dddd");
        break;

      default:
        break;
    }
  }

  public render() {
    return (
      <>
        {this.props.data.length > 0 ? (
          <>
            <div className="stand-schedule stand__block">
              <div className="stand-schedule__title">{this.props.title}</div>
              <div className="stand-schedule__list">
                {this.props.data.map((item: any, index: number) => {
                  return (
                    <>
                      {index < 3 && !this.state.isAllShowed ? (
                        <>
                          <div key={index} className="stand-schedule__item">
                            <div className="stand-schedule__item-content">
                              <div className="stand-schedule__item-date">
                                {this.scheduleDate(item.date, "dayMonth")}
                                <span className="stand-schedule__item-time">
                                  {this.scheduleDate(item.date, "time")}{" "}
                                  <span className="stand-schedule__item-timezone">МСК</span>
                                </span>
                              </div>
                              <div className="stand-schedule__item-day">
                                {this.scheduleDate(item.date, "dayOfWeek")}
                              </div>
                              <div className="stand-schedule__item-title">{item.title}</div>
                            </div>
                            <div className="stand-schedule__item-btn-wrapper">
                              <a
                                href={`https://profi.travel${item.path}`}
                                className="stand-schedule__item-btn btn btn--red"
                                type="submit"
                              >
                                Записаться
                              </a>
                              <div className="stand-schedule__item-info">
                                {/*<div className="stand-schedule__item-views">*/}
                                {/*  {item.viewsCount} просмотров*/}
                                {/*</div>*/}
                                <div className="stand-schedule__item-participants">
                                  {item.participantsCount} участников
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.state.isAllShowed ? (
                        <>
                          <div key={index} className="stand-schedule__item">
                            <div className="stand-schedule__item-content">
                              <div className="stand-schedule__item-date">
                                {this.scheduleDate(item.date, "dayMonth")}
                                <span className="stand-schedule__item-time">
                                  {this.scheduleDate(item.date, "time")}{" "}
                                  <span className="stand-schedule__item-timezone">МСК</span>
                                </span>
                              </div>
                              <div className="stand-schedule__item-day">
                                {this.scheduleDate(item.date, "dayOfWeek")}
                              </div>
                              <div className="stand-schedule__item-title">{item.title}</div>
                            </div>
                            <div className="stand-schedule__item-btn-wrapper">
                              <a
                                href={`https://profi.travel${item.path}`}
                                className="stand-schedule__item-btn btn btn--red"
                                type="submit"
                                style={{ fontWeight: "normal" }}
                              >
                                Записаться
                              </a>
                              <div className="stand-schedule__item-info">
                                {/*<div className="stand-schedule__item-views">*/}
                                {/*  {item.viewsCount} просмотров*/}
                                {/*</div>*/}
                                <div className="stand-schedule__item-participants">
                                  {item.participantsCount} участников
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              {!this.state.isAllShowed ? (
                <>
                  <Link
                    onClick={() => {
                      this.setState({ isAllShowed: true });
                      console.log(this.state.isAllShowed);
                    }}
                    className="stand-schedule__more-link"
                    to="#"
                  >
                    Показать ещё презентации
                    <img src={require("@public/images/stand-arrow.svg")} alt="" />
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Schedule)
);
