import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";
import moment from "moment-timezone";

export default class Schedule {
  public static async sectionList() {
    useDispatch(Action.Schedule.setStatus("loading"));
    try {
      const response = await new Model.Schedule().list();
      useDispatch(Action.Schedule.setSectionList(response.data));
      useDispatch(Action.Schedule.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }

  public static async section(
    section_id: number,
    date_list: string[]
  ): Promise<Record<string, unknown[]> | undefined> {
    try {
      useDispatch(Action.Schedule.setStatus("loading"));

      const response = await new Model.Schedule().section(section_id);
      const schedule: Record<string, unknown[]> = {};

      useDispatch(Action.Schedule.setList(response.data));

      response.data.forEach((item: any) => {
        const dateFormat = moment(item.date).tz("Europe/Moscow").format();

        if (!schedule.hasOwnProperty(dateFormat)) {
          schedule[dateFormat] = [];
        }

        schedule[dateFormat].push(item);
      });

      date_list.forEach((item, index) => {
        const dateFormat = moment
          .utc(item, "DD.MM.YYYY")
          .tz("Europe/Moscow")
          .format();
        const dateEndFormat = moment
          .utc(item, "DD.MM.YYYY")
          .tz("Europe/Moscow")
          .add(18, "h")
          .format();

        if (!schedule.hasOwnProperty(dateFormat)) {
          schedule[dateFormat] = [];
        }

        schedule[dateFormat].push({
          label: "HELLO",
          date: dateFormat,
          dateEnd: dateEndFormat,
          networkingDay: true,
          id: 1000 + index,
        });
      });

      useDispatch(Action.Schedule.setModifiedList(schedule));
      useDispatch(Action.Schedule.setStatus("loaded"));
      return schedule;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async subscribe(event_id: number, event_type: string) {
    try {
      // useDispatch(Action.Schedule.setStatus("loading"));
      const response =
        event_type === "webinar"
          ? await new Model.Webinar().subscribe(event_id)
          : event_type === "broadcast"
          ? await new Model.Broadcast().subscribe(event_id)
          : null;

      useDispatch(Action.Schedule.patch(response.data));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
