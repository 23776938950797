import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Auth extends ModelPrototype {
  public async me(): Promise<any> {
    try {
      return this.withUrl(`user`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async checkEmail(data: { login: string }): Promise<any> {
    try {
      return this.withUrl(`auth/email/check`).setMethod(Method.POST).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async signin(data: { login: string; password: string }): Promise<any> {
    try {
      return this.withUrl(`auth/signin`).setMethod(Method.POST).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async signinConfirm(): Promise<any> {
    try {
      return this.withUrl(`auth/set`).setMethod(Method.PUT).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async signout(): Promise<any> {
    try {
      return this.withUrl(`auth/signout`).setMethod(Method.POST).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async signup(data: { registration_form: any; captcha: string }): Promise<any> {
    try {
      return (
        this.withUrl(`auth/signup`)
          .setMethod(Method.POST)
          // .emulateForm()
          .request(data)
      );
    } catch (Exception) {
      throw Exception;
    }
  }

  public async session(): Promise<any> {
    try {
      return this.withUrl(`auth/token`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async passwordRestore(data: { login: string }): Promise<any> {
    try {
      return this.withUrl(`auth/password/restore`).setMethod(Method.POST).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async passwordReset(data: { password: string; token: string }): Promise<any> {
    try {
      return this.withUrl(`auth/password/reset`).setMethod(Method.PUT).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
