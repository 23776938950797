import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class User extends ModelPrototype {
  public async get(user_id: number): Promise<any> {
    try {
      return this.withUrl(`user/${user_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async find(data: { text: string }): Promise<any> {
    try {
      return this.withUrl(`user/search`).setMethod(Method.GET).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
