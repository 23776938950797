import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

const Specializations = (): ReactElement => {
  const specializations = useSelector(
    (state: any) => state.Company?.Current?.data?.specializations
  );
  const specializationsList = useSelector((state: any) => state.Company?.Specialization?.list);

  return (
    <>
      {specializations?.length > 0 ? (
        <>
          <div className="stand-spec stand__block">
            <div className="stand-spec__header">
              <p className="stand-spec__title">Специализация компании</p>
            </div>
            <div className="stand-spec__body">
              <ul className="stand-spec__list">
                {specializationsList?.map((item: any, index: number) => {
                  if (specializations?.includes(item.id)) {
                    return (
                      <li className="stand-spec__item" key={item.id}>
                        {item.name}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Specializations;
