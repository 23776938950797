import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch } from "react-router-dom";
import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import PasswordChanged from "./password-changed";
import RegistrationComplete from "./registration-complete";

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <>
        <Switch>
          <Router path="/static/password-changed" exact={true} component={PasswordChanged} />
          <Router
            path="/static/registration-complete"
            exact={true}
            component={RegistrationComplete}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
