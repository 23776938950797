import React, { ReactElement } from "react";
import { Checkbox } from "@components/form";
import { useState } from "@helpers/Store";
import * as Type from "@interface/index";

interface IProps {
  setNetworkingProfile: any;
  networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  editMode: boolean;
}

const About = ({
  networkingProfile,
  setNetworkingProfile,
  editMode,
}: IProps): ReactElement => {
  const onChangeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    networkingProfile.about = event.target.value;
    setNetworkingProfile({ ...networkingProfile });
  };

  if (editMode) {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">О себе</p>
          </div>
          <div className="profile-netw-step__main">
            <div className="textarea">
              <textarea
                className="textarea__input"
                name="about"
                rows={5}
                onChange={onChangeHandler}
                maxLength={500}
              >
                {networkingProfile?.about}
              </textarea>
              <div className="textarea__counter">
                {500 -
                  (networkingProfile?.about
                    ? networkingProfile?.about?.length
                    : 0)}{" "}
                знаков
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">О себе</p>
          </div>
          <div className="profile-netw-step__main">
            {networkingProfile?.about}
          </div>
        </div>
      </div>
    );
  }
};

export default About;
