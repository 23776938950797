import { PreloaderComponent } from "@components/preloader";
import React from "react";
import { useSelector } from "react-redux";

interface partner {
  id: number;
  image: string;
  sort: number;
  title?: string;
  type: string;
}

const Partners: React.FC = () => {
  const participants = useSelector(
    (state: any) => state.Main.Workshop.data?.partners
  );
  const organizers = participants?.filter(
    (partner: partner) => partner.type === "Организатор"
  );
  const partners = participants?.filter(
    (partner: partner) => partner.type === "Партнёры выставки"
  );

  const sorting = (array: partner[]) => {
    array?.sort((x: partner, y: partner) => x.sort - y.sort);
  };

  sorting(organizers);
  sorting(partners);

  if (!participants || participants.length === 0) {
    return (
      <section className="partners flex items-center">
        <PreloaderComponent />
      </section>
    );
  }

  return (
    <>
      <div className="cont">
        <section className="partners">
          {participants?.length > 0 && (
            <div
              className={`partners__wrapper ${
                partners?.length === 0 ? "partners__wrapper--two-item" : ""
              }`}
            >
              {organizers?.length > 0 ? (
                <div className="partners__group partners__group--org">
                  <div className="partners__title">Организатор:</div>
                  <ul className="partners__list">
                    {organizers.map((partner: partner) => {
                      return (
                        <li className="partners__item" key={partner.id}>
                          <img src={partner.image} alt={partner.title} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <PreloaderComponent />
              )}

              {partners?.length > 0 ? (
                <div className="partners__group partners__group--org">
                  <div className="partners__title">Партнеры:</div>
                  <ul className="partners__list">
                    {partners.map((partner: partner) => {
                      return (
                        <li className="partners__item" key={partner.id}>
                          <img src={partner.image} alt={partner.title} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <PreloaderComponent />
              )}
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Partners;
