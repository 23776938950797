import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";

import Style from "./Networking.module.scss";

const Networking: React.FC = () => {
  const networking = useSelector(
    (store: any) => store.Profile?.data?.useNetworking
  );
  const fillStatus = useSelector(
    (store: any) => store.Profile?.data?.networkingFillStatus
  );
  const netwDays = useSelector((state) => state.Networking?.Days.data);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const submit = (): void => {
    setLoading(true);
    new Model.Profile()
      .setNetworkingStatus(!networking)
      .then(() => {
        Middleware.Profile.load().then(() => {
          setLoading(false);
          // ! костыль, статус useNetworking почему-то обратный (не обновляется)
          if (fillStatus < 3 && !networking) {
            history.push("/cabinet/networking/reg");
          } else if (fillStatus === 3 && !networking) {
            history.push("/cabinet/networking/profile");
          } else {
            return;
          }
        });
      })
      .catch(() => setLoading(false));
  };

  if (networking === undefined) {
    return <></>;
  }

  return (
    <div className={`${Style.block} cabinet__block`}>
      {networking && fillStatus === 3 ? (
        <div className={[Style.title, Style.titleGreen].join(" ")}>
          Вы участник <span>Нетворкинга</span>
        </div>
      ) : (
        <div className={[Style.title, Style.titleRed].join(" ")}>
          Ваш профиль не участвует в <span>Нетворкинге</span>
        </div>
      )}

      {networking && fillStatus === 3 ? (
        <div className={Style.description}>
          Вы можете {netwDays?.length ? "назначать встречи, " : null}
          обмениваться визитками с другими участниками системы Делового общения.
        </div>
      ) : (
        <div className={Style.description}>
          Вы не можете {netwDays?.length ? "назначать встречи, " : null}
          обмениваться визитками с другими участниками системы Делового общения.
        </div>
      )}

      <div className={Style.footer}>
        <Link
          to="#"
          className={`btn btn--medium ${
            networking && fillStatus === 3 ? "btn--grey" : "btn--red"
          }`}
          onClick={submit}
        >
          {loading ? (
            <span>Загрузка...</span>
          ) : networking && fillStatus === 3 ? (
            <span>Отключить опцию</span>
          ) : (
            <span>Подключить опцию</span>
          )}
        </Link>
        {networking && fillStatus === 3 ? (
          <div className={Style.footerDescription}>
            Ваш доступ к вебинарам, стендам и рейтингу Loyalty сохранится.
          </div>
        ) : (
          <div className={Style.footerDescription}>
            Это бесплатно. Ваш доступ к вебинарам, стендам и рейтингу Loyalty
            сохранится.
          </div>
        )}
      </div>
    </div>
  );
};

export default Networking;
