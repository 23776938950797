import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter, Link } from "react-router-dom";
// import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  show: boolean;
}

class InputDropdown extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      show: false,
    };
  }

  public render() {
    return (
      <>
        <label className="visually-hidden" htmlFor="companytype">
          Тип компании *
        </label>

        <div tabIndex={0} className={`input-dd ${!this.state.show ? "input-dd--close" : ""}`}>
          <div
            className="input-dd__trigger"
            onClick={() => this.setState({ show: !this.state.show })}
          >
            <span>Тип компании: </span> Принимающие компании за рубежом, Турагентство, ОТА, Отель,
            Санаторий, Природный парк
          </div>
          <div className="input-dd__main">
            <div className="input-dd__item">
              <input className="visually-hidden" type="checkbox" name="dd1" id="dd1" />
              <label htmlFor="dd1">НТО</label>
            </div>

            <div className="input-dd__group">
              <div className="input-dd__group-title">Туроператоры</div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd2" id="dd2" />
                <label htmlFor="dd2">Принимающие компании за рубежом</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd3" id="dd3" />
                <label htmlFor="dd3">Отправляющие туроператоры в России</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd4" id="dd4" />
                <label htmlFor="dd4">Круизные туроператоры</label>
              </div>
            </div>
            <div className="input-dd__group">
              <div className="input-dd__group-title">Туристические агентства</div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd21" id="dd21" />
                <label htmlFor="dd21">Турагентства</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd22" id="dd22" />
                <label htmlFor="dd22">Авиакассы</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd23" id="dd23" />
                <label htmlFor="dd23">ОТА</label>
              </div>
            </div>
            <div className="input-dd__group">
              <div className="input-dd__group-title">Средства размещения</div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd31" id="dd31" />
                <label htmlFor="dd31">Отели</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd32" id="dd32" />
                <label htmlFor="dd32">Курорты</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd33" id="dd33" />
                <label htmlFor="dd33">Санатории</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd34" id="dd34" />
                <label htmlFor="dd34">Медицинские центры</label>
              </div>
            </div>
            <div className="input-dd__group">
              <div className="input-dd__group-title">Транспорт</div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd41" id="dd41" />
                <label htmlFor="dd41">Авиакомпании</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd42" id="dd42" />
                <label htmlFor="dd42">ЖД-операторы</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd43" id="dd43" />
                <label htmlFor="dd43">Аренда автомобилей</label>
              </div>
            </div>
            <div className="input-dd__group">
              <div className="input-dd__group-title">Объекты показа</div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd51" id="dd51" />
                <label htmlFor="dd51">Музеи</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd52" id="dd52" />
                <label htmlFor="dd52">Парки развлечений</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd53" id="dd53" />
                <label htmlFor="dd53">Природные парки</label>
              </div>
              <div className="input-dd__item">
                <input className="visually-hidden" type="checkbox" name="dd54" id="dd54" />
                <label htmlFor="dd54">Другое</label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(InputDropdown)
);
