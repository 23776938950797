import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class UserSelected {
  public static async load(user_id: number) {
    useDispatch(Action.User.Selected.setStatus("loading"));
    try {
      const response = await new Model.User().get(user_id);
      useDispatch(Action.User.Selected.setData(response.data));
      useDispatch(Action.User.Selected.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }

  public static async setUserCutawayStatus(status: number) {
    try {
      useDispatch(Action.User.Selected.setUserCutawayStatus(status));
    } catch (Exception) {}
  }

  public static async clear() {
    try {
      useDispatch(Action.User.Selected.setData(null));
      useDispatch(Action.User.Selected.setStatus("waiting"));
    } catch (Exception) {}
  }
}
