import React from "react";

interface BorderedTextProps {
  text: string;
}

const BorderedText = ({ text }: BorderedTextProps): JSX.Element => {
  return (
    <>
      <div className="w-fit leading-[20.8px] border border-solid border-red-500 rounded-full py-[8.5px] px-5 lg:text-lg xl:text-2xl xl:py-2.5 xl:px-8">
        <p>{text}</p>
      </div>
    </>
  );
};

export default BorderedText;
