import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class DashboardPage extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount(): void {
    document.title = "Login" + " | " + process.env.SITE_NAME;
    this.props.Dispatch(Middleware.Ui.Footer.hide());
  }

  public render() {
    return (
      <>
        <div className="auth auth--reg-complete">
          <div className="auth__wrapper auth__wrapper--big">
            <div className="auth__header ">
              <h1>Проверьте почту!</h1>
              <div className="form__group--margin">
                Мы отправили ссылку на подтверждение аккаунта на вашу почту.
                Пожалуйста, проверьте папку «Спам», если письма нет во
                «Входящих».
              </div>
            </div>
            <Link to="/" className="auth__btn btn btn--red">
              Ок
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(DashboardPage);
