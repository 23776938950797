import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class AuthJWT extends ModelPrototype {
  public BaseUrl = process.env.API_URL_AUTH + "/";

  public async signin(data: {
    id: number;
    firstname: string;
    lastname: string;
    avatar: string;
    email: string;
    phone: string;
    position: string;
    company: string;
    company_id: number;
    city: string;
    country_id: number;
    country: string;
  }): Promise<any> {
    try {
      return this.withUrl("api/auth/signin")
        .setMethod(Method.POST)
        .withoutCredentials()
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
