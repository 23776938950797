import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class UserRating {
  public static async load() {
    useDispatch(Action.UserRating.setStatus("loading"));
    try {
      const response = await new Model.UserRating().get();
      useDispatch(Action.UserRating.setData(response.data));
      useDispatch(Action.UserRating.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      useDispatch(Action.UserRating.setError(Exception.statusText));
      throw Exception;
    }
  }
}
