/* eslint-disable @typescript-eslint/indent */
import ModelPrototype, { Method } from "@models/ModelPrototype";
import { ProfileInterface } from "@interface/store/Profile";

export default class Profile extends ModelPrototype {
  public async get(): Promise<{ data: ProfileInterface }> {
    try {
      return this.withUrl(`user/profile`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  /**
   * Change user avatar
   *
   * @param avatar base64 string
   *
   * @returns Promise
   */
  public async avatar(avatar: string): Promise<any> {
    try {
      return this.withUrl(`user/profile/image`)
        .setMethod(Method.PATCH)
        .request({
          user_image_form: { fileObject: avatar },
        });
    } catch (Exception) {}
  }

  /**
   * Change user password
   *
   * @returns Promise
   */
  public async password(form: {
    password: string;
    newpassword: string;
    passwordconfirm: string;
  }): Promise<any> {
    try {
      return this.withUrl(`user/profile/password`).setMethod(Method.PATCH).request({
        user_change_password_form: form,
      });
    } catch (Exception) {}
  }

  public async update(data: {
    firstname?: string;
    lastname?: string;
    country_id?: number | null;
    city_id?: number | null;
    worktype?: number;
    companyname?: string;
    companytypes?: any[];
    position?: string;
    phone?: string;
    email?: string;
    avatar?: string | null;
    password?: string;
    passwordconfirm?: string;
  }): Promise<{ data: ProfileInterface }> {
    try {
      return this.withUrl(`user/profile`)
        .setMethod(Method.PUT)
        .request({
          user_network_profile_form: {
            email: data.email,
            profile: {
              firstName: data.firstname,
              lastName: data.lastname,
              position: data.position,
              phone: data.phone?.replace(/[^+\d]/g, ""),
              workName: data.companyname,
              ...(data.avatar ? { fileObject: data.avatar } : {}),
            },
            networkingProfile: {
              country: data.country_id,
              city: data.city_id,
              employmentType: data.worktype,
            },
            companyTypes: data.worktype === 1 ? data.companytypes : [],
            ...(data.password
              ? {
                  plainPassword: {
                    first: data.password,
                    second: data.passwordconfirm,
                  },
                }
              : {}),
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async updateAdditional(data: {
    interestCompanyTypes: number[];
    specializations: number[];
    languages: number[];
    directions: number[];
    about: string;
  }): Promise<{ data: ProfileInterface }> {
    try {
      return this.withUrl(`user/profile/additional`)
        .setMethod(Method.PUT)
        .request({
          additional_fields_profile: {
            interestCompanyTypes: data.interestCompanyTypes,
            specializations: data.specializations,
            languages: data.languages,
            directions: data.directions,
            networkingProfile: {
              about: data.about,
            },
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async setNetworkingStatus(status: boolean): Promise<{ data: ProfileInterface }> {
    try {
      return this.withUrl(`user/set/networking`)
        .setMethod(Method.PUT)
        .request({ useNetworking: status });
    } catch (Exception) {
      throw Exception;
    }
  }
}
