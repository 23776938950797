import React, { ReactElement, useEffect, useState } from "react";
import { InputAutocomplete } from "@components/form";
import { Link, useHistory } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import * as Model from "@models/index";

interface IProps {
  currentStep: number;
  isContactsValid: boolean;
  setCurrentStep: (value: number) => void;
  company: any;
  profileReload: any;
  required?: boolean;
}

const RegStep6 = ({
  currentStep,
  isContactsValid,
  setCurrentStep,
  company,
  profileReload,
  required,
}: IProps): ReactElement => {
  const history = useHistory();

  const onSelectHandler = (event: any): void => {
    if (!event.target.networkingStatus && !event.target.currentWorkshopStatus) {
      // Юзер уходит создавать компанию
      Middleware.Company.Current.load(Number(event.target.value), true).then(
        () => {
          history.push(`/company/create/${event.target.value}`);
        }
      );
    } else if (
      event.target.networkingStatus &&
      !event.target.currentWorkshopStatus
    ) {
      // Юзер начинает управлять компанией
      Middleware.Company.Employee.invite(event.target.value).then(
        (response: any) => {
          profileReload().then((profile: any) => {
            history.push(`/company/${event.target.value}/edit`);
          });
        }
      );
    } else if (
      event.target.networkingStatus &&
      event.target.currentWorkshopStatus
    ) {
      // Юзер присоединяется к компании
      Middleware.Company.Employee.invite(event.target.value).then(
        (response: any) => {
          profileReload();
        }
      );
    }
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isContactsValid) {
      let ProgressAlert: any;
      await Middleware.Ui.Alert.open("Process", {
        text: "Обновите ваши контактные данные для продолжения регистрации",
        component: (c: any) => {
          ProgressAlert = c;
        },
      });
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    } else if (isCompanySelected && isContactsValid) {
      history.push("/service/networking/enabled");
    } else {
      let ProgressAlert: any;
      await Middleware.Ui.Alert.open("Process", {
        text: "Выберите компанию из списка или создайте страницу вашей компании",
        component: (c: any) => {
          ProgressAlert = c;
        },
      });
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  };

  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);

  useEffect(() => {
    setIsCompanySelected(company?.status !== undefined && company?.status > 0);
  }, [company]);

  return (
    <>
      <section className="cabinet__block">
        <form onSubmit={onSubmitHandler} className="profile-netw-step">
          <div className="profile-netw-step__header">
            <span> Шаг {currentStep}</span> / 6
          </div>
          <div className="profile-netw-step__body">
            <div className="profile-netw-step__body-header">
              <p
                className={`profile-netw-step__title ${
                  isCompanySelected ? "" : "profile-netw-step__title--big"
                } `}
              >
                {required && !isCompanySelected ? <span>* </span> : <></>}
                {isCompanySelected
                  ? "Ваша компания"
                  : "Присоединитесь к странице вашей компании"}
              </p>
              {!isCompanySelected ? (
                <p className="profile-netw-step__required">
                  {required
                    ? "Обязательный пункт"
                    : "Вы можете заполнить этот пункт потом"}
                </p>
              ) : (
                <></>
              )}
            </div>

            {isCompanySelected ? (
              <></>
            ) : (
              <p className="profile-netw-step__desc profile-netw-step__desc--company">
                Обратите внимание: название компании может быть указано на
                русском или английском языке. Попробуйте искать в двух
                вариантах.
              </p>
            )}

            <div
              className={`profile-netw-step__main ${
                isCompanySelected
                  ? "profile-netw-step__main--company"
                  : "profile-netw-step__main--company-select"
              }`}
            >
              {isCompanySelected ? (
                <>
                  <div className="profile-netw-step__company-image">
                    {company?.companyImage !== null ? (
                      <img src={company?.companyImage} alt={company?.title} />
                    ) : (
                      <img
                        src={require("@public/images/defaultAvatarCompany.svg")}
                      />
                    )}
                  </div>
                  <div className="profile-netw-step__company-title">
                    {company.title}
                  </div>
                  {company.status === 0 ? (
                    <div className="profile-netw-step__company-status">
                      На модерации
                    </div>
                  ) : (
                    <button className="profile-netw-step__company-status">
                      Отвязать компанию
                    </button>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <InputAutocomplete
                    name="company_id"
                    type="company"
                    placeholder="Введите название компании"
                    onSelect={onSelectHandler}
                    withCompanyStatus={true}
                  />
                  <Link
                    className="profile-netw-step__company-create-link"
                    to="/company/create"
                  >
                    Моей компании нет в списке
                  </Link>
                </>
              )}
            </div>
          </div>

          <div className="profile-netw-step__footer">
            <button
              type="submit"
              className={`${
                isCompanySelected && isContactsValid ? "btn--red" : "btn--grey"
              } btn `}
            >
              Готово!
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default RegStep6;
