import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";
import PreloaderComponent from "@components/preloader/PreloaderComponent";
import ScheduleViews from "@pages/dashboard/components/Schedule/ScheduleViews";
import ScheduleListView from "@pages/dashboard/components/Schedule/ScheduleListView";
import ScheduleTableView from "@pages/dashboard/components/Schedule/ScheduleTableView";
import ScheduleFilter from "./ScheduleFilter";

// В эти дни висит плашка "Спецэфир"
export const specialAirtime = [
  "11 февраля",
  "13 февраля",
  "18 февраля",
  "20 февраля",
  "25 февраля",
  "27 февраля",
  "28 февраля",
  "13 сентября",
];

// Эти эвенты будут с бордером
export const specialAirtimeId = [
  168638, 168634, 168635, 168636, 168637, 168640, 168639,
];

const scheduleWizard = {
  startHour: 10,
  startMinute: 0,
  dayPeriodLength: 60,
  dayPeriodCount: 9,
};

const Schedule: React.FC = () => {
  const ref = useRef(null);
  const location = useLocation();
  const scheduleData = useSelector((state: any) => state.Schedule);
  const [isTableView, setIsTableView] = useState(false);
  const [weeks, setWeeks] = useState<any>({});
  const workshopData = useSelector((state: any) => state.Main.Workshop);
  const firstWeek = moment(workshopData.data.startAt)
    .tz("Europe/Moscow")
    .format("w");
  const networkingDays = useSelector(
    (state: any) => state.Networking.Days.data
  );
  const previousNetworkingDays = useRef<any>(networkingDays);
  const scheduleStatus = useSelector((state: any) => state.Schedule.status);
  const [currentWeek, setCurrentWeek] = useState<number>(1); // для табличного вида
  const [activeWeek, setActiveWeek] = useState<number>(0); // 0 - "все дни"
  const scheduleId = Number(process.env.SCHEDULE_ID);

  useEffect(() => {
    if (scheduleData.status === "loaded") {
      const newWeeks: any = {};

      for (const [date, arr] of Object.entries(scheduleData.modifiedList)) {
        const dateWeekFormat = moment(date).tz("Europe/Moscow").format("w");

        if (!newWeeks[dateWeekFormat]) {
          newWeeks[dateWeekFormat] = [];
        }

        newWeeks[dateWeekFormat] = [...newWeeks[dateWeekFormat], ...arr];
      }

      setWeeks(newWeeks);
    }
  }, [scheduleData]);

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "schedule-anchor" && ref) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  useEffect(() => {
    if (
      JSON.stringify(previousNetworkingDays.current) !==
      JSON.stringify(networkingDays)
    ) {
      Middleware.Schedule.section(scheduleId, networkingDays);
      previousNetworkingDays.current = networkingDays;
    }
  }, [networkingDays]);

  useEffect(() => {
    // Middleware.Schedule.sectionList();
    Middleware.Schedule.section(scheduleId, networkingDays);
  }, []);

  useEffect(() => {
    if (workshopData.data.startAt && workshopData.data.endAt) {
      const firstEventDate = moment(workshopData.data.startAt)
        .tz("Europe/Moscow")
        .format();
      const lastEventDate = moment(workshopData.data.endAt)
        .tz("Europe/Moscow")
        .format();
      const lastWeek = moment(lastEventDate).tz("Europe/Moscow").format("w");
      const now = moment().tz("Europe/Moscow").format();

      if (now < firstEventDate) {
        setCurrentWeek(Number(firstWeek));
      } else if (now > lastEventDate) {
        setCurrentWeek(Number(lastWeek));
      } else {
        setCurrentWeek(Number(moment(now).tz("Europe/Moscow").format("w")));
      }
    }
  }, [
    workshopData.data.endAt,
    workshopData.data.startAt,
    firstWeek,
    setCurrentWeek,
  ]);

  return (
    <section className="schedule" id="schedule-anchor" ref={ref}>
      <div className="cont">
        <div className="schedule__header">
          <p className="title-middle">Расписание интенсива</p>
          <div className="schedule__wrapper-filter">
            <ScheduleFilter
              activeWeek={activeWeek}
              setActiveWeek={setActiveWeek}
              setCurrentWeek={setCurrentWeek}
              weeks={weeks}
              isTableView={isTableView}
              currentWeek={currentWeek}
            />
            {/* Возможность выбора "таблица/список" скрыта до починки всех багов таблицы
            <div className="schedule__flow-header">
              <div
                className={`schedule__flow-view ${
                  isTableView ? "schedule__flow-view--active" : ""
                }`}
                onClick={() => {
                  setIsTableView(true);
                }}
              >
                <p>Таблица</p>
                <img
                  src={require("@public/images/schedule/schedule-table.svg")}
                  alt="Таблица"
                />
              </div>
              <div
                className={`schedule__flow-view ${
                  isTableView ? "" : "schedule__flow-view--active"
                }`}
                onClick={() => {
                  setIsTableView(false);
                }}
              >
                <p>Список</p>
                <img
                  src={require("@public/images/schedule/schedule-list.svg")}
                  alt="Список"
                />
              </div>
            </div> */}
          </div>
        </div>
        <div className="schedule__flow" id="schedule__flow-1">
          {scheduleStatus === "loading" ? (
            <div className="schedule__preloader">
              <PreloaderComponent />
            </div>
          ) : (
            <ScheduleViews>
              <ScheduleListView
                weeks={weeks}
                activeWeek={activeWeek}
                scheduleWizard={scheduleWizard}
                isShowed={!isTableView}
              />
              <ScheduleTableView
                activeWeek={activeWeek > 0 ? activeWeek : currentWeek} // если в списке выбраны "все дни", то таблица использут свой стейт currentWeek
                scheduleWizard={scheduleWizard}
                weeks={weeks}
                isShowed={isTableView}
              />
            </ScheduleViews>
          )}
        </div>
      </div>
    </section>
  );
};

export default Schedule;
