import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Pages extends ModelPrototype {
  public BaseUrl = process.env.API_URL_VIDYOIO + "/";

  public async generateToken(data: { room: string; name: string }): Promise<any> {
    try {
      return this.withUrl("api/room/" + data.room)
        .setMethod(Method.POST)
        .request({ name: data.name });
    } catch (Exception) {
      throw Exception;
    }
  }
}
