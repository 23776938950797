import React from "react";
import clsx from "clsx";

interface ThreeWeeksItemProps {
  icon: string;
  speakerPhoto: string;
  title: string;
  description: string | JSX.Element;
}

const ThreeWeeksItem = ({
  icon,
  speakerPhoto,
  title,
  description,
}: ThreeWeeksItemProps): JSX.Element => {
  return (
    <div
      className={clsx(
        "flex border-[1px] gap-2.5 border-solid border-light-gray rounded-xl p-5 md:p-8",
        {
          "w-full flex-col md:flex-row-reverse justify-between": speakerPhoto,
          "md:w-[calc(50%-1.25rem)] lg:md:w-[calc(50%-2rem)] md:flex-1 flex-col":
            !speakerPhoto,
        }
      )}
    >
      {icon && <img className="w-[45px] h-[45px]" src={icon} alt="Спикер" />}
      {speakerPhoto && (
        <div className="relative w-fit">
          <img
            className="w-[80px] h-auto rounded-full object-cover md:w-[162.79px]"
            src={speakerPhoto}
            alt="Спикер"
          />
          <img
            className="absolute w-[32.34px] md:w-[66px] h-auto leading-[40.42px] right-0 bottom-0"
            src={require(`@public/images/fire.png`)}
            alt="Огонь"
          />
        </div>
      )}
      <div
        className={clsx(
          "flex flex-1 flex-col gap-2.5 md:gap-3 lg:gap-[19px] 2xl:gap-2.5 3xl:gap-[16.4] ",
          {
            "justify-between max-w-[413px]": speakerPhoto,
          }
        )}
      >
        <p className="font-bold text-lg leading-[22.5px] md:text-24 lg:text-3xl 3xl:text-36">
          {title}
        </p>
        <p className="lg:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default ThreeWeeksItem;
