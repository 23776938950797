import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Rating {
  public static async page(pagination: any, page?: number) {
    useDispatch(Action.Main.Rating.setStatus("loading"));
    try {
      const response = await pagination.page(page);
      useDispatch(Action.Main.Rating.setList(response.data));
      useDispatch(Action.Main.Rating.setPaginator(response.paginator));
      useDispatch(Action.Main.Rating.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async pageBase(pagination: any, page?: number) {
    try {
      const response = await pagination.pageBase(page);
      useDispatch(Action.Main.Rating.setListBase(response.data));
      useDispatch(Action.Main.Rating.setPaginatorBase(response.paginator));
      useDispatch(Action.Main.Rating.setStatusBase("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async pageFull(pagination: any, page?: number) {
    try {
      const response = await pagination.pageFull(page);
      useDispatch(Action.Main.Rating.setListFull(response.data));
      useDispatch(Action.Main.Rating.setPaginatorFull(response.paginator));
      useDispatch(Action.Main.Rating.setStatusFull("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  // TODO надо будет заменить setList, setPaginator на другое, чтобы не пересекалось с page
  public static async pageAll(pagination: any, page?: number) {
    useDispatch(Action.Main.Rating.setStatus("loading"));
    try {
      const response = await pagination.pageAll(page);
      useDispatch(Action.Main.Rating.setList(response.data));
      useDispatch(Action.Main.Rating.setPaginator(response.paginator));
      useDispatch(Action.Main.Rating.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
