export default class Authorized {
  private Store: any;
  public constructor(Store: any) {
    this.Store = Store;
  }

  public validate() {
    switch (this.Store.Auth.status) {
      case "authorized":
        return { isValid: true };
      case "loading":
        return { isValid: false };
      default:
        return { isValid: false, redirectPath: "/auth/login", preurl: true };
    }
  }
}
