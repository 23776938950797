import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter, Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class FooterComponent extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        {!this.props.Store.Ui.Footer.display ? (
          <></>
        ) : (
          <footer className="footer">
            <div className="cont">
              <div className="footer__top">
                <a
                  className="footer__logo"
                  href="https://profi.travel/"
                  target="_blank"
                >
                  <img
                    alt="Profi.Travel"
                    src={require("@public/images/logo-pt.svg")}
                  />
                </a>
                <a href="https://t.me/otm_tourism" target="_blank">
                  <img
                    alt="Telegram"
                    src={require("@public/images/icon-telegram-grey.svg")}
                  />
                </a>
                <div className="footer__text">
                  ООО «Профи.Трэвел»
                  <br />
                  ИНН 6685014210
                </div>
              </div>
              <div className="footer__content">
                {/* <div className="footer__item">
                  <p className="footer__item-title">Ведущая выставки</p>
                  <p className="footer__item-name">Ульяна Тюменцева</p>
                  <Link to="mailto:ulyana@profi.travel">
                    ulyana@profi.travel
                  </Link>
                </div> */}
                <div className="footer__item footer__item--long">
                  <p className="footer__item-title">
                    По вопросам участия в онлайн-интенсиве
                  </p>
                  <p className="footer__item-name">Ирина Васильева</p>
                  <Link to="mailto:vasilyeva.i@profi.travel">
                    vasilyeva.i@profi.travel
                  </Link>
                  {/* <br />
                  <a
                    href="https://welcome.profi.travel/#form"
                    target="_blank"
                    className="footer__item-contact-us"
                  >
                    Связаться
                  </a> */}
                </div>
                {/* <div className="footer__item footer__item--long">
                  <p className="footer__item-title">По вопросам нетворкинга</p>
                  <Link to="mailto:networking@profi.travel">
                    networking@profi.travel
                  </Link>
                </div> */}
                <div className="footer__item footer__item--long">
                  <p className="footer__item-title">
                    Техническая поддержка
                    <br />
                    (вопросы, связанные с качеством трансляции)
                  </p>
                  <p className="footer__item-name">Андрей Шумов</p>
                  <Link to="mailto:shumov@profi.travel">
                    shumov@profi.travel
                  </Link>
                  <br />
                  <Link to="tel:+74997050825">
                    +7 (499) 705-08-25{" "}
                    <span className="text-green-footer">(доб.&nbsp;170)</span>
                  </Link>
                </div>
                <div className="footer__item">
                  <p className="footer__item-title">
                    По вопросам начисления&nbsp;баллов <span>Loyalty</span>
                  </p>
                  <p className="footer__item-name">Сергей Перемутов</p>
                  <a
                    href="https://t.me/joinchat/R2ZetIMuTgIwODVi"
                    target="_blank"
                    className="footer__item-loyalty"
                  >
                    Чат поддержки Loyalty
                    <span className="footer__item-loyalty-tg" />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(FooterComponent)
);
