import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import * as Action from "@actions/index";
import { ITariff } from "@pages/dashboard/components/Price";
import { TariffDowngradeModal } from "./TariffDowngradeModal";
import * as Middleware from "@middlewares/index";

// TODO: вынести интерфейсы, много где нужны

interface Price {
  rub: number;
  loyalty: number;
}

interface Description {
  text: string;
  description: string;
  isMissing?: boolean;
}

interface IProps {
  authStatus: string;
  currentTariff: number;
  currentTariffName: string;
  now: string;
  promoDate: string;
  tariff: ITariff;
  benefits?: Description[];
  isArchive?: boolean;
  isArchiveButtonVisible?: boolean;
  changeStep?: (step: number) => void;
}

const Tariff: React.FC<IProps> = ({
  authStatus,
  currentTariff,
  currentTariffName,
  now,
  promoDate,
  tariff,
  benefits = [],
  isArchive,
  isArchiveButtonVisible,
  changeStep,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isHaveThisTariff = currentTariff && currentTariff === tariff.type;

  return (
    <>
      <div className={`tariff price__item`} key={tariff.id}>
        {currentTariff === tariff.type && (
          <div className="tariff__tag">Ваш тариф</div>
        )}
        <div className="tariff__header">
          <h3 className="tariff__title">{tariff.name}</h3>
          <div className="tariff__price">
            {now < promoDate ? (
              <>
                <div className="tariff__old-price">
                  <span>{tariff.price.rub}&nbsp;₽</span>/ {tariff.price.loyalty}{" "}
                  Loyalty
                </div>
                <div className="tariff__new-price">
                  {tariff.newPrice.rub}&nbsp;₽ / {tariff.newPrice.loyalty}{" "}
                  Loyalty с{" "}
                  {moment(promoDate).tz("Europe/Moscow").format("D MMMM")}
                </div>
              </>
            ) : (
              <div className="tariff__old-price">
                <span>{tariff.newPrice.rub}&nbsp;₽</span>/{" "}
                {tariff.newPrice.loyalty} Loyalty
              </div>
            )}
          </div>
        </div>
        <div className="tariff__divider" />
        <div className="tariff__description">
          <ul className="tariff__features">
            {benefits.map((item: Description, index: number) => {
              return (
                <li
                  className={`tariff__features-item ${
                    item.isMissing ? "tariff__features-item-missing" : ""
                  }`}
                  key={index}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </div>
        {authStatus !== "authorized" ? (
          <Link
            to="#"
            className="btn btn--medium btn--red tariff__btn"
            onClick={() => {
              if (window.location.pathname.startsWith("/auth")) {
                dispatch(Action.Auth.setPreurl("/"));
              } else {
                dispatch(Action.Auth.setPreurl(window.location.pathname));
              }

              history.push("/auth/signin");
            }}
          >
            Купить
          </Link>
        ) : isArchive && isArchiveButtonVisible ? (
          <Link
            to={"/tariffs-and-payment"}
            className="btn btn--medium btn--red price__item-btn"
            onClick={() => {
              Middleware.Payment.tariff(tariff.id);
              if (changeStep) {
                changeStep(1);
              }
              window.scrollTo(0, 0);
            }}
          >
            Купить
          </Link>
        ) : isArchive && !isArchiveButtonVisible ? (
          <Link
            to=""
            className="btn btn--medium btn--red price__item-btn disabled"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            У вас есть доступ к архивным записям
          </Link>
        ) : (
          <Link
            to={"/tariffs-and-payment"}
            className={`btn btn--medium btn--red tariff__btn ${
              isHaveThisTariff && "disabled"
            }`}
            onClick={(event) => {
              if (isHaveThisTariff) {
                event.preventDefault();
              } else if (currentTariff > tariff.type) {
                event.preventDefault();
                setIsModalVisible(true);
              } else {
                window.scrollTo(0, 0);
              }
            }}
          >
            {isHaveThisTariff
              ? `Подключен тариф ${tariff.name}`
              : currentTariff > 0
              ? `Сменить тариф ${currentTariffName} на ${tariff.name}`
              : "Купить"}
          </Link>
        )}
      </div>
      {isModalVisible && (
        <TariffDowngradeModal
          newTariffName={tariff.name}
          handlerOnClose={() => {
            setIsModalVisible(false);
          }}
        />
      )}
    </>
  );
};

export default Tariff;
