import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {}

class About extends React.Component<PropsInterface, StateInterface> {
  constructor(Props: PropsInterface) {
    super(Props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
    };
  }
  public dataMarkup() {
    return { __html: this.props.data };
  }

  public render() {
    return (
      <>
        {this.props.data || this.props.Store.Stand.Selected.data?.directions.length > 0 ? (
          <>
            <div className="stand__block stand-about">
              <div className="stand-about__title">{this.props.title}</div>
              <div className="stand-about__content" dangerouslySetInnerHTML={this.dataMarkup()} />
              {this.props.Store.Stand.Selected.data?.directions.length > 0 ? (
                <>
                  <div className="stand-about__directions">
                    <div className="stand-about__directions-title">Направления работы</div>
                    <div className="stand-about__directions-list">
                      {this.props.Store.Company.Direction.list
                        .filter((direction) =>
                          this.props.Store.Stand.Selected.data?.directions?.includes(direction.id)
                        )
                        .map((direction: any, index: number) => {
                          return (index === 0 ? "" : ", ") + direction.name;
                        })}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(About)
);
