import React from "react";
import moment from "moment-timezone";

interface IProps {
  specialClass: string;
  specialDayDate: string;
  specialDayName?: string;
  webinar: any;
}

const ScheduleItem: React.FC<IProps> = ({
  specialClass,
  specialDayDate,
  specialDayName,
  webinar,
}) => {
  return (
    <div className={`payment__schedule-item ${specialClass}`}>
      <div className="payment__schedule-item-header">
        {specialDayDate}
        {specialDayName ? (
          <>
            <span className="payment__schedule-item-separator">|</span>
            <span>{specialDayName}</span>
          </>
        ) : null}
      </div>
      <div className="payment__schedule-item-body">
        <div className="payment__schedule-item-info">
          <div className="payment__schedule-item-date">
            {moment(webinar?.date).tz("Europe/Moscow").format("HH:mm")}
            <sup>МСК</sup>
          </div>
          <div className="payment__schedule-item-flag">{webinar?.label}</div>
        </div>
        {webinar?.companyTitle && (
          <div className="payment__schedule-item-company">
            {webinar?.companyTitle}
          </div>
        )}
        <a
          href={"https://profi.travel" + webinar?.path}
          className="payment__schedule-item-desc"
          target="_blank"
        >
          {webinar?.title}
        </a>
        <div className="payment__schedule-item-btn">
          <a href={"https://profi.travel" + webinar?.path} target="_blank">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="33"
                height="33"
                rx="3.5"
                stroke="#FF0011"
              />
              <path
                d="M21.5 15.634C22.1667 16.0189 22.1667 16.9811 21.5 17.366L14.75 21.2631C14.0833 21.648 13.25 21.1669 13.25 20.3971L13.25 12.6029C13.25 11.8331 14.0833 11.352 14.75 11.7369L21.5 15.634Z"
                fill="#FF0011"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ScheduleItem;
