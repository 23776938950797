import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Payment extends ModelPrototype {
  public async payLoyalty(data: {
    tariffId: number;
    tags: string;
  }): Promise<any> {
    try {
      return this.withUrl(`user/buyTariffByLoyalty`)
        .setMethod(Method.POST)
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async payMoney(data: {
    tariffId: number;
    tags: string;
  }): Promise<any> {
    try {
      return this.withUrl(`user/buyTariff`)
        .setMethod(Method.POST)
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
