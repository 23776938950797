import React, { lazy, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import StandsFilter from "@pages/dashboard/components2.0/Stands/StandsFilter";
import StandsGroups from "@pages/dashboard/components2.0/Stands/StandsGroups";

const Stands: React.FC = () => {
  const location = useLocation();

  const [standsFilter, setStandsFilter] = useState<{
    direction?: number|null;
    types?: number|null;
    specializations?: number|null;
  }>({
    direction: undefined,
    types: undefined,
    specializations: undefined
  });

  const ref = useRef(null);

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "stands-anchor" && ref) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  return (
    <section className="stands" id="stands-anchor" ref={ref}>
      <div className="cont">
        <div className="stands__header">
          <div className="flex flex-col justify-between gap-8 lg:flex-row">
            <h2 className="title-middle">Стенды</h2>
            <p className="max-w-[456px] xl:max-w-[544px] 2xl:max-w-624px 3xl:max-w-[704px] 4xl:max-w-[744px]">
              Знакомьтесь с популярными зарубежными направлениями на стендах
              участников выставки. Переходите в онлайн-каталоги, чтобы узнать
              больше о продуктах и обменяться визитками с коллегами.
            </p>
          </div>
          <StandsFilter
            callback={(filters: any) => {
              setStandsFilter(filters);
            }}
          />
        </div>
        <hr className="m-0" />
      </div>
      <StandsGroups filter={standsFilter} />
    </section>
  );
};

export default Stands;
