import React, { ReactElement } from "react";
import * as Type from "@interface/index";
import ScheduleTableDay from "./ScheduleTableDay";
import moment from "moment-timezone";

const ScheduleTableWeek = (props: any): ReactElement => {
  const days: any = { 1: [], 2: [], 3: [], 4: [], 5: [] };
  const currentDay = props.weekStartDay;

  props.events.forEach((event: any) => {
    days[moment(event.date).tz("Europe/Moscow").day()].push(event);
  });

  return (
    <>
      <div className="schedule__table-week" data-week={props.weekNum}>
        {Object.keys(days).map((day: any, index: number) => {
          return (
            <ScheduleTableDay
              key={index}
              scheduleWizard={props.scheduleWizard}
              events={days[day]}
              currentDay={
                index > 0
                  ? currentDay.add(1, "day").toDate()
                  : currentDay.toDate()
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default ScheduleTableWeek;
