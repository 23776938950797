import React, { useState } from "react";
import { useSelector } from "react-redux";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import { ITariff } from "@pages/dashboard/components/Price";
import { useCurrentTariff } from "@hooks/useCurrentTariff";

const TariffsAndPayment: React.FC = () => {
  /*
    шаг 1 - компонент с тарифами - выбрать тариф
    шаг 2 - компонент тарифа - выбрать способ оплаты
          - если рублями, то переход сразу на оплату
          - если баллами, то дальше по шагам
    шаг 3 - компонент недостаточно баллов - если выбрали оплату баллами, но их недостаточно - кнопка возврата на шаг 2
    шаг 4 - компонент оплаты баллами
    шаг 5 - отдельная страница с успешной оплатой
  */
  const profileData = useSelector((state: any) => state.Profile.data);
  const tariffs: ITariff[] = useSelector(
    (state: any) => state.Main?.Workshop?.data.tariffs
  );
  const promoDate = useSelector(
    (state: any) => state.Main?.Workshop?.data.promoDate
  );
  const selectedForPayTariff = useSelector(
    (state: any) => state.Payment?.currentTariff
  );
  const currentTariff = useCurrentTariff();
  const currentPayment = useSelector(
    (state: any) => state.Payment?.currentPayment
  );
  const thisYearRating = useSelector(
    (state: any) => state.UserRating?.data?.thisYearRating
  );

  const [currentStep, setCurrentStep] = useState<number>(1);

  const changeStep = (size: number) => {
    setCurrentStep(currentStep + size);
  };

  const isPromoPurchasePrice =
    profileData.tariffPurchasedAt >= promoDate ? false : true;

  const utmTags = localStorage.getItem("utm_data") || "{}";

  return (
    <div className="payment">
      <div className="cont payment__cont">
        {currentStep === 1 ? (
          <Step1
            promoDate={promoDate}
            tariffs={tariffs}
            changeStep={changeStep}
          />
        ) : currentStep === 2 ? (
          <Step2
            currentPayment={currentPayment}
            selectedForPayTariff={selectedForPayTariff}
            currentTariff={currentTariff}
            promoDate={promoDate}
            tariffs={tariffs}
            isPromoPurchasePrice={isPromoPurchasePrice}
            utmTags={utmTags}
          />
        ) : currentStep === 3 ? (
          <Step3
            thisYearRating={thisYearRating}
            setCurrentStep={setCurrentStep}
          />
        ) : currentStep === 4 ? (
          <Step4
            currentTariff={selectedForPayTariff}
            setCurrentStep={setCurrentStep}
            thisYearRating={thisYearRating}
            utmTags={utmTags}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TariffsAndPayment;
