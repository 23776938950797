import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";
import * as Middleware from "@middlewares/index";
import { ProfileInterface } from "@interface/store/Profile";

export default class Profile {
  public static async load(): Promise<ProfileInterface> {
    useDispatch(Action.Profile.setStatus("loading"));
    try {
      const response = await new Model.Profile().get();

      useDispatch(Action.Profile.setData(response.data));
      this.isContactsValid(response.data);
      useDispatch(Action.Profile.setStatus("loaded"));
      useDispatch(Middleware.Auth.me());
      return response.data;
    } catch (Exception) {
      return Exception;
    }
  }

  public static async isContactsValid(data: any): Promise<ProfileInterface> {
    if (
      data?.firstName?.length > 0 &&
      data?.lastName?.length > 0 &&
      data?.phone?.length > 0 &&
      data?.email?.length > 0 &&
      // data?.workName?.length > 0 &&
      data?.position?.length > 0 &&
      data?.networkingProfile?.country > 0 &&
      data?.networkingProfile?.city > 0 &&
      data?.networkingProfile?.employmentType !== null &&
      ((data?.networkingProfile?.employmentType === 1 &&
        data?.networkingProfile?.companyTypes?.length > 0) ||
        data?.networkingProfile?.employmentType === 2)
    ) {
      useDispatch(Action.Profile.setIsContactsValid(true));
      return true;
    } else {
      useDispatch(Action.Profile.setIsContactsValid(false));
      return false;
    }
    return data;
  }

  public static async update(data: {
    firstname?: string;
    lastname?: string;
    country_id?: number | null;
    city_id?: number | null;
    worktype?: number;
    companyname?: string;
    companytypes?: any[];
    position?: string;
    phone?: string;
    email?: string;
    avatar?: string | null;
    password?: string;
    passwordconfirm?: string;
  }): Promise<ProfileInterface> {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Обновляем данные профиля...",
      component: (c: any) => (ProgressAlert = c),
    });
    try {
      const response = await new Model.Profile().update(data);
      ProgressAlert.setText("Данные профиля обновлены");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      useDispatch(Action.Profile.setData(response.data));
      useDispatch(Middleware.Auth.me());
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при обновлении данных!");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      return Exception;
    }
  }

  public static async updateAdditional(data: {
    interestCompanyTypes: number[];
    specializations: number[];
    languages: number[];
    directions: number[];
    about: string;
  }): Promise<ProfileInterface> {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Обновляем данные профиля...",
      component: (c: any) => (ProgressAlert = c),
    });
    try {
      const response = await new Model.Profile().updateAdditional(data);
      ProgressAlert.setText("Данные профиля обновлены");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      useDispatch(Action.Profile.setData(response.data));
      useDispatch(Middleware.Auth.me());
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при обновлении данных!");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      return Exception;
    }
  }

  public static async employeeRemove(
    company_id: number,
    user_id: number
  ): Promise<ProfileInterface> {
    try {
      const response = await new Model.Employee().remove(company_id, { user_id });
      useDispatch(Middleware.Profile.load());
      useDispatch(Middleware.Auth.me());
      return response.data;
    } catch (Exception) {
      return Exception;
    }
  }

  /**
   * Change user avatar
   *
   * @param avatar base64
   *
   * @returns Promise
   */
  public static async avatar(avatar: string): Promise<ProfileInterface> {
    try {
      const response = await new Model.Profile().avatar(avatar);
      await Middleware.Profile.load();
      Middleware.Auth.me();
      return response.data;
    } catch (Exception) {
      return Exception;
    }
  }

  /**
   * Change user password\
   *
   * @returns Promise
   */
  public static async password(form: {
    password: string;
    newpassword: string;
    passwordconfirm: string;
  }): Promise<ProfileInterface> {
    try {
      const response = await new Model.Profile().password(form);
      await Middleware.Profile.load();
      Middleware.Auth.me();
      return response.data;
    } catch (Exception) {
      return Exception;
    }
  }
}
