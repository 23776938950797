import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Link, withRouter } from "react-router-dom";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { userCutawayStatus } from "@helpers/UserCutawayStatus";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class Members extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount() {}

  public render() {
    const companyCurrentData = this.props.Store.Company.Current?.data;
    console.log("companyCurrentData", companyCurrentData);
    const authData = this.props.Store.Auth?.data;
    const registerEndAt = moment(
      this.props.Store.Main.Workshop.data.registerEndAt
    )
      .tz("Europe/Moscow")
      .format();
    const now = moment().tz("Europe/Moscow").format();
    const netwDays = this.props.Store.Networking?.Days.data;
    const currentProfile = this.props.Store.Profile?.data;

    if (!companyCurrentData?.users?.length) {
      return <></>;
    }

    return (
      <div className="stand-members stand__block">
        <div className="stand-members__header">
          <div className="stand-members__title">Представители организации</div>
          {Number(this.props.match.params.company_id) ===
            authData?.company?.id && authData?.isAdmin ? (
            <div className="stand-members__change-link">
              <Link
                to={`/company/${this.props.match.params.company_id}/employees/inbox`}
              >
                Изменить
              </Link>
            </div>
          ) : null}
          <div className="stand-members__desc">
            Наши эксперты готовы обсудить с вами детали сотрудничества.
            собеседника и начните общение:
          </div>
        </div>
        <div className="stand-members__body">
          {companyCurrentData?.users?.map((User: any, index: number) => {
            if (index < 3) {
              return (
                <div key={User.id} className="stand-user">
                  <Link to={`/user/${User.id}`} className="stand-user__body">
                    <div className="stand-user__img">
                      {User.image ? (
                        <img src={User.image} alt={User.fullName} />
                      ) : (
                        <div className="stand-user__img-placeholder" />
                      )}
                    </div>
                    <div className="stand-user__name">{User.fullName}</div>
                    <div className="stand-user__position">{User.position}</div>
                  </Link>
                  <div className="stand-user__footer">
                    {netwDays?.length > 0 && (
                      <Link
                        className="btn btn--white-red"
                        to={`/user/${User.id}/event/create`}
                        onClick={(event) => {
                          if (now > registerEndAt) {
                            event.preventDefault();
                            Middleware.Ui.Modal.open("RegistrationClosed", {
                              callback: "",
                            });
                          } else if (
                            !currentProfile?.useNetworking ||
                            currentProfile?.networkingFillStatus !== 3
                          ) {
                            event.preventDefault();
                            Middleware.Ui.Modal.open("EnterNetworking");
                          }
                        }}
                      >
                        Назначить встречу
                      </Link>
                    )}

                    {currentProfile?.id === User.id ? null : (
                      <Link
                        className={`btn btn--white-grey ${userCutawayStatus(
                          User
                        )}`}
                        to="#"
                        onClick={async (event) => {
                          event.preventDefault();
                          if (now > registerEndAt) {
                            Middleware.Ui.Modal.open("RegistrationClosed", {
                              callback: "",
                            });
                          } else if (
                            currentProfile?.useNetworking &&
                            currentProfile?.networkingFillStatus === 3
                          ) {
                            try {
                              await Middleware.User.Cutaway.invite(User.id);
                              await Middleware.Company.Current.setUserCutawayStatus(1, User.id);
                            } catch (Exception) {
                              throw Exception;
                            }
                          } else {
                            Middleware.Ui.Modal.open("EnterNetworking");
                          }
                        }}
                      >
                        Обменяться визитками
                      </Link>
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
        {companyCurrentData?.users.length > 3 ? (
          <div className="stand-members__footer">
            <Link
              className="stand-members__more-link"
              to="#"
              onClick={() =>
                Middleware.Ui.Modal.open("StandUsers", {
                  data: {
                    list: companyCurrentData?.users,
                    companyName: companyCurrentData?.title ? (
                      companyCurrentData?.title
                    ) : (
                      <></>
                    ),
                  },
                })
              }
            >
              Показать ещё {companyCurrentData?.users.length - 3} экспертов
            </Link>
          </div>
        ) : null}
        {/* <div className="stand-members__footer">
          <Link className="stand-members__more-link" to="#">
            Показать ещё 15 экспертов
          </Link>
        </div> */}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Members)
);
