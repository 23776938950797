import React, { ReactElement, useEffect, useState } from "react";
import * as Middleware from "@middlewares/index";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  About,
  CompanyType,
  Language,
  Direction,
  Specialization,
  Company,
  Status,
} from "./components";

const NetworkingPage = (): ReactElement => {
  const history = useHistory();
  const profile = useSelector((state: any) => state.Profile.data);
  const company = useSelector((state: any) => state.Profile.data.company);
  const languagesList = useSelector((state: any) => state.Language.list);
  const companyTypesList = useSelector((state: any) => state.Company.Type.list);
  const companyDirectionsList = useSelector(
    (state: any) => state.Company.Direction.list
  );
  const companySpecializationsList = useSelector(
    (state: any) => state.Company.Specialization.list
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [networkingProfile, setNetworkingProfile] = useState({
    interestCompanyTypes: profile?.networkingProfile?.interestCompanyTypes,
    languages: profile?.networkingProfile?.languages,
    directions: profile?.networkingProfile?.directions,
    specializations: profile?.networkingProfile?.specializations,
    about: profile?.networkingProfile?.about,
  });

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Middleware.Profile.updateAdditional(networkingProfile).then(() => {
      setEditMode(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!profile?.useNetworking) {
      history.push("/cabinet/networking");
    } else if (profile?.useNetworking && profile?.networkingFillStatus < 3) {
      history.push("/cabinet/networking/reg");
    }
  }, []);

  return (
    <>
      <div className="cabinet__layout-col">
        <form
          onSubmit={onSubmitHandler}
          className="cabinet__block netw-profile"
        >
          <div className="netw-profile__title">
            <h2>Ваши профессиональные интересы</h2>
            {!editMode && (
              <button
                className="netw-profile__edit-btn"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Изменить
              </button>
            )}
          </div>
          <hr />
          <About
            networkingProfile={networkingProfile}
            setNetworkingProfile={setNetworkingProfile}
            editMode={editMode}
          />
          <hr />
          <CompanyType
            networkingProfile={networkingProfile}
            setNetworkingProfile={setNetworkingProfile}
            companyTypesList={companyTypesList}
            editMode={editMode}
          />
          <hr />
          <Language
            networkingProfile={networkingProfile}
            setNetworkingProfile={setNetworkingProfile}
            languagesList={languagesList}
            editMode={editMode}
          />
          <hr />
          <Direction
            networkingProfile={networkingProfile}
            setNetworkingProfile={setNetworkingProfile}
            companyDirectionsList={companyDirectionsList}
            editMode={editMode}
          />
          <hr />
          <Specialization
            networkingProfile={networkingProfile}
            setNetworkingProfile={setNetworkingProfile}
            companySpecializationsList={companySpecializationsList}
            editMode={editMode}
          />
          {profile?.employmentType === 1 ? (
            <>
              <hr />
              <Company
                company={company}
                userId={profile?.id}
                userCompanyStatus={profile?.userModerationStatus}
              />
            </>
          ) : (
            <></>
          )}

          {editMode ? (
            <button
              type="submit"
              className="netw-profile__submit-btn btn btn--red"
            >
              Сохранить
            </button>
          ) : (
            <></>
          )}
        </form>
      </div>
      <div className="cabinet__layout-col">
        <Status />
      </div>
    </>
  );
};

export default NetworkingPage;
