import * as Action from "@actions/index";
import { useDispatch } from "@helpers/Store";

export default class Sidebar {
  public static show() {
    useDispatch(Action.Ui.Sidebar.show());
  }

  public static hide() {
    useDispatch(Action.Ui.Sidebar.hide());
  }
}
