import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Link } from "react-router-dom";
import Router, { Validator } from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Pages from ".";

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <>
        <div className="event-page">
          <Switch>
            <Router
              path="/user/:user_id/event/create"
              validator={Validator.Authorized}
              exact={true}
              component={Pages.Create}
            />
          </Switch>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
