import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import { specialAirtimeId } from "@pages/dashboard/components2.0/Schedule/Schedule";
import { TariffType } from "@interface/enums";

interface IProps {
  eventId: number;
  eventType: string;
  isAuthorized: string;
  isEnded: boolean;
  isOnline: boolean;
  path: string;
  status: boolean;
  currentTariffType: number | null;
}

const ListSubscribeBtn = ({
  eventId,
  eventType,
  isAuthorized,
  isEnded,
  isOnline,
  path,
  status,
  currentTariffType,
}: IProps): ReactElement => {
  const isSpecialAirtime = specialAirtimeId.includes(eventId);

  if (isEnded || isOnline) {
    return (
      <a
        className="btn btn--schedule btn--schedule-watch"
        href={
          ![TariffType.Full, TariffType.FullPlus, TariffType.Archive].includes(
            currentTariffType as TariffType
          ) && isSpecialAirtime
            ? "/?anchor=price-anchor"
            : `https://profi.travel${path}`
        }
        {...(currentTariffType !== TariffType.Full &&
        currentTariffType !== TariffType.FullPlus &&
        currentTariffType !== TariffType.Archive &&
        isSpecialAirtime
          ? {}
          : { target: "_blank" })}
      >
        <div className="btn__title">Смотреть</div>
        <div className="btn__status">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 0.114258C7.26522 0.114258 7.51957 0.219615 7.70711 0.407151C7.89464 0.594688 8 0.849041 8 1.11426V6.11426H13C13.2652 6.11426 13.5196 6.21961 13.7071 6.40715C13.8946 6.59469 14 6.84904 14 7.11426C14 7.37947 13.8946 7.63383 13.7071 7.82137C13.5196 8.0089 13.2652 8.11426 13 8.11426H8V13.1143C8 13.3795 7.89464 13.6338 7.70711 13.8214C7.51957 14.0089 7.26522 14.1143 7 14.1143C6.73478 14.1143 6.48043 14.0089 6.29289 13.8214C6.10536 13.6338 6 13.3795 6 13.1143V8.11426H1C0.734784 8.11426 0.48043 8.0089 0.292893 7.82137C0.105357 7.63383 0 7.37947 0 7.11426C0 6.84904 0.105357 6.59469 0.292893 6.40715C0.48043 6.21961 0.734784 6.11426 1 6.11426H6V1.11426C6 0.849041 6.10536 0.594688 6.29289 0.407151C6.48043 0.219615 6.73478 0.114258 7 0.114258Z"
              fill="white"
            />
          </svg>
        </div>
      </a>
    );
  } else if (status) {
    return (
      <Link
        className="btn btn--schedule btn--schedule-subscribed"
        to="#"
        onClick={() => {
          Middleware.Schedule.subscribe(eventId, eventType);
        }}
      >
        <div className="btn__title">В календаре</div>
        <div className="btn__title">Убрать</div>
        <div className="btn__status">
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 5L5 9L13 1"
              stroke="#888888"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1V6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7C14 7.26522 13.8946 7.51957 13.7071 7.70711C13.5196 7.89464 13.2652 8 13 8H8V13C8 13.2652 7.89464 13.5196 7.70711 13.7071C7.51957 13.8946 7.26522 14 7 14C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V8H1C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289C0.48043 6.10536 0.734784 6 1 6H6V1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0Z"
              fill="#000000"
            />
          </svg>
        </div>
      </Link>
    );
  } else {
    return (
      <Link
        className="btn btn--schedule"
        to={
          isAuthorized !== "authorized"
            ? "/auth/login"
            : currentTariffType === null
            ? "/?anchor=price-anchor"
            : currentTariffType === TariffType.Basic && isSpecialAirtime
            ? "/?anchor=price-anchor"
            : "#"
        }
        onClick={() => {
          if (isAuthorized !== "authorized" || currentTariffType === null) {
            return;
          } else if (currentTariffType === TariffType.Basic) {
            if (isSpecialAirtime) {
              return;
            } else {
              Middleware.Schedule.subscribe(eventId, eventType);
            }
          } else {
            Middleware.Schedule.subscribe(eventId, eventType);
          }
        }}
      >
        <div className="btn__title">Записаться</div>
        <div className="btn__status">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1V6H13C13.2652 6 13.5196 6.10536 13.7071 6.29289C13.8946 6.48043 14 6.73478 14 7C14 7.26522 13.8946 7.51957 13.7071 7.70711C13.5196 7.89464 13.2652 8 13 8H8V13C8 13.2652 7.89464 13.5196 7.70711 13.7071C7.51957 13.8946 7.26522 14 7 14C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V8H1C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289C0.48043 6.10536 0.734784 6 1 6H6V1C6 0.734784 6.10536 0.48043 6.29289 0.292893C6.48043 0.105357 6.73478 0 7 0Z"
              fill="#000000"
            />
          </svg>
        </div>
      </Link>
    );
  }
};

export default ListSubscribeBtn;
