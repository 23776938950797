import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import Connector from "@helpers/Connector";
import Jaas from "@components/jaas/Player";
import * as Middleware from "@middlewares/index";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
// import Chat from "./components/Chat";
const Chat = React.lazy(() => import("./components/Chat"));

interface PropsInterface extends Type.ComponentInterface {}

enum EventStatus {
  Loading = 0,
  Waiting,
  Active,
  Rejected,
  Canceled,
  Finished,
  Closed,
  NotFound,
}

interface StateInterface {
  status: EventStatus;
  session: string | null;
}

export default Connector(
  class EventShow extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        status: EventStatus.Loading,
        session: null,
      };
      this.videoBlockRenderer = this.videoBlockRenderer.bind(this);
      this.updateTask = this.updateTask.bind(this);
    }

    public componentDidMount() {
      Middleware.Auth.session().then((session: string) =>
        this.setState({ session })
      );
      // Middleware.Event.load(Number(this.props.match.params.event_id))
      //   .then(() => {
      //     this.setState({ status: EventStatus.Waiting });
      //     this.updateTask();
      //   })
      //   .catch(() => this.setState({ status: EventStatus.NotFound }));

      this.updateTask();
      Middleware.Company.Current.load(
        Number(this.props.Store.Auth.data?.company?.id)
      );
    }

    public async updateTask() {
      await Middleware.Event.load(Number(this.props.match.params.event_id))
        .then(() => {
          this.setState({ status: EventStatus.Waiting });
          if (this.props.Store.Event.data?.currentEventStatus === "waiting") {
            setTimeout(() => this.updateTask(), 1000 * 60);
          }
        })
        .catch(() => this.setState({ status: EventStatus.NotFound }));
    }

    public videoBlockRenderer() {
      console.log("videoBlockRenderer start");
      let element: React.ReactElement = <></>;
      console.log("videoBlockRenderer element created");

      console.log("videoBlockRenderer start checking status");
      switch (this.props.Store.Event.data?.status) {
        case null:
          console.log("videoBlockRenderer status === null");
          console.log("videoBlockRenderer start checking current event status");
          switch (this.props.Store.Event.data?.currentEventStatus) {
            case "waiting":
              console.log("videoBlockRenderer start checking owner");
              if (this.props.Store.Event.data?.isOwner === true) {
                console.log("videoBlockRenderer you is owner");
                element = (
                  <div className="popup" id="invite">
                    <div className="popup__grid">
                      <p>Вы приглашаете на встречу</p>
                      <Link
                        className="btn btn--red"
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          Middleware.Ui.Modal.open("EventCancel", {
                            callback: () =>
                              Middleware.Event.load(
                                Number(this.props.match.params.event_id)
                              )
                                .then(() =>
                                  this.setState({ status: EventStatus.Waiting })
                                )
                                .catch(() =>
                                  this.setState({
                                    status: EventStatus.NotFound,
                                  })
                                ),
                            data: {
                              id: this.props.Store.Event.data?.id,
                              isOwner: this.props.Store.Event.data?.isOwner,
                            },
                          });
                        }}
                      >
                        Отозвать приглашение
                      </Link>
                      {/* <a className="text-red small" href="#">
                    Предложить другое время
                  </a> */}
                    </div>
                  </div>
                );
              } else {
                console.log("videoBlockRenderer you not owner");
                return (
                  <div className="popup" id="invitation">
                    <div className="popup__grid">
                      <div className="popup__card">
                        <div className="popup__card-hint">Приглашает:</div>
                        {this.props.Store.Event.data?.owner?.userImage ? (
                          <img
                            className="popup__card-photo"
                            src={this.props.Store.Event.data?.owner?.userImage}
                            alt=""
                          />
                        ) : (
                          <div className="popup__card-photo" />
                        )}
                        <div className="popup__card-name">{`${this.props.Store.Event.data?.owner?.firstName} ${this.props.Store.Event.data?.owner?.lastName}`}</div>
                        <div className="popup__card-position">
                          {this.props.Store.Event.data?.owner?.position}
                        </div>
                      </div>
                      <hr />
                      <div className="popup__grid_2">
                        <Link
                          className="btn btn--red"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            Middleware.User.Event.accept(
                              this.props.Store.Event.data?.id
                            ).then(() =>
                              Middleware.Event.load(
                                Number(this.props.match.params.event_id)
                              )
                                .then(() =>
                                  this.setState({ status: EventStatus.Waiting })
                                )
                                .catch(() =>
                                  this.setState({
                                    status: EventStatus.NotFound,
                                  })
                                )
                            );
                          }}
                          // onClick={() => this.setState({ status: EventStatus.Active })}
                        >
                          Принять
                        </Link>
                        <Link
                          className="btn btn--grey-1"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            Middleware.Ui.Modal.open("EventCancel", {
                              callback: () =>
                                Middleware.Event.load(
                                  Number(this.props.match.params.event_id)
                                )
                                  .then(() =>
                                    this.setState({
                                      status: EventStatus.Waiting,
                                    })
                                  )
                                  .catch(() =>
                                    this.setState({
                                      status: EventStatus.NotFound,
                                    })
                                  ),
                              data: {
                                id: this.props.Store.Event.data?.id,
                                isOwner: this.props.Store.Event.data?.isOwner,
                              },
                            });
                          }}
                          // onClick={() => this.setState({ status: EventStatus.Rejected })}
                        >
                          Отклонить
                        </Link>
                      </div>
                      {/* <a className="text-red small" href="#">
                    Предложить другое время
                  </a> */}
                    </div>
                  </div>
                );
              }
              break;
            case "active":
              console.log("videoBlockRenderer current event status === active");
              if (this.state.status === EventStatus.Active) {
                element = (
                  <Jaas
                    User={{
                      id: Number(this.props.match.params.event_id),
                      name:
                        String(this.props.Store.Auth.data.firstName) +
                        " " +
                        String(this.props.Store.Auth.data.lastName),
                      email: this.props.Store.Auth.data.email,
                      avatar: this.props.Store.Auth.data.userImage || "",
                      moderator: false,
                    }}
                  />
                );
              } else if (this.state.status === EventStatus.Waiting) {
                element = (
                  <div className="popup" id="comeIn">
                    <div className="popup__grid_2">
                      <Link
                        className="btn btn--red"
                        to="#"
                        onClick={() =>
                          this.setState({ status: EventStatus.Active })
                        }
                      >
                        Войти в конференцию
                      </Link>
                      {/* <a className="btn btn--grey-1" href="#">
                        Тест звука и видео
                      </a> */}
                    </div>
                  </div>
                );
              }
              break;
            case "rejected":
            case "canceled":
              console.log(
                "videoBlockRenderer current event status === rejected | canceled"
              );
              element = (
                <div className="popup" id="canceled">
                  <p className="text-red">Встреча отменена</p>
                </div>
              );
              break;
            case "finished":
            case "closed":
              console.log(
                "videoBlockRenderer current event status === finished | closed"
              );
              element = (
                <div className="popup" id="finished">
                  <p>Встреча закончена</p>
                </div>
              );
              break;
          }

          break;
        case false:
          console.log("videoBlockRenderer status === false");
          element = (
            <div className="popup" id="canceled">
              <p className="text-red">Встреча отменена</p>
            </div>
          );
          break;
        case true:
          console.log("videoBlockRenderer status === true");
          console.log("videoBlockRenderer start checking current event status");
          switch (this.props.Store.Event.data?.currentEventStatus) {
            case "waiting":
              console.log(
                "videoBlockRenderer current event status === waiting"
              );
              element = (
                <div className="popup" id="finished">
                  <p>Встреча еще не началась</p>
                </div>
              );
              break;
            case "active":
              console.log("videoBlockRenderer current event status === active");
              if (this.state.status === EventStatus.Active) {
                element = (
                  <>
                    {this.props.Store.Auth.jwt && (
                      <Jaas
                        User={{
                          id: Number(this.props.match.params.event_id),
                          name:
                            String(this.props.Store.Auth.data.firstName) +
                            " " +
                            String(this.props.Store.Auth.data.lastName),
                          email: this.props.Store.Auth.data.email,
                          avatar: this.props.Store.Auth.data.userImage || "",
                          moderator: false,
                        }}
                      />
                    )}
                  </>
                );
              } else if (this.state.status === EventStatus.Waiting) {
                element = (
                  <div className="popup" id="comeIn">
                    <div className="popup__grid_2">
                      <Link
                        className="btn btn--red"
                        to="#"
                        onClick={() =>
                          this.setState({ status: EventStatus.Active })
                        }
                      >
                        Войти в конференцию
                      </Link>
                      {/* <a className="btn btn--grey-1" href="#">
                        Тест звука и видео
                      </a> */}
                    </div>
                  </div>
                );
              }
              break;
            case "rejected":
            case "canceled":
              console.log(
                "videoBlockRenderer current event status === rejected | canceled"
              );
              element = (
                <div className="popup" id="canceled">
                  <p className="text-red">Встреча отменена</p>
                </div>
              );
              break;
            case "finished":
            case "closed":
              console.log(
                "videoBlockRenderer current event status === finished | closed"
              );
              element = (
                <div className="popup" id="finished">
                  <p>Встреча закончена</p>
                </div>
              );
              break;
          }
      }
      console.log("videoBlockRenderer returning element");
      console.log(element);
      return element;
    }

    public render() {
      if (!this.props.Store.Event.data) {
        return <>Загружаем</>;
      }
      if (this.state.status === EventStatus.NotFound) {
        return <>Такой встречи не существует!</>;
      }

      return (
        <>
          <Helmet>
            <title>
              {this.props.Store.Event.data?.title || ""} |{" "}
              {this.props.Store.Main.Workshop.data.title}!
            </title>
          </Helmet>

          <div className="meeting">
            <div className="meeting__event">
              <div className="meeting__event-info">
                <p className="meeting__event-type">Встреча:</p>
                <time className="meeting__event-date">
                  <b>
                    {moment(this.props.Store.Event.data?.startAt)
                      .tz("Europe/Moscow")
                      .format("HH:mm")}{" "}
                    –{" "}
                    {moment(this.props.Store.Event.data?.endAt)
                      .tz("Europe/Moscow")
                      .format("HH:mm")}{" "}
                    МСК (GMT +3)
                  </b>
                  <span className="text-gray88">
                    {moment(this.props.Store.Event.data?.startAt)
                      .tz("Europe/Moscow")
                      .format("DD MMMM YYYY")}
                  </span>
                </time>
              </div>
              <hr />
              <div className="meeting__event-participants">
                <p>{this.props.Store.Event.data?.title}</p>
                {/* <p>Представительство туристической организации Никарагуа в&nbsp;Москве</p>
                <figure>
                  <img src={require("@public/images/plus.svg")} alt="" />
                </figure>
                <p>Profi.Travel</p> */}
              </div>
            </div>

            {this.state.session ? (
              <>
                <div className="meeting__stream">
                  {this.videoBlockRenderer()}
                </div>
                {this.props.Store.Auth.jwt && (
                  <Chat
                    event_id={Number(this.props.match.params.event_id)}
                    token={this.props.Store.Auth.jwt}
                  />
                )}
              </>
            ) : (
              <>Загружаем сессию...</>
            )}

            <div className="meeting__participants">
              <h2 className="meeting__participants-title">
                Информация об участниках встречи
              </h2>
              <div className="meeting__participant">
                <div className="meeting__participant-name">
                  <Link
                    target="_blank"
                    to={`/company/${this.props.Store.Event.data?.owner?.company?.id}`}
                  >
                    {this.props.Store.Event.data?.owner?.company ? (
                      <img
                        className="meeting__participant-logo"
                        src={
                          this.props.Store.Event.data?.owner?.company
                            ?.companyImage ||
                          require("@public/images/defaultAvatarCompany.svg")
                        }
                        alt=""
                      />
                    ) : (
                      <></>
                    )}
                    {this.props.Store.Event.data?.owner?.company?.title}
                  </Link>
                </div>
                <hr />
                <div className="meeting__experts">
                  <div className="meeting__participant-hint">
                    Представители:
                  </div>
                  <Link
                    target="_blank"
                    to={
                      !this.props.Store.Event.data?.owner?.useNetworking ||
                      this.props.Store.Event.data?.owner?.networkingFillStatus <
                        3
                        ? `#`
                        : `/user/${this.props.Store.Event.data?.owner?.id}`
                    }
                    onClick={(e: any) => {
                      if (
                        !this.props.Store.Event.data?.owner?.useNetworking ||
                        this.props.Store.Event.data?.owner
                          ?.networkingFillStatus < 3
                      ) {
                        e.preventDefault();

                        Middleware.Ui.Modal.open("UserHasNotNetworking", {
                          firstName:
                            this.props.Store.Event.data?.owner?.firstName,
                          lastName:
                            this.props.Store.Event.data?.owner?.lastName,
                        });
                      }
                    }}
                    className="meeting__expert"
                  >
                    {this.props.Store.Event.data?.owner?.userImage ? (
                      <img
                        className="meeting__expert-photo"
                        src={this.props.Store.Event.data?.owner?.userImage}
                        alt=""
                      />
                    ) : (
                      <div className="meeting__expert-photo" />
                    )}

                    <span className="meeting__expert-name">{`${this.props.Store.Event.data?.owner?.firstName} ${this.props.Store.Event.data?.owner?.lastName}`}</span>
                    <span className="meeting__expert-position">
                      {this.props.Store.Event.data?.owner?.position}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="meeting__participant">
                <div className="meeting__participant-name">
                  <Link
                    target="_blank"
                    to={`/company/${this.props.Store.Event.data?.participantList[0]?.company?.id}`}
                  >
                    {this.props.Store.Event.data?.participantList[0]
                      ?.company ? (
                      <img
                        className="meeting__participant-logo"
                        src={
                          this.props.Store.Event.data?.participantList[0]
                            ?.company?.companyImage ||
                          require("@public/images/defaultAvatarCompany.svg")
                        }
                        alt=""
                      />
                    ) : (
                      <></>
                    )}
                    {
                      this.props.Store.Event.data?.participantList[0]?.company
                        ?.title
                    }
                  </Link>
                </div>
                <hr />
                <div className="meeting__experts">
                  <div className="meeting__participant-hint">
                    Представители:
                  </div>
                  {this.props.Store.Event.data?.participantList?.map(
                    (User: any, index: number) => {
                      return (
                        <Link
                          key={index}
                          target="_blank"
                          to={
                            !User?.useNetworking ||
                            User?.networkingFillStatus < 3
                              ? `#`
                              : `/user/${User?.id}`
                          }
                          onClick={(e: any) => {
                            if (
                              !User?.useNetworking ||
                              User?.networkingFillStatus < 3
                            ) {
                              e.preventDefault();

                              Middleware.Ui.Modal.open("UserHasNotNetworking", {
                                firstName: User?.firstName,
                                lastName: User?.lastName,
                              });
                            }
                          }}
                          // to={`/user/${User.id}`}
                          className="meeting__expert"
                        >
                          {User.userImage ? (
                            <img
                              className="meeting__expert-photo"
                              src={User.userImage}
                              alt=""
                            />
                          ) : (
                            <div className="meeting__expert-photo" />
                          )}
                          <span className="meeting__expert-name">{`${User.firstName} ${User.lastName}`}</span>
                          <span className="meeting__expert-position">
                            {User.position}
                          </span>
                        </Link>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="meeting__participants-add">
                <Link
                  className="btn btn--grey-1"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    Middleware.Ui.Modal.open("NewEventUser", {
                      event_id: Number(this.props.match.params.event_id),
                    });
                  }}
                >
                  Присоединить участников
                  <img
                    className="btn_icon"
                    src={require("@public/images/plus-gray.svg")}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
);
