import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";

interface IProps {
  id: number;
  owner: any;
  participantList: any[];
  startAt: Date;
  endAt: Date;
  isOwner: boolean;
  description: string;
  eventType: string;
}

const CabinetEvent = ({
  id,
  owner,
  participantList,
  startAt,
  endAt,
  isOwner,
  description,
  eventType,
}: IProps): ReactElement => {
  return (
    <>
      <div
        className={`cabinet-event ${
          eventType === "incomingDeclined" || eventType === "outgoingDeclined"
            ? "cabinet-event--declined"
            : ""
        }`}
      >
        <div className="cabinet-event__column cabinet-event__column--title">
          <Link to={`/event/${id}`} className="cabinet-event__title">
            <span className="cabinet-event__title-name">{`${owner.firstName} ${owner.lastName}`}</span>
            <span className="cabinet-event__title-plus">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 0C7.89782 0 8.27936 0.158036 8.56066 0.43934C8.84197 0.720645 9 1.10218 9 1.5V6H13.5C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5C15 7.89782 14.842 8.27936 14.5607 8.56066C14.2794 8.84197 13.8978 9 13.5 9H9V13.5C9 13.8978 8.84197 14.2794 8.56066 14.5607C8.27936 14.842 7.89782 15 7.5 15C7.10218 15 6.72064 14.842 6.43934 14.5607C6.15804 14.2794 6 13.8978 6 13.5V9H1.5C1.10218 9 0.720645 8.84197 0.43934 8.56066C0.158036 8.27936 0 7.89782 0 7.5C0 7.10218 0.158036 6.72064 0.43934 6.43934C0.720645 6.15804 1.10218 6 1.5 6H6V1.5C6 1.10218 6.15804 0.720645 6.43934 0.43934C6.72064 0.158036 7.10218 0 7.5 0Z"
                  fill="#ff0011"
                />
              </svg>
            </span>
            <span className="cabinet-event__title-name">{`${participantList[0].firstName} ${participantList[0].lastName}`}</span>
          </Link>
          <div className="cabinet-event__small-text">
            {moment(startAt).tz("Europe/Moscow").format("DD MMMM YYYY")} |{" "}
            {moment(startAt).tz("Europe/Moscow").format("HH:mm")} –{" "}
            {moment(endAt).tz("Europe/Moscow").format("HH:mm")} MSK (GMT+3)
          </div>
        </div>
        <div className="cabinet-event__column cabinet-event__column--inviter">
          <div className="cabinet-event__small-text">
            {isOwner ? "Приглашены:" : "Приглашает:"}
          </div>
          {isOwner ? (
            <>
              {participantList.map((participant: any, index: number) => {
                return (
                  <div key={index} className="cabinet-event__inviter">
                    <div className="cabinet-event__inviter-img">
                      {participant.userImage !== null ? (
                        <img src={participant.userImage} alt="" />
                      ) : (
                        <></>
                      )}
                    </div>
                    <Link
                      to={
                        !participant.useNetworking ||
                        participant.networkingFillStatus < 3
                          ? `#`
                          : `/user/${participant.id}`
                      }
                      className="cabinet-event__inviter-name"
                      onClick={(e: any) => {
                        if (
                          !participant.useNetworking ||
                          participant.networkingFillStatus < 3
                        ) {
                          e.preventDefault();

                          Middleware.Ui.Modal.open("UserHasNotNetworking", {
                            firstName: participant.firstName,
                            lastName: participant.lastName,
                          });
                        }
                      }}
                    >{`${participant.firstName} ${participant.lastName}`}</Link>
                    <div className="cabinet-event__inviter-company">
                      {participant.company !== null
                        ? participant.company.title
                        : "Фрилансер"}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="cabinet-event__inviter">
                <div className="cabinet-event__inviter-img">
                  {owner.userImage !== null ? (
                    <img src={owner.userImage} alt="" />
                  ) : (
                    <></>
                  )}
                </div>
                <Link
                  to={
                    !owner.useNetworking || owner.networkingFillStatus < 3
                      ? `#`
                      : `/user/${owner.id}`
                  }
                  className="cabinet-event__inviter-name"
                  onClick={(e: any) => {
                    if (
                      !owner.useNetworking ||
                      owner.networkingFillStatus < 3
                    ) {
                      e.preventDefault();

                      Middleware.Ui.Modal.open("UserHasNotNetworking", {
                        firstName: owner.firstName,
                        lastName: owner.lastName,
                      });
                    }
                  }}
                >{`${owner.firstName} ${owner.lastName}`}</Link>
                <div className="cabinet-event__inviter-company">
                  {owner.company !== null ? owner.company.title : "Фрилансер"}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="cabinet-event__column cabinet-event__column--desc">
          <div className="cabinet-event__small-text">Описание:</div>
          <div className="cabinet-event__desc">{description}</div>
          <Link to={`/event/${id}`} className="cabinet-event__chat-link">
            Написать в чат
          </Link>
        </div>
        <div className="cabinet-event__column cabinet-event__column--buttons">
          {eventType === "incomingWaiting" ? (
            <>
              <button
                className="btn btn--red"
                onClick={(e) => {
                  e.preventDefault();
                  Middleware.User.Event.accept(id).then(() => {
                    Middleware.User.Event.list();
                  });
                }}
              >
                Принять
              </button>
            </>
          ) : eventType === "incomingApproved" ||
            eventType === "outgoingApproved" ? (
            <>
              <div className="btn cabinet-event__status">
                Принято{" "}
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 5L4.33333 8.5L11 1.5"
                    stroke="#ff0011"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : eventType === "incomingDeclined" ||
            eventType === "outgoingDeclined" ? (
            <>
              <div className="btn cabinet-event__status">
                Отклонено{" "}
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.69398 0.306019C7.88992 0.50196 8 0.767714 8 1.04482C8 1.32192 7.88992 1.58767 7.69398 1.78361L5.47759 4L7.69398 6.21639C7.88992 6.41233 8 6.67808 8 6.95518C8 7.23229 7.88992 7.49804 7.69398 7.69398C7.49804 7.88992 7.23229 8 6.95518 8C6.67808 8 6.41233 7.88992 6.21639 7.69398L4 5.47759L1.78361 7.69398C1.58767 7.88992 1.32192 8 1.04482 8C0.767714 8 0.501961 7.88992 0.30602 7.69398C0.110079 7.49804 0 7.23229 0 6.95518C0 6.67808 0.110079 6.41233 0.30602 6.21639L2.52241 4L0.30602 1.78361C0.110079 1.58767 0 1.32192 0 1.04482C0 0.767714 0.110079 0.50196 0.30602 0.306019C0.501961 0.110078 0.767714 0 1.04482 0C1.32192 0 1.58767 0.110078 1.78361 0.306019L4 2.52241L6.21639 0.306019C6.41233 0.110078 6.67808 0 6.95518 0C7.23229 0 7.49804 0.110078 7.69398 0.306019Z"
                    fill="#ff0011"
                  />
                </svg>
              </div>
            </>
          ) : (
            <></>
          )}

          {eventType !== "incomingDeclined" &&
          eventType !== "outgoingDeclined" ? (
            <>
              <button
                className="btn btn--grey"
                onClick={(e) => {
                  e.preventDefault();
                  Middleware.Ui.Modal.open("EventCancel", {
                    callback: "",
                    data: {
                      id,
                      isOwner,
                    },
                  });
                }}
              >
                {isOwner ? "Отозвать" : "Отклонить"}
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CabinetEvent;
