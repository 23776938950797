import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Broadcast extends ModelPrototype {
  public async subscribe(event_id: number): Promise<any> {
    try {
      return this.withUrl(`schedule/event/broadcast/${event_id}/subscribe`)
        .setMethod(Method.POST)
        .request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
