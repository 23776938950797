import { Input } from "@components/form";
import * as Middleware from "@middlewares/index";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Style from "./PasswordChange.module.scss";

const PasswordChange: React.FC = () => {
  const [show, setShow] = useState(false);

  const [password, setPassword] = useState("");

  const [newpassword, setNewpassword] = useState("");
  const [passwordconfirm, setPasswordconfirm] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = (): void => {
    setLoading(true);
    Middleware.Profile.password({
      password,
      newpassword,
      passwordconfirm,
    }).then(() => {
      setPassword("");
      setNewpassword("");
      setPasswordconfirm("");
      setLoading(false);
    });
  };

  return (
    <div className={Style.block}>
      <div className={Style.header}>
        <h2 className="form__title">Сменить пароль</h2>
        <Link
          to="#"
          className={[Style.showLink, show ? "" : Style.hideLink].join(" ")}
          onClick={() => setShow(!show)}
        >
          <img src={require("@public/images/stand-arrow.svg")} alt="" />
        </Link>
      </div>
      <form
        onSubmit={submit}
        className={Style.body}
        style={{ display: show ? "block" : "none" }}
      >
        <div className={Style.description}>
          Пожалуйста, убедитесь, что вся контактная информация актуальна — по
          указанному адресу электронной почты и телефону вы будете получать
          уведомления об эфирах, новых встречах и визитках (если ваш профиль
          участвует в нетворкинге)
        </div>

        <div className="form__group">
          <Input
            type="password"
            name="password"
            placeholder="Старый пароль"
            required={true}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            disabled={loading}
          />
        </div>

        <hr />

        <div className="form__group">
          <Input
            type="password"
            name="newpassword"
            placeholder="Новый пароль"
            required={true}
            value={newpassword}
            onChange={(e: any) => setNewpassword(e.target.value)}
            disabled={loading}
          />
          <Input
            type="password"
            name="passwordconfirm"
            placeholder="Повторите новый пароль"
            required={true}
            value={passwordconfirm}
            pattern={newpassword.replace(
              /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
              "\\$&"
            )}
            onChange={(e: any) => setPasswordconfirm(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className={Style.footer}>
          <button type="submit" className="btn btn--red">
            {loading ? <span>Загрузка...</span> : <span>Сменить пароль</span>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordChange;
