import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Components from "./components";
import Connector from "@helpers/Connector";
import { Moment } from "moment-timezone";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  step: number;
  duration: number;
  datetime: Moment | null;
  description: string;
  Event: any;
}
export default Connector(
  class EventCreate extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        step: 1,
        duration: 0,
        datetime: null,
        description: "",
        Event: null,
      };
      this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    public componentDidMount() {
      Middleware.User.Selected.load(Number(this.props.match.params.user_id));
      window.scrollTo(0, 0);
    }

    private stepsRenderer(step: number) {
      let Component: any = <></>;
      switch (this.state.step) {
        case 1:
          Component = <Components.Step1 onSubmit={this.onSubmitHandler} />;
          break;
        case 2:
          Component = (
            <Components.Step2 duration={this.state.duration} onSubmit={this.onSubmitHandler} />
          );
          break;
        case 3:
          Component = (
            <Components.Step3
              onSubmit={this.onSubmitHandler}
              description={this.state.description}
            />
          );
          break;
      }
      return Component;
    }

    private onSubmitHandler(data: any): void {
      if (data.step === 4) {
        this.setState({ description: data.description }, () => {
          Middleware.User.Event.create(Number(this.props.match.params.user_id), {
            startAt: this.state.datetime?.format("YYYY-MM-DD HH:mm"),
            endAt: this.state.datetime
              ?.clone()
              .add(this.state.duration, "minutes")
              .format("YYYY-MM-DD HH:mm"),
            description: this.state.description,
          })
            .then((Event) => {
              this.setState({ step: 4, Event });
              Middleware.User.Event.list();
            })
            .catch((Exception) => {
              Middleware.Ui.Alert.open("Default", {
                text: "Ошибка! Невозможно отправить запрос на встречу.",
              });
            });
        });
      } else {
        this.setState(data, () => {});
      }
    }

    public render() {
      return (
        <>
          {/* <Link to="#" onClick={() => this.setState({ step: this.state.step + 1 })}>
            step++
          </Link>
          <Link to="#" onClick={() => this.setState({ step: this.state.step - 1 })}>
            step--
          </Link> */}
          <div className="event-create">
            <h1 className="event-create__title">Назначение встречи</h1>
            {this.state.step === 4 ? (
              <div className="event-create-step event-create__block">
                <Components.Done
                  state={{
                    duration: this.state.duration,
                    datetime: this.state.datetime,
                    description: this.state.description,
                    Event: this.state.Event,
                  }}
                  callback={(data) => {
                    this.setState({
                      step: data.step,
                      duration: data.duration,
                      datetime: data.datetime,
                      description: data.description,
                      Event: data.Event,
                    });
                  }}
                />
              </div>
            ) : (
              <div className="event-create-step event-create__block">
                <div className="event-create-step__header">
                  <div className="event-create-step__title">
                    <p>
                      {this.props.Store.User.Selected.data?.firstName}{" "}
                      {this.props.Store.User.Selected.data?.lastName} (
                      {this.props.Store.User.Selected.data?.employmentType === 1
                        ? this.props.Store.User.Selected.data?.company?.title
                        : this.props.Store.User.Selected.data?.position}
                      )
                    </p>
                    <span>+</span>
                    <p>{`${this.props.Store.Auth.data.firstName} ${this.props.Store.Auth.data.lastName}`}</p>
                  </div>
                  <div className="event-create-step__tags" />
                </div>
                <hr />
                <div className="event-create-step__body">{this.stepsRenderer(this.state.step)}</div>
              </div>
            )}
          </div>
        </>
      );
    }
  }
);
