import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class StandSelected {
  public static async load(stand_id: number) {
    useDispatch(Action.Stand.Selected.setStatus("loading"));
    try {
      const response = await new Model.Stand().get(stand_id);
      useDispatch(Action.Stand.Selected.setData(response.data));
      useDispatch(Action.Stand.Selected.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async setUserCutawayStatus(status: number, user_id: number) {
    try {
      useDispatch(Action.Stand.Selected.setUserCutawayStatus({status, user_id}));
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async clear() {
    useDispatch(Action.Stand.Selected.clear());
    useDispatch(Action.Stand.Selected.setStatus("waiting"));
  }
}
