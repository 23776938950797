import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class NetworkingExponentGeneral {
  public static async page(page: number, filters?: any) {
    try {
      useDispatch(Action.Networking.Exponent.General.setStatus("loading"));
      const response = await new Model.Networking.Exponent.General().page(page, filters);
      useDispatch(Action.Networking.Exponent.General.setData(response.data));
      // useDispatch(Action.Networking.Exponent.General.setData(response.paginator));
      useDispatch(Action.Networking.Exponent.General.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
