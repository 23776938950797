import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class NetworkingUser {
  /**
   * @return function(Dispatch)->Country.list
   */
  public static async count() {
    try {
      const response = await new Model.Networking.Company().count();
      useDispatch(Action.Networking.Company.setCount(response.data));
      return response.data;
    } catch (Exception) {}
  }
}
