import React from "react";
import Connector from "@helpers/Connector";
import * as Type from "@interface/index";

interface PropsInterface extends Type.ComponentInterface {
  children: any;
  onMount: () => void;
}

interface StateInterface {}

export default Connector(
  class Redirect extends React.Component<PropsInterface, StateInterface> {
    public mounted: boolean = false;
    componentDidMount() {
      this.props.onMount();
      this.mounted = true;
    }

    public render() {
      return this.mounted ? this.props.children : <></>;
    }
  }
);
