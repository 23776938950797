import React, { ReactElement } from "react";
import { Checkbox } from "@components/form";
import * as Middleware from "@middlewares/index";

interface IProps {
  currentStep: number;
  maxStep: number;
  setCurrentStep: (value: number) => void;
  languagesList: number[];
  networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  required?: boolean;
  updateNetworkingProfile: any;
}

const RegStep2 = ({
  currentStep,
  maxStep,
  setCurrentStep,
  languagesList,
  networkingProfile,
  required,
  updateNetworkingProfile,
}: IProps): ReactElement => {
  const onCheckHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    if (networkingProfile?.languages?.includes(+event.target.value)) {
      networkingProfile?.languages?.splice(
        networkingProfile?.languages?.indexOf(+event.target.value),
        1
      );
    } else {
      networkingProfile?.languages?.push(+event.target.value);
    }
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (networkingProfile?.languages?.length > 0) {
      updateNetworkingProfile(networkingProfile);
    } else {
      let ProgressAlert: any;
      await Middleware.Ui.Alert.open("Process", {
        text: "Выберите хотя бы одно значение",
        component: (c: any) => {
          ProgressAlert = c;
        },
      });
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  };

  return (
    <>
      <section className="cabinet__block">
        <form onSubmit={onSubmitHandler} className="profile-netw-step">
          <div className="profile-netw-step__header">
            <span> Шаг {currentStep}</span> / {maxStep}
          </div>
          <div className="profile-netw-step__body">
            <div className="profile-netw-step__body-header">
              <p className="profile-netw-step__title">
                {required ? <span>* </span> : <></>}На каком языке вы планируете
                общаться в нетворкинге ?
              </p>
              <p className="profile-netw-step__required">
                {required
                  ? "Обязательный пункт"
                  : "Вы можете заполнить этот пункт потом"}
              </p>
            </div>

            <p className="profile-netw-step__desc">
              Выберите хотя бы одно значение
            </p>
            <div className="profile-netw-step__main profile-netw-step__main--checkbox-list">
              {languagesList?.map((Item: any, index: number) => {
                return (
                  <Checkbox
                    key={index}
                    name="languages"
                    type="profile-language"
                    placeholder={Item.name}
                    value={Item.id}
                    hiddenInput
                    checked={networkingProfile?.languages?.includes(Item.id)}
                    onChange={onCheckHandler}
                  />
                );
              })}
            </div>
          </div>

          <div className="profile-netw-step__footer">
            <button type="submit" className="btn btn--red">
              Дальше
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default RegStep2;
