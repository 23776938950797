import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class StandCommon {
  public static async count() {
    try {
      const response = await new Model.Stand().count();
      useDispatch(Action.Stand.Common.setCount(response.data));
      return response.data;
    } catch (Exception) {}
  }
}
