import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";
import { useCurrentTariff } from "@hooks/useCurrentTariff";
import { TariffType } from "@interface/enums";
import Tariff from "./Tariff";
import { ITariff } from "../Price";
import Step2 from "@pages/tariffs-and-payment/components/Step2";
import Step3 from "@pages/tariffs-and-payment/components/Step3";
import Step4 from "@pages/tariffs-and-payment/components/Step4";

const archiveTariff: ITariff = {
  id: 7,
  type: 666,
  name: "Архив",
  description: [
    {
      text: "Доступ к архивным записям всех вебинаров (~60 эфиров от НТО, туроператоров, отелей, авиакомпаний)",
      description: "",
    },
    {
      text: "Доступ к архивным записям 7 спецэфиров по привлечению туристов",
      description: "",
    },
  ],
  newPrice: {
    rub: 5000,
    loyalty: 5000,
  },
  price: {
    rub: 5000,
    loyalty: 5000,
  },
};

// TODO это потом добавить в Price
export const TariffArchive: React.FC = () => {
  const location = useLocation();
  const ref = useRef(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const authStatus = useSelector((state: any) => state.Auth?.status);
  const currentTariff = useCurrentTariff();
  const profileData = useSelector((state: any) => state.Profile.data);
  const currentTariffType = currentTariff ? currentTariff?.type : 0;
  const currentTariffName = currentTariff ? currentTariff?.name : "";
  const promoDate = useSelector((state: any) => state.Payment?.promoDate);
  const now = moment().tz("Europe/Moscow").format();
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );
  const isArchive = now > moment(endWorkshop).tz("Europe/Moscow").format();

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "price-anchor" && ref) {
      console.log("SCROLL EVENT");
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  const isBuyButtonVisible =
    !currentTariff || currentTariffType === TariffType.Basic;

  const changeStep = (size: number) => {
    setCurrentStep(currentStep + size);
  };

  const currentPayment = useSelector(
    (state: any) => state.Payment?.currentPayment
  );

  const thisYearRating = useSelector(
    (state: any) => state.UserRating?.data?.thisYearRating
  );

  const selectedForPayTariff = useSelector(
    (state: any) => state.Payment?.currentTariff
  );

  const utmTags = localStorage.getItem("utm_data") || "{}";

  const isPromoPurchasePrice =
    profileData.tariffPurchasedAt >= promoDate ? false : true;

  return (
    <>
      {currentStep === 1 ? (
        <section className="price bg-white" id="price-anchor" ref={ref}>
          <div className="cont price__cont">
            <div
              className={`price__list ${
                isArchive ? "price__list--archive" : ""
              }`}
            >
              <div className="lg:w-1/2 flex flex-col gap-8 lg:justify-between">
                <div className="price__header md:max-w-[437px]">
                  <h2 className="price__title">
                    Покупайте архив, если пропустили интенсив
                  </h2>
                </div>
                <div className="price__desc flex flex-col gap-3 md:max-w-[480px] lg:max-w-full">
                  <p>
                    Если вам не удалось принять участие в онлайн-интенсиве – вы
                    можете приобрести записи вебинаров по продукту и спецэфиров
                    по продвижению.
                  </p>
                  <p>
                    Доступ к архиву будет открыт 6 месяцев – до июля 2025 года.
                  </p>
                  <Link
                    to="#"
                    className="price__link btn btn--red-underline text-base font-bold	"
                    onClick={(e) => {
                      e.preventDefault();
                      Middleware.Ui.Modal.open("LoyaltyPointsShort", {
                        callback: "",
                      });
                    }}
                  >
                    Как получить баллы Loyalty прямо сейчас?
                  </Link>
                </div>
              </div>
              <div className="lg:w-1/2">
                <Tariff
                  authStatus={authStatus}
                  currentTariffName={currentTariffName}
                  currentTariff={currentTariffType}
                  now={moment().tz("Europe/Moscow").format()}
                  promoDate={promoDate}
                  tariff={archiveTariff}
                  benefits={[...archiveTariff.description]}
                  isArchive={true}
                  isArchiveButtonVisible={isBuyButtonVisible}
                  changeStep={changeStep}
                />
              </div>
            </div>
          </div>
        </section>
      ) : currentStep === 2 ? (
        <div className="payment">
          <div className="cont payment__cont">
            <Step2
              currentPayment={currentPayment}
              selectedForPayTariff={selectedForPayTariff}
              currentTariff={currentTariff}
              promoDate={promoDate}
              tariffs={[archiveTariff]}
              isPromoPurchasePrice={isPromoPurchasePrice}
              utmTags={utmTags}
              isArchive
            />
          </div>
        </div>
      ) : currentStep === 3 ? (
        <div className="payment">
          <div className="cont payment__cont">
            <Step3
              thisYearRating={thisYearRating}
              setCurrentStep={setCurrentStep}
            />
          </div>
        </div>
      ) : currentStep === 4 ? (
        <div className="payment">
          <div className="cont payment__cont">
            <Step4
              currentTariff={selectedForPayTariff}
              setCurrentStep={setCurrentStep}
              thisYearRating={thisYearRating}
              utmTags={utmTags}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
