import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import * as Type from "@interface/index";

const SignInPage: React.FC<Type.ComponentInterface> = (props) => {
  const [login, setLogin] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const authStore = useSelector((state: any) => state.Auth);

  useEffect(() => {
    document.title = "Login | " + process.env.SITE_NAME;
    dispatch(Middleware.Ui.Footer.hide());

    return () => {
      dispatch(Middleware.Ui.Footer.show());
    };
  }, [dispatch]);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      Middleware.Auth.checkEmail({ login }, (data: any) => {
        if (data.exist === true) {
          history.push("/auth/signin");
        } else {
          history.push("/auth/signup");
        }
      })
    );
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  };

  return (
    <div className="auth auth--login">
      <div className="auth__wrapper">
        <Link
          className="auth__close"
          to={authStore.preurl_status === "redirected" ? "/" : authStore.preurl}
        />
        <div className="auth__header">
          <h1>Регистрация</h1>
        </div>
        <div className="auth__body">
          <form onSubmit={onSubmitHandler}>
            <div className="auth__input">
              <label className="visually-hidden" htmlFor="login">
                Ваш email*
              </label>
              <input
                className="input"
                type="email"
                name="login"
                id="login"
                value={login}
                onChange={onChangeHandler}
                placeholder="E-mail"
                required
              />
            </div>
            <div className="auth__footer">
              <button className="btn btn--red auth__btn" type="submit">
                Ок
              </button>

              {/* <div className="auth__socials">
                    <div className="auth__socials-desc">Быстрый вход:</div>
                    <div className="auth__socials-icons">
                      <Link to="#">
                        <i className="icon-vk" />
                      </Link>
                      <Link to="#">
                        <i className="icon-facebook" />
                      </Link>
                    </div>
                </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(SignInPage);
