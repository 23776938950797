import React from "react";
import * as Middleware from "@middlewares/index";

interface IProps {
  thisYearRating: number;
  setCurrentStep: (value: number) => void;
}

const Step3: React.FC<IProps> = ({
  thisYearRating,
  setCurrentStep,
}) => {
  return (
    <div className="payment__step">
      <div className="payment__block">
        <h1 className="payment__step-title">У вас недостаточно баллов</h1>
        <div className="payment__step-description">
          На вашем балансе {thisYearRating} баллов, оплатите тариф рублями
        </div>
        <button
          className="btn btn--medium btn--red"
          onClick={() => {
            Middleware.Payment.payment("rub");
            setCurrentStep(2);
          }}
        >
          Оплатить рублями
        </button>
        <div className="points">
          <h2 className="points__title">Как получить баллы?</h2>
          <div className="points__steps">
            <div className="points__step">
              <div className="points__step-header">
                <span className="points__step-number">1</span>
                <h3 className="points__step-title">
                  Как получить баллы Loyalty прямо сейчас?
                </h3>
              </div>
              <div className="points__step-description">
                <ol>
                  <li>
                    Изучить информацию об экспонентах - посмотреть стенды
                    (10&nbsp;баллов), скачать каталоги (15&nbsp;баллов).
                  </li>
                  <li>
                    Внести данные о брони на сайте Loyalty (от 100 баллов).
                  </li>
                </ol>
                <p>
                  Доступно для тех, кто осуществлял бронирование у партнёров
                  программы Loyalty.
                </p>
                <p>
                  <b>Как это проверить?</b> Ознакомьтесь со{" "}
                  <a
                    href="https://profi.travel/loyalty#partners"
                    target="_blank"
                  >
                    <b>списком парнёров</b>
                  </a>
                  . Если в нём есть компании, через которые вы недавно
                  бронировали туры для туристов, внесите данные о своей брони на
                  сайт Loyalty, дождитесь подтверждения данных о брони и
                  получите баллы.
                </p>
              </div>
            </div>
            <div className="points__step">
              <div className="points__step-header">
                <span className="points__step-number">2</span>
                <h3 className="points__step-title">
                  Кто может получить баллы Loyalty?
                </h3>
              </div>
              <div className="points__step-description">
                <p>
                  В Loyalty могут участвовать только действующие турагенты
                  (собственники или официально трудоустроенные). Мы можем
                  запросить подтверждающие документы. Победителями в Loyalty не
                  могут быть самостоятельные турагенты, работающие с системами
                  онлайн-бронирования (например, Workle).
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          className="btn btn--medium btn--white-red"
          href="https://profi.travel/loyalty#partners"
          target="_blank"
        >
          Получить баллы
        </a>
      </div>
    </div>
  );
};

export default Step3;
