import Clickoutside from "@helpers/Clickoutside";
import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Style from "./Select.module.scss";

interface PropsInterface {
  name: string;
  required?: boolean;
  value?: number;
  options: { id: number; name: string; isPriority?: boolean }[];
  onChange?: (event: any) => any;
  placeholder?: string;
  disabled?: boolean;
  clear: () => void;
  hasChevron: boolean;
}

const Select = ({
  name,
  value = 0,
  options = [],
  onChange = () => {},
  placeholder = name,
  clear,
  hasChevron = false,
}: PropsInterface): ReactElement => {
  const [show, setShow] = useState(false);
  const [prioritied, setPrioritied] = useState<PropsInterface["options"]>([]);
  const [items, setItems] = useState<PropsInterface["options"]>([]);

  useEffect(() => {
    setPrioritied(options.filter((Item) => Item.isPriority));
    setItems(options.filter((Item) => !Item.isPriority));
  }, [options]);

  return (
    <Clickoutside clickOutside={() => setShow(false)}>
      <div
        className={`${Style.container} ${
          show && Style.container__chevronShow
        } ${hasChevron && !value && Style.container__chevron}`}
      >
        <input
          type="text"
          placeholder={placeholder || name}
          className={Style.input}
          value={options.find((option) => option.id === value)?.name || ""}
          onFocus={() => setShow(true)}
          readOnly
        />
        {value ? (
          <button
            className="btn btn--filter-reset netw-filter__country-reset"
            type="button"
            onClick={() => clear()}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 1L5 5M5 5L1 1M5 5L1 9M5 5L9 9" stroke="#ff0011" />
            </svg>
          </button>
        ) : (
          <></>
        )}
        <div
          className={[Style.selector, show ? Style.selectorShow : ""].join(" ")}
        >
          <ul className={Style.priority}>
            {prioritied.map(
              (option: { id: number; name: string }, index: number) => {
                return (
                  <li key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(false);
                        onChange({
                          target: { name, value: option.id },
                        });
                      }}
                    >
                      {option.name}
                    </Link>
                  </li>
                );
              }
            )}
          </ul>

          {prioritied.length && items.length ? <hr /> : <></>}

          <ul>
            {items.map(
              (option: { id: number; name: string }, index: number) => {
                return (
                  <li key={index}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(false);
                        onChange({
                          target: { name, value: option.id },
                        });
                      }}
                    >
                      {option.name}
                    </Link>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </Clickoutside>
  );
};

export default Select;
