import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Pages extends ModelPrototype {
  public async load(url: string): Promise<any> {
    try {
      return this.withUrl(url).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
