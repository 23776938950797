import React from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import {
  Input,
  Select,
  InputCompanyType,
  InputAutocomplete,
} from "@components/form/index";
import Connector from "@helpers/Connector";
import { ContactsValidation } from "@components/cabinet";
import PasswordChanger from "./components/PasswordChange";
import Networking from "./components/Networking";
import Style from "./Profile.module.scss";
import { PreloaderComponent } from "@components/preloader";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  firstname: string;
  lastname: string;
  country_id: number | null;
  city_id: number | null;
  worktype: number;
  companyname: string;
  companytypes: any[];
  position: string;
  phone: string;
  email: string;
  edit: boolean;
  isProfileLoaded: boolean;
}

export default Connector(
  class ProfilePage extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        firstname: "",
        lastname: "",
        country_id: null,
        city_id: null,
        worktype: 1,
        companyname: "",
        companytypes: [],
        position: "",
        phone: "",
        email: "",
        edit: false,
        isProfileLoaded: false,
      };

      this.onSubmitHandler = this.onSubmitHandler.bind(this);
      this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    public componentDidMount() {
      document.title = "Profile" + " | " + process.env.SITE_NAME;
      Middleware.Location.Country.load();
      Middleware.Profile.load().then((Profile) => {
        this.setState({
          firstname: Profile.firstName || "",
          lastname: Profile.lastName || "",
          country_id: Profile.networkingProfile?.country || null,
          city_id: Profile.networkingProfile?.city || null,
          worktype: Profile.networkingProfile?.employmentType || 1,
          companyname: Profile.workName || "",
          companytypes: Profile.networkingProfile?.companyTypes || [],
          position: Profile.position || "",
          phone: Profile.phone || "",
          email: Profile.email,
          isProfileLoaded: true,
        });
      });
    }

    private onSubmitHandler(event: React.FormEvent<HTMLFormElement>): void {
      event.preventDefault();
      Middleware.Profile.update({
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        country_id: this.state.country_id,
        city_id: this.state.city_id,
        worktype: this.state.worktype,
        companyname: this.state.companyname,
        companytypes: this.state.companytypes,
        position: this.state.position,
        phone: this.state.phone,
        email: this.state.email,
      }).then(() => {
        Middleware.Profile.load();
        this.setState({ edit: false });
      });
    }

    private onChangeHandler(
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): void {
      this.setState({ [event.target.name]: event.target.value });
    }

    public render() {
      return (
        <div className="cabinet__layout">
          <h1 className="cabinet__title">Контактные данные</h1>
          <div className="cabinet__layout-body">
            <div className="cabinet__layout-col">
              {this.state.isProfileLoaded ? (
                <ContactsValidation isLinkBtn={false} />
              ) : null}
              <div className="cabinet__block">
                {this.state.isProfileLoaded ? (
                  <form className="form" onSubmit={this.onSubmitHandler}>
                    <div className={Style.header}>
                      <h2 className="form__title">Пользователь</h2>
                      <Link
                        to="#"
                        className={Style.editLink}
                        style={{ display: this.state.edit ? "none" : "flex" }}
                        onClick={() => this.setState({ edit: true })}
                      >
                        Изменить
                      </Link>
                    </div>
                    <div className="form__body">
                      <div className="form__group form__group--disclaimer">
                        Пожалуйста, убедитесь, что вся контактная информация
                        актуальна — по указанному адресу электронной почты и
                        телефону вы будете получать уведомления об эфирах, новых
                        встречах и визитках (если ваш профиль участвует в
                        нетворкинге)
                      </div>
                      <div className="form__group">
                        <div className="form__group form__group--col-2">
                          <Input
                            type="text"
                            name="firstname"
                            placeholder="Имя"
                            value={this.state.firstname}
                            required={true}
                            onChange={this.onChangeHandler}
                            disabled={!this.state.edit}
                          />
                          <Input
                            type="text"
                            name="lastname"
                            placeholder="Фамилия"
                            required={true}
                            value={this.state.lastname}
                            onChange={this.onChangeHandler}
                            disabled={!this.state.edit}
                          />
                        </div>
                        <Select
                          name="country_id"
                          value={this.state.country_id}
                          onChange={this.onChangeHandler}
                          options={this.props.Store.Location.Country.list}
                          required={true}
                          disabled={!this.state.edit}
                          placeholder="Ваша страна"
                        />
                        {this.state.country_id === null ? (
                          <></>
                        ) : (
                          <InputAutocomplete
                            name="city_id"
                            type="city"
                            selected={this.state.city_id}
                            country_id={this.state.country_id}
                            placeholder="Город"
                            onSelect={this.onChangeHandler}
                            required={true}
                            disabled={!this.state.edit}
                          />
                        )}

                        {/* <Input
                            type="text"
                            name="phone"
                            id="phone"
                            value={this.state.phone}
                            placeholder={"Телефон"}
                            onChange={this.onChangeHandler}
                            modificator={Modificator.PhoneWithPrefix}
                            disabled={!this.state.edit}
                            required={true}
                          /> */}
                        <PhoneInput
                          country={"ru"}
                          placeholder={"Телефон"}
                          value={this.state.phone}
                          onChange={(phone) => {
                            this.setState({ phone });
                          }}
                          disabled={!this.state.edit}
                          containerClass={"phone-input"}
                          inputProps={{
                            name: "phone",
                            required: true,
                            pattern: ".{7,}",
                          }}
                        />
                        <Input
                          type="email"
                          name="email"
                          placeholder="E-mail"
                          required={true}
                          value={this.state.email}
                          onChange={this.onChangeHandler}
                          disabled={!this.state.edit}
                        />
                      </div>

                      <hr />

                      <div className="form__group">
                        <div className="radio">
                          <input
                            className="visually-hidden"
                            type="radio"
                            name="worktype"
                            id="jobtype-1"
                            onChange={() => this.setState({ worktype: 1 })}
                            checked={this.state.worktype === 1}
                            disabled={!this.state.edit}
                          />
                          <label tabIndex={0} htmlFor="jobtype-1">
                            Работаю в компании
                          </label>
                          <input
                            className="visually-hidden"
                            type="radio"
                            name="worktype"
                            id="jobtype-2"
                            value={2}
                            onChange={() => this.setState({ worktype: 2 })}
                            checked={this.state.worktype === 2}
                            disabled={!this.state.edit}
                          />
                          <label htmlFor="jobtype-2">
                            Фрилансер, самозанятый
                          </label>
                        </div>
                      </div>

                      <div className="form__group">
                        {this.state.worktype !== 1 ? (
                          <Input
                            type="text"
                            name="position"
                            placeholder={
                              this.state.worktype !== 1
                                ? "Ваша профессия"
                                : "Ваша должность"
                            }
                            onChange={this.onChangeHandler}
                            value={this.state.position}
                            required={true}
                            disabled={!this.state.edit}
                          />
                        ) : (
                          <>
                            <InputAutocomplete
                              value={this.state.companyname}
                              name="companyname"
                              type="company"
                              placeholder="Компания"
                              onChange={this.onChangeHandler}
                              required={true}
                              disabled={!this.state.edit}
                            />

                            <div className="form__group form__group--col-2">
                              <InputCompanyType
                                name="companytypes"
                                value={this.state.companytypes}
                                onChange={this.onChangeHandler}
                                required={true}
                                disabled={!this.state.edit}
                              />
                              <Input
                                type="text"
                                name="position"
                                placeholder={
                                  this.state.worktype !== 1
                                    ? "Ваша профессия"
                                    : "Ваша должность"
                                }
                                onChange={this.onChangeHandler}
                                value={this.state.position}
                                required={true}
                                disabled={!this.state.edit}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {this.state.edit ? (
                      <div className="form__footer">
                        <button type="submit" className="btn btn--red">
                          Сохранить
                        </button>
                      </div>
                    ) : null}
                  </form>
                ) : (
                  <PreloaderComponent />
                )}
              </div>
              <PasswordChanger />
            </div>
            <div className="cabinet__layout-col">
              <Networking />
            </div>
          </div>
        </div>
      );
    }
  }
);
