import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Test extends ModelPrototype {
  public async initializeRequest(url: string, method: Method, data: any = {}): Promise<any> {
    try {
      return this.withUrl(url).setMethod(method).request(data);
    } catch (Exception) {
      console.error(Exception);
      throw Exception;
    }
  }
}
