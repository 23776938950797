import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

enum ViewType {
  Input = 0,
  Output,
}

interface StateInterface {
  viewType: ViewType;
}

class EventsListPage extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      viewType: ViewType.Input,
    };
  }

  public render() {
    return (
      <>
        <div className="page-cutaways-requests">
          <div className="header">
            <h1>Запросы на визитку</h1>
            <Link to="/cabinet/cutaways" className="btn btn-lg btn-shadow br-10">
              Визитки
            </Link>
          </div>
          <div className="row flex-nowrap data">
            <div className="sidebar">
              <Link to="#">Отправить запрос</Link>
              <ul>
                <li>
                  <Link to="#" className="active">
                    Входящие
                  </Link>
                </li>
                <li>
                  <Link to="#">Исходящие</Link>
                </li>
              </ul>
            </div>
            <div className="flex-wrap w-100">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i: number) => {
                return (
                  <div className="cutaway" key={i}>
                    <div className="row jc-between">
                      <div className="avatar">
                        <img src={require("@public/images/avatar.png")} alt="" />
                      </div>
                      <div className="company">
                        <img src={require("@public/images/logo_pt.png")} alt="" />
                      </div>
                    </div>
                    <div className="row name">
                      <b>
                        <Link to="#">Tokisaki Kurumi</Link>
                      </b>
                    </div>
                    <div className="row">Директор</div>
                    <div className="row">ООО "СОВРЕМЕННЫЕ ТЕХНОЛОГИИ"</div>
                    <br />

                    <div className="row">kryuchkova@turprofi.ru</div>
                    <div className="row">7 903 589-06-66</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <Link to="/cabinet/cutaway/1">Визитка 1</Link> */}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(EventsListPage)
);
