import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Model from "@models/index";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  Events: any[];
  Dates: any[];
  Refresh: any;
}

interface StateInterface {}

class CalendarComponent extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount() {
    // Middleware.Ui.Modal.open("CalendarEvent", {
    //   callback: "",
    // });
  }

  public render() {
    return (
      <>
        <div className="calendar">
          <div className="calendar__header">
            {this.props.Dates.map((Date: any, index: number) => {
              return (
                <div className="calendar__header-item" key={index}>
                  <div className="calendar__header-date">
                    {Date.locale("ru").format("DD MMMM, dd")}
                  </div>
                  {moment().tz("Europe/Moscow").isSame(Date, "day") ? (
                    <div className="calendar__header-today">Сегодня</div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
          <div className="calendar__aside">
            <div className="calendar__aside-item">8:00</div>
            <div className="calendar__aside-item">9:00</div>
            <div className="calendar__aside-item">10:00</div>
            <div className="calendar__aside-item">11:00</div>
            <div className="calendar__aside-item">12:00</div>
            <div className="calendar__aside-item">13:00</div>
            <div className="calendar__aside-item">14:00</div>
            <div className="calendar__aside-item">15:00</div>
            <div className="calendar__aside-item">16:00</div>
            <div className="calendar__aside-item">17:00</div>
            <div className="calendar__aside-item">18:00</div>
            <div className="calendar__aside-item">19:00</div>
            <div className="calendar__aside-item">20:00</div>
          </div>
          <div className="calendar__body">
            <div className="calendar__week">
              {this.props.Dates.map((Date: any, index: number) => {
                return (
                  <div className="calendar__day" key={index}>
                    <div className="calendar__layout">
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                      <div className="calendar__layout-item" />
                    </div>
                    <div className="calendar__events">
                      {this.props.Events.map((Event: any, index: number) => {
                        {
                          // console.log(Event);
                        }
                        if (
                          moment(Event.startAt)
                            // .subtract(1, "days")
                            .tz("Europe/Moscow")
                            .isSame(Date, "day")
                        ) {
                          const duration =
                            Math.floor(
                              moment(Event.endAt)
                                .tz("Europe/Moscow")
                                .diff(moment(Event.startAt).tz("Europe/Moscow"), "minutes") / 15
                            ) * 15;
                          let formatedMinutes = String(
                            Math.floor(
                              Number(moment(Event.startAt).tz("Europe/Moscow").format("mm")) / 15
                            ) * 15
                          );
                          if (formatedMinutes.length === 1) {
                            formatedMinutes = "0" + formatedMinutes;
                          }

                          let formatedHours = String(
                            moment(Event.startAt).tz("Europe/Moscow").format("HH")
                          );
                          if (formatedHours.length === 1) {
                            formatedHours = "0" + formatedHours;
                          }
                          const start = formatedHours + formatedMinutes;
                          return (
                            <Link
                              to="#"
                              onClick={() =>
                                Middleware.Ui.Modal.open("CalendarEvent", {
                                  callback: this.props.Refresh,
                                  data: {
                                    start: Event.startAt,
                                    title: Event.title,
                                    type: Event.type,
                                    id: Event.id,
                                    status: Event.status,
                                    isOwner: Event.isOwner,
                                  },
                                })
                              }
                              key={index}
                              className={`calendar__item calendar__item--${
                                Event.type === "broadcast"
                                  ? "broadcast"
                                  : Event.type === "webinar"
                                  ? "webinar"
                                  : Event.type === "userEvent"
                                  ? Event.status
                                    ? "confirmed"
                                    : Event.isOwner
                                    ? "waiting"
                                    : "meet-request"
                                  : ""
                              }`}
                              data-event-duration={duration}
                              data-event-start={start}
                            >
                              <div className="calendar__item-content">
                                <div className="calendar__item-title">
                                  {Event.type === "broadcast"
                                    ? "Трансляция"
                                    : Event.type === "webinar"
                                    ? "Вебинар"
                                    : Event.type === "userEvent"
                                    ? Event.status
                                      ? "Подтверждено"
                                      : Event.isOwner
                                      ? "Ждет ответа"
                                      : "Запрос на встречу!"
                                    : ""}
                                </div>
                                <div className="calendar__item-desc">{Event.title}</div>
                              </div>
                            </Link>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              })}
              {/* <div className="calendar__events">
                  {this.props.Events.map((Event: any, index: number) => {
                    if (moment(event.startAt).isSame(date, "day")) {
                      const tmpDate = moment(Event.startAt);
                      tmpDate.diff(Date);
                      console.log(tmpDate.format("YYYY-MM-DD HH:mm"));
                      tmpDate.subtract(60000 * 60 * 12);
                      console.log(tmpDate.format("YYYY-MM-DD HH:mm"));
                      const top = tmpDate.hour() * 60 + tmpDate.minute();
                      console.log(top / 60);

                    return (
                      <div
                        key={index}
                        className="calendar__item calendar__item--meet-request"
                        data-event-duration="60"
                        data-event-start="0800"
                      >
                        <div className="calendar__item-content">
                          <div className="calendar__item-title">Запрос на встречу!</div>
                          <div className="calendar__item-desc">{Event.title}</div>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="15"
                    data-event-start="1200"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>

                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="15"
                    data-event-start="1215"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>

                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="15"
                    data-event-start="1230"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>

                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="15"
                    data-event-start="1245"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>

                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="30"
                    data-event-start="1300"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>

                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-duration="30"
                    data-event-start="1330"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>  </div>
          </div>

          <div className="calendar__day">
            <div className="calendar__layout">
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
            </div>

            <div className="calendar__events"> <div
                    className="calendar__item calendar__item--broadcast"
                    data-event-start="1000"
                    data-event-duration="60"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Трансляция</div>
                      <div className="calendar__item-desc">
                        Ensana Health Spa Hotels — 5 стран, 23 отеля! Встречайте новые отели в
                        Великобритании и Румынии.
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--broadcast"
                    data-event-start="1100"
                    data-event-duration="120"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Трансляция</div>
                      <div className="calendar__item-desc">
                        Окинава — ведущее туристическое направление Японии: советы по продажам,
                        питание и размещение, актуальная информация по COVID-19
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--meet-request"
                    data-event-start="1400"
                    data-event-duration="30"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Запрос на встречу!</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div> </div>
          </div>

          <div className="calendar__day">
            <div className="calendar__layout">
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
            </div>

            <div className="calendar__events">  <div
                    className="calendar__item calendar__item--meet-request"
                    data-event-start="1000"
                    data-event-duration="45"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Запрос на встречу!</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--more-requests"
                    data-event-start="1045"
                    data-event-duration="15"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">
                        <span>+10</span> запросов
                      </div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--confirmed"
                    data-event-start="1200"
                    data-event-duration="60"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Подтверждено</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>  </div>
          </div>

          <div className="calendar__day">
            <div className="calendar__layout">
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
            </div>

            <div className="calendar__events"><div
                    className="calendar__item calendar__item--confirmed"
                    data-event-start="1000"
                    data-event-duration="60"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Подтверждено</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--confirmed"
                    data-event-start="1200"
                    data-event-duration="60"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Подтверждено</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div> </div>
          </div>

          <div className="calendar__day">
            <div className="calendar__layout">
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
              <div className="calendar__layout-item"></div>
            </div>

            <div className="calendar__events"> <div
                    className="calendar__item calendar__item--meet-request"
                    data-event-start="0800"
                    data-event-duration="60"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Запрос на встречу!</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-start="1200"
                    data-event-duration="15"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
                  <div
                    className="calendar__item calendar__item--waiting"
                    data-event-start="1300"
                    data-event-duration="30"
                  >
                    <div className="calendar__item-content">
                      <div className="calendar__item-title">Ждет ответа</div>
                      <div className="calendar__item-desc">
                        Встреча Qatar Airways + Profi.Travel
                      </div>
                    </div>
                  </div>
              </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CalendarComponent)
);
