import React, { ReactElement, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import ListSubscribeBtn from "./ListSubscribeBtn";
import { useSelector } from "react-redux";
import { specialAirtimeId } from "@pages/dashboard/components2.0/Schedule/Schedule";
import { useCurrentTariff } from "@hooks/useCurrentTariff";
import { ITariff } from "../Price";

interface IProps {
  event: any;
  networkingDay: boolean;
}

const ScheduleListEvent = ({ event, networkingDay }: IProps): ReactElement => {
  const [isDescShowed, setIsDescShowed] = useState(false);
  const isAuthorized = useSelector((state: any) => state.Auth.status);
  const currentTariff: ITariff | null = useCurrentTariff();
  const currentTariffType = currentTariff ? currentTariff.type : null;

  SwiperCore.use([Navigation]);

  const isSpecialAirtime = specialAirtimeId.includes(event.id);

  {
    return networkingDay ? (
      <div
        id={`event_${event.id}`}
        className="schedule__event schedule__item-netw"
        key={event.id}
      >
        <div className="schedule__item-netw-body">
          <div className="schedule__item-netw-content">
            <p className="schedule__item-netw-title">День нетворкинга</p>
            <p className="schedule__item-netw-desc">
              В этот день открыта возможность проведения встреч в системе
              нетворкинга. Выберите партнеров в турбизнесе и начните общение с
              ними!
            </p>
          </div>
          <div className="schedule__item-netw-btn">
            <Link to="/networking" className="btn btn--red">
              Перейти в систему
            </Link>
          </div>
        </div>
      </div>
    ) : (
      <div
        id={`event_${event.id}`}
        className={`schedule__event schedule__item ${
          isSpecialAirtime ? "schedule__item--special" : ""
        }`}
      >
        <div className="schedule__item-body">
          <div className="flex gap-10 2xl:gap-[64px]">
            <div className="flex flex-col lg:min-w-[264px]">
              <div className="schedule__item-date-block">
                <div className="schedule__item-date">
                  <p>
                    {moment(event.date).tz("Europe/Moscow").format("HH:mm")}
                  </p>
                  <span className="schedule__item-date-timezone">мск</span>
                </div>
              </div>

              <div className="flex flex-col lg:hidden gap-3 md:gap-2 !mb-5 md:!mb-6">
                <a
                  href={"https://profi.travel" + event.path}
                  className="schedule__item-title"
                  target="_blank"
                >
                  {event.title}
                </a>
                {event.scheduleDescription && (
                  <div className="schedule__item-desc">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: event.scheduleDescription,
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="schedule__item-btn-block">
                <ListSubscribeBtn
                  currentTariffType={currentTariffType}
                  eventId={event.id}
                  eventType={event.type}
                  isAuthorized={isAuthorized}
                  status={event.subscribed}
                  isEnded={
                    moment().tz("Europe/Moscow") >
                    moment(event.dateEnd).tz("Europe/Moscow")
                  }
                  isOnline={
                    moment().tz("Europe/Moscow") >
                    moment(event.date).tz("Europe/Moscow") &&
                    moment().tz("Europe/Moscow") <
                    moment(event.dateEnd).tz("Europe/Moscow")
                  }
                  path={event.path}
                />
                <div className="schedule__item-participants">
                  {currentTariff !== 2 && isSpecialAirtime && (
                    <div>
                      <p>Только по тарифу «Полный»</p>
                    </div>
                  )}
                  <div className="hidden lg:block">
                    <span className="odometer odometer-auto-theme font-bold">
                      {event.participantsCount}
                    </span>{" "}
                    участников уже записались
                  </div>
                </div>
              </div>
            </div>

            <div className="schedule__item-content">
              <div className="flex-col hidden lg:flex gap-6">
                <a
                  href={"https://profi.travel" + event.path}
                  className="schedule__item-title"
                  target="_blank"
                >
                  {event.title}
                </a>
                {event.scheduleDescription && (
                  <div className="schedule__item-desc">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: event.scheduleDescription,
                      }}
                    />
                  </div>
                )}
              </div>

              {event.description && (
                <button
                  className={`hidden lg:flex !mt-6 schedule__item-more-btn ${
                    isDescShowed ? "schedule__item-more-btn--opened" : ""
                  }`}
                  type="button"
                  onClick={() => {
                    setIsDescShowed(!isDescShowed);
                  }}
                >
                  <span>Программа</span>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1.5L6 6.5L1 1.5"
                      stroke="#191919"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M11 1.5L6 6.5L1 1.5"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M11 1.5L6 6.5L1 1.5"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="1.5"
                    />
                  </svg>
                </button>
              )}

              {event.description ? (
                <div
                  className={`schedule__item-longdesc ${
                    isDescShowed ? "schedule__item-longdesc--active" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              ) : null}
            </div>
          </div>

          <div className="hidden lg:flex flex-col shrink-0 xl:w-[350px]">
            <div className="schedule__item-company">
              {event.companyTitle && (
                <div className="flex justify-between xl:flex-col xl:gap-2">
                  <p className="schedule__item-company-title">
                    {event.companyTitle}
                  </p>
                  {event.standId && (
                    <div className="text-red flex gap-2">
                      <Link to={`company/stand/${event.standId}`}>
                        <p className="text-red-500">Стенд компании</p>
                      </Link>
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.625 5.18945L17.9362 10.5007L12.625 15.812"
                          stroke="#FF0011"
                          strokeWidth="1.3125"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.0625 10.5H17.7887"
                          stroke="#FF0011"
                          strokeWidth="1.3125"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="schedule__item-slider-block">
              <div className="schedule__item-slider-wrapper">
                {event.experts.map((expert: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="schedule__expert">
                        <img
                          className="schedule__expert-photo"
                          src={
                            expert.image
                              ? expert.image
                              : require("@public/images/default_avatar.png")
                          }
                          alt=""
                        />
                        <div className="flex flex-col gap-[4px]">
                          <div className="schedule__expert-name">
                            {expert.fullName}
                          </div>
                          <div className="schedule__expert-workplace">
                            {expert.position}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ScheduleListEvent;
