import React from "react";
import { connect } from "react-redux";
import * as Middleware from "@middlewares/index";

interface PropsInterface {
  Store: any;
  Dispatch: any;
  children: any;
}

interface StateInterface {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  public componentDidCatch(error:Error, errorInfo:React.ErrorInfo) {

    console.log(error);
    console.log(errorInfo);

    // Send error report
    Middleware.Ui.Alert.open("Default", {
      text: "Что то пошло не так! Компонент сломался :(",
    });
  }

  public render() {
    if (this.state.hasError) {
      return <h1>Что-то пошло не так.</h1>;
    }

    return this.props.children;
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: any) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(ErrorBoundary);
