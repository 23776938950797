import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Calendar extends ModelPrototype {
  public async times(user_id: number, data?: { day: any; duration: any }): Promise<any> {
    try {
      return this.withUrl(`user/${user_id}/calendar/free`).setMethod(Method.GET).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
