import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  state: any;
  callback: (data: any) => void;
}

interface StateInterface {
  isCardAlertShowed: boolean;
  inviteStatus: any;
}

class Done extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      isCardAlertShowed: false,
      inviteStatus: this.props.state.Event.status,
    };
  }

  public render() {
    const authData = this.props.Store.Auth?.data;

    return (
      <div
        className={`event-create-done ${
          this.state.isCardAlertShowed
            ? "event-create-done--decline-popup"
            : this.state.inviteStatus === "error"
            ? "event-create-done--error-popup"
            : ""
        }`}
      >
        {this.state.isCardAlertShowed || this.state.inviteStatus === "error" ? (
          <></>
        ) : (
          <div className="event-create-done__title">
            <div>Готово</div>
            Приглашение на встречу отправлено!
          </div>
        )}

        <hr className="event-create-done__divider event-create-done__divider--first" />
        <div className="event-create-done__card">
          <div
            className={`event-create-done__card-alert ${
              this.state.isCardAlertShowed
                ? "event-create-done__card-alert--open"
                : ""
            }`}
          >
            <div className="event-create-done__card-alert-title">
              Отозвать приглашение?
            </div>
            <div className="event-create-done__card-alert-buttons">
              <Link
                className="btn btn--red event-create-done__card-alert-accept"
                to="#"
                onClick={() => {
                  Middleware.Ui.Modal.open("EventCancel", {
                    callback: "",
                    data: this.props.state.Event,
                  });
                }}
              >
                Да, Отозвать
              </Link>
              <Link
                onClick={() => {
                  this.setState({ isCardAlertShowed: false });
                }}
                className="btn btn--grey event-create-done__card-alert-decline"
                to="#"
              >
                Нет, оставить
              </Link>
            </div>
          </div>

          <div
            className={`event-create-done__card-alert event-create-done__card-alert--error ${
              this.state.inviteStatus === "error"
                ? "event-create-done__card-alert--open"
                : ""
            }`}
          >
            <div className="event-create-done__card-alert-content">
              <div className="event-create-done__card-alert-title">
                Вы популярны
              </div>
              <div className="event-create-done__card-alert-desc">
                Нам не удалось создать запрос на встречу, потому что кто-то уже
                занял выбранное вами время. Пожалуйста, создайте встречу на
                другое время.
              </div>
            </div>

            <div className="event-create-done__card-alert-buttons">
              <Link
                className="btn btn--red event-create-done__card-alert-accept"
                to={`/user/${this.props.Store.User.Selected.data?.id}/event/create`}
                onClick={() => {
                  this.props.callback({
                    step: 1,
                    duration: 0,
                    datetime: null,
                    description: this.props.state.description,
                    Event: null,
                  });
                }}
              >
                Изменить время
              </Link>
            </div>
          </div>

          <div className="event-create-done__card-header">
            <div className="event-create-done__card-title">
              <p>
                {this.props.Store.User.Selected.data?.firstName}{" "}
                {this.props.Store.User.Selected.data?.lastName} (
                {this.props.Store.User.Selected.data?.employmentType === 1
                  ? this.props.Store.User.Selected.data?.company?.title
                  : this.props.Store.User.Selected.data?.position}
                )
              </p>
              <span>+</span>
              <p>{`${authData?.firstName} ${authData?.lastName}`}</p>
            </div>
            <div className="event-create-done__date">
              {this.props.state.datetime?.format("DD MMM YYYY")} |{" "}
              {this.props.state.datetime?.format("HH:mm")} –{" "}
              {this.props.state.datetime
                ?.clone()
                .add(this.props.state.duration, "minutes")
                .format("DD MMMM YYYY")}
            </div>
          </div>
          <hr />
          <div className="event-create-done__card-user">
            <div className="event-create-done__user-title">Приглашает:</div>
            <div className="event-create-done__user">
              <div className="event-create-done__user-img">
                {authData?.userImage ? (
                  <img src={authData?.userImage || ""} alt="" />
                ) : (
                  <div className="void" />
                )}
              </div>
              <div className="event-create-done__user-name">{`${authData?.firstName} ${authData?.lastName}`}</div>
              <div className="event-create-done__user-company">
                {authData?.company?.title
                  ? authData?.company?.title
                  : "Фрилансер"}
              </div>
            </div>
          </div>
          <hr />
          <div className="event-create-done__card-desc">
            <div className="event-create-done__desc-title">Описание:</div>
            <div className="event-create-done__desc-text">
              {this.props.state.description}
            </div>
          </div>
          <div className="event-create-done__card-buttons">
            <div className="event-create-done__card-status">Отправлено</div>
            <Link
              onClick={() => {
                this.setState({ isCardAlertShowed: true });
              }}
              to="#"
              className="event-create-done__cancel-btn"
            >
              Отозвать
            </Link>
            {/* <Link className="event-create-done__other-time-btn" to="">
              Предложить другое время
            </Link> */}
          </div>
        </div>
        {this.state.isCardAlertShowed ? (
          <>
            <div className="event-create-done__decline-disclaimer">
              Если вы отзовете приглашение, оно будет удалено у обоих участников
              встречи.
              <span>Восстановить его будет нельзя.</span>
            </div>
          </>
        ) : this.state.inviteStatus === "error" ? (
          <></>
        ) : (
          <>
            <hr className="event-create-done__divider event-create-done__divider--second" />
            <div className="event-create-done__text">
              <p>
                Вы получите уведомление, когда{" "}
                {`${this.props.Store.User.Selected.data?.firstName} ${this.props.Store.User.Selected.data?.lastName}`}{" "}
                ответит на ваш запрос. Если ваши планы изменились, вы можете
                исправить или отозвать приглашение в вашем календаре
              </p>
              {/* <p>
                Если ваши планы изменились, вы можете исправить или отозвать приглашение в вашем
                календаре.
              </p> */}
            </div>
            <div className="event-create-done__buttons">
              <Link to="/cabinet/events" className="btn btn--red">
                ОК
              </Link>
              <Link
                className="event-create-done__to-calendar-link"
                to="/cabinet/events/calendar"
              >
                Перейти в календарь
              </Link>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Done)
);
