import React, { useEffect, useRef } from "react";
import ThreeWeeksItem from "./ThreeWeeksItem";
import BorderedText from "./BorderedText";
import { Link, useLocation } from "react-router-dom";

const speakers = [
  {
    icon: "icon-arrow-up.svg",
    title: "Сезон-2025",
    description: (
      <>
        Новые знания о популярных зарубежных направлениях лета 2025 года на{" "}
        <Link className="red-link" to="/?anchor=schedule-anchor">
          вебинарах
        </Link>{" "}
        и{" "}
        <Link className="red-link" to="/?anchor=stands-anchor">
          стендах
        </Link>{" "}
        экспонентов
      </>
    ),
  },
  {
    icon: "connect.svg",
    title: "Полезные связи",
    description: (
      <>
        Удобная система{" "}
        <a className="red-link" target="_blanc" href="/networking">
          Нетворкинга
        </a>{" "}
        для поиска партнёров и дружелюбное комьюнити в 
        <a
          className="red-link"
          target="_blanc"
          href="https://t.me/joinchat/R2ZetIMuTgIwODVi"
        >
          чате ProfiTravel Loyalty
        </a>
        .
      </>
    ),
  },
  {
    title: "7 спецэфиров по привлечению туристов",
    description:
      "Кейсы по продвижению в ВК и Telegram, работа с видео, бесплатные методы привлечения клиентов от топовых экспертов отрасли",
    speakerPhoto: "speaker-photo-1.png",
  },
  {
    icon: "present.svg",
    title: "Призы за активность",
    description: (
      <>
        За просмотры вебинаров, стендов, скачивание каталогов есть шанс выиграть{" "}
        <Link className="red-link" to="/?anchor=prizes-anchor">
          отдых на Мальдивах, Таиланде, ОАЭ
        </Link>
      </>
    ),
  },
  {
    icon: "diplom.svg",
    title: "Диплом участника",
    description: "Диплом о прохождении интенсива по зарубежному турпродукту",
  },
];

const infoText = [
  { text: "3 недели" },
  { text: "2-3 часа в день" },
  { text: "Приветливое комьюнити" },
  { text: "Онлайн-презентации" },
];

const title = "Три недели активной работы";

const ThreeWeeksOverview = (): JSX.Element => {
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "about" && ref) {
      console.log("SCROLL EVENT");
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  return (
    <section
      id="about"
      ref={ref}
      className="bg-white py-14 md:py-16 lg:py-[100px] 2xl:py-[120px] 3xl:py-[140px]"
    >
      <div className="cont grid 2xl:grid-cols-[auto,1fr] gap-5 2xl:gap-[76px]">
        <div className="hidden 2xl:flex flex-col items-start justify-between w-[362px] 3xl:w-[390px]">
          <p className="title-middle">{title}</p>
          <div className="flex flex-col md:flex pt-[50px] xl:pt-16 gap-2">
            {infoText.map((item, index) => (
              <BorderedText key={index} text={item.text} />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5 w-full">
          <div className="2xl:hidden">
            <p className="title-middle pb-10">{title}</p>
          </div>
          <div className="flex flex-wrap gap-5 lg:gap-8">
            {speakers.map((item, index) => (
              <ThreeWeeksItem
                key={index}
                icon={item.icon && require(`@public/images/${item.icon}`)}
                title={item.title && item.title}
                description={item.description && item.description}
                speakerPhoto={
                  item.speakerPhoto &&
                  require(`@public/images/${item.speakerPhoto}`)
                }
              />
            ))}
          </div>
          <div className="hidden md:flex flex-wrap md:flex pt-[50px] xl:pt-16 gap-2 2xl:hidden">
            {infoText.map((item, index) => (
              <BorderedText key={index} text={item.text} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThreeWeeksOverview;
