import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import { Input } from "@components/form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  startDate: Date;
  endDate: Date;
}

class Request extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <>
        <div className="stand-request stand__block">
          <div className="stand-request__title">Запрос на тур / размещение</div>
          <div className="stand-request__body">
            <div className="stand-request__row stand-request__row--first">
              <div className="stand-request__row-title">Количество</div>
              <div className="stand-request__row-body">
                <Input className="input" type="number" placeholder="Взрослые" />
                <Input className="input" type="number" placeholder="Дети от 0 до 3 лет" />
              </div>
            </div>
            <div className="stand-request__row stand-request__row--second">
              <div className="stand-request__row-title">Период</div>
              <div className="stand-request__row-body">
                <DatePicker
                  name="startDate"
                  dateFormat="dd.MM.yyyy"
                  autoComplete="off"
                  selected={this.state.startDate}
                  onChange={(date: any) => this.setState({ startDate: date })}
                  placeholderText="Заезд"
                />
                <DatePicker
                  name="endDate"
                  dateFormat="dd.MM.yyyy"
                  autoComplete="off"
                  selected={this.state.endDate}
                  onChange={(date: any) => this.setState({ endDate: date })}
                  placeholderText="Выезд"
                />
              </div>
            </div>
            <div className="stand-request__row stand-request__row--third">
              <textarea
                className="textarea"
                name="comment"
                id="comment"
                placeholder="Добавить комментарий"
              ></textarea>
            </div>
          </div>
          <div className="stand-request__footer">
            <button className="btn btn--red" type="submit">
              Отправить заявку
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Request)
);
