import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import * as Action from "@actions/index";
import RedirectContainer from "./Redirect";
import Connector from "@helpers/Connector";
import { useDispatch } from "react-redux";

interface PropsInterface extends RouteProps {
  validator?: any | any[];
}

// class Router extends Route<PropsInterface> {
export default (props: PropsInterface) => {

  const dispatch = useDispatch();

  const initRoute = () => {
    return (
      <Route
        {...{
          ...props
        }}
      />
    );
  };

  const redirectTo = (path: string, pre: () => void = () => {}) => {
    return (
      <Route
        {...{
          ...props,
          component: undefined,
        }}
      >
        <RedirectContainer onMount={pre}>
          <Redirect to={{ pathname: path }} />
        </RedirectContainer>
      </Route>
    );
  };

  // const validate = (Validator: any): any => {
  //   return new Validator(this.props.Store).validate();
  // };

  // if (props.validator !== undefined) {
  //   if (Array.isArray(props.validator) === true) {
  //     for (const Validator of props.validator) {
  //       const Response = validate(Validator);
  //       if (Response.isValid === false) {
  //         if (Response.redirectPath) {
  //           if (Response.preurl === true) {
  //             return redirectTo(Response.redirectPath, () => {
  //               dispatch(Action.Auth.setPreurl(window.location.pathname));
  //               dispatch(Action.Auth.setPreurlStatus("redirected"));
  //             });
  //           } else {
  //             return redirectTo(Response.redirectPath, () => {});
  //           }
  //         } else {
  //           return <></>;
  //         }
  //       }
  //     }
  //   } else {
  //     const Response = validate(props.validator);
  //     if (Response.isValid === false) {
  //       if (Response.redirectPath) {
  //         if (Response.preurl === true) {
  //           return redirectTo(Response.redirectPath, () => {
  //             dispatch(Action.Auth.setPreurl(window.location.pathname));
  //             dispatch(Action.Auth.setPreurlStatus("redirected"));
  //           });
  //         } else {
  //           return redirectTo(Response.redirectPath, () => {});
  //         }
  //       } else {
  //         return <></>;
  //       }
  //     }
  //   }
  // }

  return initRoute();
};
