import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {
  currentSlide: number;
}

class Video extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
  }

  public render() {
    SwiperCore.use([Navigation]);

    return (
      <>
        {this.props.data.length > 0 ? (
          <>
            <div className="stand-slider stand__block">
              <div className="stand-slider__title">{this.props.title}</div>
              <div className="stand-slider__counter">
                <div className="stand-slider__counter-current">{this.state.currentSlide + 1}</div>
                <div className="stand-slider__counter-total"> / {this.props.data.length}</div>
              </div>
              <div className="stand-slider__slider">
                <Swiper
                  slidesPerView={1}
                  onSlideChange={(e) => this.setState({ currentSlide: e.realIndex })}
                  navigation={true}
                  loop={true}
                >
                  {this.props.data.map((item: any, index: number) => {
                    return (
                      <SwiperSlide key={index}>
                        {console.log(window.YT)}
                        <iframe
                          width="560"
                          height="315"
                          src={item.path}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Video)
);
