import React, { useState } from "react";
import { RegistrationProps } from "./page";
import {
  Input,
  InputAutocomplete,
  InputCompanyTypeForRegistration,
} from "@components/form";

const EMPLOYMENT_TYPE_COMPANY = 1;
const EMPLOYMENT_TYPE_SELF = 2;

const CompanyForm: React.FC<RegistrationProps> = ({
  data,
  onChange = () => {},
}) => {
  const [employmentType, setEmploymentType] = useState(
    Number(data.employmentType || EMPLOYMENT_TYPE_COMPANY)
  );

  return (
    <div className="form__group--margin">
      <div className="form__group form__group--margin">
        <div className="radio">
          <input
            className="visually-hidden"
            type="radio"
            name="employmentType"
            id="jobtype-1"
            onChange={() => {
              setEmploymentType(EMPLOYMENT_TYPE_COMPANY);
              onChange("employmentType", EMPLOYMENT_TYPE_COMPANY.toString());
            }}
            checked={employmentType === EMPLOYMENT_TYPE_COMPANY}
          />
          <label className="radio__two-labels" htmlFor="jobtype-1">
            Работаю в компании
          </label>
          <input
            className="visually-hidden"
            type="radio"
            name="employmentType"
            id="jobtype-2"
            onChange={() => {
              setEmploymentType(EMPLOYMENT_TYPE_SELF);
              onChange("employmentType", EMPLOYMENT_TYPE_SELF.toString());
            }}
            checked={employmentType === EMPLOYMENT_TYPE_SELF}
          />
          <label className="radio__two-labels" htmlFor="jobtype-2">
            Работаю на себя
          </label>
        </div>
      </div>

      <div className="form__group">
        {employmentType === EMPLOYMENT_TYPE_COMPANY && (
          <>
            <InputAutocomplete
              name="company"
              type="company"
              placeholder="Название компании"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange("nameCompany", e.target.value)
              }
              onSelect={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange("nameCompany", e.target.title)
              }
              value={data.nameCompany}
              required={true}
            />
            <InputCompanyTypeForRegistration
              name="companytypes"
              value={data.typeCompany}
              onChange={onChange}
              required={true}
            />
          </>
        )}

        <label className="visually-hidden" htmlFor="position">
          Ваша должность
        </label>
        <Input
          type="text"
          name="position"
          id="position"
          value={data.position}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange("position", e.target.value)
          }
          placeholder="Ваша должность"
          required={true}
        />
      </div>
    </div>
  );
};

export default CompanyForm;
