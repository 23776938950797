import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import Router from "@helpers/router";
import ErrorBoundary from "@containers/ErrorBoundary";
import Page from "./Page";
import PageSuccess from "./PageSuccess";
import moment from "moment";
import { useSelector } from "react-redux";
import { TariffArchive } from "@pages/dashboard/components/Price/TariffArchive";

const RouterComponent: React.FC = () => {
  useEffect(() => {
    Middleware.Networking.Days.get();
  }, []);
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );
  const now = moment().tz("Europe/Moscow").format();
  const isArchive = now > moment(endWorkshop).tz("Europe/Moscow").format();

  return (
    <ErrorBoundary>
      <Switch>
        {isArchive ? (
          <Router
            path="/tariffs-and-payment"
            exact={true}
            component={TariffArchive}
          />
        ) : (
          <Router path="/tariffs-and-payment" exact={true} component={Page} />
        )}

        <Router
          path="/tariffs-and-payment/success"
          exact={true}
          component={PageSuccess}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default RouterComponent;
