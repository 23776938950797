import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class City extends ModelPrototype {
  public async suggest(country_id: number, data: { text: string }): Promise<any> {
    try {
      return this.withUrl(`location/country/${country_id}/city/search`)
        .setMethod(Method.GET)
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async get(city_id: number): Promise<any> {
    try {
      return this.withUrl(`location/city/${city_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
