import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";
import * as Components from "./components";
import * as Middleware from "@middlewares/index";
import { Helmet } from "react-helmet";
import { PreloaderPage } from "@components/preloader";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  companyType: string;
}

class StandPage extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      companyType: "silver",
    };
  }

  public componentDidMount() {
    Middleware.Company.Direction.load();
    Middleware.Stand.Selected.load(Number(this.props.match.params.stand_id));
  }

  public componentWillUnmount() {
    Middleware.Stand.Selected.clear();
  }

  public render() {
    const standSelectedData = this.props.Store.Stand.Selected?.data;
    const workshopData = this.props.Store.Main.Workshop?.data;
    if (
      !standSelectedData ||
      (typeof standSelectedData === "object" &&
        Object.keys(standSelectedData).length === 0)
    ) {
      return <PreloaderPage />;
    }

    return (
      <>
        <Helmet
          title={`${
            standSelectedData?.title ? standSelectedData?.title : "Стенд"
          } | ${workshopData?.title}`}
          meta={[
            {
              name: "description",
              content: `Стенд компании «${standSelectedData?.title}» на онлайн-выставке`,
            },
          ]}
        />

        <div
          className={`stand ${
            standSelectedData?.type === 2 || standSelectedData?.type === 3
              ? "stand--general"
              : ""
          } ${
            standSelectedData?.mainImage &&
            (standSelectedData?.type === 2 || standSelectedData?.type === 3)
              ? ""
              : "stand--no-desktop"
          } ${
            standSelectedData?.additionalImage &&
            (standSelectedData?.type === 2 || standSelectedData?.type === 3)
              ? ""
              : "stand--no-mobile"
          }`}
        >
          {standSelectedData?.type === 2 || standSelectedData?.type === 3 ? (
            <Components.Header />
          ) : (
            ""
          )}

          <div className="stand__grid">
            <div className="stand__header">
              <Components.Title />
              <Components.Menu />
            </div>

            <div className="stand__body">
              <div className="stand__left-col">
                {standSelectedData?.blocks?.map((item: any, index: number) => {
                  switch (item.type) {
                    case "presentations":
                      return (
                        <Components.Schedule
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "pastPresentations":
                      return (
                        <Components.Schedule
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "about":
                      return (
                        <Components.About
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "partners":
                      return (
                        <Components.Partners
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "catalogs":
                      return (
                        <Components.Cats
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "photo":
                      return (
                        <Components.Photo
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "video":
                      return (
                        <Components.Video
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "partnersSites":
                      return (
                        <Components.PartnersSites
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    case "guide":
                      return (
                        <Components.Guide
                          key={index}
                          title={item.title}
                          data={item.data}
                        />
                      );
                    default:
                      break;
                  }
                })}
                {/* <Components.Partners
                  key={1}
                  title="Компании-партнеры в нетворкинге"
                  data={[
                    {
                      id: 1,
                      logo: null,
                      title: "Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                    {
                      id: 2,
                      logo: null,
                      title: "Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                    {
                      id: 3,
                      logo: null,
                      title:
                        "Tokisaki Kurumi Tokisaki Kurumi Tokisaki Kurumi Tokisaki Kurumi Tokisaki Kurumi Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                    {
                      id: 4,
                      logo: null,
                      title: "Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                    {
                      id: 5,
                      logo: null,
                      title: "Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                    {
                      id: 6,
                      logo: null,
                      title: "Tokisaki Kurumi",
                      users: [{ image: null, fullName: "1" }],
                    },
                  ]}
                /> */}
                {/* <Components.PartnersSites
                  key={124}
                  title="Сайты компаний-партнеров"
                  data={[
                    {
                      image:
                        "https://cdn.profi.travel/storage/profi-travel/uploads/CompanyStandPartner/61237c1b8f12f820111731.png",
                      title: "Туристическая компания «АРГО»",
                      url: "https://tourargo.ru",
                    },
                    {
                      image:
                        "https://cdn.profi.travel/storage/profi-travel/uploads/CompanyStandPartner/61237c1b8f12f820111731.png",
                      title: "Туристическая компания «АРГО»",
                      url: "https://tourargo.ru",
                    },
                  ]}
                /> */}

                {/* <Components.Schedule
                  key={123}
                  title={"Презентации"}
                  data={[
                    {
                      date: "10.10.2021 10:00:00",
                      path: "/asdad/asdads",
                      title: "Hello world",
                      viewsCount: 10,
                      participantsCount: 123,
                    },
                  ]}
                /> */}

                {/* <Components.Questions>
                  <Components.Answer />
                  <Components.Answer companyAdmin={true} />
                </Components.Questions> */}
                {/* <Components.Request /> */}

                <Components.Contacts />
              </div>
              <div className="stand__right-col">
                {standSelectedData?.blocks?.map((item: any, index: number) => {
                  if (item.type === "users") {
                    return (
                      <Components.Members
                        key={index}
                        title={item.title}
                        data={item.data}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(StandPage)
);
