import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Event extends ModelPrototype {
  public async get(event_id: number): Promise<any> {
    try {
      return this.withUrl(`user/event/${event_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async count(): Promise<any> {
    try {
      return this.withUrl(`event/count`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
