import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  onSubmit: any;
  description?: string;
}

interface StateInterface {
  description: string;
}

class Step3 extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      description: this.props.description || "",
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  private onSubmitHandler(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    this.props.onSubmit({
      step: 4,
      description: this.state.description,
    });
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ [event.target.name]: event.target.value });
  }

  public render() {
    return (
      <>
        <form className="event-create-step-3" onSubmit={this.onSubmitHandler}>
          <div className="event-create-step-3__content">
            <div className="event-create-step-3__title">
              Шаг 3 <span>/ 3</span>
            </div>
            <div className="event-create-step-3__desc">
              Обязательно напишите, что бы вы хотели обсудить
            </div>
          </div>
          <div className="event-create-step-3__body">
            <div className="event-create-step-3__textarea">
              <div className="textarea">
                <textarea
                  name="description"
                  id="msg"
                  className="textarea__input"
                  placeholder="Здравствуйте,"
                  onChange={this.onChangeHandler}
                  required={true}
                  value={this.state.description}
                  maxLength={300}
                />
                <div className="textarea__counter">
                  {300 - this.state.description.length} знаков
                </div>
              </div>
            </div>
            <div className="event-create-step-3__buttons">
              {/* <div className="event-create-step-3__file-btn">
                <input type="file" name="file" id="file-1" />
                Отправить файл <img src={require("@public/images/btn-file.svg")} alt="" />
              </div> */}
              {/* <div className="event-create-step-3__cutaway-btn">
                <Link
                  to="#"
                  onClick={() =>
                    Middleware.User.Cutaway.invite(Number(this.props.match.params.user_id))
                  }
                >
                  Отправить визитку <img src={require("@public/images/btn-file.svg")} alt="" />
                </Link>
              </div> */}
              <button
                type="submit"
                className={`btn btn--red  ${this.state.description ? "" : "disabled"}`}
              >
                Отправить
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Step3)
);
