import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Specialization {
  public static async load() {
    useDispatch(Action.Profile.setStatus("loading"));
    try {
      const response = await new Model.Company().specializations();
      useDispatch(Action.Company.Specialization.setList(response.data));
      useDispatch(Action.Profile.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }
}
