import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Link } from "react-router-dom";
import Router, { Validator } from "@helpers/router";
import { ComponentInterface } from "@interface/index";
import CompanyBar from "./components/CompanyBar";
import * as Middleware from "@middlewares/index";

import * as Pages from ".";
import FreeCompany from "../_show_free/page";

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <>
        <div className="company-cabinet">
          <div className="company-cabinet__side">
            <CompanyBar />
          </div>
          <div className="company-cabinet__main">
            <Switch>
              <Router path="/company/cabinet" exact={true} component={FreeCompany} />
              <Router
                path="/company/cabinet/edit"
                exact={true}
                validator={Validator.Authorized}
                component={Pages.Edit}
              />
              <Router path="/company/cabinet/employees" component={Pages.Employees} />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
