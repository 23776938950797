import React from "react";

interface AchievementsProps {
  achievements: string[];
}

const AchievementsItem = ({ achievements }: AchievementsProps): JSX.Element => {
  return (
    <>
      {achievements.map((item, index) => (
        <div
          key={index}
          className="w-fit text-sm leading-[18.2px] border border-solid border-red-500 rounded-full py-2.5 px-6 lg:text-24"
        >
          <p className="leading-[18.2px] lg:leading-[30px]" key={index}>
            {item}
          </p>
        </div>
      ))}
    </>
  );
};

export default AchievementsItem;
