import React from "react";

export default class InputModificatorPhoneWithoutPrefix {
  public static onChange(event: React.ChangeEvent<HTMLInputElement>) {
    return InputModificatorPhoneWithoutPrefix.mask(event);
  }

  public static onInit(ref: HTMLInputElement) {
    if (ref.required !== true) {
      ref.pattern = `.{15}`;
    } else {
      ref.pattern = `.{0}|.{15}`;
    }
  }

  private static mask(event: React.ChangeEvent<HTMLInputElement>) {
    const matrix = "(___) ___ __-__";
    const def = matrix.replace(/\D/g, "");
    let val = event.target.value.replace(/\D/g, "");

    if (def.length >= val.length) {
      val = def;
    }
    let i = 0;
    event.target.value = matrix.replace(/./g, (value) => {
      return /[_\d]/.test(value) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : value;
    });
  }
}
