import React, { useRef, useState } from "react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import AchievementsItem from "./AchievementsItem";
import { Swiper as SwiperInstance } from "swiper";

const localSlides = [
  {
    id: 1,
    name: "Елена Белоусова",
    text: "Какую стратегию продвижения в соцсетях выбрать турагентству в 2025 году",
    textAbout: (
      <>
        Travel-маркетолог, специалист по продвижению, автор проекта{" "}
        <a
          className="red-link"
          target="_blanc"
          href="https://travel-marketing.ru/"
        >
          Travel Marketing
        </a>
      </>
    ),
    achievements: ["Digital-маркетинг"],
    image: "speaker-1.png",
    imageWebp: "speaker-1.webp",
    imageCircle: "speaker-1-circle.png",
    imageCircleWebp: "speaker-1-circle.webp",
    sort: 0,
  },
  {
    id: 2,
    name: "Дмитрий Потапов",
    text: "Как с помощью рекламы, чат-ботов и рассылок создать стабильный поток заявок от туристов из ВК",
    textAbout: "Сооснователь проекта TurMarketing.ru",
    achievements: ["ВКонтакте"],
    image: "potapov.png",
    imageWebp: "potapov.webp",
    imageCircle: "potapov-circle.png",
    imageCircleWebp: "potapov-circle.webp",
    sort: 1,
  },
  {
    id: 3,
    name: "Диана Фердман",
    text: "10+ неочевидных методов продвижения для турагентства",
    textAbout:
      "Автор сообщества «Страна туризма», генеральный директор агентства путешествий «Бельмаре»",
    achievements: ["UGC-контент"],
    image: "ferdman.png",
    imageWebp: "ferdman.webp",
    imageCircle: "ferdman-circle.png",
    imageCircleWebp: "ferdman-circle.webp",
    sort: 2,
  },
  {
    id: 4,
    name: "Алла Малафеева",
    text: "Понятным языком обо всех тонкостях ведения канала в Telegram, а также правилах маркировки в нем рекламы",
    textAbout:
      "Основатель нишевого агентства по интернет-продвижению в туризме ADSTravel",
    achievements: ["Telegram"],
    image: "malafeeva.png",
    imageWebp: "malafeeva.webp",
    imageCircle: "malafeeva-circle.png",
    imageCircleWebp: "malafeeva-circle.webp",
    sort: 3,
  },
  {
    id: 5,
    name: "Дарья Лосева",
    text: "Прибавит звук «сарафанному радио» — научит повышать лояльность туристов, чтобы они возвращались сами и приводили друзей",
    textAbout:
      "Директор по развитию холдинга «Русский экспресс», CEO тревел-школы «Sequoia»",
    achievements: ["Сарафанное радио"],
    image: "loseva.png",
    imageWebp: "loseva.webp",
    imageCircle: "loseva-circle.png",
    imageCircleWebp: "loseva-circle.webp",
    sort: 4,
  },
  {
    id: 6,
    name: "Наталья Недбай",
    text: "Сделает лучший reels для лучших продаж и проведет работу над ошибками турагентов, не продающих через соцсети",
    textAbout:
      "Собственник турагентств Pegas Touristik и Anex Tour (Красноярск)",
    achievements: ["Reels"],
    image: "nedbay.png",
    imageWebp: "nedbay.webp",
    imageCircle: "nedbay-circle.png",
    imageCircleWebp: "nedbay-circle.webp",
    sort: 5,
  },
  {
    id: 7,
    name: "Артур Мурадян",
    text: "Выездной туризм в 2025 году: тенденции и ключевые тренды сезона",
    textAbout: (
      <>
        <b>Space</b> Travel, генеральный директор
      </>
    ),
    achievements: ["Тренды"],
    image: "Muradyan.jpg",
    imageWebp: "Muradyan.webp",
    imageCircle: "Muradyan-circle.jpg",
    imageCircleWebp: "Muradyan-circle.webp",
    sort: 6,
  },
  {
    id: 8,
    name: "Наташа Веббер",
    text: "Лучшие пляжи и отели Маврикия, особенности размещения, клады и самые красивые закаты острова",
    textAbout:
      "Marriott, директор по продажам гостиничной группы Marriott на Маврикии",
    achievements: ["Маврикий"],
    image: "vebber.png",
    imageWebp: "vebber.webp",
    imageCircle: "vebber-circle.png",
    imageCircleWebp: "vebber-circle.webp",
    sort: 7,
  },
  {
    id: 9,
    name: "Алла Филимонова",
    text: "Север Египта — от Каира до Александрии. ОАЭ — от пустыни до небоскребов",
    textAbout:
      "Carthage Group, генеральный представитель в России, СНГ и Европе",
    achievements: ["ОАЭ", "Египет"],
    image: "filimonova.png",
    imageWebp: "filimonova.webp",
    imageCircle: "filimonova-circle.png",
    imageCircleWebp: "filimonova-circle.webp",
    sort: 8,
  },
  {
    id: 10,
    name: "Светлана Давыдкина",
    text: "Возможности для активного отдыха и особенности продаж одного из лучших семейных отелей 4* на Мальдивах",
    textAbout:
      "Holiday Inn Kandooma от компании Coati Representative, представитель отеля",
    achievements: ["Мальдивы"],
    image: "davidkina.png",
    imageWebp: "davidkina.webp",
    imageCircle: "davidkina-circle.jpg",
    imageCircleWebp: "davidkina-circle.webp",
    sort: 7,
  },
];

const Speakers: React.FC = () => {
  const ref = useRef<SwiperInstance | null>(null);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

  const goToSlide = (index: number) => {
    if (ref.current) {
      ref.current.slideTo(index);
    }
  };

  return (
    <section className="bg-grey-f5 xl:pt-[100px] 2xl:pt-[120px] 3xl:pt-[140px]">
      <div className="cont xl:flex xl:gap-[52px] 2xl:gap-[60px] 4xl:gap-[85px]">
        <div className="flex xl:min-w-[333px] 2xl:min-w-[377px] 3xl:min-w-[432px] xl:flex-col justify-between pt-14 pb-10 md:pb-[54px] md:pt-16 lg:pb-16 xl:pt-0 xl:pb-[100px] lg:pt-[100px] 2xl:pb-[120px] 3xl:pb-[140px]">
          <p className="title-middle w-fit">Найдем туристов и подход к ним</p>
          <div className="hidden lg:flex gap-0.5 w-fit max-w-[472px] flex-wrap xl:max-w-auto">
            {localSlides.map((item, index) => (
              <picture key={index}>
                <source
                  srcSet={require(`@public/images/slider/${item.imageCircleWebp}`)}
                  type="image/webp"
                />
                <img
                  className={`h-[65px] w-[65px] rounded-full object-cover cursor-pointer border-solid ${
                    activeButtonIndex === index + 1 &&
                    "outline-2 outline outline-red-500"
                  }`}
                  src={require(`@public/images/slider/${item.imageCircle}`)}
                  alt="Спикер"
                  onClick={() => goToSlide(index + 1)}
                />
              </picture>
            ))}
          </div>
        </div>
        <Swiper
          onSlideChange={(swiper) => {
            setActiveButtonIndex(swiper.activeIndex);
          }}
          breakpoints={{
            1024: {
              simulateTouch: false,
              allowTouchMove: false,
              keyboard: false,
              mousewheel: false,
            },
          }}
          spaceBetween={50}
          slidesPerView={1}
          fadeEffect={{ crossFade: true }}
          effect={"fade"}
          pagination={{
            clickable: true,
            type: "bullets",
          }}
          loop={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          className="speakers-slider"
          onSwiper={(swiper) => {
            ref.current = swiper;
          }}
          observer={true}
          observeParents={true}
        >
          {localSlides?.map((slide) => {
            return (
              <SwiperSlide className="swiper-container" key={slide.id}>
                <div className="bg-white rounded-xl flex flex-col md:flex-row md:gap-10">
                  <picture className="speakers-slider__photo rounded-xl h-auto object-cover md:w-[334px] lg:w-[456px] xl:w-[361px] 2xl:w-[405px] 3xl:w-[458px] 4xl:w-[485px]">
                    <source
                      srcSet={require(`@public/images/slider/${slide?.imageWebp}`)}
                      type="image/webp"
                    />
                    <img
                      className="speakers-slider__photo rounded-xl h-auto object-cover md:w-[334px] lg:w-[456px] xl:w-[361px] 2xl:w-[405px] 3xl:w-[458px] 4xl:w-[485px]"
                      src={require(`@public/images/slider/${slide?.image}`)}
                      alt="Спикер"
                    />
                  </picture>
                  <div className="flex-1 px-[10px] md:px-0 h-auto min-h-[281px] rounded-b-xl md:px-0 xl:pr-8 2xl:pr-[40px] justify-start md:justify-between flex flex-col py-8 2xl:py-10 gap-5 md:gap-0">
                    <div className="flex flex-col gap-5 md:gap-8">
                      <p className="text-2xl leading-[30px] font-bold lg:text-36">
                        {slide.name}
                      </p>

                      {slide.textAbout && (
                        <p className="leading-[20.8px] lg:text-24">
                          {slide.textAbout}
                        </p>
                      )}
                    </div>

                    <div className="md:justify-between flex gap-5 md:gap-8 flex-col">
                      {slide?.achievements && (
                        <div className="flex gap-2 flex-col lg:gap-3">
                          <AchievementsItem
                            achievements={slide?.achievements}
                          />
                        </div>
                      )}

                      <div
                        className="leading-[20.8px] lg:text-24"
                        dangerouslySetInnerHTML={{ __html: slide.text }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Speakers;
