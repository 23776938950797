import React from "react";
import { Link } from "react-router-dom";

import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";
import PreloaderComponent from "@components/preloader/PreloaderComponent";

import Style from "../Cutaways.module.scss";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

export default Connector(
  class CutawayListPage extends React.Component<
    PropsInterface,
    StateInterface
  > {
    public render() {
      const userCutaway = this.props.Store.User.Cutaway;

      return (
        <div className={Style.cutaways}>
          {userCutaway.status !== "loaded" ? (
            <div className={Style.message}>
              <PreloaderComponent />
            </div>
          ) : userCutaway?.outgoings?.length ? (
            <div className={`${Style.message} ${Style.messageWarning}`}>
              <div className={Style.messageWrapper}>
                <div className={Style.messageCol}>
                  <div className="text-red">Внимание!</div>
                </div>
                <div className={Style.messageCol}>
                  <div className={Style.messageText}>
                    Вы получите визитку собеседника как только он&nbsp;примет
                    запрос на&nbsp;обмен контактами. До&nbsp;этого ваши контакты
                    собеседнику не&nbsp;видны.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={Style.message}>
              <div className={Style.messageCol}>
                <div className={Style.messageTitle}>Пока тут ничего нет</div>
                <div className={Style.messageText}>
                  Отправьте вашу визитку интересным вам людям.{" "}
                  <Link to="/networking" className={Style.messageLink}>
                    Перейти в Нетворкинг
                  </Link>
                </div>
              </div>
            </div>
          )}
          {userCutaway?.outgoings?.map((Cutaway: any) => {
            return (
              <div className={Style.cutaway_outbox} key={Cutaway.id}>
                <div className={Style.persona}>
                  <figure className={Style.personaPhoto}>
                    {Cutaway.data?.userImage ? (
                      <img src={Cutaway.data?.userImage || ""} alt="" />
                    ) : (
                      <div className={Style.personaBgAvatar} />
                    )}
                  </figure>
                  <div className={Style.personaName}>
                    <div>{Cutaway.data?.fullName}</div>
                  </div>
                  <div className={Style.personaPosition}>
                    {Cutaway.data?.position}
                    {Cutaway.data?.companyTitle ? (
                      <>, {Cutaway.data?.companyTitle}</>
                    ) : (
                      <>, Фрилансер</>
                    )}
                  </div>
                </div>
                <div className={Style.company}>
                  {Cutaway.data?.companyTitle && (
                    <div className={Style.companyImg}>
                      <img
                        src={
                          Cutaway.data?.companyImage ||
                          require("@public/images/defaultAvatarCompany.svg")
                        }
                        alt=""
                      />
                    </div>
                  )}
                </div>
                <div className={Style.buttons}>
                  <Link
                    to="#"
                    className={`${Style.btn} btn btn--transparent-c0`}
                  >
                    {Cutaway.status === 0
                      ? "Отправлено"
                      : Cutaway.status === 1
                      ? "Получена"
                      : "Отклонено"}
                  </Link>
                  <Link
                    to="#"
                    className={`${Style.btn} btn btn--dark-grey`}
                    onClick={async () => {
                      try {
                        await Middleware.User.Cutaway.delete(Cutaway.id);
                        await Middleware.User.Cutaway.outgoing();
                      } catch (Exception) {
                        throw Exception;
                      }
                    }}
                  >
                    Отозвать
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
);
