import React from "react";

interface ScheduleFilterProps {
  weeks: Record<number, any>;
  activeWeek: number;
  currentWeek: number;
  setActiveWeek: React.Dispatch<React.SetStateAction<number>>;
  setCurrentWeek: React.Dispatch<React.SetStateAction<number>>;
  isTableView: boolean;
}

const ScheduleFilter = ({
  activeWeek,
  currentWeek,
  setActiveWeek,
  setCurrentWeek,
  weeks,
  isTableView,
}: ScheduleFilterProps): JSX.Element => {
  const weekForCompare = () => {
    if (isTableView && activeWeek === 0) {
      return currentWeek;
    } else {
      return activeWeek;
    }
  };

  const setStateForChangeWeeks = (week: number) => {
    if (isTableView && activeWeek === 0) {
      return setCurrentWeek(week);
    } else {
      return setActiveWeek(week);
    }
  };

  return (
    <div className="schedule__table-filter">
      <div className="schedule__table-filter-title">Порядок:</div>
      <div className="schedule__table-filter-list">
        {!isTableView && (
          <div
            className={`schedule__table-filter-item ${
              Number(0) === weekForCompare()
                ? "schedule__table-filter-item--active"
                : ""
            }`}
            onClick={() => {
              setStateForChangeWeeks(Number(0));
            }}
          >
            Все дни
          </div>
        )}
        {Object.keys(weeks).map((key: any, index: number) => {
          return (
            <div
              key={index}
              className={`schedule__table-filter-item ${
                Number(key) === weekForCompare()
                  ? "schedule__table-filter-item--active"
                  : ""
              }`}
              onClick={() => {
                setStateForChangeWeeks(Number(key));
              }}
            >
              {index + 1}-я неделя
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleFilter;
