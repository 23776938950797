import React, { useState } from "react";
import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";

import * as Middleware from "@middlewares/index";
import { ITariff } from "@pages/dashboard/components/Price";
import { PreloaderPage } from "@components/preloader";

// TODO: Вынести интерфейс
// TODO: На промо-код нет логики
// TODO: На смену суммы при применении промо-кода тоже нет логики. По дизайну отрисовано, что должно быть просто 0р, если промокод был применен

interface Price {
  rub: number;
  loyalty: number;
}

interface IProps {
  currentPayment: string;
  selectedForPayTariff: number;
  promoDate: string;
  tariffs: ITariff[];
  currentTariff: ITariff | null;
  isPromoPurchasePrice: boolean;
  utmTags: string;
  isArchive?: boolean;
}

const Step2: React.FC<IProps> = ({
  currentPayment,
  currentTariff,
  promoDate,
  tariffs,
  selectedForPayTariff,
  isPromoPurchasePrice,
  utmTags,
  isArchive = false,
}) => {
  const history = useHistory();
  const tariff = tariffs.find(
    (tariff) => tariff.id === selectedForPayTariff
  ) as ITariff;
  const now = moment().tz("Europe/Moscow").format();
  const [payment, setPayment] = useState<string>(currentPayment);
  const [isEnoughPoints, setIsEnoughPoints] = useState<boolean>(true);
  const currentTariffPriceRub = isPromoPurchasePrice
    ? currentTariff?.price?.rub
    : currentTariff?.newPrice?.rub;
  const currentTariffPriceLoyalty = isPromoPurchasePrice
    ? currentTariff?.price?.loyalty
    : currentTariff?.newPrice?.loyalty;

  const showTotalPrice = (price: Price) => {
    let totalPrice: string;

    if (payment === "rub") {
      totalPrice = String(price.rub - (currentTariffPriceRub ?? 0)) + " ₽";
    } else {
      totalPrice =
        String(price.loyalty - (currentTariffPriceLoyalty ?? 0)) + " Loyalty";
    }

    return totalPrice;
  };

  if (!selectedForPayTariff) {
    return <PreloaderPage />;
  }

  return (
    <div className="payment__wrapper payment__wrapper--method">
      <h1 className="payment__title">
        {currentTariff && !isArchive
          ? `Сменить тариф на «${tariff.name}»`
          : `Оплатить тариф «${tariff.name}»`}
      </h1>
      <form action="" className="payment__form">
        <div className="payment__radio-group">
          <div className="payment__radio-description">
            Выберите способ оплаты
          </div>
          <div className="payment__radio payment__radio--method">
            <input
              className="visually-hidden"
              id="rub"
              name="payment"
              type="radio"
              onChange={() => setPayment("rub")}
              checked={payment === "rub"}
            />
            <label className="payment__radio-label" htmlFor="rub">
              Оплата рублями
            </label>
          </div>
          {currentTariff === null && !isArchive && (
            <div className="payment__radio payment__radio--method">
              <input
                className="visually-hidden"
                id="loyalty"
                name="payment"
                type="radio"
                onChange={() => setPayment("loyalty")}
                checked={payment === "loyalty"}
              />
              <label className="payment__radio-label" htmlFor="loyalty">
                Оплата баллами Loyalty
              </label>
            </div>
          )}
        </div>
        {/* Промо-код */}
        {/*<div className="payment__promo">
          <input
            className="payment__input"
            placeholder="Введите промокод"
            type="text"
          />
          <button
            className="btn btn--medium btn--red payment__promo-btn"
            onClick={() => {}}
          >
            Применить
          </button>
          <div className="payment__error">Какая-то ошибка</div>
        </div>*/}
        {/* Промо-код */}
        <div className="payment__form-divider" />
      </form>
      <div className="payment__total">
        <div className="payment__total-title">Итого к оплате:</div>
        <div className="payment__total-value">
          {now < promoDate
            ? showTotalPrice(tariff.price)
            : showTotalPrice(tariff.newPrice)}
        </div>
        {/* Текст от промокода скрыт, пока промокоды не реализованы */}
        {/* <div className="payment__total-promo">
          действует 100% скидка с промокодом
        </div> */}
      </div>
      <div className="payment__bottom">
        {payment === "rub" ? (
          <button
            className="btn btn--medium btn--red"
            onClick={() => {
              localStorage.removeItem("currentTariff");
              Middleware.Payment.payMoney(selectedForPayTariff, utmTags).then(
                (data: any) => {
                  window.location.href = data.paymentLink;
                }
              );
            }}
          >
            Оплатить
          </button>
        ) : (
          <button
            className="btn btn--medium btn--red"
            onClick={async () => {
              try {
                await Middleware.Payment.payLoyalty(
                  selectedForPayTariff,
                  utmTags
                );
                Middleware.UserRating.load();
                history.push("/tariffs-and-payment/success");

                let ProgressAlert: any;
                Middleware.Ui.Alert.open("Process", {
                  text: "Ваш тариф изменён",
                  component: (c: any) => {
                    ProgressAlert = c;
                  },
                });
                ProgressAlert.setStatus("success");
                ProgressAlert.startTimer();

                localStorage.setItem(
                  "currentTariff",
                  String(selectedForPayTariff)
                );
              } catch (Exception) {
                console.log(Exception);
                setIsEnoughPoints(false);
              }
            }}
          >
            Оплатить
          </button>
        )}
        {!isEnoughPoints && (
          <div className="payment__error">
            У Вас недостаточно баллов Loyalty.{" "}
            <Link
              to="#"
              className="btn btn--red-underline payment__error-link"
              onClick={(event) => {
                event.preventDefault();
                Middleware.Ui.Modal.open("LoyaltyPointsInTariffs");
              }}
            >
              Как их получить?
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
