import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface IProps {
  currentStep: number;
  employmentType: number;
  isContactsValid: boolean;
  maxStep: number;
  setCurrentStep: (value: number) => void;
  networkingProfile: {
    companyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  required?: boolean;
  updateNetworkingProfile: any;
}

const RegStep5 = ({
  currentStep,
  employmentType,
  isContactsValid,
  maxStep,
  setCurrentStep,
  networkingProfile,
  required,
  updateNetworkingProfile,
}: IProps): ReactElement => {
  const history = useHistory();

  const onChangeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    networkingProfile.about = event.target.value;
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (networkingProfile?.about?.length > 0) {
      updateNetworkingProfile(networkingProfile);
    }

    if (employmentType === 2) {
      if (!isContactsValid) {
        let ProgressAlert: any;
        await Middleware.Ui.Alert.open("Process", {
          text: "Обновите ваши контактные данные для продолжения регистрации",
          component: (c: any) => {
            ProgressAlert = c;
          },
        });
        ProgressAlert.setStatus("danger");
        ProgressAlert.startTimer();
      } else {
        history.push("/service/networking/enabled");
      }
    } else if (!required) {
      setCurrentStep(currentStep + 1);
    } else {
      let ProgressAlert: any;
      await Middleware.Ui.Alert.open("Process", {
        text: "Заполните информацию о себе",
        component: (c: any) => {
          ProgressAlert = c;
        },
      });
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  };

  const renderCharacterCount = () => {
    return networkingProfile?.about?.length === undefined
      ? 0
      : networkingProfile?.about?.length;
  };

  const renderButton = () => {
    if (employmentType === 2) {
      return (
        <button
          type="submit"
          className={`${isContactsValid ? "btn--red" : "btn--grey"} btn `}
        >
          Готово!
        </button>
      );
    }
    return (
      <>
        <button type="submit" className="btn btn--red">
          Дальше
        </button>
        {required ? (
          <></>
        ) : (
          <button
            className="btn btn--transparent"
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            Пропустить
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <section className="cabinet__block">
        <form onSubmit={onSubmitHandler} className="profile-netw-step">
          <div className="profile-netw-step__header">
            <span> Шаг {currentStep}</span> / {maxStep}
          </div>
          <div className="profile-netw-step__body">
            <div className="profile-netw-step__body-header">
              <p className="profile-netw-step__title profile-netw-step__title--big">
                {required ? <span>* </span> : <></>}Расскажите о себе
              </p>
              <p className="profile-netw-step__required">
                {required
                  ? "Обязательный пункт"
                  : "Вы можете заполнить этот пункт потом"}
              </p>
            </div>

            <div className="profile-netw-step__main">
              <div className="textarea">
                <textarea
                  className="textarea__input"
                  name="about"
                  rows={5}
                  onChange={onChangeHandler}
                  maxLength={500}
                >
                  {networkingProfile?.about}
                </textarea>
                <div className="textarea__counter">
                  {500 - renderCharacterCount()} знаков
                </div>
              </div>
            </div>
          </div>

          <div className="profile-netw-step__footer">{renderButton()}</div>
        </form>
      </section>
    </>
  );
};

export default RegStep5;
