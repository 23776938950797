import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";

import Style from "./Cats.module.scss";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {}

class Cats extends React.Component<PropsInterface, StateInterface> {
  constructor(Props: PropsInterface) {
    super(Props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
    };
  }
  public render() {
    return (
      <>
        {this.props.data.length > 0 ? (
          <>
            <div className={Style.title}>{this.props.title}</div>
            <div className={Style.grid}>
              {this.props.data.map((item: any, index: number) => {
                return (
                  <div key={index} className={["stand__block", Style.block].join(" ")}>
                    <div className="stand-cats__item-img">
                      <img src={require("@public/images/catalogue-stand-title.svg")} alt="" />
                    </div>
                    <div className={Style.filename}>{item.title}</div>
                    <a
                      className={Style.downloadBtn}
                      href={`https://profi.travel${item.path}`}
                      target="_blank"
                    >
                      Скачать.pdf
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Cats)
);
