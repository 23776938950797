import React, { ReactElement, useEffect, useState } from "react";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { useSelector } from "react-redux";
import Select2 from "@components/form/Select2";

interface IProps {
  callback: (filters: any) => void;
}

// Middleware.Stand.Direction.load();
// Middleware.Stand.Type.load();
// Middleware.Stand.Specialization.load();

const StandsFilter = ({ callback }: IProps): ReactElement => {
  const directions = useSelector((state: any) => state.Stand.Direction.list);
  const type = useSelector((state: any) => state.Stand.Type.list);
  const specialization = useSelector(
    (state: any) => state.Stand.Specialization.list
  );
  const standCounter = useSelector((state: any) => state.Stand.Common.count);

  const [selectedDirection, setSelectedDirection] = useState<
    undefined | number
  >();
  const [selectedType, setSelectedType] = useState<undefined | number>();
  const [selectedSpecialization, setSelectedSpecialization] = useState<
    undefined | number
  >();

  useEffect(() => {
    callback({
      direction: selectedDirection,
      types: selectedType,
      specializations: selectedSpecialization,
    });
  }, [selectedDirection, selectedType, selectedSpecialization]);

  useEffect(() => {
    Middleware.Stand.Common.count();
    Middleware.Stand.Direction.load();
    Middleware.Stand.Type.load();
    Middleware.Stand.Specialization.load();
  }, []);

  const OnDirectionChangeHandler = (event: any) => {
    setSelectedDirection(event.target.value);
  };

  const OnTypeChangeHandler = (event: any) => {
    setSelectedType(event.target.value);
  };

  const OnSpecializationChangeHandler = (event: any) => {
    setSelectedSpecialization(event.target.value);
  };

  const clearDirection = () => {
    setSelectedDirection(undefined);
  };

  const clearType = () => {
    setSelectedType(undefined);
  };

  const clearSpecialization = () => {
    setSelectedSpecialization(undefined);
  };

  const clear = () => {
    setSelectedDirection(undefined);
    setSelectedType(undefined);
    setSelectedSpecialization(undefined);
  };

  const reload = () => {};

  return (
    <div className="stands__filter">
      <div className="stands__filter-group">
        <div className="stands__filter-select stands__filter-select--direction">
          <Select2
            clear={clearDirection}
            name={"direction"}
            onChange={OnDirectionChangeHandler}
            options={directions?.filter(
              (Direction: any) => Direction.count > 0
            )}
            placeholder={"Выберите направление"}
            value={selectedDirection}
          />
        </div>
        <div className="stands__filter-select stands__filter-select--type">
          <Select2
            clear={clearType}
            name={"type"}
            onChange={OnTypeChangeHandler}
            options={type}
            placeholder={"Тип компании"}
            value={selectedType}
          />
        </div>
        <div className="stands__filter-select stands__filter-select--specialization">
          <Select2
            clear={clearSpecialization}
            name={"specialization"}
            onChange={OnSpecializationChangeHandler}
            options={specialization}
            placeholder={"Специализация"}
            value={selectedSpecialization}
          />
        </div>
      </div>
    </div>
  );
};

export default StandsFilter;
