import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";
import HTMLReactParser, { DOMNode } from "html-react-parser";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  password: string;
}

class SignUpPage extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      password: "",
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  public componentDidMount() {
    document.title = "Signup" + " | " + process.env.SITE_NAME;
  }

  private onSubmitHandler(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    this.props.Dispatch(
      Middleware.Auth.passwordReset({ ...this.state, token: this.props.match.params.hash }, () => {
        this.props.history.push("/static/password-changed");
      })
    );
  }

  private onChangeHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ [event.target.name]: event.target.value });
  }

  public render() {
    return (
      <>
        <div className="page-static">
          <h1>Придумайте новый пароль</h1>
          <div className="text">Почти готово! Придумайте новый пароль</div>
          <form onSubmit={this.onSubmitHandler}>
            <div className="row">
              <input
                type="password"
                name="password"
                id="password"
                value={this.state.password}
                onChange={this.onChangeHandler}
                minLength={8}
                placeholder="Не менее 8 символов"
                required={true}
              />
              <button type="submit" className="btn-red">
                ОК
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(SignUpPage);
