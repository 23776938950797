import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { userCutawayStatus } from "@helpers/UserCutawayStatus";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {}

class Members extends React.Component<PropsInterface, StateInterface> {
  constructor(props: PropsInterface) {
    super(props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
    };
  }

  public render() {
    const standSelectedData = this.props.Store.Stand.Selected?.data;
    const profileData = this.props.Store.Profile?.data;
    const registerEndAt = moment(
      this.props.Store.Main.Workshop.data?.registerEndAt
    )
      .tz("Europe/Moscow")
      .format();
    const now = moment().tz("Europe/Moscow").format();
    const netwDays = this.props.Store.Networking?.Days.data;

    return (
      <>
        {this.props.data.length > 0 ? (
          <div className="stand-members stand__block">
            <div className="stand-members__header">
              <div className="stand-members__title">{this.props.title}</div>
              <div className="stand-members__desc">
                Наши специалисты готовы обсудить с вами детали сотрудничества.
                Выберите собеседника и начните общение:
              </div>
            </div>
            <div className="stand-members__body">
              {this.props.data.map((User: any, index: number) => {
                if (index < 3) {
                  return (
                    <div key={User.id} className="stand-user">
                      <Link
                        to={`/user/${User.id}`}
                        className="stand-user__body"
                      >
                        <div className="stand-user__img">
                          {User.image ? (
                            <img src={User.image} alt={User.fullName} />
                          ) : (
                            <div className="stand-user__img-placeholder" />
                          )}
                        </div>
                        <div className="stand-user__name">{User.fullName}</div>
                        <div className="stand-user__position">
                          {User.position ? User.position : ""}
                          {User.position && standSelectedData?.title
                            ? ", "
                            : ""}
                          {standSelectedData?.title && standSelectedData?.title}
                        </div>
                      </Link>
                      <div className="stand-user__footer">
                        {netwDays?.length ? (
                          <Link
                            className="btn btn--white-red"
                            to={`/user/${User.id}/event/create`}
                            onClick={(event) => {
                              if (now > registerEndAt) {
                                event.preventDefault();
                                Middleware.Ui.Modal.open("RegistrationClosed", {
                                  callback: "",
                                });
                              } else if (
                                !profileData?.useNetworking ||
                                profileData?.networkingFillStatus !== 3
                              ) {
                                event.preventDefault();
                                Middleware.Ui.Modal.open("EnterNetworking");
                              }
                            }}
                          >
                            Назначить встречу
                          </Link>
                        ) : null}

                        <Link
                          className={`btn btn--white-grey-2 ${userCutawayStatus(
                            User
                          )}`}
                          to="#"
                          onClick={async (event) => {
                            event.preventDefault();
                            if (now > registerEndAt) {
                              Middleware.Ui.Modal.open("RegistrationClosed", {
                                callback: "",
                              });
                            } else if (
                              profileData?.useNetworking &&
                              profileData?.networkingFillStatus === 3
                            ) {
                              try {
                                await Middleware.User.Cutaway.invite(User.id);
                                await Middleware.Stand.Selected.setUserCutawayStatus(
                                  1,
                                  User.id
                                );
                              } catch (Exception) {
                                throw Exception;
                              }
                            } else {
                              Middleware.Ui.Modal.open("EnterNetworking");
                            }
                          }}
                        >
                          Обменяться визитками
                        </Link>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {this.props.data.length > 3 ? (
              <div className="stand-members__footer">
                <Link
                  className="stand-members__more-link"
                  to="#"
                  onClick={() =>
                    Middleware.Ui.Modal.open("StandUsers", {
                      data: {
                        companyName: standSelectedData?.title
                          ? standSelectedData?.title
                          : null,
                      },
                    })
                  }
                >
                  Показать ещё {this.props.data.length - 3} экспертов
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Members)
);
