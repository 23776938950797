import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import Signin from "./signin";
import Signup from "./signup";
import Login from "./login";
import Reset from "./password/Reset";
import Restore from "./password/Restore";
import ProfileConfirm from "./confirm/Profile";
import AuthNetworking from "./networking";
import { UnauthorizedRoute } from "@helpers/router/UnauthorizedRoute";

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <>
        <Switch>
          <Route
            path="/auth/login"
            exact={true}
            render={() => (
              <UnauthorizedRoute>
                <Login />
              </UnauthorizedRoute>
            )}
          />
          <Route
            path="/auth/signin"
            exact={true}
            render={() => (
              <UnauthorizedRoute>
                <Signin />
              </UnauthorizedRoute>
            )}
          />
          <Route path="/auth/signup" exact={true} render={() => <Signup />} />
          <Route
            path="/auth/password/restore"
            exact={true}
            render={() => (
              <UnauthorizedRoute>
                <Restore />
              </UnauthorizedRoute>
            )}
          />
          <Route
            path="/auth/password/reset/:hash"
            exact={true}
            render={() => (
              <UnauthorizedRoute>
                <Reset />
              </UnauthorizedRoute>
            )}
          />
          <Router
            path="/auth/confirm/profile"
            exact={true}
            component={ProfileConfirm}
          />
          <Router
            path="/auth/networking"
            exact={true}
            component={AuthNetworking}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
