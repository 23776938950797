import React, { ReactElement, useEffect, useState } from "react";
import StandsItem from "./StandsItem";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StandsPartnersItem from "./StandsPartnersItem";
import debounce from "lodash.debounce";

interface IProps {
  stands: any[];
  type: number;
  isPartner?: boolean;
}

const StandsGroups = ({
  stands,
  type,
  isPartner = false,
}: IProps): ReactElement => {
  const breakpoint = window.matchMedia("(min-width: 768px)");
  const breakpointPartner = window.matchMedia("(min-width: 1024px)");
  const ComponentStandsItem = isPartner ? StandsPartnersItem : StandsItem;

  const [countVisibleItems, setCountVisibleItems] = useState(
    getDisplayCount(window.innerWidth)
  );

  function getDisplayCount(width: number) {
    if (width >= 1280) return 8;
    if (width >= 1024) return 6;
    if (width >= 768) return 4;
    return stands.length;
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      const newWidth = window.innerWidth;
      setCountVisibleItems(getDisplayCount(newWidth));
    }, 200);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowMore = () => {
    setCountVisibleItems(
      (prevCount) => prevCount + getDisplayCount(window.innerWidth)
    );
  };

  useEffect(() => {}, [breakpoint]);
  SwiperCore.use([Pagination]);
  if (
    !isPartner
      ? !breakpoint.matches && type !== 4
      : !breakpointPartner.matches && type !== 4
  ) {
    return (
      <Swiper
        loop={false}
        spaceBetween={20}
        slidesPerView={1}
        autoHeight={true}
        pagination={{
          el: !isPartner ? ".swiper-pagination" : ".swiper-pagination--partner",
          clickable: true,
          type: "bullets",
        }}
      >
        {stands?.map((stand: any, index: number) => {
          if (!isPartner && !breakpoint.matches) {
            if (index % 2 === 0) {
              return (
                <SwiperSlide key={index}>
                  <div className="flex flex-col gap-5">
                    <ComponentStandsItem
                      type={stand.type || type}
                      id={stand.id}
                      bgImage={stand.standImage}
                      logo={stand.companyImage}
                      title={stand.title}
                      description={stand.description}
                      users={stand.users}
                    />

                    {stands[index + 1] && (
                      <ComponentStandsItem
                        type={stands[index + 1].type || type}
                        id={stands[index + 1].id}
                        bgImage={stands[index + 1].standImage}
                        logo={stands[index + 1].companyImage}
                        title={stands[index + 1].title}
                        description={stands[index + 1].description}
                        users={stands[index + 1].users}
                      />
                    )}
                  </div>
                </SwiperSlide>
              );
            }
          } else {
            return (
              <SwiperSlide key={index}>
                <ComponentStandsItem
                  type={stand.type || type}
                  id={stand.id}
                  bgImage={stand.standImage}
                  logo={stand.companyImage}
                  title={stand.title}
                  description={stand.description}
                  users={stand.users}
                />
              </SwiperSlide>
            );
          }
        })}
        <div
          className={
            !isPartner ? "swiper-pagination" : "swiper-pagination--partner"
          }
        ></div>
      </Swiper>
    );
  } else {
    return (
      <>
        <div className="stands__group-list">
          {stands
            ?.slice(0, countVisibleItems)
            .map((stand: any, index: number) => {
              return (
                <ComponentStandsItem
                  key={index}
                  type={stand.type || type}
                  id={stand.id}
                  bgImage={stand.standImage}
                  logo={stand.companyImage}
                  title={stand.title}
                  description={stand.description}
                  users={stand.users}
                />
              );
            })}
        </div>
        {countVisibleItems < stands.length && (
          <button className="stands__show-more-button" onClick={handleShowMore}>
            <span>Показать ещё</span>
            <img
              src={require("@public/images/icons/chevron-down.svg")}
              alt="Иконка"
            />
          </button>
        )}
      </>
    );
  }
};

export default StandsGroups;
