import { PreloaderComponent } from "@components/preloader";
import React from "react";
import { Link } from "react-router-dom";

interface TariffBannerProps {
  text: string;
  rating: number | null;
}

export const TariffBanner = ({
  text,
  rating,
}: TariffBannerProps): JSX.Element => {
  if (rating === null) {
    return <PreloaderComponent />;
  }

  return (
    <div className="flex main-nav__user-nav-item main-nav__user-nav-item--tariff h-[36px]">
      <Link className="whitespace-nowrap" to="/cabinet/rating">
        <span className="text-dark-gray">{text}</span>
        <span className="!rounded-[2px]">{rating}</span>
      </Link>
    </div>
  );
};
