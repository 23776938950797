import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Input, InputAutocomplete } from "@components/form/index";

const CabinetStand: React.FC = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [stand, setStand] = useState({
    about:
      "<p>За неполных 33 года существования компания Space Travel расширила сферу деятельности до более чем 50 направлений.</p>",
    video: "",
    directions: [],
    partners: [],
    guide: "",
    catalogs: null,
  });
  const directions = useSelector((state: any) => state.Company.Direction?.list);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setStand((prevStand) => ({
      ...prevStand,
      [name]: value,
    }));
    console.log("stand", stand);
  };

  return (
    <div className="cabinet-stand">
      <h1 className="cabinet__title">Стенд компании</h1>
      <div className="cabinet-stand__main">
        <div className="cabinet-stand__col">
          <form action="" className="cabinet-stand__form">
            <div className="cabinet-stand__form-header">
              <h2 className="cabinet-stand__form-title">Данные о стенде</h2>
              <Link
                to="#"
                className={`cabinet-stand__form-edit ${isEdit ? "hide" : ""}`}
                onClick={() => setIsEdit(!isEdit)}
              >
                Изменить
              </Link>
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Основная информация
              </div>
              <div
                className="cabinet-stand__form-about"
                dangerouslySetInnerHTML={{ __html: stand.about }}
              />
              <div className="textarea">
                <textarea
                  className="textarea__input"
                  name="about"
                  id=""
                  placeholder="До 1 000 знаков с пробелами. Без этой информации мы не сможем открыть стенд"
                  onChange={onChangeHandler}
                  value={stand.about}
                  required={true}
                  maxLength={1000}
                />
                <div className="textarea__counter">
                  {stand.about.length} / 1000 знаков
                </div>
              </div>
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Направления работы
              </div>
              <div className="cabinet-stand__form-group-desc">
                Укажите одно или несколько направлений деятельности,
                взаимодействия с рынком
              </div>
              {stand.directions.length > 0 && (
                <div className="cabinet-stand__form-tags">
                  {directions.map((direction: any) => {
                    if (stand.directions.includes(direction.id)) {
                      return (
                        <Link
                          to="#"
                          className="form__autocomplete-tag"
                          key={direction.id}
                          onClick={() => {
                            const directionList = stand.directions;
                            const id = stand.directions.indexOf(direction.id);
                            if (id >= 0) {
                              directionList.splice(id, 1);
                            }
                            setStand((prevStand) => ({
                              ...prevStand,
                              directions: directionList,
                            }));
                          }}
                        >
                          {direction.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              )}
              <InputAutocomplete
                name="directions"
                type="custom"
                list={directions}
                placeholder="Начните вводить название направления"
                clearOnSelect={true}
                onSelect={(event: any) => {
                  const directionList = stand.directions;
                  if (
                    !directionList.includes(event.target.value) &&
                    event.target.value !== null
                  ) {
                    directionList.push(event.target.value);
                  }
                  setStand((prevStand) => ({
                    ...prevStand,
                    directions: directionList,
                  }));
                }}
              />
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Путеводитель компании с портала Profi.Travel
              </div>
              <Input
                type="text"
                name="guide"
                placeholder="Введите ссылку"
                value={stand.guide}
                onChange={onChangeHandler}
              />
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Компании-партнеры
              </div>
              {stand.partners.length > 0 && (
                <div className="cabinet-stand__form-tags">
                  {directions.map((partner: any) => {
                    if (stand.partners.includes(partner.id)) {
                      return (
                        <Link
                          to="#"
                          className="form__autocomplete-tag"
                          key={partner.id}
                          onClick={() => {
                            const partnersList = stand.partners;
                            const id = stand.partners.indexOf(partner.id);
                            if (id >= 0) {
                              partnersList.splice(id, 1);
                            }
                            setStand((prevStand) => ({
                              ...prevStand,
                              partners: partnersList,
                            }));
                          }}
                        >
                          {partner.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              )}
              <InputAutocomplete
                name="partners"
                type="custom"
                list={directions}
                placeholder="Начните вводить название компании-партнера"
                clearOnSelect={true}
                onSelect={(event: any) => {
                  const partnersList = stand.partners;
                  if (
                    !partnersList.includes(event.target.value) &&
                    event.target.value !== null
                  ) {
                    partnersList.push(event.target.value);
                  }
                  setStand((prevStand) => ({
                    ...prevStand,
                    partners: partnersList,
                  }));
                }}
              />
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Каталог компании
              </div>
              <div className="cabinet-stand__form-group-desc">
                Размер файла не должен превышать более 50 мб
              </div>
              <div className="cabinet-stand__form-tags"></div>
              <label className="cabinet-stand__form-file" htmlFor="catalogs">
                <input
                  type="file"
                  multiple
                  name="catalogs"
                  id="catalogs"
                  className="visually-hidden"
                />
                Загрузить PDF-файл с устройства
              </label>
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">Фотографии</div>
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">Видео</div>
              <Input
                type="text"
                name="video"
                placeholder="Введите ссылку на видеохостинг"
                value={stand.video}
                onChange={onChangeHandler}
              />
            </div>
            <hr />
            <div className="cabinet-stand__form-group">
              <div className="cabinet-stand__form-group-title">
                Представитель организации
              </div>
            </div>
            <div className="cabinet-stand__form-footer">
              <button className="btn btn--medium btn--red" type="submit">
                Сохранить изменения
              </button>
              <button className="btn btn--medium btn--grey" type="reset">
                Отменить изменения
              </button>
            </div>
          </form>
          <div className="cabinet-stand__contacts">
            <h2 className="cabinet-stand__contacts-title">
              Контактные данные компании
            </h2>
            <Link
              to=""
              className="cabinet-stand__contacts-link btn btn--red-underline"
            >
              Редактировать страницу компании
            </Link>
          </div>
        </div>
        <div className="cabinet-stand__col">
          <div className="cabinet-stand__netw">
            <h2 className="cabinet-stand__netw-title">
              Ваш стенд находится <span>в Нетворкинге</span>
            </h2>
            <div className="cabinet-stand__netw-desc">
              <p>
                Вы можете посмотреть, как другие участники ОТМ видят стенд вашей
                компании
              </p>
            </div>
            <Link to="" className="btn btn--medium btn--red tariff__btn">
              Перейти на стенд
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabinetStand;
