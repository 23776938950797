import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  placeholder: string;
  name: string;
  hiddenInput?: boolean;
  value?: string | number;
  onChange?: (event: any) => void;
  checked?: boolean;
  type?: string;
}

interface StateInterface {
  checked: boolean;
}

class Checkbox extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  public componentDidMount() {
    if (this.props.checked !== undefined) {
      this.setState({
        checked: this.props.checked,
      });
    }
  }

  public componentDidUpdate(props: PropsInterface) {
    if (this.props.checked !== props.checked && this.props.checked !== undefined) {
      this.setState({
        checked: this.props.checked,
      });
    }
  }

  public render() {
    return (
      <>
        <div
          className={`checkbox ${
            this.props.type !== undefined && this.props.type?.length > 0
              ? `checkbox--${this.props.type}`
              : ""
          }`}
        >
          <input
            className={
              this.props.hiddenInput !== undefined && this.props.hiddenInput !== false
                ? "visually-hidden"
                : ""
            }
            type="checkbox"
            name={this.props.name}
            value={this.props.value}
            checked={this.state.checked}
          />
          <label
            htmlFor={this.props.name}
            onClick={() => {
              this.setState({ checked: !this.state.checked }, () => {
                if (this.props.onChange) {
                  this.props.onChange({
                    target: {
                      value: this.props.value,
                      name: this.props.name,
                    },
                  });
                }
              });
            }}
          >
            {this.props.placeholder}
          </label>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Checkbox)
);
