import React from "react";
import { RegistrationProps } from "./page";
import { Input, InputAutocomplete } from "@components/form";
import Connector from "@helpers/Connector";
import SelectCountry from "@components/form/SelectCountry";

const NameAndAddressForm: React.FC<RegistrationProps> = ({
  Store,
  data,
  onChange,
}) => {
  return (
    <div className="form__group--margin">
      <div className="form__group form__group--col-2 form__group--margin">
        <Input
          type="text"
          name="lastName"
          placeholder="Фамилия"
          required={true}
          value={data.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange("lastName", e.target.value)
          }
        />
        <Input
          type="text"
          name="firstName"
          placeholder="Имя"
          value={data.firstName}
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange("firstName", e.target.value)
          }
        />
      </div>

      <div className="form__group">
        <SelectCountry
          name="countryId"
          selected={Number(data.countryId) || null}
          placeholder="Ваша страна / Country"
          options={Store.Location.Country.list}
          onChange={(e) => onChange("countryId", e.target.value)}
          required={true}
        />
        <InputAutocomplete
          name="cityId"
          type="city"
          country_id={Number(data.countryId) || 0}
          placeholder="Город / City"
          value={data.city}
          selected={data.city || null}
          onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange("city", e.target.title);
            onChange("cityId", e.target.value);
          }}
          required={true}
        />
      </div>
    </div>
  );
};

export default Connector(NameAndAddressForm);
