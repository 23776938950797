import React from "react";
import { Switch } from "react-router-dom";
import Router from "@helpers/router";
import ErrorBoundary from "@containers/ErrorBoundary";
import NetworkingDisabled from "./networking/Disabled";
import NetworkingEnabled from "./networking/Enabled";

const RouterComponent: React.FC = () => {
  return (
    <ErrorBoundary>
      <Switch>
        <Router
          path="/service/networking/disabled"
          component={NetworkingDisabled}
          exaсt={true}
        />
        <Router
          path="/service/networking/enabled"
          component={NetworkingEnabled}
          exaсt={true}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default RouterComponent;
