import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class Title extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="stand-title stand__block">
          <div className="stand-title__logo">
            <img
              src={
                this.props.Store.Company.Current.data.companyImage ||
                require("@public/images/defaultAvatarCompany.svg")
              }
              alt=""
            />
          </div>
          <div className="stand-title__content">
            <h1 className="stand-title__title">{this.props.Store.Company.Current.data.title}</h1>

            <div className="stand-title__desc">
              {this.props.Store.Company.Type.list.map((Item: any, index: number) => {
                if (this.props.Store.Company.Current.data.types?.includes(Item.id)) {
                  return <div key={Item.id}>{Item.name}</div>;
                }
              })}
            </div>
          </div>

          {Number(this.props.match.params.company_id) === this.props.Store.Auth.data?.company?.id &&
          this.props.Store.Auth.data?.isAdmin ? (
            <div className="stand-title__change-link">
              <Link to={`/company/${this.props.match.params.company_id}/edit`}>Изменить</Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Title)
);
