import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import moment from "moment-timezone";

import ScheduleItem from "./components/ScheduleItem";
import { ITariff } from "@pages/dashboard/components/Price";

// TODO: выяснить как должно работать расписание на этой странице и переверстать

const PageSuccess: React.FC = () => {
  const location = useLocation();
  const tariffs: ITariff[] = useSelector(
    (state: any) => state.Main?.Workshop?.data.tariffs
  );
  const networkingDays = useSelector(
    (state: any) => state.Networking.Days.data
  );
  const currentTariffId = localStorage.getItem("currentTariff")
    ? Number(localStorage.getItem("currentTariff"))
    : Number(new URLSearchParams(location.search).get("tariffId"));
  const workshopTitle: string = useSelector(
    (state: any) => state.Main.Workshop.data.title
  );
  const scheduleList = useSelector((state: any) => state.Schedule?.list);
  const webinarFirst = scheduleList.find((item: any) => item.id === 168469);
  const now = moment().tz("Europe/Moscow");
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );
  const isArchive =
    now.format() > moment(endWorkshop).tz("Europe/Moscow").format();
  const scheduleId = Number(process.env.SCHEDULE_ID);

  useEffect(() => {
    Middleware.Schedule.section(scheduleId, networkingDays);
  }, [networkingDays]);

  useEffect(() => {
    Middleware.Schedule.sectionList();
    Middleware.Profile.load();
  }, []);

  return (
    <div className="payment">
      <div className="cont payment__cont">
        <div className="payment__wrapper">
          <div className="payment__header">
            <h1 className="payment__title">
              Поздравляем!
              <span>
                Вы участник интенсива:
                <br />
                Продукт. Продажи. Продвижение.
              </span>
            </h1>
            <div className="payment__desc">
              <p>
                С 10 по 28 февраля запланированы вебинары по летним направлениям
                от туроператоров и отельеров со всего мира, а также спецэфиры по
                привлечению туристов – рабочие кейсы представят лучшие эксперты
                туротрасли.
              </p>
              <p>
                Вы можете посещать <b>все мероприятия</b> или составить
                индивидуальное расписание.
              </p>
              <Link
                to="/?anchor=schedule-anchor"
                className="payment__header-link btn btn--red-underline"
                onClick={() => {
                  localStorage.removeItem("currentTariff");
                }}
              >
                Составить персональное расписание
              </Link>
            </div>
          </div>
          <div className="payment__schedule">
            <div className="payment__schedule-header">
              <h2 className="payment__schedule-title">Интересные события</h2>
              <div className="payment__schedule-desc">
                <Link
                  to="/?anchor=schedule-anchor"
                  className="payment__header-link btn btn--red-underline"
                  onClick={() => {
                    localStorage.removeItem("currentTariff");
                  }}
                >
                  Посмотреть расписание
                </Link>
              </div>
            </div>
            <div className="payment__schedule-list">
              {now <
                moment("2024-08-26T13:00:00+03:00").tz("Europe/Moscow") && (
                <ScheduleItem
                  specialClass="payment__schedule-item--start"
                  specialDayDate="26 августа"
                  specialDayName="Старт онлайн-интенсива"
                  webinar={webinarFirst}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSuccess;
