import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class Menu extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="stand-menu stand__block">
          <div className="stand-menu__otm">
            <div className="stand-menu__otm-logo">
              <img src={require("@public/images/logo.svg")} alt="" />
            </div>
            <div className="stand-menu__otm-title">
              Стенд экспонента онлайн-выставки
            </div>
          </div>
          <ul className="stand-menu__menu">
            <li>
              <Link className="stand-menu__back-link" to="/networking">
                Вернуться к стендам
              </Link>
            </li>
            {/* <li>
              <Link to="#">Задать вопрос</Link>
            </li>
            <li>
              <Link to="#">Запрос на тур или размещение</Link>
            </li> */}
          </ul>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Menu)
);
