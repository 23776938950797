import * as Action from "@actions/index";
import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";
import { useDispatch } from "@helpers/Store";

export default class CompanyEmployee {
  public static async load(company_id: number) {
    useDispatch(Action.Company.Employee.setStatus("loading"));
    try {
      const response = await new Model.Employee().list(company_id);
      useDispatch(Action.Company.Employee.setList(response.data));
      useDispatch(Action.Company.Employee.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }

  public static async invite(company_id: number) {
    try {
      const response = await new Model.Employee().invite(company_id);
      return response.data;
    } catch (Exception) {}
  }

  public static async remove(company_id: number, user_id: number) {
    try {
      const response = await new Model.Employee().remove(company_id, { user_id });
      CompanyEmployee.load(company_id);
      return response.data;
    } catch (Exception) {}
  }

  public static async accept(company_id: number, user_id: number) {
    try {
      const response = await new Model.Employee().accept({
        userId: user_id,
        companyId: company_id,
      });
      CompanyEmployee.load(company_id);
      return response.data;
    } catch (Exception) {}
  }

  public static async reject(company_id: number, user_id: number) {
    try {
      const response = await new Model.Employee().reject({
        userId: user_id,
        companyId: company_id,
      });
      CompanyEmployee.load(company_id);
      return response.data;
    } catch (Exception) {}
  }

  public static async setIsAdmin(company_id: number, user_id: number, status: boolean) {
    try {
      const response = await new Model.Employee().setIsAdmin({
        userId: user_id,
        companyId: company_id,
        isAdmin: status,
      });
      CompanyEmployee.load(company_id);
      return response.data;
    } catch (Exception) {}
  }
}
