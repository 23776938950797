import React from "react";
import { Switch } from "react-router-dom";
import Router, { Validator } from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Pages from ".";
import Connector from "@helpers/Connector";

export default Connector(
  class RouterComponent extends React.Component<ComponentInterface> {
    public render() {
      return (
        <>
          <Switch>
            <Router
              path="/company/stand/:stand_id"
              exact={true}
              render={(props: any) => (
                <Pages.Show {...props} key={"company-stand-" + props.match.params.stand_id} />
              )}
            />
          </Switch>
        </>
      );
    }
  }
);
