import * as Action from "@actions/index";

export default class Footer {
  /**
   * @return function(Dispatch)->Footer
   */
  public static show() {
    return (Dispatch: any) => {
      Dispatch(Action.Ui.Footer.show());
    };
  }

  /**
   * @return function(Dispatch)->Footer
   */
  public static hide() {
    return (Dispatch: any) => {
      Dispatch(Action.Ui.Footer.hide());
    };
  }
}
