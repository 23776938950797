import * as Action from "@actions/index";
import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";
import { useDispatch } from "@helpers/Store";

export default class Current {
  public static async load(company_id: number, isNetworkingNotImportant?: boolean) {
    useDispatch(Action.Company.Current.setStatus("loading"));
    try {
      const response = await new Model.Company().get(company_id, isNetworkingNotImportant);
      useDispatch(Action.Company.Current.setData(response.data));
      useDispatch(Action.Company.Current.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }

  public static async employees(company_id: number) {
    try {
      const response = await new Model.Company().employees(company_id);
      return response.data;
    } catch (Exception) {}
  }

  public static async setUserCutawayStatus(status: number, user_id: number) {
    try {
      useDispatch(Action.Company.Current.setUserCutawayStatus({status, user_id}));
    } catch (Exception) {}
  }

  public static async create(data: {
    comany_id?: number;
    name: string;
    types: number[];
    about: string;
    directions?: number[];
    specializations?: number[];
    avatar?: string;
    website?: string;
    creator: number;
    socials?: {
      facebook?: string;
      instagram?: string;
      vkontakte?: string;
      youtube?: string;
      tiktok?: string;
      telegram?: string;
    };
    offices?: Array<{
      name: string;
      address: string;
      phone: string;
      email: string;
      country_id: number;
      city_id: number;
      username: string;
      userposition: string;
    }>;
  }) {
    try {
      const response = await new Model.Company().create(data);
      return response.data;
    } catch (Exception) {}
  }

  public static async update(
    company_id: number,
    data: {
      name: string;
      types: number[];
      about: string;
      directions?: number[];
      specializations?: number[];
      avatar?: string;
      website?: string;
      creator: number;
      socials?: {
        facebook?: string;
        instagram?: string;
        vkontakte?: string;
        youtube?: string;
        tiktok?: string;
        telegram?: string;
      };
      offices?: Array<{
        name: string;
        address: string;
        phone: string;
        email: string;
        country_id: number;
        city_id: number;
        username: string;
        userposition: string;
      }>;
    }
  ) {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Отправляем запрос на обновление компании...",
      component: (c: any) => (ProgressAlert = c),
    });
    try {
      const response = await new Model.Company().update(company_id, data);
      ProgressAlert.setText("Компания успешно обновлена!");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      useDispatch(Middleware.Auth.me());
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("При обновлении компании произошла ошибка!");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  }
}
