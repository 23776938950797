import { Networking as NetworkingAction } from "@actions/index";
import { Networking } from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Days {
  public static async get() {
    try {
      const response = await new Networking.Days().get();

      useDispatch(NetworkingAction.Days.setData(response));

      return response.data;
    } catch (Exception) {}
  }
}
