import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Link } from "react-router-dom";

import Router from "@helpers/router";
import * as Middleware from "@middlewares/index";
import { ComponentInterface } from "@interface/index";
import ErrorBoundary from "@containers/ErrorBoundary";

import Userbar from "./components/Userbar";
import * as Page from "./index";

class RouterComponent extends React.Component<ComponentInterface> {
  componentDidMount() {
    Middleware.Language.load();
    Middleware.Company.Type.load();
    Middleware.Company.Direction.load();
    Middleware.Company.Specialization.load();
  }

  public render() {
    return (
      <div className="cabinet">
        <div className="cabinet__side">
          <Userbar />
        </div>
        <div className="cabinet__main">
          <ErrorBoundary>
            <Switch>
              <Router
                path={["/cabinet/event", "/cabinet/events"]}
                component={Page.Events.default}
              />
              <Router path="/cabinet/rating" component={Page.Rating.default} />
              <Router
                path="/cabinet/profile"
                component={Page.Profile.default}
              />
              <Router path="/cabinet/chats" component={Page.Chats.default} />
              <Router
                path={["/cabinet/cutaway", "/cabinet/cutaways"]}
                component={Page.Cutways.default}
              />
              <Router
                path="/cabinet/networking"
                component={Page.Networking.default}
              />
              <Router
                path="/cabinet/stand"
                component={Page.Stand.default}
              />

              {/* <Router path="/cabinet/company" component={Page.Company.default} /> */}
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
