import React from "react";
import * as Type from "@interface/index";
import Connector from "@helpers/Connector";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  children: any;
}

interface StateInterface {}

export default Connector(
  class Sidebar extends React.Component<PropsInterface, StateInterface> {
    public render() {
      if (Number(this.props.match.params.company_id) !== this.props.Store.Auth.data?.company?.id) {
        return this.props.children;
      } else {
        return (
          <>
            <div className="company-cabinet">
              <div className="company-cabinet__side">
                <section className="company-bar">
                  <div className="company-bar__header">
                    <div className="company-bar__img">
                      <img
                        src={
                          this.props.Store.Auth.data?.company?.companyImage ||
                          require("@public/images/defaultAvatarCompany.svg")
                        }
                        alt=""
                      />
                    </div>
                    <div className="company-bar__title">
                      <div className="company-bar__company-name">
                        {this.props.Store.Auth.data?.company?.title}
                      </div>
                      {/* <div className="company-bar__company-desc">Принимающий туроператор (DMO)</div> */}
                    </div>
                  </div>
                  <hr />
                  <ul className="company-bar__nav-list">
                    {/* <li className="company-bar__nav-item">
                      <Link className="company-bar__nav-item-link" to="#">
                        <span>Заявки на тур</span>
                        <div className="company-bar__nav-item-counter company-bar__nav-item-counter--active">
                          +12
                        </div>
                      </Link>
                    </li> */}
                    <li className="company-bar__nav-item">
                      <Link
                        className="company-bar__nav-item-link"
                        to={`/company/${this.props.Store.Auth.data?.company?.id}/employees/inbox`}
                      >
                        <span>Модерация сотрудников</span>
                        {/* <div className="company-bar__nav-item-counter">242</div> */}
                      </Link>
                    </li>
                    <li className="company-bar__nav-item">
                      <Link
                        className="company-bar__nav-item-link"
                        to={`/company/${this.props.Store.Auth.data?.company?.id}/edit`}
                      >
                        Редактировать страницу
                      </Link>
                    </li>
                  </ul>
                  {/* <hr />
                  <div className="company-bar__time">
                    23 декабря, 14:09
                    <br />
                    Москва - UTC +3
                  </div> */}
                  <hr />
                  {this.props.Store.Company?.Current?.data?.standList !== null &&
                  this.props.Store.Company?.Current?.data?.standList?.length > 0 ? (
                    <>
                      <Link
                        to={`/company/stand/${this.props.Store.Company?.Current?.data?.standList[0]?.id}`}
                        className="company-bar__company-link"
                      >
                        Перейти на страницу компании
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`/company/${this.props.Store.Company?.Current?.data?.id}`}
                        className="company-bar__company-link"
                      >
                        Перейти на страницу компании
                      </Link>
                    </>
                  )}
                </section>
              </div>
              <div className="company-cabinet__main">{this.props.children}</div>
            </div>
          </>
        );
      }
    }
  }
);
