import React, { ReactElement } from "react";
import moment, { Moment } from "moment-timezone";
import ScheduleListDay from "./ScheduleListDay";

interface ScheduleListWeekProps {
  scheduleWizard: {
    dayPeriodCount: number;
    dayPeriodLength: number;
    startHour: number;
    startMinute: number;
  };
  events: Days[];
  isShowActiveDays?: boolean;
  isShowArchiveDays?: boolean;
}

interface Days {
  date: string;
}

const ScheduleListWeek = ({
  scheduleWizard,
  events,
  isShowActiveDays = true,
  isShowArchiveDays = true,
}: ScheduleListWeekProps): ReactElement => {
  const days: any = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
  const periodLength = scheduleWizard.dayPeriodLength;
  const periodCount = scheduleWizard.dayPeriodCount;
  const startHour = +scheduleWizard.startHour;
  const startMinute = +scheduleWizard.startMinute;

  const getDayStartTime = (day: number): Moment =>
    moment(days[day][0]?.date)
      .tz("Europe/Moscow")
      .hour(startHour)
      .minute(startMinute);

  const getDayEndTime = (day: number): Moment =>
    getDayStartTime(day)
      .tz("Europe/Moscow")
      .add(periodLength * periodCount, "minutes");

  events.forEach((event: any) => {
    days[moment(event.date).tz("Europe/Moscow").day()].push(event);
  });

  return (
    <>
      {isShowActiveDays &&
        Object.keys(days)
          .filter(
            (day: any) => getDayEndTime(day) > moment().tz("Europe/Moscow")
          )
          .map((day: any, index: number) => {
            return (
              <ScheduleListDay
                key={index}
                events={days[day]}
                dayEndTime={getDayEndTime(day)}
                isArchived={false}
              />
            );
          })}

      {isShowArchiveDays &&
        Object.keys(days)
          .filter(
            (day: any) => getDayEndTime(day) < moment().tz("Europe/Moscow")
          )
          .map((day: any, index: number) => {
            return (
              <ScheduleListDay
                key={index}
                events={days[day]}
                dayEndTime={getDayEndTime(day)}
                isArchived={true}
              />
            );
          })}
    </>
  );
};

export default ScheduleListWeek;
