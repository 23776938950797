import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NetworkingDisabled: React.FC = () => {
  const preurl = useSelector((state: any) => state.Auth.preurl);

  return (
    <div className="auth auth--thanks">
      <div className="auth__wrapper">
        <div className="auth__body">
          <div className="auth__title">Спасибо!</div>
          <div className="auth__desc">
            Вы зарегистрированы как участник деловой программы. Уже сейчас вы
            можете{" "}
            <Link to="/?anchor=stands-anchor">ознакомиться со стендами</Link>{" "}
            экспонентов и{" "}
            <Link to="/?anchor=schedule-anchor">составить личное</Link>{" "}
            расписание онлайн-презентаций
          </div>
        </div>
        <div className="auth__footer">
          <Link to={preurl} className="btn btn--red">
            Понятно!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NetworkingDisabled;
