import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

interface IProps {
  isLinkBtn?: boolean;
}

const defaultProps = {
  isLinkBtn: true,
};

const ContactsValidation = ({ isLinkBtn }: IProps): ReactElement => {
  const isContactsValid = useSelector(
    (state: any) => state.Profile?.isContactsValid
  );

  return !isContactsValid ? (
    <div className="contacts-valid">
      <div className="contacts-valid__content">
        <div className="contacts-valid__title">
          Обновите ваши контактные данные
        </div>
        <div className="contacts-valid__desc">
          Похоже, при регистрации вы указали не всю информацию о себе. Чтобы
          сервис нетворкинга работал корректно, пожалуйста, обновите ваши
          контактные данные.
        </div>
      </div>
      <div className="contacts-valid__button-block">
        {isLinkBtn ? (
          <Link to="/cabinet/profile" className="btn btn--red">
            Обновить
          </Link>
        ) : null}
      </div>
    </div>
  ) : (
    <></>
  );
};

ContactsValidation.defaultProps = defaultProps;

export default ContactsValidation;
