import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Jaas extends ModelPrototype {
  public BaseUrl = process.env.API_URL_VIDYOIO + "/";

  public async generateToken(
    room: string,
    data: { id: number; name: string; email: string; avatar: string; moderator: boolean }
  ): Promise<any> {
    try {
      return this.withUrl("api/room/" + room)
        .setMethod(Method.POST)
        .withAuth()
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
