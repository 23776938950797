import React from "react";
import { Link, useHistory } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface IProps {
  currentTariff: number;
  thisYearRating: number;
  setCurrentStep: (value: number) => void;
  utmTags: string;
}

const Step4: React.FC<IProps> = ({
  currentTariff,
  thisYearRating,
  setCurrentStep,
  utmTags,
}) => {
  const history = useHistory();

  return (
    <div className="payment__step">
      <div className="payment__block">
        <h1 className="payment__step-title">Оплата баллами Loyalty</h1>
        <div className="payment__step-description">
          На вашем балансе {thisYearRating} баллов
        </div>
        <Link
          to="#"
          className="btn btn--medium btn--red"
          onClick={() => {
            let ProgressAlert: any;

            Middleware.Payment.payLoyalty(currentTariff, utmTags)
              .then(() => {
                Middleware.UserRating.load();
                history.push("/tariffs-and-payment/success");
                localStorage.setItem("currentTariff", String(currentTariff));

                Middleware.Ui.Alert.open("Process", {
                  text: "Ваш тариф изменён",
                  component: (c: any) => {
                    ProgressAlert = c;
                  },
                });
                ProgressAlert.setStatus("success");
                ProgressAlert.startTimer();
              })
              .catch((Exception: any) => {
                if (
                  Exception.data?.error ===
                  "Недостаточно баланса лоялти для покупки тарифа"
                ) {
                  Middleware.Ui.Alert.open("Process", {
                    text: "У вас недостаточно баллов",
                    component: (c: any) => {
                      ProgressAlert = c;
                    },
                  });
                  ProgressAlert.setStatus("danger");
                  setCurrentStep(3);
                } else {
                  Middleware.Ui.Alert.open("Process", {
                    text: Exception.error,
                    component: (c: any) => {
                      ProgressAlert = c;
                    },
                  });
                  ProgressAlert.setStatus("danger");
                }
              });
          }}
        >
          Оплатить баллами
        </Link>
      </div>
    </div>
  );
};

export default Step4;
