import React, { ReactElement } from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListSubscribeBtn from "./ListSubscribeBtn";
import { specialAirtimeId } from "@pages/dashboard/components2.0/Schedule/Schedule";
import { useCurrentTariff } from "@hooks/useCurrentTariff";
import { ITariff } from "../Price";

interface IProps {
  event: any;
  scheduleWizard: any;
}

const ScheduleTableEvent = ({
  event,
  scheduleWizard,
}: IProps): ReactElement => {
  const periodLength = scheduleWizard.dayPeriodLength;
  const periodCount = scheduleWizard.dayPeriodCount;
  const dayStartTime = moment(event.date)
    .tz("Europe/Moscow")
    .hour(+scheduleWizard.startHour)
    .minute(+scheduleWizard.startMinute);

  const eventStart =
    moment(event.date)
      .tz("Europe/Moscow")
      .diff(moment(dayStartTime).tz("Europe/Moscow"), "minute") /
      periodLength +
    1;
  const eventDuration =
    moment(event.dateEnd)
      .tz("Europe/Moscow")
      .diff(moment(event.date).tz("Europe/Moscow"), "minute") / periodLength;

  const isAuthorized = useSelector((state: any) => state.Auth.status);
  const currentTariff: ITariff | null = useCurrentTariff();
  const currentTariffType = currentTariff ? currentTariff.type : null;

  const formattedEventDate = moment(event.date)
    .tz("Europe/Moscow")
    .format("D MMMM");

  const specialAirtime = specialAirtimeId.includes(event.id);

  return event.networkingDay ? (
    <div
      className="schedule__table-item schedule__table-item-netw"
      data-start-time={eventStart}
      data-event-duration={eventDuration}
    >
      <div className="schedule__table-item-netw-content">
        <p className="schedule__table-item-netw-title">День нетворкинга</p>
        <p className="schedule__table-item-netw-desc">
          В этот день открыта возможность проведения встреч в системе
          нетворкинга. Выберите партнеров в турбизнесе и начните общение с ними!
        </p>
      </div>
      <Link to="/networking" className="btn btn--red">
        Перейти в систему
      </Link>
    </div>
  ) : (
    <div
      id={"event_" + event.id}
      className={`schedule__table-item ${
        specialAirtime ? "schedule__table-item--special" : ""
      }`}
      data-start-time={eventStart}
      data-event-duration={eventDuration}
    >
      <div className="schedule__table-item-header">
        <div className="schedule__table-item-date">
          {moment(event.date).tz("Europe/Moscow").format("HH:mm")}
          <span>МСК</span>
        </div>
        <div className="schedule__table-item-flag">{event.label}</div>
      </div>
      {event.companyTitle ? (
        <div className="schedule__table-item-company">{event.companyTitle}</div>
      ) : (
        <></>
      )}

      <a
        href={"https://profi.travel" + event.path}
        className="schedule__table-item-desc"
        target="_blank"
      >
        {event.title}
      </a>
      <div className="schedule__table-item-bottom">
        <ListSubscribeBtn
          currentTariffType={currentTariffType}
          eventId={event.id}
          eventType={event.type}
          isAuthorized={isAuthorized}
          status={event.subscribed}
          isEnded={
            moment().tz("Europe/Moscow") >
            moment(event.dateEnd).tz("Europe/Moscow")
              ? true
              : false
          }
          isOnline={
            moment().tz("Europe/Moscow") >
              moment(event.date).tz("Europe/Moscow") &&
            moment().tz("Europe/Moscow") <
              moment(event.dateEnd).tz("Europe/Moscow")
              ? true
              : false
          }
          path={event.path}
        />
      </div>
    </div>
  );
};

export default ScheduleTableEvent;
