import React from "react";

interface RatingStarRulesModalProps {
  handlerOnClose: () => void;
}

const RatingStarRulesModal = ({
  handlerOnClose,
}: RatingStarRulesModalProps): JSX.Element => {
  return (
    <div
      className="modal-background"
      onClick={() => {
        handlerOnClose();
      }}
    >
      <div
        className="modal-wrap"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="modal__close"
          onClick={() => {
            handlerOnClose();
          }}
        />
        <div className="modal modal--content">
          <div className="modal__header">
            <h3 className="modal__title">
              Что означает значок «звезда» и как её получить
            </h3>
          </div>
          <div className="modal__body">
            <h3>Зачем мне «звезда» в рейтинге?</h3>
            <div>
              Если напротив вашего имени в рейтинге стоит специальный значок
              «звезда» — это означает, что вы входите в число лидеров рейтинга
              Loyalty и претендуете на участие в розыгрыше призового фонда.
            </div>
            <hr />
            <h2>Как формируется рейтинг?</h2>
            <div>
              Баллы в программе Profi.Travel Loyalty в рамках онлайн-интенсива
              «Продукт. Продажи. Продвижение» автоматически начисляются
              пользователям за:
            </div>

            <div className="modal__rating-star-grid">
              <div>просмотр вебинаров в онлайне</div>
              <div>+100 баллов</div>

              <div>
                просмотр вебинаров в архиве (если вебинар не был просмотрен в
                прямом эфире)
              </div>
              <div>+20 баллов</div>

              <div>
                просмотр стендов экспонентов онлайн-мероприятия (один раз за
                онлайн-мероприятие)
              </div>
              <div>+10 баллов </div>

              <div>
                скачивание каталогов экспонентов онлайн-мероприятия (за каждый
                каталог один раз за онлайн-мероприятие)
              </div>
              <div>+15 баллов</div>

              <div>
                прохождение финального тестирования на итоговом эфире (максимум
                за 10 вопросов тестирования)
              </div>
              <div>+1000 баллов</div>

              <div>регистрацию на онлайн-мероприятие</div>
              <div>+10 баллов</div>
            </div>
            <hr />

            <h2>Как получить звезду?</h2>
            <div>
              Звездой будут отмечены участники онлайн-интенсива «Продукт.
              Продажи. Продвижение», набравшие максимальный балл на текущий
              момент, а также те, кто отстают от лидеров в своем рейтинге
              максимум на 400 баллов.
            </div>

            <hr />

            <h2>Как определят победителей?</h2>
            <div>
              Определение обладателей призов состоится в последний день
              онлайн-интенсива в прямом эфире на портале Profi.Travel с помощью
              рандомайзера среди всех участников онлайн-интенсива «Продукт.
              Продажи. Продвижение», отмеченных звездой в рейтинге. Дорогие
              участники, если у вас остались вопросы, пишите нам на почту{" "}
              <a className="red-link" href="mailto:academy@profi.travel">
                academy@profi.travel
              </a>
              . Мы с удовольствием ответим!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingStarRulesModal;
