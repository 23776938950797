import React, { useEffect, useRef } from "react";
import PrizeItem from "./PrizeItem";
import { Link, useLocation } from "react-router-dom";
import * as Middleware from "@middlewares/index";

export const prizesList = [
  {
    id: 1,
    image: "super-prize.jpg",
    imageWebp: "super-prize.jpg",
    tariffs: [2, 3],
    name: "Рекламный тур на Мальдивы в августе 2025 года",
    title: "Суперприз",
    duration: "10 ночей на Мальдивах",
    country: "Таиланд",
    hotels: [
      {
        name: "Партнёр OTM: SUMMER 2025 туроператор MaldivesIN",
        description:
          "Проживание в 5 отелях, питание FB/ALL, красивые локации и море впечатлений. \nПерелет авиaкомпанией Emirates включен!",
      },
    ],
  },
  {
    id: 2,
    image: "prize-thailand.png",
    imageWebp: "prize-thailand.webp",
    tariffs: [1, 2, 3],
    title: "Приз №1",
    name: "6 ночей в Таиланде",
    duration: "6 ночей",
    country: "Таиланд",
    hotels: [
      {
        name: "The Oriental Beach Pearl Resort",
        description:
          "Двухместное проживание с завтраком, обедом и ужином на 3 ночи в отеле",
      },
      {
        name: "The Beach Village Resort ",
        description:
          "Двухместное проживание с завтраком, обедом и ужином на 3 ночи в отеле",
      },
    ],
  },
  {
    id: 3,
    image: "prize-maldives.png",
    imageWebp: "prize-maldives.webp",
    tariffs: [2, 3],
    name: "3 ночи на Мальдивах",
    duration: "3 ночи",
    title: "Приз №2",
    country: "Мальдивы",
    hotels: [
      {
        name: "Holiday Inn Resort Kandooma Maldives",
        description:
          "Двухместное проживание с завтраком, обедом и ужином на 3 ночи в отеле",
      },
    ],
  },
  {
    id: 4,
    image: "prize-uae.png",
    imageWebp: "prize-uae.webp",
    tariffs: [2, 3],
    name: "3 ночи в ОАЭ",
    duration: "3 ночи",
    title: "Приз №3",
    country: "ОАЭ",
    hotels: [
      {
        name: "Fujairah Rotana Resort & Spa – Al Aqah Beach",
        description:
          "Двухместное проживание по системе «Всё включено» на 3 ночи в отеле",
      },
    ],
  },
  {
    id: 5,
    image: "prize-uae-2.jpg",
    // TODO конвертировать в webp эту картинку
    imageWebp: "prize-uae-2.jpg",
    tariffs: [2, 3],
    name: "2 ночи в ОАЭ",
    duration: "2 ночи",
    title: "Приз №4",
    country: "ОАЭ",
    hotels: [
      {
        name: "Sofitel Al Hamra Beach Resort",
        description:
          "Ваучер на двухместное проживание с завтраками на 2 ночи в отеле",
      },
    ],
  },
  {
    id: 6,
    image: "prize-uae-3.jpg",
    imageWebp: "prize-uae-3.webp",
    tariffs: [2, 3],
    name: "2 ночи в ОАЭ",
    duration: "2 ночи",
    title: "Приз №5",
    country: "ОАЭ",
    hotels: [
      {
        name: "The First Collection Waterfront",
        description:
          "Ваучер на двухместное проживание с завтраком на 2 ночи в номере категории Deluxe. А также эксклюзивный ваучер на ужин для двоих в одном из лучших ресторанов The First Group Hospitality.",
      },
    ],
  },
  {
    id: 7,
    image: "prize-uae-4.jpg",
    imageWebp: "prize-uae-4.webp",
    tariffs: [2, 3],
    name: "2 ночи в ОАЭ",
    duration: "2 ночи",
    title: "Приз №6",
    country: "ОАЭ",
    hotels: [
      {
        name: "CIEL",
        description:
          "Ваучер  на двухместное проживание с завтраком на 2 ночи в номере категории Deluxe.",
      },
    ],
  },
];

const getTariffText = (tariff: number) => {
  switch (tariff) {
    case 1:
      return "Базовый";
    case 2:
      return "Полный";
    case 3:
      return "Полный плюс";
    default:
      return "Неизвестный тариф";
  }
};

const Prizes = (): JSX.Element => {
  const location = useLocation();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "prizes-anchor" && ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  return (
    <section
      id="prizes-anchor"
      ref={ref}
      className="bg-grey-f5 py-[54px] md:py-16 lg:py-[100px] 2xl:py-[120px] 3xl:py-[140px]"
    >
      <div className="cont">
        <div className="flex flex-col lg:flex-row gap-8 justify-between">
          <p className="text-36 md:text-48 font-bold">Призовой фонд</p>
          <div className="flex flex-col lg:text-18 gap-3.5 md:w-1/2">
            <p>
              Зарегистрировавшись на онлайн-интенсив, вы становитесь участником
              программы лояльности Profi.Travel Loyalty и получаете баллы за
              активность. Зарабатывайте как можно больше баллов и участвуйте в
              розыгрыше призов
            </p>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                Middleware.Ui.Modal.open("PrizesRules", {
                  callback: "",
                });
              }}
              className="red-link"
            >
              Подробности розыгрыша призов и правила выдачи дипломов
            </Link>
          </div>
        </div>

        <div className="flex flex-col pt-10 lg:pt-20 gap-5 lg:gap-8">
          {prizesList.map((prize, index) => (
            <React.Fragment key={prize.id}>
              <PrizeItem
                index={index + 1}
                image={prize.image}
                imageWebp={prize.imageWebp}
                tariffs={prize.tariffs.map(getTariffText)}
                name={prize.name}
                title={prize.title}
                hotels={prize.hotels}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Prizes;
