import React, { ReactElement } from "react";
import { Checkbox } from "@components/form";
import { useState } from "@helpers/Store";
import * as Type from "@interface/index";

interface IProps {
  setNetworkingProfile: any;
  companyTypesList: number[];
  networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  editMode: boolean;
}

const CompanyType = ({
  companyTypesList,
  networkingProfile,
  setNetworkingProfile,
  editMode,
}: IProps): ReactElement => {
  // const [selectedLanguages, setSelectedLanguages] = useState(networkingProfile.languages)

  const onCheckHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    if (
      networkingProfile?.interestCompanyTypes?.includes(+event.target.value)
    ) {
      networkingProfile?.interestCompanyTypes?.splice(
        networkingProfile?.interestCompanyTypes?.indexOf(+event.target.value),
        1
      );
    } else {
      networkingProfile?.interestCompanyTypes?.push(+event.target.value);
    }
    setNetworkingProfile({ ...networkingProfile });
  };

  if (editMode) {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">
              С компаниями какого типа вы ищете контакты?
            </p>
          </div>

          <p className="profile-netw-step__desc">
            Выберите хотя бы одно значение
          </p>
          <div className="profile-netw-step__main profile-netw-step__main--checkbox-list">
            {companyTypesList?.map((Item: any) => {
              return (
                <Checkbox
                  key={Item.id}
                  name="languages"
                  type="profile"
                  placeholder={Item.name}
                  value={Item.id}
                  hiddenInput
                  checked={networkingProfile?.interestCompanyTypes?.includes(
                    Item.id
                  )}
                  onChange={onCheckHandler}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">
              С компаниями какого типа вы ищете контакты?
            </p>
          </div>

          <div className="profile-netw-step__main profile-netw-step__main--item-list">
            {companyTypesList?.map((Item: any) => {
              return networkingProfile?.interestCompanyTypes?.includes(
                Item.id
              ) ? (
                <div key={Item.id} className="profile-netw-step__main-item">
                  {Item.name}
                </div>
              ) : (
                <></>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default CompanyType;
