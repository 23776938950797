import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import * as Middleware from "@middlewares/index";
import { WorkshopNews as WorkshopNewsType } from "@interface/entities/workshopNews";

const AboutNetworking = (): ReactElement => {
  useEffect(() => {
    Middleware.Main.WorkshopNews.get();
    // Middleware.Main.NetworkingStats.get();
  }, []);

  const stats = useSelector((state: any) => state.Main.NetworkingStats.data);
  const workshopNews = useSelector(
    (state: any) => state.Main.WorkshopNews.data
  );
  const authStatus = useSelector((state: any) => state.Auth?.status);
  const currentTariff = useSelector((state: any) => state.Profile?.data.tariff);
  const workshopTitle: string = useSelector(
    (state: any) => state.Main.Workshop.data.title
  );
  const now = moment().tz("Europe/Moscow").format();
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );
  const isArchive = now > moment(endWorkshop).tz("Europe/Moscow").format();

  return (
    <section className="about-netw">
      <div className="about-netw__main">
        <div className="cont about-netw__cont">
          <div className="flex flex-col gap-6">
            <img
              className="about-netw__logo"
              src={require("@public/images/otm-logo-white.svg")}
            />
            <h2 className="about-netw__title">
              Онлайн-интенсив
              <br />
              «Продукт, продажи, продвижение»
              <br />
            </h2>
          </div>
          <div className="about-netw__stats">
            <div className="about-netw__stats-item">
              <div className="about-netw__stats-number">
                {/* {stats.webinar_hours} */}
                25+ {}
                <br />
                экспонентов
              </div>
              <div className="about-netw__stats-text">
                НТО, туроператоры, авиакомпании, отели
              </div>
            </div>
            <div className="about-netw__stats-item">
              <div className="about-netw__stats-number">
                {/* {stats.webinar_experts} */}30+ {}
                <br />
                эфиров
              </div>
              <div className="about-netw__stats-text">
                онлайн-презентации, спецэфиры по продвижению
              </div>
            </div>
            <div className="about-netw__stats-item">
              <div className="about-netw__stats-number">
                {/* {stats.networking_users} */}7 спецэфиров
                {}
                <br />
              </div>
              <div className="about-netw__stats-text">
                по привлечению клиентов от топовых экспертов туротрасли
              </div>
            </div>
          </div>
          {authStatus !== "authorized" || currentTariff === null ? (
            <div
              className={`about-netw__buttons ${
                isArchive ? "about-netw__buttons--archive" : ""
              }`}
            >
              <Link
                to="/tariffs-and-payment"
                className="btn btn--medium btn--red !w-full h-[60px]"
              >
                {isArchive ? "Купить архив" : "Купить"}
              </Link>
              <div className="about-netw__buttons-text">
                {isArchive
                  ? "Онлайн-интенсив завершен"
                  : "Оплатить можно деньгами или баллами Loyalty"}
              </div>
            </div>
          ) : null}
        </div>
        <div className="forum-news">
          <div className="forum-news__title">Новости выставки</div>
          <div className="forum-news__list">
            {workshopNews.map((item: WorkshopNewsType) => {
              return (
                <div className="forum-news__item" key={item.id}>
                  <div className="forum-news__item-title">{item.title}</div>
                  {item.startAt ? (
                    <div className="forum-news__item-date-block">
                      <div className="forum-news__item-time">
                        {moment(item.startAt)
                          .tz("Europe/Moscow")
                          .format("HH:mm")}{" "}
                        <span>мск</span>
                      </div>
                      <hr />
                      <div className="forum-news__item-date">
                        {moment(item.startAt)
                          .tz("Europe/Moscow")
                          .format("D MMMM")}
                      </div>
                    </div>
                  ) : null}
                  <a
                    className="forum-news__item-text"
                    href={`${item.url}`}
                    target="_blank"
                  >
                    {item.description}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutNetworking;
