import React, { ReactElement, useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";
import * as Pages from "@pages/index";
import Router, { Validator } from "@helpers/router";
import ErrorBoundary from "./ErrorBoundary";
import { useDispatch } from "react-redux";
import * as Middleware from "@middlewares/index";
import moment from "moment";
import { Route } from "react-router-dom";
import { AuthorizedRoute } from "@helpers/router/AuthorizedRoute";

interface UtmData {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
}

import Header from "@components/Header";
import Footer from "@components/Footer";
import DashboardPage from "@pages/dashboard";
//const DashboardPage = React.lazy(() => import("@pages/dashboard"));
import PreloaderComponent from "@components/preloader/PreloaderComponent";

const Adfox = React.lazy(
  () => import(/* webpackChunkName: 'ComponentAdfox' */ "@components/Adfox")
);

const Cabinet = React.lazy(
  () => import(/* webpackChunkName: 'PageCabinet' */ "@pages/cabinet/router")
);

const RouterContainer = (): ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get("utm_source");
    const utmMedium = params.get("utm_medium");
    const utmCampaign = params.get("utm_campaign");

    const utmData: UtmData = {};
    if (utmSource) utmData.utm_source = utmSource;
    if (utmMedium) utmData.utm_medium = utmMedium;
    if (utmCampaign) utmData.utm_campaign = utmCampaign;

    if (Object.keys(utmData).length > 0) {
      localStorage.setItem("utm_data", JSON.stringify(utmData));
    }
  }, []);

  useEffect(() => {
    document.title = "" + process.env.SITE_NAME;
    moment.locale("ru");
  }, []);

  useEffect(() => {
    Middleware.Main.User.count();
    dispatch(Middleware.Auth.me());
  }, [dispatch]);

  useEffect(() => {
    Middleware.Main.Workshop.get();
  }, []);

  return (
    <ErrorBoundary>
      <Header />
      <div className="container">
        <React.Suspense
          fallback={
            <div style={{ margin: "auto" }}>
              <PreloaderComponent />
            </div>
          }
        >
          <Switch>
            <Router path="/" exact={true} component={DashboardPage} />

            <Router
              path="/networking-soon"
              component={Pages.NetworkingSoon.default}
            />
            <Router path="/networking" component={Pages.Networking.default} />
            <Router path="/auth" component={Pages.Auth.default} />
            <Route
              path="/cabinet"
              render={() => (
                <AuthorizedRoute>
                  <Cabinet />
                </AuthorizedRoute>
              )}
            />
            <Router path="/static" component={Pages.Static.default} />
            <Router path="/test" component={Pages.Test.default} />
            <Router path="/videotest" component={Pages.Videotest.default} />
            <Router path="/company" component={Pages.Company.default} />
            <Router path="/user" component={Pages.User.default} />
            <Router path="/service" component={Pages.Service.default} />
            <Route
              path="/tariffs-and-payment"
              render={() => (
                <AuthorizedRoute>
                  <Pages.TariffsAndPayment.default />
                </AuthorizedRoute>
              )}
            />
            <Route
              path="/event/:event_id"
              exact={true}
              render={() => (
                <AuthorizedRoute>
                  <Pages.EventShow />
                </AuthorizedRoute>
              )}
            />
          </Switch>
        </React.Suspense>
      </div>
      <Footer />
      <Adfox />
    </ErrorBoundary>
  );
};

export default RouterContainer;
