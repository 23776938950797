import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";
import * as Middleware from "@middlewares/index";

export default class UserEvent {
  public static async create(user_id: number, data: any) {
    try {
      const response = await new Model.User2.Event().create(user_id, data);
      return response.data;
    } catch (Exception) {
      return Exception.data;
    }
  }

  public static async invite(user_id: number, event_id: number) {
    try {
      const response = await new Model.User2.Event().invite(user_id, event_id);
      return response.data;
    } catch (Exception) {
      throw Exception.data;
    }
  }

  public static async list() {
    useDispatch(Action.User.Event.setStatus("loading"));
    try {
      const response = await new Model.User2.Event().list();
      useDispatch(Action.User.Event.setList(response.data));
      useDispatch(Action.User.Event.setStatus("loaded"));

      const incomingWaiting: any[] = [];
      const incomingApproved: any[] = [];
      const incomingDeclined: any[] = [];
      const outgoingWaiting: any[] = [];
      const outgoingApproved: any[] = [];
      const outgoingDeclined: any[] = [];

      response.data.map((item: any) => {
        if (item.isOwner === true) {
          switch (item.status) {
            case null:
              outgoingWaiting.push(item);
              break;

            case true:
              outgoingApproved.push(item);
              break;
            case false:
              outgoingDeclined.push(item);
              break;

            default:
              break;
          }
        } else if (item.isOwner === false) {
          switch (item.status) {
            case null:
              incomingWaiting.push(item);
              break;

            case true:
              incomingApproved.push(item);
              break;
            case false:
              incomingDeclined.push(item);
              break;

            default:
              break;
          }
        }
      });

      useDispatch(Action.User.Event.setIncomingWaiting(incomingWaiting));
      useDispatch(Action.User.Event.setIncomingApproved(incomingApproved));
      useDispatch(Action.User.Event.setIncomingDeclined(incomingDeclined));
      useDispatch(Action.User.Event.setOutgoingWaiting(outgoingWaiting));
      useDispatch(Action.User.Event.setOutgoingApproved(outgoingApproved));
      useDispatch(Action.User.Event.setOutgoingDeclined(outgoingDeclined));

      return response.data;
    } catch (Exception) {}
  }

  public static async remove(event_id: number, data: { description: string }) {
    try {
      const response = await new Model.User2.Event().remove(event_id, data);
      let ProgressAlert: any;
      Middleware.Ui.Alert.open("Process", {
        text: "Запрос удален!",
        component: (c: any) => (ProgressAlert = c),
      });
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      UserEvent.list();
      return response.data;
    } catch (Exception) {}
  }

  public static async reject(event_id: number, data: { description: string }): Promise<any> {
    try {
      const response = await new Model.User2.Event().reject(event_id, data);
      let ProgressAlert: any;
      Middleware.Ui.Alert.open("Process", {
        text: "Запрос отклонен!",
        component: (c: any) => (ProgressAlert = c),
      });
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      UserEvent.list();
      return response.data;
    } catch (Exception) {}
  }

  public static async accept(event_id: number) {
    try {
      const response = await new Model.User2.Event().accept(event_id);
      let ProgressAlert: any;
      Middleware.Ui.Alert.open("Process", {
        text: "Запрос принят!",
        component: (c: any) => (ProgressAlert = c),
      });
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      UserEvent.list();
      return response.data;
    } catch (Exception) {}
  }

  public static async counter(type: string) {
    try {
      const response = await new Model.User2.Event().counter(type);

      switch (type) {
        case "incoming":
          useDispatch(Action.User.Event.incomingCounter(response.data));
          return response.data;
        case "outgoing":
          useDispatch(Action.User.Event.outgoingCounter(response.data));
          return response.data;

        default:
          break;
      }

      return response.data;
    } catch (Exception) {}
  }
}
