import Clickoutside from "@helpers/Clickoutside";
import React, { ReactElement, useState } from "react";
import Style from "./SelectCity.module.scss";

interface PropsInterface {
  name: string;
  selected?: number | null;
  options: { id: number; name: string; isPriority?: boolean }[];
  onChange?: (event: any) => any;
  placeholder?: string;
  required?: boolean;
}

const SelectCountry = ({
  name,
  selected,
  options = [],
  onChange = () => {},
  placeholder = name,
  required,
}: PropsInterface): ReactElement => {
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getOptionList = () => {
    if (searchValue.length < 3) {
      return options;
    }

    return options.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const selectedOption = options.find((option) => option.id === selected);

  return (
    <Clickoutside clickOutside={() => setShow(false)}>
      <div className={Style.container}>
        <div
          className={`${Style.select} ${show ? Style.selectShow : ""} ${
            required && !show && !selectedOption?.name && "required-input--z-56"
          }`}
        >
          <input
            type="text"
            placeholder={(required ? " " : "") + placeholder}
            className={Style.input}
            onFocus={() => setShow(true)}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            value={show ? searchValue : selectedOption?.name || ""}
            required={required}
          />
          <div
            className={[
              Style.selector,
              show ? Style.selectorShow : "",
              Style.selectorNoBefore,
            ].join(" ")}
          >
            {getOptionList().length > 0 && (
              <ul>
                {getOptionList().map(
                  (option: { id: number; name: string }, index: number) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setSearchValue("");
                          setShow(false);
                          onChange({
                            target: { value: option.id, name },
                          });
                        }}
                      >
                        {option.name}
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Clickoutside>
  );
};

export default SelectCountry;
