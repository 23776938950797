import React from "react";
import * as Type from "@interface/index";
import { isGoogleChrome } from "@helpers/Chrome";
import Connector from "@helpers/Connector";

interface PropsInterface extends Type.ComponentInterface {
  id?: string | number;
  type?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  className?: string;
  autoComplete?: boolean;
  pattern?: string;
  onChange?: (data: any) => void;
  modificator?: any;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
}

interface StateInterface {
  isFocused: boolean;
}

export default Connector(
  class Input extends React.Component<PropsInterface, StateInterface> {
    private inputRef: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: PropsInterface) {
      super(props);
      this.state = {
        isFocused: false,
      };
    }

    public componentDidMount() {
      if (typeof this.props.modificator === "function") {
        this.props.modificator.onInit(this.inputRef.current);
      } else if (Array.isArray(this.props.modificator)) {
        // Запустить все модификаторы по очереди
      }
    }

    public componentDidUpdate(props: PropsInterface) {
      if (this.props.value !== props.value && this.props.value !== undefined) {
        const event = {
          target: {
            value: this.props.value,
            name: this.props.name,
          },
        };
        if (typeof this.props.modificator === "function") {
          this.props.modificator.onChange(event);
        } else if (Array.isArray(this.props.modificator)) {
          // Запустить все модификаторы по очереди
        }

        this.props.onChange !== undefined
          ? this.props.onChange(event)
          : (() => {})();
      }
    }

    public render() {
      const { isFocused } = this.state;

      return (
        <>
          <label className="visually-hidden" htmlFor={this.props.name}>
            {(this.props.required ? "*" : "") + this.props.placeholder}
          </label>
          <div
            className={`${
              this.props.required &&
              this.props.value === "" &&
              !isFocused &&
              "required-input"
            }`}
          >
            <input
              ref={this.inputRef}
              className={this.props.className || "input"}
              type={this.props.type}
              name={this.props.name}
              id={this.props.name}
              value={this.props.value || ""}
              onChange={(event: any) => {
                if (typeof this.props.modificator === "function") {
                  this.props.modificator.onChange(event);
                } else if (Array.isArray(this.props.modificator)) {
                  // Запустить все модификаторы по очереди
                }

                this.props.onChange !== undefined
                  ? this.props.onChange(event)
                  : (() => {})();
              }}
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={() => this.setState({ isFocused: false })}
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              placeholder={
                (this.props.required ? " " : "") + this.props.placeholder
              }
              required={this.props.required}
              autoComplete={isGoogleChrome() ? "disabled" : "off"}
              pattern={this.props.pattern}
              disabled={this.props.disabled}
            />
          </div>
        </>
      );
    }
  }
);
