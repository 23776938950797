import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class NetworkingCompany extends ModelPrototype {
  public async count(): Promise<{ data: any }> {
    try {
      return this.withUrl(`networking/company_count`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
