import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class CompanyEmployeesDeclined extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="company-employees__inbox company-employees__inbox--declined">
          <div className="company-employees__inbox-item">
            <div className="company-employees__inbox-item-body">
              <div className="company-employees__inbox-item-user">
                <div className="company-employees__inbox-item-img">
                  <img src={require("@public/images/avatar.png")} alt="" />
                </div>
                <div className="company-employees__inbox-item-name">Евгения Скакун</div>
                <div className="company-employees__inbox-item-desc">
                  Директор Представительства Польской туристической организации в Москве
                </div>
              </div>
              <div className="company-employees__inbox-item-contacts">
                <a href="mailto:sofi@compain.ru">sofi@compain.ru</a>
                <a href="tel:+79005000000">+7 900 500-00-00</a>
              </div>
            </div>
            <hr />
            <div className="company-employees__inbox-item-buttons">
              <div className="btn company-employees__inbox-item-status">
                Отклонено
                <img src={require("@public/images/employee-item.svg")} alt="" />
              </div>

              <Link className="btn btn--grey" to="#">
                Добавить в компанию
              </Link>
            </div>
          </div>
          <hr className="company-employees__inbox-divider" />
          <div className="company-employees__inbox-item">
            <div className="company-employees__inbox-item-body">
              <div className="company-employees__inbox-item-user">
                <div className="company-employees__inbox-item-img">
                  <img src={require("@public/images/avatar.png")} alt="" />
                </div>
                <div className="company-employees__inbox-item-name">Евгения Скакун</div>
                <div className="company-employees__inbox-item-desc">
                  Директор Представительства Польской туристической организации в Москве
                </div>
              </div>
              <div className="company-employees__inbox-item-contacts">
                <a href="mailto:sofi@compain.ru">sofi@compain.ru</a>
                <a href="tel:+79005000000">+7 900 500-00-00</a>
              </div>
            </div>
            <hr />
            <div className="company-employees__inbox-item-buttons">
              <div className="btn company-employees__inbox-item-status">
                Отклонено
                <img src={require("@public/images/employee-item.svg")} alt="" />
              </div>

              <Link className="btn btn--grey" to="#">
                Добавить в компанию
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CompanyEmployeesDeclined)
);
