import React, { Suspense } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Link } from "react-router-dom";

import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";
import ErrorBoundary from "@containers/ErrorBoundary";
import PreloaderComponent from "@components/preloader/PreloaderComponent";

import * as Page from "./index";

const Networking = React.lazy(() => import("@pages/auth/networking"));

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <div className="cabinet__layout">
        <h1 className="cabinet__title">Профиль в Нетворкинге</h1>
        <div className="cabinet__layout-body">
          <ErrorBoundary>
            <Suspense fallback={<PreloaderComponent />}>
              <Switch>
                <Router
                  path="/cabinet/networking/reg"
                  component={Page.Registration}
                  exaсt={true}
                />
                <Router
                  path="/cabinet/networking/profile"
                  component={Page.Profile}
                  exaсt={true}
                />
                <Router
                  path="/cabinet/networking"
                  component={Networking}
                  exaсt={true}
                />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
