import React from "react";

interface HashtagProps {
  text: string;
}

const Hashtag = ({ text }: HashtagProps): JSX.Element => {
  return (
    <div className="w-fit tracking-tighter py-2.5 3xl:px-[34px] px-5 xl:px-8 md:py-5 md:px-6 md:text-38 xl:text-44 2xl:text-52 3xl:text-60 4xl:text-64 rounded-lg bg-white text-24 font-bold">
      <span>#{text}</span>
    </div>
  );
};

export default Hashtag;
