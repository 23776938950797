import { PreloaderPage } from "@components/preloader";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Action from "@actions/index";

interface AuthorizedRouteProps {
  children: React.ReactNode;
}

export const AuthorizedRoute = ({
  children,
}: AuthorizedRouteProps): JSX.Element => {
  const dispatch = useDispatch();
  const authStatus = useSelector((state: any) => state.Auth.status);
  const locationRef = useRef(window.location.pathname);

  useEffect(() => {
    if (authStatus === "unauthorized") {
      dispatch(Action.Auth.setPreurl(locationRef.current));
      dispatch(Action.Auth.setPreurlStatus("redirected"));
    }
  }, [authStatus, dispatch]);

  if (authStatus === "loading") {
    return <PreloaderPage />;
  }

  if (authStatus === "unauthorized") {
    return <Redirect to="/auth/signin" />;
  }

  return <>{children}</>;
};
