import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class CutawaysPage extends React.Component<PropsInterface, StateInterface> {
  constructor(props: PropsInterface) {
    super(props);

    this.getBase64 = this.getBase64.bind(this);
  }

  public componentDidMount() {
    Middleware.User.Event.counter("incoming");
    Middleware.User.Cutaway.counter("incoming");
    Middleware.Profile.load();
  }

  public getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  public render() {
    const netwDays = this.props.Store.Networking?.Days.data;
    const thisYearRating = this.props.Store.UserRating?.data?.thisYearRating;
    const authData = this.props.Store.Auth?.data;
    const profileData = this.props.Store.Profile?.data;
    const userEvent = this.props.Store.User?.Event;
    const userCutaway = this.props.Store.User?.Cutaway;

    if (!this.props.Store.Ui.Sidebar.display) {
      return <></>;
    }

    return (
      <section className="userbar">
        <div className="userbar__user">
          <div className="userbar__avatar">
            <div className="userbar__avatar-img">
              <img
                className={
                  authData?.userImage ? "" : "userbar__avatar-imgDefault"
                }
                src={
                  authData?.userImage ||
                  require("@public/images/defaultAvatarCompany.svg")
                }
                alt=""
              />
            </div>
            <div className="userbar__avatar-changer">
              <img src={require("@public/images/darkphoto.svg")} alt="" />
              <input
                type="file"
                name="avatar"
                onChange={(e) => {
                  this.getBase64(e).then((image) =>
                    Middleware.Ui.Modal.open("AvatarChanger", {
                      image,
                    })
                  );
                }}
              />
            </div>
          </div>
          {profileData?.useNetworking ||
          profileData?.networkingFillStatus === 3 ? (
            <Link to={`/user/${authData?.id}`} className="userbar__name">
              {authData?.firstName} {authData?.lastName}
            </Link>
          ) : (
            <div className="userbar__name">
              {authData?.firstName} {authData?.lastName}
            </div>
          )}
          <div className="userbar__position">{authData?.position}</div>
        </div>

        <hr />

        <nav className="usernav">
          <ul className="usernav__list">
            <li
              className={`usernav__item ${
                this.props.location.pathname.startsWith(
                  `/cabinet/events/calendar`
                )
                  ? `usernav__item--active`
                  : ``
              }`}
            >
              <Link
                className={`usernav__item-link `}
                to="/cabinet/events/calendar"
              >
                Календарь
              </Link>
            </li>

            <li
              className={`usernav__item ${
                this.props.location.pathname.startsWith(`/cabinet/rating`)
                  ? `usernav__item--active`
                  : ``
              }
              `}
            >
              <Link className="usernav__item-link" to="/cabinet/rating">
                <span>Баллы и рейтинг</span>
                {thisYearRating ? (
                  <span className="usernav__item-counter usernav__item-counter--active">
                    {thisYearRating}
                  </span>
                ) : null}
              </Link>
            </li>
            <li
              className={`usernav__item ${
                this.props.location.pathname.startsWith(`/cabinet/profile`)
                  ? `usernav__item--active`
                  : ``
              }
              `}
            >
              <Link className="usernav__item-link" to="/cabinet/profile">
                Контактные данные
              </Link>
            </li>
          </ul>
          <hr />
          <ul className="usernav__list">
            <li
              className={`usernav__item ${
                this.props.location.pathname.startsWith(`/cabinet/networking`)
                  ? `usernav__item--active`
                  : ``
              }
              `}
            >
              <Link
                className="usernav__item-link"
                to={`${
                  profileData?.useNetworking &&
                  profileData?.networkingFillStatus === 3
                    ? "/cabinet/networking/profile"
                    : profileData?.useNetworking &&
                      profileData?.networkingFillStatus < 3
                    ? "/cabinet/networking/reg"
                    : "/cabinet/networking"
                }`}
              >
                Профиль в нетворкинге
              </Link>
            </li>
            {profileData?.useNetworking &&
            profileData?.networkingFillStatus === 3 ? (
              <>
                {netwDays?.length > 0 && (
                  <li
                    className={`usernav__item ${
                      this.props.location.pathname === "/cabinet/events"
                        ? `usernav__item--active`
                        : ``
                    }`}
                  >
                    <Link
                      className={`usernav__item-link `}
                      to="/cabinet/events"
                    >
                      <span>Мои встречи</span>
                      {userEvent?.incomingCounter > 0 && (
                        <span className="usernav__item-counter usernav__item-counter--active">
                          +{userEvent.incomingCounter}
                        </span>
                      )}
                    </Link>
                  </li>
                )}

                <li
                  className={`usernav__item ${
                    this.props.location.pathname.startsWith(`/cabinet/cutaways`)
                      ? `usernav__item--active`
                      : ``
                  }`}
                >
                  <Link className="usernav__item-link" to="/cabinet/cutaways">
                    <span>Визитки</span>
                    {userCutaway?.incomingCounter > 0 && (
                      <span className="usernav__item-counter usernav__item-counter--active">
                        +{userCutaway.incomingCounter}
                      </span>
                    )}
                  </Link>
                </li>
              </>
            ) : null}
          </ul>
          {profileData?.useNetworking &&
          profileData?.networkingFillStatus === 3 ? null : (
            <ul className="usernav__list usernav__list--closed">
              {netwDays?.length ? (
                <li className="usernav__item">
                  <Link className="usernav__item-link" to="#">
                    Мои встречи
                  </Link>
                </li>
              ) : null}
              <li className="usernav__item">
                <Link className="usernav__item-link" to="#">
                  Визитки
                </Link>
              </li>
            </ul>
          )}
        </nav>

        {authData?.company ? (
          <>
            <hr />
            <div className="userbar__company">
              {this.props.location.pathname.startsWith("/cabint/profile") ? (
                ""
              ) : (
                <div className="userbar__company-title">Моя компания:</div>
              )}
              <Link
                to={
                  authData?.isAdmin
                    ? `/company/${authData?.company?.id}/edit`
                    : profileData?.companyStand
                    ? `/company/stand/${profileData?.companyStand}`
                    : `/company/${profileData?.company?.id}`
                }
                className="userbar__company-name"
              >
                {authData.company.title}
              </Link>
              <Link
                to={
                  authData?.isAdmin
                    ? `/company/${authData?.company?.id}/edit`
                    : profileData?.companyStand
                    ? `/company/stand/${profileData?.companyStand}`
                    : `/company/${profileData?.company?.id}`
                }
                className="userbar__company-img"
              >
                <img
                  src={
                    authData.company.companyImage ||
                    require("@public/images/defaultAvatarCompany.svg")
                  }
                  alt=""
                />
              </Link>
            </div>
          </>
        ) : null}
      </section>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CutawaysPage)
);
