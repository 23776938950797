import React, { ReactElement, useEffect, useState } from "react";
import { InputAutocomplete } from "@components/form";
import { Link, useHistory } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import * as Model from "@models/index";

interface IProps {
  company: any;
  profileReload?: any;
  userId: number;
  userCompanyStatus: number;
}

const Company = ({
  company,
  profileReload,
  userId,
  userCompanyStatus,
}: IProps): ReactElement => {
  const history = useHistory();
  const onSelectHandler = (event: any): void => {
    Middleware.Company.Employee.invite(event.target.value).then(
      (response: any) => {
        // profileReload();
        Middleware.Profile.load();
      }
    );
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push("/");
  };

  const companyReject = (company_id: number, user_id: number) => {
    Middleware.Company.Employee.remove(company_id, user_id).then(() => {
      new Model.Profile().setNetworkingStatus(false).then(() => {
        Middleware.Profile.load().then(() => {
          window.scrollTo(0, 0);
          history.push("/cabinet/networking");
        });
      });
    });
  };

  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);

  useEffect(() => {
    setIsCompanySelected(company?.status !== undefined && company !== null);
  }, [company]);

  return (
    <div className="profile-netw-step">
      <div className="profile-netw-step__body">
        <div className="profile-netw-step__body-header">
          <p
            className={`profile-netw-step__title ${
              isCompanySelected ? "" : "profile-netw-step__title--big"
            } `}
          >
            {isCompanySelected
              ? "Ваша компания"
              : "Присоединитесь к странице вашей компании"}
          </p>
        </div>

        {isCompanySelected ? (
          <></>
        ) : (
          <p className="profile-netw-step__desc profile-netw-step__desc--company">
            Обратите внимание: название компании может быть указано на русском
            или английском языке. Попробуйте искать в двух вариантах.
          </p>
        )}

        <div
          className={`profile-netw-step__main ${
            isCompanySelected
              ? "profile-netw-step__main--company"
              : "profile-netw-step__main--company-select"
          }`}
        >
          {company !== undefined && company !== null ? (
            <>
              <div className="profile-netw-step__company-image">
                {company?.companyImage !== null ? (
                  <img src={company?.companyImage} alt={company?.title} />
                ) : (
                  <img
                    src={require("@public/images/defaultAvatarCompany.svg")}
                  />
                )}
              </div>
              <div className="profile-netw-step__company-title">
                {company.title}
              </div>
              <div className="profile-netw-step__company-controls">
                {userCompanyStatus === 0 ? (
                  <>
                    <button
                      className="profile-netw-step__company-action"
                      onClick={() => {
                        companyReject(company?.id, userId);
                      }}
                    >
                      Отменить запрос
                    </button>
                    <div className="profile-netw-step__company-status">
                      На модерации
                    </div>
                  </>
                ) : userCompanyStatus === 1 ? (
                  <button
                    onClick={() => {
                      companyReject(company?.id, userId);
                    }}
                    className="profile-netw-step__company-action"
                  >
                    Отвязать компанию
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        companyReject(company?.id, userId);
                      }}
                      className="profile-netw-step__company-action"
                    >
                      Отвязать компанию
                    </button>
                    <div className="profile-netw-step__company-status profile-netw-step__company-status--denied">
                      Отклонена
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {" "}
              <InputAutocomplete
                name="company_id"
                type="company"
                placeholder="Введите название компании"
                onSelect={onSelectHandler}
              />
              <Link
                className="profile-netw-step__company-create-link"
                to="/company/create"
              >
                Моей компании нет в списке
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Company;
