import React, { ReactElement } from "react";
import { InputAutocomplete } from "@components/form";
import { useState } from "@helpers/Store";
import { Link } from "react-router-dom";

interface IProps {
  setNetworkingProfile: any;
  companyDirectionsList: number[];
  networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  };
  editMode: boolean;
}

const Direction = ({
  companyDirectionsList,
  networkingProfile,
  setNetworkingProfile,
  editMode,
}: IProps): ReactElement => {
  if (editMode) {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">
              Продукт какой дестинации представляете или реализуете?
            </p>
          </div>

          <p className="profile-netw-step__desc profile-netw-step__desc--small">
            Отметьте один или несколько пунктов, если вы специализируетесь на
            определенных направлениях. Если вы работаете со всеми направлениями,
            введите «Все направления» в окне поиска
          </p>
          <div className="profile-netw-step__main">
            {networkingProfile.directions.length > 0 ? (
              <div className="profile-netw-step__autocomplete-tags">
                {companyDirectionsList?.map((Item: any) => {
                  if (networkingProfile?.directions?.includes(Item.id)) {
                    return (
                      <Link
                        key={Item.id}
                        to="#"
                        className="profile-netw-step__autocomplete-tag"
                        onClick={() => {
                          const id = networkingProfile?.directions?.indexOf(
                            Item.id
                          );
                          if (id >= 0) {
                            networkingProfile?.directions?.splice(id, 1);
                          }
                          setNetworkingProfile({ ...networkingProfile });
                        }}
                      >
                        {Item.name}
                      </Link>
                    );
                  }
                })}
              </div>
            ) : (
              <></>
            )}

            <InputAutocomplete
              name="directions"
              type="custom"
              list={companyDirectionsList}
              placeholder="Выберите из списка одно или несколько значений"
              clearOnSelect={true}
              onSelect={(event: any) => {
                if (
                  !networkingProfile?.directions?.includes(
                    event.target.value
                  ) &&
                  event.target.value !== null
                ) {
                  networkingProfile?.directions?.push(event.target.value);
                }
                setNetworkingProfile({ ...networkingProfile });
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="profile-netw-step">
        <div className="profile-netw-step__body">
          <div className="profile-netw-step__body-header">
            <p className="profile-netw-step__title">
              Продукт какой дестинации представляете или реализуете?
            </p>
          </div>

          <div className="profile-netw-step__main profile-netw-step__main--item-list">
            {companyDirectionsList?.map((Item: any) => {
              return networkingProfile?.directions?.includes(Item.id) ? (
                <div key={Item.id} className="profile-netw-step__main-item">
                  {Item.name}
                </div>
              ) : (
                <></>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default Direction;
