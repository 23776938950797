import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import { Tariff } from "../Rating";

interface IProps {
  selectedTariff: Tariff;
  list: any[];
  paginator: {
    itemCount: number;
    pageCount: number;
    perPage: number;
    page: number;
  };
  pagination: any;
}

const PaginationList: React.FC<IProps> = ({
  selectedTariff,
  list,
  pagination,
  paginator,
}) => {
  const pages: number[] = [];
  const [startPaginationArray, setStartPaginationArray] = useState<number[]>(
    []
  );
  const [endPaginationArray, setEndPaginationArray] = useState<number[]>([]);

  for (let i: number = 1; i <= paginator?.pageCount; i++) {
    pages.push(i);
  }

  useEffect(() => {
    if (paginator?.page < 5 || paginator?.page > paginator?.pageCount - 5) {
      setStartPaginationArray([1, 2, 3, 4, 5]);
      setEndPaginationArray([4, 3, 2, 1, 0]);
    } else {
      setStartPaginationArray([1, 2, 3]);
      setEndPaginationArray([2, 1, 0]);
    }
  }, [paginator?.page]);

  return (
    <>
      {list?.length > 0 ? (
        <div className="rating__pagination">
          <ul className="rating__pagination-list">
            {paginator.pageCount > 9 ? (
              <>
                {startPaginationArray.map((item: number) => {
                  return (
                    <li key={item}>
                      <Link
                        to="#"
                        className={`rating__pagination-link${
                          paginator.page === item
                            ? " rating__pagination-link--active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedTariff === Tariff.Basic) {
                            Middleware.Main.Rating.pageBase(pagination, item);
                          } else if (selectedTariff === Tariff.Full) {
                            Middleware.Main.Rating.pageFull(pagination, item);
                          } else {
                            Middleware.Main.Rating.pageAll(pagination, item);
                          }
                        }}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
                <li>
                  <span className="rating__pagination-separator" />
                </li>
                {paginator.page > 4 &&
                paginator.page < paginator.pageCount - 4 ? (
                  <>
                    {[
                      paginator.page - 1,
                      paginator.page,
                      paginator.page + 1,
                    ].map((item: number) => {
                      return (
                        <li key={item}>
                          <Link
                            to="#"
                            className={`rating__pagination-link ${
                              paginator.page === item
                                ? "rating__pagination-link--active"
                                : ""
                            }`}
                            onClick={() => {
                              if (selectedTariff === Tariff.Basic) {
                                Middleware.Main.Rating.pageBase(
                                  pagination,
                                  item
                                );
                              } else if (selectedTariff === Tariff.Full) {
                                Middleware.Main.Rating.pageFull(
                                  pagination,
                                  item
                                );
                              } else {
                                Middleware.Main.Rating.pageAll(
                                  pagination,
                                  item
                                );
                              }
                            }}
                          >
                            {item}
                          </Link>
                        </li>
                      );
                    })}
                    <li>
                      <span className="rating__pagination-separator" />
                    </li>
                  </>
                ) : (
                  <>
                    {/* {[
                    Math.floor(paginator.pageCount / 2) - 1,
                    Math.floor(paginator.pageCount / 2),
                    Math.floor(paginator.pageCount / 2) + 1,
                  ].map((item: number) => {
                    return (
                      <li key={item}>
                        <Link
                          to="#"
                          className={`rating__pagination-link ${
                            paginator.page === item
                              ? "rating__pagination-link--active"
                              : ""
                          }`}
                          onClick={() => {
                            Middleware.Main.Rating.page(pagination, item);
                          }}
                        >
                          {item}
                        </Link>
                      </li>
                    );
                  })} */}
                  </>
                )}

                {endPaginationArray?.map((item: number) => {
                  return (
                    <li key={item}>
                      <Link
                        to="#"
                        className={`rating__pagination-link${
                          paginator.page === paginator.pageCount - item
                            ? " rating__pagination-link--active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedTariff === Tariff.Basic) {
                            Middleware.Main.Rating.pageBase(
                              pagination,
                              paginator.pageCount - item
                            );
                          } else if (selectedTariff === Tariff.Full) {
                            Middleware.Main.Rating.pageFull(
                              pagination,
                              paginator.pageCount - item
                            );
                          } else {
                            Middleware.Main.Rating.pageAll(
                              pagination,
                              paginator.pageCount - item
                            );
                          }
                        }}
                      >
                        {paginator.pageCount - item}
                      </Link>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {pages.map((item: number) => {
                  return (
                    <li key={item}>
                      <Link
                        to="#"
                        className={`rating__pagination-link${
                          paginator.page === item
                            ? " rating__pagination-link--active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedTariff === Tariff.Basic) {
                            Middleware.Main.Rating.pageBase(pagination, item);
                          } else if (selectedTariff === Tariff.Full) {
                            Middleware.Main.Rating.pageFull(pagination, item);
                          } else {
                            Middleware.Main.Rating.pageAll(pagination, item);
                          }
                        }}
                      >
                        {item}
                      </Link>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default PaginationList;
