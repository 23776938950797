import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class NetworkingMember {
  public static async page(page: number, filters?: any, options: { onlyLinkedToWorkshop?: number } = {}) {
    try {
      useDispatch(Action.Networking.Member.setStatus("loading"));
      const response = await new Model.Networking.Member().page(page, filters, options);
      useDispatch(Action.Networking.Member.clear());
      useDispatch(Action.Networking.Member.setList(response.data));
      useDispatch(Action.Networking.Member.setPaginator(response.paginator));
      useDispatch(Action.Networking.Member.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async addPage(page: number, filters?: any) {
    try {
      useDispatch(Action.Networking.Member.setStatus("loading"));
      const response = await new Model.Networking.Member().page(page, filters);
      useDispatch(Action.Networking.Member.setList(response.data));
      useDispatch(Action.Networking.Member.setPaginator(response.paginator));
      useDispatch(Action.Networking.Member.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async clear() {
    useDispatch(Action.Networking.Member.setStatus("waiting"));
    useDispatch(Action.Networking.Member.clear());
  }
}
