/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Search extends ModelPrototype {
  public async list(query: string): Promise<any> {
    try {
      return this.withUrl(`networking/search?text=${query}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
