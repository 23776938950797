import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class MainUser {
  /**
   * @return function(Dispatch)->Country.list
   */
  public static async count() {
    try {
      const response = await new Model.Main.User().count();
      useDispatch(Action.Main.User.setCount(response.data));
      return response.data;
    } catch (Exception) {}
  }
}
