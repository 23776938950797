import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  onSubmit: any;
}

interface StateInterface {
  duration: number;
}

// onSubmit;

class Step1 extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      duration: 0,
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onCheckHandler = this.onCheckHandler.bind(this);
  }

  private onSubmitHandler(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    this.props.onSubmit({
      duration: this.state.duration,
      step: 2,
    });
  }

  private onCheckHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ [event.target.name]: Number(event.target.value) });
  }

  public render() {
    return (
      <>
        <form className="event-create-step-1" onSubmit={this.onSubmitHandler}>
          <div className="event-create-step-1__content">
            <div className="event-create-step-1__title">
              Шаг 1 <span>/ 3</span>
            </div>
            <div className="event-create-step-1__desc">Выберите продолжительность встречи</div>
          </div>
          <div className="event-create-step-1__time-buttons">
            <div className="radio">
              <input
                className="visually-hidden"
                type="radio"
                name="duration"
                value={15}
                id="timerange-1"
                required={true}
                checked={this.state.duration === 15}
                onChange={this.onCheckHandler}
              />
              <label htmlFor="timerange-1">15 минут</label>
            </div>
            <div className="radio">
              <input
                className="visually-hidden"
                type="radio"
                name="duration"
                value={30}
                id="timerange-2"
                required={true}
                checked={this.state.duration === 30}
                onChange={this.onCheckHandler}
              />
              <label htmlFor="timerange-2">30 минут</label>
            </div>
            <div className="radio">
              <input
                className="visually-hidden"
                type="radio"
                name="duration"
                value={60}
                id="timerange-3"
                required={true}
                checked={this.state.duration === 60}
                onChange={this.onCheckHandler}
              />
              <label htmlFor="timerange-3">60 минут</label>
            </div>
          </div>
          <div className="event-create-step-1__buttons">
            <button
              className={`btn btn--red ${this.state.duration === 0 ? "disabled" : ""}`}
            >
              Далее
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Step1)
);
