import React, { Ref } from "react";
import * as Type from "@interface/index";
import Clickoutside from "@helpers/Clickoutside";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";

interface PropsInterface extends Type.ComponentInterface {
  name: string;
  value: any[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (data: any) => void;
}

interface StateInterface {
  show: boolean;
  value: any[];
}

export default Connector(
  class InputCompanyType extends React.Component<
    PropsInterface,
    StateInterface
  > {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        show: false,
        value: this.props.value || [],
      };
    }

    public componentDidMount() {
      Middleware.Company.Type.load();
    }

    public componentDidUpdate(props: PropsInterface) {
      if (this.props.value !== props.value) {
        const value = this.props.value;
        value.sort((a, b) => a - b);
        this.setState({ value });
      }
    }

    private renderItems(Group: any) {
      const items: JSX.Element[] = [];
      Group.itemList.map((Item: any, index: number) => {
        items.push(
          <div className="auth__dropdown-item" key={index}>
            <input
              tabIndex={0}
              className="visually-hidden"
              type="checkbox"
              name="dd1"
              id="dd1"
              value={Item.id}
              checked={this.state.value.includes(Item.id)}
              readOnly
            />
            <label
              htmlFor="dd1"
              onClick={(e) => {
                const selected = this.state.value || [];
                if (selected.includes(Item.id)) {
                  const i = selected.indexOf(Item.id);
                  selected.splice(i, 1);
                } else {
                  selected.push(Item.id);
                }
                selected.sort((a, b) => a - b);
                if (this.props.onChange) {
                  this.setState({ value: selected }, () => {
                    if (this.props.onChange) {
                      this.props.onChange({
                        target: { name: this.props.name, value: selected },
                      });
                    }
                  });
                }
              }}
            >
              {Item.name}
            </label>
          </div>
        );
      });
      return items;
    }

    public render() {
      return (
        <>
          <Clickoutside clickOutside={() => this.setState({ show: false })}>
            <label className="visually-hidden" htmlFor="companytype">
              Тип компании
            </label>

            <div
              className={`input-dd ${
                !this.state.show ? "input-dd--close" : ""
              }`}
            >
              <label
                htmlFor="companyType"
                className={`input-dd__trigger ${
                  this.props.disabled ? "disabled" : ""
                }`}
                onClick={() => {
                  if (!this.props.disabled) {
                    this.setState({ show: !this.state.show });
                  }
                }}
              >
                <input
                  type="text"
                  name="companyTypeCustomInput"
                  id="companyTypeCustomInput"
                  value={this.state.value.join()}
                  className="visually-hidden"
                  required={this.props.required}
                  onFocus={() => this.setState({ show: true })}
                  // onBlur={() => this.setState({ show: false })}
                  style={{
                    width: "100% !important",
                    height: "2.5em !important",
                  }}
                  readOnly
                />
                <span>Тип компании{this.props.required ? "*" : ""}: </span>{" "}
                {this.props.Store.Company.Type?.list
                  .filter((Type) => this.state.value.includes(Type.id))
                  .map((Type: any, index: number) => {
                    return (
                      <span key={Type.id}>
                        {(index !== 0 ? ", " : "") + Type.name}
                      </span>
                    );
                  })}
                {/* {this.state.items.join(", ")} */}
              </label>
              <div className="input-dd__main">
                {this.props.Store.Company.Type?.groups.map(
                  (Group: any, index: number) => {
                    if (Group.id !== 0) {
                      return (
                        <div className="auth__dropdown-group" key={index}>
                          <div className="auth__dropdown-group-title">
                            {Group.name}
                          </div>
                          {this.renderItems(Group)}
                        </div>
                      );
                    } else {
                      return this.renderItems(Group);
                    }
                  }
                )}
              </div>
            </div>
          </Clickoutside>
        </>
      );
    }
  }
);
