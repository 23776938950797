import React from "react";
import * as Type from "@interface/index";
import Clickoutside from "@helpers/Clickoutside";
import Connector from "@helpers/Connector";
import { Link } from "react-router-dom";
import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";
import md5 from "@helpers/MD5";

interface PropsInterface extends Type.ComponentInterface {
  name: string;
  value: string;
  type: string;
  selected?: number;
  placeholder?: string;
  autoComplete?: boolean;
  country_id?: number;
  required?: boolean;
  list?: any[];
  clearOnSelect?: boolean;
  searchIcon?: boolean;
  withCompanyStatus?: boolean;
  onChange?: (data: any) => void;
  onSelect?: (data: any) => void;
  disabled?: boolean;
}

interface StateInterface {
  show: boolean;
  value: string;
  selected: any;
  list: any[];
  suggestionsLoading: boolean;
  hash: string;
  isFocused: boolean;
}

export default Connector(
  class InputAutocomplete extends React.Component<
    PropsInterface,
    StateInterface
  > {
    private suggestionTimeout: any;
    private _isMounted = false;
    componentWillUnmount() {
      this._isMounted = false;
    }

    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        show: false,
        value: this.props.value || "",
        selected: null,
        list: [],
        suggestionsLoading: false,
        hash: md5(
          props.name + "hash-for-disable-autocomplete" + new Date().getTime()
        ),
        isFocused: false,
      };

      this.onChangeHandler = this.onChangeHandler.bind(this);
      this.getItemName = this.getItemName.bind(this);
    }
    public static renderContainer() {}

    public async suggest(text: string) {
      if (text?.length >= 3) {
        switch (this.props.type) {
          case "company":
            if (this.state.suggestionsLoading) {
              clearTimeout(this.suggestionTimeout);
              this.suggestionTimeout = setTimeout(
                (text) => this.suggest(text),
                1000
              );
            } else {
              this.setState({ suggestionsLoading: true }, async () => {
                const companies: any = await new Model.Company().suggest({
                  text,
                });
                this.setState({
                  list: companies.data,
                  suggestionsLoading: false,
                });
              });
            }
            break;
          case "user":
            if (this.state.suggestionsLoading) {
              clearTimeout(this.suggestionTimeout);
              this.suggestionTimeout = setTimeout(
                (text) => this.suggest(text),
                1000
              );
            } else {
              this.setState({ suggestionsLoading: true }, async () => {
                const users: any = await new Model.User().find({ text });
                this.setState({
                  list: users.data,
                  suggestionsLoading: false,
                });
              });
            }
            break;
          case "city":
            if (this.state.suggestionsLoading) {
              clearTimeout(this.suggestionTimeout);
              this.suggestionTimeout = setTimeout(
                () => this.suggest(text),
                1000
              );
            } else {
              if (this._isMounted) {
                this.setState({ suggestionsLoading: true });
                const cities = await new Model.Location.City().suggest(
                  Number(this.props.country_id),
                  {
                    text,
                  }
                );
                this.setState({
                  list: cities.data,
                });
                this.setState({
                  suggestionsLoading: false,
                });
              }
            }
            break;
        }
      }
    }

    private getItemName(Item: any) {
      switch (this.props.type) {
        case "city":
          return (
            (Item.region !== null ? Item.region + ", " : "") +
            (Item.area !== null ? Item.area + ", " : "") +
            Item.name
          );
        case "user":
          return `${Item.firstName} ${Item.lastName}`;
        default:
          return Item.name;
      }
    }

    public componentDidUpdate(props: PropsInterface) {
      if (this.props.value !== props.value && this.props.value !== undefined) {
        this.setState({ value: this.props.value });
      }

      if (
        this.props.selected !== props.selected &&
        this.props.selected !== undefined
      ) {
        this.selectFromParentHandler();
      }
    }

    public componentDidMount() {
      this._isMounted = true;
      if (this.props.selected !== undefined) {
        this.selectFromParentHandler();
      }
    }

    private async selectFromParentHandler() {
      if (this.props.selected !== null && this.props.selected !== undefined) {
        if (
          this.state.selected === null ||
          this.state.selected.id !== this.props.selected
        ) {
          const item = this.state.list.find(
            (Item: any) => Item.id === this.props.selected
          );
          if (item) {
            this.setState({ selected: item, value: item.name, show: false });
          } else {
            switch (this.props.type) {
              case "company":
                const Company = await Middleware.Company.Current.load(
                  this.props.selected
                );
                if (Company) {
                  this.setState({
                    selected: Company,
                    value: Company.title,
                    show: false,
                  });
                }
                break;
              case "city":
                const City = await Middleware.Location.City.get(
                  this.props.selected
                );
                if (City) {
                  this.setState({
                    selected: City,
                    value: City.name,
                    show: false,
                  });
                }
                break;
            }
          }
        }
      }
    }

    private onChangeHandler(event: any) {
      const target = event.target;
      this.setState({ value: target.value, selected: null }, () =>
        this.suggest(target.value)
      );
      if (this.props.onChange) {
        this.props.onChange(event);
      }
    }

    public render() {
      const { isFocused } = this.state;

      return (
        <>
          <Clickoutside clickOutside={() => this.setState({ show: false })}>
            <div
              className={`input-ac ${
                this.props.required &&
                this.state.value === "" &&
                !isFocused &&
                "required-input"
              } ${
                this.state.show &&
                (this.props.type === "custom"
                  ? this.props.list?.filter((Item: any) =>
                      Item.name
                        .toLowerCase()
                        .includes(this.state.value.toLowerCase())
                    ) || []
                  : this.state.list
                ).length
                  ? "input-ac--open"
                  : ""
              } ${this.props.searchIcon ? "input-ac--search" : ""}
              ${
                this.props.withCompanyStatus ? "input-ac--company-status" : ""
              }`}
            >
              <label htmlFor={this.state.hash}>
                <input
                  className={`input-ac__input`}
                  autoComplete={this.state.hash}
                  type="text"
                  name={this.state.hash}
                  id={this.state.hash}
                  value={this.state.value}
                  onChange={this.onChangeHandler}
                  disabled={this.props.disabled}
                  onBlur={(event) => {
                    if (
                      !this.state.show &&
                      this.state.selected === null &&
                      this.props.type !== "company"
                    ) {
                      this.setState({ value: "", isFocused: false });
                      if (this.props.onChange) {
                        this.props.onChange({
                          target: { name: this.props.name, value: "" },
                        });
                      }
                      if (this.props.onSelect) {
                        this.props.onSelect({
                          target: {
                            name: this.props.name,
                            value: null,
                            ...(this.props.withCompanyStatus
                              ? {
                                  networkingStatus: Number(
                                    Item.is_has_networking_profile
                                  ),
                                }
                              : {}),
                          },
                        });
                      }
                    } else {
                      this.setState({ isFocused: false });
                    }
                  }}
                  placeholder={
                    (this.props.required ? " " : "") + this.props.placeholder
                  }
                  onFocus={() => {
                    this.setState({ show: true, isFocused: true });
                  }}
                  required={this.props.required}
                />

                <ul className="input-ac__dropdown">
                  {(this.props.type === "custom"
                    ? this.props.list?.filter((Item: any) =>
                        Item.name
                          .toLocaleLowerCase()
                          .includes(this.state.value.toLocaleLowerCase())
                      ) || []
                    : this.state.list
                  )
                    .filter((Item) => Item.isPriority)
                    .map((Item: any) => {
                      return (
                        <li className="input-ac__item" key={Item.id}>
                          <Link
                            to="#"
                            onClick={() => {
                              this.setState(
                                this.props.clearOnSelect
                                  ? { selected: null, value: "", show: false }
                                  : {
                                      selected: Item,
                                      value: Item.name,
                                      show: false,
                                    },
                                () => {
                                  if (this.props.onChange) {
                                    this.props.onChange({
                                      target: {
                                        name: this.props.name,
                                        value: Item.name,
                                      },
                                    });
                                  }
                                  if (this.props.onSelect) {
                                    this.props.onSelect({
                                      target: {
                                        name: this.props.name,
                                        value: Item.id,
                                        ...(this.props.withCompanyStatus
                                          ? {
                                              networkingStatus: Number(
                                                Item.is_has_networking_profile
                                              ),
                                            }
                                          : {}),
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            <b>{this.getItemName(Item)}</b>
                            {this.props.withCompanyStatus ? (
                              !Item.is_has_networking_profile &&
                              !Item.is_current_workshop_use ? (
                                <span>Создать</span>
                              ) : Item.is_has_networking_profile &&
                                !Item.is_current_workshop_use ? (
                                <span>Управлять</span>
                              ) : (
                                <span>Присоединиться</span>
                              )
                            ) : (
                              <></>
                            )}
                          </Link>
                        </li>
                      );
                    })}
                  {(this.props.type === "custom"
                    ? this.props.list?.filter((Item: any) =>
                        Item.name
                          .toLocaleLowerCase()
                          .includes(this.state.value.toLocaleLowerCase())
                      ) || []
                    : this.state.list
                  )
                    .filter((Item) => !Item.isPriority)
                    .map((Item: any) => {
                      return (
                        <li className="input-ac__item" key={Item.id}>
                          <Link
                            to="#"
                            onClick={() => {
                              this.setState(
                                this.props.clearOnSelect
                                  ? { selected: null, value: "", show: false }
                                  : {
                                      selected: Item,
                                      value: Item.name,
                                      show: false,
                                    },
                                () => {
                                  if (this.props.onChange) {
                                    this.props.onChange({
                                      target: {
                                        name: this.props.name,
                                        value: Item.name,
                                      },
                                    });
                                  }
                                  if (this.props.onSelect) {
                                    this.props.onSelect({
                                      target: {
                                        name: this.props.name,
                                        value: Item.id,
                                        title: Item.name,
                                        ...(this.props.withCompanyStatus
                                          ? {
                                              networkingStatus: Boolean(
                                                Item.is_has_networking_profile
                                              ),
                                              currentWorkshopStatus: Boolean(
                                                Item.is_current_workshop_use
                                              ),
                                            }
                                          : {}),
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                          >
                            {this.getItemName(Item)}
                            {this.props.withCompanyStatus ? (
                              !Item.is_has_networking_profile &&
                              !Item.is_current_workshop_use ? (
                                <span>Создать</span>
                              ) : Item.is_has_networking_profile &&
                                !Item.is_current_workshop_use ? (
                                <span>Управлять</span>
                              ) : (
                                <span>Присоединиться</span>
                              )
                            ) : (
                              <></>
                            )}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </label>
            </div>
          </Clickoutside>
        </>
      );
    }
  }
);
