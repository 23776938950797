import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class Title extends React.Component<PropsInterface, StateInterface> {
  public typeSwitch(type: number) {
    switch (type) {
      case 0:
        return "Стенд выставки";
      case 1:
        return "Топ-стенд выставки";
      case 2:
        return "Партнер выставки";
      case 3:
        return "Генеральный партнер выставки";
    }
  }

  public render() {
    return (
      <>
        <div
          className={`stand-title ${
            this.props.Store.Stand.Selected.data?.type === 1 ? `stand-title--gold` : <></>
          } stand__block`}
        >
          {this.props.Store.Stand.Selected.data?.type === 1 ? (
            <div className="stand-title__bg">
              <img src={this.props.Store.Stand.Selected.data?.mainImage} alt="" />
            </div>
          ) : (
            <></>
          )}

          <div className="stand-title__logo">
            {this.props.Store.Stand.Selected.data?.companyImage === null ? (
              <img src={require("@public/images/defaultAvatarCompany.svg")} alt="" />
            ) : (
              <img src={this.props.Store.Stand.Selected.data?.companyImage} alt="" />
            )}
          </div>
          <div className="stand-title__content">
            <h1 className="stand-title__title">{this.props.Store.Stand.Selected.data?.title}</h1>
            <div className="stand-title__type">
              {this.typeSwitch(this.props.Store.Stand.Selected.data?.type)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Title)
);
