import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {
  showedPartners: number;
}

class Partners extends React.Component<PropsInterface, StateInterface> {
  constructor(Props: PropsInterface) {
    super(Props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
      showedPartners: 8,
    };
  }
  public render() {
    return (
      <>
        {this.props.data.length > 0 ? (
          <>
            <div className="stand-partners">
              <div className="stand-partners__title">{this.props.title}</div>
              <div className="stand-partners__grid">
                {this.props.data.map((User: any, index: number) => {
                  return (
                    <>
                      {index < this.state.showedPartners ? (
                        <>
                          <a
                            href={User.url}
                            target="_blank"
                            key={index}
                            className="stand-partners__item"
                          >
                            <div className="stand-partners__item-logo">
                              {User.image ? (
                                <img src={User.image} alt="" />
                              ) : (
                                <img
                                  src={require("@public/images/defaultAvatarCompany.svg")}
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="stand-partners__item-title">{User.title}</div>
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              {this.state.showedPartners < this.props.data.length ? (
                <>
                  <Link
                    to="#"
                    onClick={() => {
                      this.setState({ showedPartners: this.props.data.length });
                    }}
                    className="stand-partners__more-link"
                  >
                    Показать все сайты
                    <img src={require("@public/images/stand-arrow.svg")} alt="" />
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Partners)
);
