export function userCutawayStatus(User: any) {
  switch (User?.cutaway?.status) {
    case 0:
    case 1:
      return "btn--cutaway-disabled";
    case 2:
      return "btn--cutaway-rejected";
    default:
      return "btn--cutaway-enabled";
  }
}
