import React from "react";

export const TariffDowngradeModal = ({
  handlerOnClose,
  newTariffName,
}: {
  handlerOnClose: () => void;
  newTariffName: string;
}): JSX.Element => {
  return (
    <div
      className="modal-background"
      onClick={() => {
        handlerOnClose();
      }}
    >
      <div
        className="modal-wrap"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="modal__close"
          onClick={() => {
            handlerOnClose();
          }}
        />
        <div className="modal modal--content">
          {/* Этот пустой div чтобы выровнять модалку, т.к. стиль не расчитан на одну строчку, некрасиво выходит */}
          <div className="modal__body" />
          <div className="modal__body">
            Чтобы подключить тариф {newTariffName} сделайте возврат оплаты и
            купите тариф {newTariffName}
          </div>
          <button onClick={() => handlerOnClose()} className="btn btn--red">
            Ясно!
          </button>
        </div>
      </div>
    </div>
  );
};
