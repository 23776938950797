/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Employee extends ModelPrototype {
  public async list(company_id: number): Promise<any> {
    try {
      return this.withUrl(`company/${company_id}/employee/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async remove(company_id: number, data: { user_id: number }): Promise<any> {
    try {
      return this.withUrl(`company/${company_id}/employee/remove`)
        .setMethod(Method.POST)
        .request({ userId: data.user_id });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async invite(company_id: number): Promise<any> {
    try {
      return this.withUrl(`company/${company_id}/employee/request`)
        .setMethod(Method.POST)
        .request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async reject(data: any): Promise<any> {
    try {
      return this.withUrl(`company/employee/request/set_status`)
        .setMethod(Method.POST)
        .request({ ...data, statusId: 2 });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async accept(data: any): Promise<any> {
    try {
      return this.withUrl(`company/employee/request/set_status`)
        .setMethod(Method.POST)
        .request({ ...data, statusId: 1 });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async setIsAdmin(data: any): Promise<any> {
    try {
      return this.withUrl(`company/employee/request/set_status`)
        .setMethod(Method.POST)
        .request(data);
    } catch (Exception) {
      throw Exception;
    }
  }
}
