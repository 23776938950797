import { ITariff } from "@pages/dashboard/components/Price";
import { useSelector } from "react-redux";

export const useCurrentTariff = (): ITariff | null => {
  const currentTariff = useSelector(
    (state: any) => state.Profile?.data?.tariff
  );
  const tariffs: ITariff[] = useSelector(
    (state: any) => state.Main?.Workshop?.data?.tariffs
  );

  if (!currentTariff || !tariffs.length) {
    return null;
  }
  return tariffs.find((tariff) => tariff.id === currentTariff) || null;
};
