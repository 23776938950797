import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Type from "@interface/index";
import * as Model from "@models/index";

interface PropsInterface extends Type.ComponentInterface {
  User: {
    id: number;
    name: string;
    avatar: string;
    email: string;
    moderator: boolean;
  };
}

interface StateInterface {
  token: string;
}

declare var JitsiMeetExternalAPI: any;

class Player extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      token: "",
    };
  }

  public componentDidMount() {
    new Model.Jaas()
      .generateToken(`event-${this.props.match.params.event_id}`, this.props.User)
      .then((data) => {
        this.setState({ token: data }, () => {
          this.loadRemoteJaasClientLib();
        });
      });
  }

  private loadRemoteJaasClientLib() {
    console.log("LOAD JAAS LIB");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://jitsi.profi.travel/external_api.js";
    document.getElementsByTagName("head")[0].appendChild(script);
    script.onload = () => {
      setTimeout(() => this.initializeJaas(), 2000);
    };
  }

  private initializeJaas() {
    console.log("INITIALIZE JAAS");
    const Jaas = new JitsiMeetExternalAPI("jitsi.profi.travel", {
      roomName: `profi-travel-conference-prod/event-${this.props.match.params.event_id}`,
      parentNode: document.querySelector("#jaas-container"),
      jwt: this.state.token,
      configOverwrite: {
        requireDisplayName: false,
        enableWelcomePage: false,
        disableProfile: true,
        enableFeaturesBasedOnToken: true,
        prejoinPageEnabled: false,
        toolbarButtons: [
          "microphone",
          "camera",
          // "closedcaptions",
          "desktop",
          "embedmeeting",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          // "chat",
          "recording",
          // "livestreaming",
          "etherpad",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          // "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "select-background",
          // "download",
          "help",
          "mute-everyone",
          "mute-video-everyone",
          "security",
        ],
      },
      interfaceConfigOverwrite: {
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          // "closedcaptions",
          "desktop",
          "embedmeeting",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          // "chat",
          "recording",
          // "livestreaming",
          "etherpad",
          "sharedvideo",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          // "feedback",
          "stats",
          "shortcuts",
          "tileview",
          "select-background",
          // "download",
          "help",
          "mute-everyone",
          "mute-video-everyone",
          "security",
        ],
        MOBILE_APP_PROMO: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
      },
    });
    console.log(Jaas);
  }

  public render() {
    return (
      <>
        <div className="video">
          <div id="jaas-container" />
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Player)
);
