import React from "react";
import { Link, Switch } from "react-router-dom";

import * as Type from "@interface/index";
import Router from "@helpers/router";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";

import * as Component from "./components";
import Style from "./Cutaways.module.scss";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

export default Connector(
  class CutawayListPage extends React.Component<
    PropsInterface,
    StateInterface
  > {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        filters: "",
      };
    }

    public componentDidMount() {
      Middleware.User.Cutaway.list();
      Middleware.User.Cutaway.incoming();
      Middleware.User.Cutaway.outgoing();
      Middleware.Company.Type.load();
      Middleware.Company.Specialization.load();
      Middleware.Company.Direction.load();
    }

    public render() {
      const incomingCutaway = this.props.Store.User.Cutaway.incomings;
      const outgoingCutaway = this.props.Store.User.Cutaway.outgoings;
      const savedCutaway = this.props.Store.User.Cutaway.list;

      return (
        <div className="cabinet__layout">
          <h1 className="cabinet__title">Визитки</h1>
          <div className="cabinet__layout-body">
            <div className="cabinet__layout-col cabinet__layout-col--gap0">
              <nav className={Style.navigation}>
                <div className={Style.navigationLeft}>
                  <Link
                    to="/cabinet/cutaways/incoming"
                    className={`${Style.navigationBtn} ${
                      this.props.location.pathname.endsWith(
                        `/cabinet/cutaways/incoming`
                      )
                        ? Style.navigationBtnActive
                        : ""
                    }`}
                  >
                    Входящие
                    {incomingCutaway?.filter(
                      (Cutaway: any) => Cutaway.status === 0
                    ).length > 0 && (
                      <span className={Style.navigationCount}>
                        {
                          incomingCutaway?.filter(
                            (Cutaway: any) => Cutaway.status === 0
                          ).length
                        }
                      </span>
                    )}
                  </Link>

                  <Link
                    to="/cabinet/cutaways/outgoing"
                    className={`${Style.navigationBtn} ${
                      this.props.location.pathname.endsWith(
                        `/cabinet/cutaways/outgoing`
                      )
                        ? Style.navigationBtnActive
                        : ""
                    }`}
                  >
                    Отправленные
                    {outgoingCutaway?.length > 0 && (
                      <span className={Style.navigationCount}>
                        {outgoingCutaway?.length}
                      </span>
                    )}
                  </Link>
                </div>
                <Link
                  to="/cabinet/cutaways"
                  className={`${Style.navigationBtn} ${
                    this.props.location.pathname.endsWith(`/cabinet/cutaways`)
                      ? Style.navigationBtnActive
                      : ""
                  }`}
                >
                  Сохраненные
                  {savedCutaway?.length > 0 && (
                    <span className={Style.navigationCount}>
                      {savedCutaway?.length}
                    </span>
                  )}
                </Link>
              </nav>
              <Switch>
                <Router
                  path="/cabinet/cutaways"
                  component={Component.Default}
                  exact={true}
                />
                <Router
                  path="/cabinet/cutaways/incoming"
                  component={Component.Ingoing}
                />
                <Router
                  path="/cabinet/cutaways/outgoing"
                  component={Component.Outgoing}
                />
              </Switch>
            </div>
          </div>
        </div>
      );
    }
  }
);
