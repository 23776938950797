import React from "react";
import { Link } from "react-router-dom";

import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";
import PreloaderComponent from "@components/preloader/PreloaderComponent";

import Style from "../Cutaways.module.scss";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

export default Connector(
  class CutawayListPage extends React.Component<
    PropsInterface,
    StateInterface
  > {
    public render() {
      const userCutaway = this.props.Store.User.Cutaway;

      return (
        <div className={Style.cutaways}>
          {userCutaway.status !== "loaded" ? (
            <div className={Style.message}>
              <PreloaderComponent />
            </div>
          ) : userCutaway.incomings?.length ? (
            <div className={`${Style.message} ${Style.messageWarning}`}>
              <div className={Style.messageWrapper}>
                <div className={Style.messageCol}>
                  <div className="text-red">Внимание!</div>
                </div>
                <div className={Style.messageCol}>
                  <div className={Style.messageText}>
                    Принимая запрос, вы автоматически делитесь вашей визиткой с
                    этим этим контактом.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={Style.message}>
              <div className={Style.messageCol}>
                <div className={Style.messageTitle}>Пока тут ничего нет</div>
                <div className={Style.messageText}>
                  Вам пока никто не отправил визитку. Будьте первыми.{" "}
                  <Link to="/networking" className={Style.messageLink}>
                    Перейти в Нетворкинг
                  </Link>
                </div>
              </div>
            </div>
          )}
          {userCutaway.incomings?.length > 0 &&
            userCutaway.incomings.map((Cutaway: any) => {
              return (
                <div className={Style.cutaway_inbox} key={Cutaway.id}>
                  <div className={Style.persona}>
                    <figure className={Style.personaPhoto}>
                      {Cutaway.data?.userImage ? (
                        <img src={Cutaway.data?.userImage || ""} alt="" />
                      ) : (
                        <div className={Style.personaBgAvatar} />
                      )}
                    </figure>
                    <div className={Style.personaName}>
                      <div>{Cutaway.data?.fullName}</div>
                    </div>
                    <div className={Style.personaPosition}>
                      {Cutaway.data?.position}
                      {Cutaway.data?.companyTitle ? (
                        <>, {Cutaway.data?.companyTitle}</>
                      ) : (
                        <>, Фрилансер</>
                      )}
                    </div>
                  </div>
                  <div className={Style.company}>
                    {Cutaway.data?.companyTitle && (
                      <div className={Style.companyImg}>
                        <img
                          src={
                            Cutaway.data?.companyImage ||
                            require("@public/images/defaultAvatarCompany.svg")
                          }
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  <div className={Style.buttons}>
                    {Cutaway.status === 0 ? (
                      <>
                        <Link
                          to="#"
                          className={`${Style.btn} btn btn--red`}
                          onClick={async () => {
                            try {
                              await Middleware.User.Cutaway.accept(Cutaway.id);
                              await Middleware.User.Cutaway.counter("incoming");
                            } catch (Exception) {
                              throw Exception;
                            }
                          }}
                        >
                          Принять
                        </Link>
                        <Link
                          to="#"
                          className={`${Style.btn} btn btn--dark-grey`}
                          onClick={async () => {
                            try {
                              await Middleware.User.Cutaway.reject(Cutaway.id);
                              await Middleware.User.Cutaway.counter("incoming");
                            } catch (Exception) {
                              throw Exception;
                            }
                          }}
                        >
                          Отклонить
                        </Link>
                      </>
                    ) : (
                      <Link
                        to="#"
                        className={`${Style.btn} btn btn--transparent-c0`}
                      >
                        {Cutaway.status === 1 ? "Получена" : "Отклонено"}
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
  }
);
