import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Stand extends ModelPrototype {
  public async get(stand_id: number): Promise<any> {
    try {
      return this.withUrl(`company/stand/${stand_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async count(): Promise<any> {
    try {
      return this.withUrl(`company/stand/count`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async directions(): Promise<any> {
    try {
      return this.withUrl(`tag/direction/list?standUsage=1`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async types(): Promise<any> {
    try {
      return this.withUrl(`tag/scope/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async specializations(): Promise<any> {
    try {
      return this.withUrl(`tag/specialization/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
