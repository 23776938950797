import React, { ReactElement, useEffect, useState } from "react";

import * as Middleware from "@middlewares/index";
import PreloaderPage from "@components/preloader/PreloaderPage";

const App = ({ children }: { children: any }) => {
  const [pingpong, setPingpong] = useState<boolean>(false);

  useEffect(() => {
    Middleware.Pingpong.ping().then((data) => {
      console.log(data);
      setPingpong(true);
    });
  }, []);

  if (pingpong === true) {
    return children;
  }

  return <PreloaderPage />;
};

export default App;
