import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class CompanyBar extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <section className="company-bar">
          <div className="company-bar__header">
            <div className="company-bar__img">
              <img src={this.props.Store.Auth.data?.company?.companyImage} alt="" />
            </div>
            <div className="company-bar__title">
              <div className="company-bar__company-name">
                {this.props.Store.Auth.data?.company?.title}
              </div>
              {/* <div className="company-bar__company-desc">Принимающий туроператор (DMO)</div> */}
            </div>
          </div>
          <hr />
          <ul className="company-bar__nav-list">
            <li className="company-bar__nav-item">
              <Link className="company-bar__nav-item-link" to="#">
                <span>Заявки на тур</span>
                <div className="company-bar__nav-item-counter company-bar__nav-item-counter--active">
                  +12
                </div>
              </Link>
            </li>
            <li className="company-bar__nav-item">
              <Link className="company-bar__nav-item-link" to="/company/cabinet/employees/inbox">
                <span>Модерация сотрудников</span>
                <div className="company-bar__nav-item-counter">242</div>
              </Link>
            </li>
            <li className="company-bar__nav-item">
              <Link className="company-bar__nav-item-link" to="/company/cabinet/edit">
                Редактировать страницу
              </Link>
            </li>
          </ul>
          <hr />
          <div className="company-bar__time">
            23 декабря, 14:09
            <br />
            Москва - UTC +3
          </div>
          <hr />
          <Link to="/company/cabinet" className="company-bar__company-link">
            Перейти на страницу компании
          </Link>
        </section>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CompanyBar)
);
