import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Cutaway extends ModelPrototype {
  public async count(): Promise<any> {
    try {
      return this.withUrl(`cutaway/count`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
