import React from "react";

interface PropsInterface {
  name: string;
  required?: boolean;
  value?: number;
  options: { id: number; name: string; isPriority?: boolean }[];
  onChange?: (event: any) => any;
  placeholder?: string;
  disabled?: boolean;
}

interface StateInterface {
  value: number | null;
}

export default class Select extends React.Component<
  PropsInterface,
  StateInterface
> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      value: this.props.value || null,
    };
  }

  public componentDidMount() {
    if (this.props.value !== undefined) {
      this.setState({ value: this.props.value });
    }
  }

  public componentDidUpdate(props: PropsInterface) {
    if (this.props.value !== props.value) {
      this.setState({ value: this.props.value });
    }
  }

  public render() {
    return (
      <>
        <div className="select">
          <label className="visually-hidden" htmlFor={this.props.name}>
            Ваша страна *
          </label>
          <div className="select__wrapper">
            <select
              className="select__placeholder"
              name={this.props.name}
              id={this.props.name}
              value={this.props.value || 0}
              required={
                this.props.required !== undefined ? this.props.required : false
              }
              onChange={(event: any) => {
                this.setState({ value: Number(event.target.value) }, () => {
                  if (this.props.onChange) {
                    this.props.onChange({
                      target: {
                        value: this.state.value,
                        name: this.props.name,
                      },
                    });
                  }
                });
              }}
              disabled={this.props.disabled}
            >
              <option value={0} disabled hidden>
                {(this.props.required ? "*" : "") + this.props.placeholder}
              </option>
              {this.props.options
                .filter((Item) => Item.isPriority)
                .map((option: { id: number; name: string }, index: number) => {
                  return (
                    <option className="bold" key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
              {this.props.options
                .filter((Item) => !Item.isPriority)
                .map((option: { id: number; name: string }, index: number) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </>
    );
  }
}
