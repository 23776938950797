import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Schedule extends ModelPrototype {
  public async list(): Promise<any> {
    try {
      return this.withUrl(`main/schedule/section/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async section(section_id: number): Promise<any> {
    try {
      return this.withUrl(`main/schedule/section/${section_id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
