import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch } from "react-router-dom";
import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Page from "./index";

class RouterComponent extends React.Component<ComponentInterface> {
  public render() {
    return (
      <Switch>
        <Router path="/cabinet/cutaways" component={Page._list.default} />
        {/* <Router path="/cabinet/cutaways/requests" exact={true} component={Page._requests.default} />
        <Router path="/cabinet/cutaway/:cutaway_id" component={Page._show.default} /> */}
      </Switch>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: any) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
