import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class RatingPage extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <h1>Чаты компании</h1>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RatingPage)
);
