import axios from "axios";
import Cookie from "@helpers/Cookie";
// import Tokens from "@helpers/Tokens";
import { useDispatch } from "@helpers/Store";
import * as Action from "@actions/index";

export enum Method {
  GET = 0,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export default abstract class ModelPrototype {
  protected Method: Method = Method.GET;
  protected Url: string = "";
  public BaseUrl: string | undefined;
  protected authToken: string | null = null;
  protected fileDownload: boolean = false;
  protected emulationForm: boolean = false;
  protected withCredentials: boolean = true;

  public cancel = (): void => {};

  public constructor(BaseUrl?: string) {
    if (BaseUrl === undefined || !this.BaseUrl.length) {
      if (process.env.API_URL) {
        if (process.env.API_URL.slice(-1) === "/") {
          this.BaseUrl = process.env.API_URL + process.env.API_URL_PREFIX + "/";
        } else {
          this.BaseUrl = process.env.API_URL + "/" + process.env.API_URL_PREFIX + "/";
        }
      } else {
        console.error("API_URL not exists.");
      }
    }
  }

  protected request(data: any = {}): any {
    this.cancel();
    const cancel = new axios.CancelToken((c) => {
      this.cancel = c;
    });
    return this.initRequest(data, cancel);
  }

  protected async initRequest(
    data: any = {},
    cancelToken: any = new axios.CancelToken((c) => {
      this.cancel = c;
    })
  ): Promise<any> {
    axios.defaults.withCredentials = this.withCredentials;
    try {
      const url = new URL(this.BaseUrl + this.Url);
      let Response: any;
      switch (this.Method) {
        case Method.GET:
          if (typeof data === "object") {
            const keys = Object.keys(data);
            keys.forEach((key: any) => {
              const value = data[key];
              if (value !== null && value !== undefined) {
                url.searchParams.set(key, value);
              }
            });
          }
          if (this.fileDownload) {
            Response = await axios.get(url.toString(), {
              cancelToken,
              responseType: "arraybuffer",
              headers: {
                maxRedirects: 0,
                "X-Requested-With": "XMLHttpRequest",
                Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
              },
            });
          } else {
            Response = await axios.get(url.toString(), {
              cancelToken,
              headers: {
                maxRedirects: 0,
                "X-Requested-With": "XMLHttpRequest",
                Accept: "application/json",
                Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
              },
            });
          }
          break;
        case Method.POST:
          data = {
            ...data,
            ...{ workshop_id: Number(process.env.WORKSHOP_ID) },
          };

          if (this.emulationForm === true) {
            if (typeof data === "object") {
              const form_data = new FormData();
              for (const key in data) {
                if (data.hasOwnProperty(key)) {
                  if (Array.isArray(data[key])) {
                    data[key].forEach((element: string) => {
                      form_data.append(key + "[]", element);
                    });
                  } else {
                    form_data.append(key, data[key]);
                  }
                }
              }
              data = form_data;
            }
          }

          // if (typeof data === "object") {
          //   const keys = Object.keys(data);
          //   keys.forEach((key: any) => {
          //     const value = data[key];
          //     if (value !== null) {
          //       url.searchParams.set(key, value);
          //     }
          //   });
          // }
          // data = {
          //   ...data,
          //   ...{ workshop_id: Number(process.env.WORKSHOP_ID) },
          // };
          Response = await axios.post(url.toString(), data ? data : {}, {
            cancelToken,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
            },
          });
          break;

        case Method.PATCH:
          data = {
            ...data,
            ...{ workshop_id: Number(process.env.WORKSHOP_ID) },
          };
          Response = await axios.patch(url.toString(), data ? data : {}, {
            cancelToken,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
            },
          });
          break;
        case Method.PUT:
          data = {
            ...data,
            ...{ workshop_id: Number(process.env.WORKSHOP_ID) },
          };
          Response = await axios.put(url.toString(), data ? data : {}, {
            cancelToken,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
            },
          });
          break;
        case Method.DELETE:
          Response = await axios.delete(url.toString(), {
            cancelToken,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              Authorization: this.authToken !== null ? "Bearer " + this.authToken : "",
            },
          });
          break;
      }

      if (Response?.data?.timestamp) {
        useDispatch(Action.Main.Time.setCurrent(Response.data.timestamp * 1000));
      }

      return Response.data;
    } catch (Exception) {
      throw Exception.response;
    }
  }

  protected withUrl(Url: string) {
    this.Url = Url;
    return this;
  }

  protected baseUrl(Url: any) {
    this.BaseUrl = Url;
    return this;
  }

  protected withAuth(token?: string) {
    // this.authToken = token;
    console.log("COOOOOOKIEEEEEE");
    console.log(document.cookie);
    console.log(Cookie.get("ptsh_n"));
    this.authToken = Cookie.get("ptsh_n");
    return this;
  }

  protected setMethod(Method: Method) {
    this.Method = Number(Method);
    return this;
  }

  protected asFile() {
    this.fileDownload = true;
    return this;
  }

  protected emulateForm() {
    this.emulationForm = true;
    return this;
  }

  protected withoutCredentials() {
    this.withCredentials = false;
    return this;
  }
}
