import React, { ReactElement } from "react";
import { connect } from "react-redux";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";

interface PropsInterface {
  children: any;
}

interface StateInterface {}

export default Connector(
  class Form extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: any) {
      super(props);
      this.state = { children: [] };
      // console.log(this.props.children);
      // this.findInputs(this.props.children);
    }

    private findInputs(elements: ReactElement[]): ReactElement[] {
      if (Array.isArray(elements)) {
        const tempElements: ReactElement[] = [];
        elements.map((Element: ReactElement) => {
          if (Element.props.name === "firstName") {
            // console.log(Element);
            // Element.props.onChange = () => {
            //   console.log("TEST TEST");
            // };
            tempElements.push(<>TEST TEST</>);
            // Element.props = { ...Element.props, ...{  } };
            // Element = React.cloneElement(Element, { value: "test" });
          } else {
            return this.findInputs(Element.props.children);
          }
        });
        return tempElements;
      } else {
        return elements;
      }
    }

    public render() {
      return this.props.children;
      // return this.findInputs(this.props.children);
    }
  }
);
