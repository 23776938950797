/* eslint-disable @typescript-eslint/indent */
import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Company extends ModelPrototype {
  public async get(company_id: number, isNetworkingNotImportant?: boolean): Promise<any> {
    try {
      return this.withUrl(
        `company/${company_id}${
          isNetworkingNotImportant !== undefined
            ? `?isNetworkingNotImportant=${isNetworkingNotImportant}`
            : ""
        }`
      )
        .setMethod(Method.GET)
        .request();
    } catch (Exception) {
      throw Exception;
    }
  }

  // public async employeesRequests(company_id: number): Promise<any> {
  //   try {
  //     return this.withUrl(`company/${company_id}/employee`)
  //       .setMethod(Method.GET)
  //       .request();
  //   } catch (Exception) {
  //     throw Exception;
  //   }
  // }

  public async suggest(data: { text: string }): Promise<any> {
    try {
      return this.withUrl(`company/search`).setMethod(Method.GET).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async create(data: {
    company_id?: number;
    name: string;
    types: number[];
    about: string;
    directions?: number[];
    specializations?: number[];
    avatar?: string;
    creator: number;
    website?: string;
    socials?: {
      facebook?: string;
      instagram?: string;
      vkontakte?: string;
      youtube?: string;
      tiktok?: string;
      telegram?: string;
    };
    offices?: {
      name: string;
      address: string;
      phone: string;
      email: string;
      country_id: number;
      city_id: number;
      username: string;
      userposition: string;
    }[];
  }): Promise<any> {
    try {
      const offices: {
        title: string;
        address: string;
        phone: string;
        email: string;
        networkingCity: number;
        networkingCountry: number;
        contactPersonName: string;
        contactPersonDescription: string;
      }[] = [];
      data.offices?.forEach((Office) => {
        offices.push({
          title: Office.name,
          address: Office.address,
          phone: Office.phone,
          email: Office.email,
          networkingCity: Office.city_id,
          networkingCountry: Office.country_id,
          contactPersonName: Office.username,
          contactPersonDescription: Office.userposition,
        });
      });

      return this.withUrl(`company/create`)
        .setMethod(Method.POST)
        .request({
          ...(data.company_id ? { companyId: data.company_id } : {}),
          company_form: {
            title: data.name,
            description: data.about,
            types: data.types,
            ...(data.website ? { website: data.website } : {}),
            ...(data.directions ? { directions: data.directions } : {}),
            ...(data.specializations ? { specializations: data.specializations } : {}),
            ...(data.avatar ? { fileObject: data.avatar } : {}),
            networkingProfile: {
              creator: data.creator,
              // workshop: Number(process.env.WORKSHOP_ID),
              ...(data.socials
                ? {
                    ...(data.socials.facebook ? { facebook: data.socials.facebook } : {}),
                    ...(data.socials.instagram ? { instagram: data.socials.instagram } : {}),
                    ...(data.socials.vkontakte ? { vkontakte: data.socials.vkontakte } : {}),
                    ...(data.socials.youtube ? { youtube: data.socials.youtube } : {}),
                    ...(data.socials.tiktok ? { tiktok: data.socials.tiktok } : {}),
                    ...(data.socials.telegram ? { telegram: data.socials.telegram } : {}),
                  }
                : {}),
            },
            ...(data.offices ? { offices } : {}),
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async update(
    company_id: number,
    data: {
      name: string;
      types: number[];
      about: string;
      directions?: number[];
      specializations?: number[];
      avatar?: string;
      creator: number;
      website?: string;
      socials?: {
        facebook?: string;
        instagram?: string;
        vkontakte?: string;
        youtube?: string;
        tiktok?: string;
        telegram?: string;
      };
      offices?: {
        name: string;
        address: string;
        phone: string;
        email: string;
        country_id: number;
        city_id: number;
        username: string;
        userposition: string;
      }[];
    }
  ): Promise<any> {
    try {
      const offices: {
        title: string;
        address: string;
        phone: string;
        email: string;
        networkingCity: number;
        networkingCountry: number;
        contactPersonName: string;
        contactPersonDescription: string;
      }[] = [];
      data.offices?.forEach((Office) => {
        offices.push({
          title: Office.name,
          address: Office.address,
          phone: Office.phone,
          email: Office.email,
          networkingCity: Office.city_id,
          networkingCountry: Office.country_id,
          contactPersonName: Office.username,
          contactPersonDescription: Office.userposition,
        });
      });

      return this.withUrl(`company/edit`)
        .setMethod(Method.POST)
        .request({
          companyId: company_id,
          company_form: {
            title: data.name,
            description: data.about,
            types: data.types,
            ...(data.website ? { website: data.website } : {}),
            ...(data.directions ? { directions: data.directions } : {}),
            ...(data.specializations ? { specializations: data.specializations } : {}),
            ...(data.avatar ? { fileObject: data.avatar } : {}),
            networkingProfile: {
              creator: data.creator,
              // workshop: Number(process.env.WORKSHOP_ID),
              ...(data.socials
                ? {
                    ...(data.socials.facebook ? { facebook: data.socials.facebook } : {}),
                    ...(data.socials.instagram ? { instagram: data.socials.instagram } : {}),
                    ...(data.socials.vkontakte ? { vkontakte: data.socials.vkontakte } : {}),
                    ...(data.socials.youtube ? { youtube: data.socials.youtube } : {}),
                    ...(data.socials.tiktok ? { tiktok: data.socials.tiktok } : {}),
                    ...(data.socials.telegram ? { telegram: data.socials.telegram } : {}),
                  }
                : {}),
            },
            ...(data.offices ? { offices } : {}),
          },
        });
    } catch (Exception) {
      throw Exception;
    }
  }

  public async types(): Promise<any> {
    try {
      return this.withUrl(`tag/scope/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async specializations(): Promise<any> {
    try {
      return this.withUrl(`tag/specialization/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async directions(): Promise<any> {
    try {
      return this.withUrl(`tag/direction/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async city(): Promise<any> {
    try {
      return this.withUrl(`networking/cities/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
