import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  show: boolean;
}

class Contacts extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      show: true,
    };
  }

  public render() {
    if (
      this.props.Store.Stand.Selected.data?.offices?.length > 0 ||
      this.props.Store.Stand.Selected.data?.website ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.facebook ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.instagram ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.vkontakte ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.youtube ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.tiktok ||
      this.props.Store.Stand.Selected.data?.networkingProfile?.telegram
    ) {
      if (this.state.show || this.props.Store.Auth.data) {
        return (
          <>
            <div className="stand-contacts  stand__block">
              <div className="stand-contacts__header">
                <div className="stand-contacts__title">Контактные данные</div>
              </div>
              <div className="stand-contacts__body">
                {this.props.Store.Stand.Selected.data?.offices.map((item: any, index: number) => {
                  return (
                    <div key={index} className="stand-contacts__office">
                      <div className="stand-contacts__office-item">
                        <div className="stand-contacts__office-title">{item.title}</div>
                        {item.address ? (
                          <div className="stand-contacts__address">{item.address}</div>
                        ) : (
                          <></>
                        )}
                        {item.phone ? (
                          <div className="stand-contacts__phone">
                            <a href={`tel:${item.phone}`}>{item.phone}</a>
                          </div>
                        ) : (
                          <></>
                        )}
                        {item.email ? (
                          <div className="stand-contacts__email">
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {item.contactPersonName || item.contactPersonDescription ? (
                        <div className="stand-contacts__office-item">
                          <div className="stand-contacts__office-title">Контактное лицо</div>
                          <div className="stand-contacts__office-person">
                            <div className="stands-contacts__office-name">
                              {item.contactPersonName}
                            </div>
                            <div className="stands-contacts__office-position">
                              {item.contactPersonDescription}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <hr />
                    </div>
                  );
                })}
              </div>

              <div className="stand-contacts__footer">
                <div className="stand-contacts__links">
                  {/* <a href="mailto:support@gabestore.ru" className="stand-contacts__links-item">
                  support@gabestore.ru
                </a> */}
                  {this.props.Store.Stand.Selected.data?.website ? (
                    <a
                      href={`//${this.props.Store.Stand.Selected.data?.website}`}
                      className="stand-contacts__links-item"
                    >
                      {this.props.Store.Stand.Selected.data?.website}
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="stand-contacts__socials">
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.facebook ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--facebook"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.facebook}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.instagram ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--instagram"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.instagram}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.vkontakte ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--vk"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.vkontakte}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.youtube ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--youtube"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.youtube}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.tiktok ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--tiktok"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.tiktok}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.Store.Stand.Selected.data?.networkingProfile?.telegram ? (
                    <a
                      className="stand-contacts__socials-item stand-contacts__socials-item--telegram"
                      target="_blank"
                      href={this.props.Store.Stand.Selected.data?.networkingProfile?.telegram}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="stand-contacts stand-contacts--unreg stand__block">
              <div className="stand-contacts__title">Контактные данные</div>
              <Link
                className="btn stand-contacts__show-link"
                to="#"
                onClick={() => this.setState({ show: true })}
              >
                Показать контакты
              </Link>
            </div>
          </>
        );
      }
    } else {
      return <></>;
    }
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Contacts)
);
