import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Type {
  public static async load() {
    useDispatch(Action.Profile.setStatus("loading"));
    try {
      const response = await new Model.Company().types();
      useDispatch(Action.Company.Type.setGroups(response.data));
      let list: any = [];
      response.data.forEach((Group: any) => {
        list = list.concat(Group.itemList);
      });
      useDispatch(Action.Company.Type.setList(list));
      useDispatch(Action.Profile.setStatus("loaded"));
      return response.data;
    } catch (Exception) {}
  }
}
