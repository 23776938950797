import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NetworkingEnabled: React.FC = () => {
  const preurl = useSelector((state: any) => state.Auth.preurl);
  const netwDays = useSelector((state) => state.Networking?.Days.data);

  return (
    <div className="auth auth--thanks">
      <div className="auth__wrapper">
        <div className="auth__body">
          <div className="auth__title">Спасибо!</div>
          <div className="auth__desc">
            Теперь вы можете обмениваться{" "}
            <Link to="/networking">визитками</Link> с коллегами
          </div>
          {/*
          {netwDays?.length ? (
            <div className="auth__desc">
              Вы открыли полный доступ к возможностям онлайн-выставки. Вы можете
              составить личное расписание{" "}
              <Link to="/?anchor=schedule-anchor">онлайн-презентаций</Link> и
              назначить <Link to="/networking">видеовстречи</Link> коллегам
            </div>
          ) : (
            <div className="auth__desc">
              Вы открыли полный доступ к возможностям онлайн-выставки. Вы можете
              составить личное расписание{" "}
              <Link to="/?anchor=schedule-anchor">онлайн-презентаций</Link> и
              обменяться <Link to="/networking">визитками</Link> с коллегами
            </div>
          )}
*/}
        </div>
        <div className="auth__footer">
          <Link to={preurl} className="btn btn--red">
            Понятно!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NetworkingEnabled;
