import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  companyAdmin?: boolean;
}

interface StateInterface {}

class Answer extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="stand-answer">
          <div className="stand-answer__item stand-answer__item--question">
            <div className="stand-answer__user">
              <div className="stand-answer__img">
                <img src={require("@public/images/avatar.png")} alt="" />
              </div>
              <div className="stand-answer__name">Сергей Николаев</div>
              <div className="stand-answer__date">07 ноября 2021</div>
              <div className="stand-answer__desc">
                Эксперт по туризму Окинавы, Okinawa Convention & Visitors Bureau
              </div>
            </div>
            <div className="stand-answer__text">
              Можно ли с вашей помощью получить деньги на реализацию проекта или познакомиться с
              инвесторами частными
            </div>
          </div>
          <hr />
          <div className="stand-answer__item stand-answer__item--answer">
            <div className="stand-answer__user">
              <div className="stand-answer__img">
                <img src={require("@public/images/stand-logo.png")} alt="" />
              </div>
              <div className="stand-answer__name">
                Представительство туристической организации Никарагуа в Москве
              </div>
              <div className="stand-answer__date">07 ноября 2021</div>
              <div className="stand-answer__desc">
                Эксперт по туризму Окинавы, Okinawa Convention & Visitors Bureau
              </div>
            </div>
            <div className="stand-answer__text">
              Это же известная практика переговоров. Если не знаешь как ответить, дай себе время на
              размышление, расскажи о том, в чем шаришь, а потом выйдешь уже на тему вопроса. Секунд
              30 выиграть можно нв раздумия так. Он ухватился за слово "нетрадиционный", ну а там
              пошло поехало...
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Answer)
);
