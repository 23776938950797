/* eslint-disable @typescript-eslint/indent */
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";
import { userCutawayStatus } from "@helpers/UserCutawayStatus";
import PreloaderPage from "@components/preloader/PreloaderPage";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

export default Connector(
  class UserPage extends React.Component<PropsInterface, StateInterface> {
    public componentDidMount() {
      Middleware.User.Selected.load(Number(this.props.match.params.user_id));
      Middleware.Company.Direction.load();
      Middleware.Company.Type.load();
      Middleware.Company.Specialization.load();
      Middleware.Language.load();
    }

    public componentWillUnmount() {
      Middleware.User.Selected.clear();
    }

    public render() {
      const selectedUserData = this.props.Store.User.Selected?.data;
      const authData = this.props.Store.Auth?.data;
      const profileData = this.props.Store.Profile?.data;
      const companyTypeList = this.props.Store.Company.Type?.list;
      const companyDirectionList = this.props.Store.Company.Direction?.list;
      const companySpecializationList =
        this.props.Store.Company.Specialization.list;
      const languageList = this.props.Store.Language?.list;
      const registerEndAt = moment(
        this.props.Store.Main.Workshop.data?.registerEndAt
      )
        .tz("Europe/Moscow")
        .format();
      const now = moment().tz("Europe/Moscow").format();
      const netwDays = this.props.Store.Networking?.Days.data;

      if (!selectedUserData) {
        return <PreloaderPage />;
      }

      return (
        <>
          <Helmet
            title={`${
              selectedUserData.firstName || selectedUserData.lastName
                ? `${selectedUserData.firstName} ${selectedUserData.lastName}`
                : "Пользователь"
            } | ОТМ: SUMMER 2025`}
            meta={[
              {
                name: "description",
                content: `${selectedUserData.firstName} ${selectedUserData.lastName} в нетворкинге ОТМ: SUMMER 2025`,
              },
            ]}
          />
          <div className="user">
            <div className="user__inner">
              <div className="user__block user__header">
                <div className="user__info">
                  {selectedUserData.userImage ? (
                    <div className="user__avatar">
                      <img src={selectedUserData.userImage} alt="" />
                    </div>
                  ) : (
                    <div className="user__avatar user__avatar--empty" />
                  )}

                  <h1 className="user__name">
                    {selectedUserData.firstName} {selectedUserData.lastName}
                  </h1>
                  <div className="user__position">
                    {selectedUserData.position ? selectedUserData.position : ""}
                    {selectedUserData.position &&
                    selectedUserData.company?.title
                      ? ", "
                      : ""}
                    {selectedUserData.company?.title &&
                      selectedUserData.company?.title}
                  </div>
                </div>
                {selectedUserData.id === authData?.id ? null : (
                  <>
                    <hr />
                    <div className="user__buttons">
                      {netwDays?.length > 0 && (
                        <Link
                          to={`/user/${selectedUserData.id}/event/create`}
                          className="btn btn--white-red"
                          onClick={(event) => {
                            if (now > registerEndAt) {
                              event.preventDefault();
                              Middleware.Ui.Modal.open("RegistrationClosed", {
                                callback: "",
                              });
                            } else if (
                              !profileData?.useNetworking ||
                              profileData?.networkingFillStatus !== 3
                            ) {
                              event.preventDefault();
                              Middleware.Ui.Modal.open("EnterNetworking");
                            }
                          }}
                        >
                          Назначить встречу
                        </Link>
                      )}

                      <Link
                        to="#"
                        className={`btn btn--white-grey ${userCutawayStatus(
                          selectedUserData
                        )}`}
                        onClick={async (event) => {
                          event.preventDefault();
                          if (now > registerEndAt) {
                            Middleware.Ui.Modal.open("RegistrationClosed", {
                              callback: "",
                            });
                          } else if (
                            profileData?.useNetworking &&
                            profileData?.networkingFillStatus === 3
                          ) {
                            try {
                              await Middleware.User.Cutaway.invite(
                                selectedUserData.id
                              );
                              await Middleware.User.Selected.setUserCutawayStatus(
                                0
                              );
                            } catch (Exception) {
                              throw Exception;
                            }
                          } else {
                            Middleware.Ui.Modal.open("EnterNetworking");
                          }
                        }}
                      >
                        Обменяться визитками
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div className="user__body">
                <div className="user__column user__column--left">
                  {selectedUserData.company &&
                  selectedUserData.company?.status !== 0 ? (
                    <div className="user__block user__company">
                      <div className="user__company-info">
                        <div className="user__company-img">
                          <img
                            src={
                              selectedUserData.company?.companyImage
                                ? selectedUserData.company?.companyImage
                                : require("@public/images/defaultAvatarCompany.svg")
                            }
                            alt=""
                          />
                        </div>
                        <h3 className="user__company-name">
                          {selectedUserData.company?.title}
                        </h3>
                        <ul className="user__company-directions">
                          {companyTypeList?.map((item: any, index: number) => {
                            if (
                              selectedUserData.networkingProfile?.companyTypes?.includes(
                                item.id
                              )
                            ) {
                              return <li>{item.name}</li>;
                            }
                          })}
                        </ul>
                      </div>
                      <Link
                        to={
                          selectedUserData?.companyStand
                            ? `/company/stand/${selectedUserData.companyStand}`
                            : `/company/${selectedUserData.company?.id}`
                        }
                        className="user__company-link"
                      >
                        Перейти на страницу
                      </Link>
                    </div>
                  ) : null}

                  <div className="user__block user__about">
                    <h2 className="user__about-title">Обо мне</h2>
                    {selectedUserData.networkingProfile?.about ? (
                      <div className="user__about-desc">
                        <p>{selectedUserData.networkingProfile?.about}</p>
                      </div>
                    ) : (
                      <div className="user__about-desc--empty">
                        Пока нет информации
                      </div>
                    )}
                    {selectedUserData.networkingProfile?.directions.length >
                      0 && (
                      <div className="user__about-directions">
                        <h3 className="user__about-directions-title">
                          Направления, с которыми я работаю
                        </h3>
                        <p className="user__about-directions-list">
                          {companyDirectionList
                            ?.filter((direction: any) =>
                              selectedUserData.networkingProfile?.directions.includes(
                                direction.id
                              )
                            )
                            .map((direction: any, index: number) => {
                              return (index === 0 ? "" : ", ") + direction.name;
                            })}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="user__column user__column--right">
                  {selectedUserData.networkingProfile?.languages.length > 0 && (
                    <div className="user__block user__props">
                      <h3 className="user__props-title">Языки общения</h3>
                      <ul className="user__props-list">
                        {languageList?.map((item: any, index: number) => {
                          if (
                            selectedUserData.networkingProfile?.languages.includes(
                              item.id
                            )
                          ) {
                            return (
                              <li className="user__props-item">{item.name}</li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}

                  {selectedUserData.networkingProfile?.interestCompanyTypes
                    .length > 0 && (
                    <div className="user__block user__props">
                      <h3 className="user__props-title">
                        Ищу контакты с компаниями:
                      </h3>
                      <ul className="user__props-list">
                        {companyTypeList?.map((item: any, index: number) => {
                          if (
                            selectedUserData.networkingProfile?.interestCompanyTypes.includes(
                              item.id
                            )
                          ) {
                            return (
                              <li className="user__props-item">{item.name}</li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  )}

                  {selectedUserData.networkingProfile?.specializations.length >
                    0 && (
                    <div className="user__block user__props">
                      <h3 className="user__props-title">Моя специализация</h3>
                      <ul className="user__props-list">
                        {companySpecializationList?.map(
                          (item: any, index: number) => {
                            if (
                              selectedUserData.networkingProfile?.specializations.includes(
                                item.id
                              )
                            ) {
                              return (
                                <li className="user__props-item">
                                  {item.name}
                                </li>
                              );
                            }
                          }
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
);
