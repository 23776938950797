import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface IProps {
  list: any[];
}

const RatingListAll: React.FC<IProps> = ({ list }) => {
  const currentUserId: number = useSelector(
    (state: any) => state.Auth.data?.id
  );

  return (
    <ul className="rating__list">
      {list?.map(
        (item: {
          id: number;
          firstName: string;
          lastName: string;
          userImage: string;
          position: number;
          size: number;
          workName: string;
          city: string;
          isLeader: boolean;
        }) => {
          return (
            <li
              key={item.id}
              className={`rating__item${
                item.isLeader ? " rating__item--leader" : ""
              }${item.id === currentUserId ? " rating__item--active" : ""}`}
            >
              <div className="rating__item-place">{item.position}</div>
              <div className="rating__item-star">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.79723 8.70347L12 1.74393L14.2028 8.70347C14.3676 9.22433 14.7757 9.63237 15.2965 9.79723L22.2561 12L15.2965 14.2028C14.7757 14.3676 14.3676 14.7757 14.2028 15.2965L12 22.2561L9.79723 15.2965C9.63237 14.7757 9.22433 14.3676 8.70347 14.2028L1.74393 12L8.70347 9.79723C9.22433 9.63237 9.63237 9.22433 9.79723 8.70347Z"
                    stroke="#C0C0C0"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="rating__item-user">
                <div className="rating__item-img">
                  {item.userImage ? <img src={item.userImage} alt="" /> : <></>}
                </div>
                <div className="rating__item-name">{`${item.firstName} ${item.lastName}`}</div>
              </div>
              {item.workName && (
                <div className="rating__item-company">
                  {item.workName}
                  {item.workName?.length > 0 && item.city?.length > 0
                    ? ", "
                    : ""}
                  {item.city}
                </div>
              )}
              {/* TODO удалить или вернуть потом */}
              {/* <div className="rating__item-score">
                <span>+{item.size}</span> Баллов
              </div> */}
            </li>
          );
        }
      )}
    </ul>
  );
};

export default RatingListAll;
