import React from "react";
import * as Type from "@interface/index";
import { Input, Modificator } from "@components/form/index";
import Connector from "@helpers/Connector";

interface PropsInterface extends Type.ComponentInterface {
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (data: any) => void;
}

interface StateInterface {
  phoneCode: string;
  phone: string;
}

export default Connector(
  class InputPhone extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        phoneCode: "+7",
        phone: "",
      };

      this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    private onChangeHandler(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
      this.setState({ [event.target.name]: event.target.value }, () => this.callback());
    }

    public componentDidMount() {
      this.setState({ phone: this.props.value?.substr(this.props.value.length - 10) || "" }, () =>
        this.callback()
      );
    }

    // public componentDidUpdate(props: PropsInterface) {
    //   if (this.props.value !== props.value && this.props.value !== undefined) {
    //     this.setState({ phone: this.props.value.substr(this.props.value.length - 10) });
    //   }
    // }

    private callback() {
      if (this.props.onChange) {
        this.props.onChange({
          target: {
            name: this.props.name,
            value: this.state.phone.length ? this.state.phoneCode + this.state.phone : "",
          },
        });
      }
    }

    public render() {
      return (
        <>
          <div className="input-phone">
            <select
              name="phone-region"
              defaultValue="+7"
              value={this.props.value}
              id="phone-region"
            >
              <option value="+7">+7</option>
              <option value="+8">+8</option>
              <option value="+9">+9</option>
              <option value="+10">+10</option>
            </select>
            <label className="visually-hidden" htmlFor={this.props.name}>
              Ваш телефон
            </label>
            <Input
              type="text"
              name={this.props.name}
              id={this.props.name}
              placeholder={this.props.placeholder || "Телефон"}
              onChange={this.onChangeHandler}
              modificator={Modificator.PhoneWithoutPrefix}
            />
          </div>
        </>
      );
    }
  }
);
