import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Pingpong {
  public static async ping(): Promise<boolean> {
    try {
      const response = await new Model.PingPong().ping();

      useDispatch(Action.Main.Pingpong.setStatus(true));

      return response.data;
    } catch (Exception) {
      return false;
    }
  }
}
