import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class NetworkingSearch {
  public static async list(query: string) {
    try {
      const response = await new Model.Networking.Search().list(query);
      useDispatch(Action.Networking.Search.clear());
      useDispatch(Action.Networking.Search.setList(response.data));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async setUserCutawayStatus(status: number, user_id: number) {
    try {
      useDispatch(Action.Networking.Search.setUserCutawayStatus({ status, user_id }));
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async clear() {
    useDispatch(Action.Networking.Search.clear());
  }
}
