import React from "react";
import PhoneInput from "react-phone-input-2";
import { RegistrationProps } from "./page";

const MailAndPhoneForm: React.FC<RegistrationProps> = ({ data, onChange }) => {
  return (
    <div className="form__group form__group--margin">
      <input
        className="input"
        type="email"
        name="login"
        id="login"
        value={data.email}
        onChange={(e) => onChange("email", e.target.value)}
        placeholder="E-mail"
        required={true}
      />
      <PhoneInput
        country={"ru"}
        placeholder={"Телефон"}
        value={data.phone}
        onChange={(phone) => onChange("phone", phone)}
        containerClass={"phone-input"}
        inputProps={{
          name: "phone",
          required: true,
          pattern: ".{7,}",
        }}
      />
    </div>
  );
};

export default MailAndPhoneForm;
