import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import Style from "./Rating.module.scss";

const RatingPage: React.FC = () => {
  useEffect(() => {
    document.title = "Loyalty" + " | " + process.env.SITE_NAME;
  });

  function dateFormat(dateString: string) {
    const date = moment(dateString);

    return date.format("D MMMM YYYY");
  }

  const rating = useSelector((state: any) => state.UserRating?.data);

  return (
    <div className="cabinet__layout">
      <h1 className="cabinet__title">Баллы и рейтинг</h1>
      <div className="cabinet__layout-body">
        <div className="cabinet__layout-col">
          <ul className={`${Style.rating} cabinet__block`}>
            {rating?.detailedRating.map((item: any, index: number) => {
              return (
                <li key={index} className={Style.item}>
                  <div className={Style.itemDate}>
                    {dateFormat(item.createdAt)}
                  </div>
                  <div className={Style.itemContent}>
                    <div className={Style.itemDesc}>{item.description}</div>
                  </div>
                  <div className={Style.itemCount}>+{item.size}</div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="cabinet__layout-col">
          <div className={`${Style.loyalty} cabinet__block`}>
            <div className={Style.loyaltyLogo}>
              <img
                src={require("@public/images/loyalty-bg.svg")}
                alt="Loyalty"
              />
            </div>
            <div className={Style.loyaltyList}>
              <div className={Style.loyaltyItem}>
                <div className={Style.loyaltyItemTitle}>
                  Ваши баллы в сезоне:
                </div>
                <div className={Style.loyaltyItemScore}>
                  {rating?.thisYearRating}
                </div>
              </div>
              <div className={Style.loyaltyItem}>
                <div className={Style.loyaltyItemTitle}>Ваши баллы:</div>
                <div className={Style.loyaltyItemScore}>
                  {rating?.totalRating}
                </div>
              </div>
            </div>
            <a
              className="btn btn--medium btn--red"
              href="https://profi.travel/loyalty"
              target="_blank"
            >
              Правила Loyalty
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingPage;
