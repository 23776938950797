import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class UserCutaway extends ModelPrototype {
  public async list(data?: { type: "incoming" | "outgoing" }): Promise<any> {
    try {
      return this.withUrl(`user/cutaway/list`).setMethod(Method.GET).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async invite(user_id: number): Promise<any> {
    try {
      return this.withUrl(`user/${user_id}/cutaway`).setMethod(Method.POST).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async update(cutaway_id: number, data: { status?: number }): Promise<any> {
    try {
      return this.withUrl(`user/cutaway/${cutaway_id}`).setMethod(Method.PUT).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async delete(cutaway_id: number): Promise<any> {
    try {
      return this.withUrl(`user/cutaway/${cutaway_id}`).setMethod(Method.DELETE).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async counter(type: string): Promise<any> {
    try {
      return this.withUrl(`user/cutaway/count/${type}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
