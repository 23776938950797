import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class NetworkingUser {
  public static async count() {
    try {
      const response = await new Model.Networking.User().count();
      useDispatch(Action.Networking.User.setCount(response.data));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async page(page: number, filters?: any) {
    useDispatch(Action.Networking.User.setStatus("loading"));
    try {
      const response = await new Model.Networking.User().list(page, filters);
      useDispatch(Action.Networking.User.clear());
      useDispatch(Action.Networking.User.setList(response.data));
      useDispatch(Action.Networking.User.setPaginator(response.paginator));
      useDispatch(Action.Networking.User.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async addPage(page: number, filters?: any) {
    useDispatch(Action.Networking.User.setStatus("loading"));
    try {
      const response = await new Model.Networking.User().list(page, filters);
      useDispatch(Action.Networking.User.setList(response.data));
      useDispatch(Action.Networking.User.setPaginator(response.paginator));
      useDispatch(Action.Networking.User.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async setUserCutawayStatus(status: number, user_id: number) {
    try {
      useDispatch(Action.Networking.User.setUserCutawayStatus({ status, user_id }));
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async clear() {
    useDispatch(Action.Networking.User.setStatus("waiting"));
    useDispatch(Action.Networking.User.clear());
  }
}
