import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import * as Model from "@models/index";
import PreloaderComponent from "@components/preloader/PreloaderComponent";
import RatingList from "./Rating/RatingList";
import PaginationList from "./Rating/PaginationList";
import RatingStarRulesModal from "./Rating/RatingStarRulesModal";
import RatingListAll from "./Rating/RatingListAll";

export enum Tariff {
  All = 0, // все вместе
  Basic = 1, // базовый
  Full = 2, // полный и полный плюс
}

const Rating: React.FC = () => {
  const location = useLocation();
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [isStarModalVisible, setIsStarModalVisible] = useState(false);
  const [selectedTariff, setSlectedTariff] = useState<Tariff>(Tariff.Basic);
  const currentTariff = useSelector((state: any) => state.Profile?.data.tariff);
  const [isAlreadyRequestPagination, setIsAlreadyRequestPagination] =
    useState(false);

  // Общий рейтинг, без тарифов
  const ratingListAll: any = useSelector(
    (state: any) => state.Main.Rating.list
  );
  const paginatorAll: any = useSelector(
    (state: any) => state.Main.Rating.paginator
  );

  const paginationAll = new Model.Main.Rating();
  const paginationBase = new Model.Main.Rating();
  const paginationFull = new Model.Main.Rating();

  const ratingListBase = useSelector(
    (state: any) => state.Main.Rating.listBase
  );
  const ratingListFull = useSelector(
    (state: any) => state.Main.Rating.listFull
  );
  const paginatorBase = useSelector(
    (state: any) => state.Main.Rating.paginatorBase
  );
  const paginatorFull = useSelector(
    (state: any) => state.Main.Rating.paginatorFull
  );
  const ratingStatusBase = useSelector(
    (state: any) => state.Main.Rating.statusBase
  );
  const ratingStatusFull = useSelector(
    (state: any) => state.Main.Rating.statusFull
  );

  useEffect(() => {
    if (currentTariff === 2) {
      setSlectedTariff(Tariff.Full);
    } else if (currentTariff === 1) {
      setSlectedTariff(Tariff.Basic);
    } else {
      // Пока решено скрыть кнопку "Все участники", поэтому Basic
      // setSlectedTariff(Tariff.All);
      setSlectedTariff(Tariff.Basic);
    }
  }, [currentTariff]);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            if (ref.current) {
              observer.unobserve(ref.current);
            }
          }
        },
        { threshold: 0.1 }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } else {
      console.log("IntersectionObserver не поддерживается");
      setIsInView(true);
    }
  }, []);

  useEffect(() => {
    if (isInView) {
      if (selectedTariff === Tariff.Basic) {
        Middleware.Main.Rating.pageBase(paginationBase);
      } else if (selectedTariff === Tariff.Full) {
        Middleware.Main.Rating.pageFull(paginationFull);
      } else {
        Middleware.Main.Rating.pageAll(paginationAll);
        // TODO раскоментить, когда вернем кнопку "Все участники"
        // Middleware.Main.Rating.page(paginationAll);
      }
    }
  }, [isInView]);

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "rating-anchor" && ref) {
      console.log("SCROLL EVENT");
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  const handleChangeTariff = () => {
    if (isAlreadyRequestPagination === true) {
      return;
    }
    setIsAlreadyRequestPagination(true);
    if (ratingStatusFull !== "loaded") {
      Middleware.Main.Rating.pageFull(paginationFull);
    }
    if (ratingStatusBase !== "loaded") {
      Middleware.Main.Rating.pageBase(paginationBase);
    }
    // TODO тоже статус проверять как выше сделано
    Middleware.Main.Rating.pageAll(paginationAll);
  };

  return (
    <section className="rating" id="rating-anchor" ref={ref}>
      <div className="cont rating__cont">
        <div className="rating__header">
          <h2 className="rating__title">Рейтинг активности посетителей</h2>
          <div className="rating__desc">
            <p>
              В Рейтинге активности посетителей отображаются все баллы, которые
              зарабатывают участники за активность – просмотры вебинаров,
              стендов, скачивание каталогов. В финале интенсива среди участников
              с наибольшим количеством баллов пройдет розыгрыш призов.
            </p>
            <p>
              Чтобы претендовать на призы, нужно набрать максимум баллов и
              автоматически получить звезду в рейтинге. Во время финального
              эфира между участниками со звездой проходит розыгрыш. Победителя
              определяет рандомайзер.
            </p>
            <Link
              to="#"
              className="price__link btn btn--red-underline red-link"
              onClick={() => {
                setIsStarModalVisible(true);
              }}
            >
              Как получить звезду?
            </Link>
          </div>
        </div>
        <div className="rating__main">
          {ratingListBase?.length || ratingListFull?.length ? (
            <div className="rating__types">
              {/* // Используется только под конец выставки */}
              {/* <div
                className={`rating__type ${
                  selectedTariff === Tariff.All && "rating__type--active"
                }`}
                onClick={() => {
                  setSlectedTariff(Tariff.All);
                  handleChangeTariff();
                }}
              >
                Все участники со звездой
              </div> */}

              {/* // Отображение всех участников */}
              {/* <div
                className={`rating__type ${
                  selectedTariff === Tariff.All && "rating__type--active"
                }`}
                onClick={() => {
                  setSlectedTariff(Tariff.All);
                  handleChangeTariff();
                }}
              >
                Все участники
              </div> */}
              <div
                className={`rating__type ${
                  selectedTariff === Tariff.Basic && "rating__type--active"
                }`}
                onClick={() => {
                  setSlectedTariff(Tariff.Basic);
                  handleChangeTariff();
                }}
              >
                Тариф «Базовый»
              </div>
              <div
                className={`rating__type ${
                  selectedTariff === Tariff.Full && "rating__type--active"
                }`}
                onClick={() => {
                  setSlectedTariff(Tariff.Full);
                  handleChangeTariff();
                }}
              >
                Тарифы «Полный» и «Полный плюс»
              </div>
            </div>
          ) : null}
          <div className="rating__body">
            {selectedTariff === Tariff.Basic ? (
              ratingStatusBase === "loading" ? (
                <div className="rating__preloader">
                  <PreloaderComponent />
                </div>
              ) : (
                <>
                  <RatingList list={ratingListBase} />
                  <PaginationList
                    list={ratingListBase}
                    paginator={paginatorBase}
                    pagination={paginationBase}
                    selectedTariff={selectedTariff}
                  />
                </>
              )
            ) : selectedTariff === Tariff.Full &&
              ratingStatusFull === "loading" ? (
              <div className="rating__preloader">
                <PreloaderComponent />
              </div>
            ) : selectedTariff === Tariff.Full ? (
              <>
                <RatingList list={ratingListFull} />
                <PaginationList
                  list={ratingListFull}
                  paginator={paginatorFull}
                  pagination={paginationFull}
                  selectedTariff={selectedTariff}
                />
              </>
            ) : (
              <>
                <RatingListAll list={ratingListAll} />
                <PaginationList
                  list={ratingListAll}
                  paginator={paginatorAll}
                  pagination={paginationAll}
                  selectedTariff={selectedTariff}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {isStarModalVisible && (
        <RatingStarRulesModal
          handlerOnClose={() => {
            setIsStarModalVisible(false);
          }}
        />
      )}
    </section>
  );
};

export default Rating;
