import React from "react";

const PreloaderComponent: React.FC = () => {
  return (
    <div className="preloader-component">
      <div className="preloader-component__item preloader-component__item--1"></div>
      <div className="preloader-component__item preloader-component__item--2"></div>
      <div className="preloader-component__item preloader-component__item--3"></div>
    </div>
  );
};

export default PreloaderComponent;
