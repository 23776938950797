import Router from "./router";
export default Router;

import Chats from "./chats";
export { Chats };
import _show_free from "./_show_free";
export { _show_free };
import Stand from "./stand";
export { Stand };
import Create from "./create";
export { Create };
import Employees from "./employees";
export { Employees };
import Edit from "./edit";
export { Edit };
import Cabinet from "./cabinet";
export { Cabinet };
