type CookieOption = Record<string, unknown> & { expires?: Date | string };

export default class Cookie {
  public static get(name: string): string | null {
    const cookie = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return cookie ? decodeURIComponent(cookie[1]) : null;
  }

  public static set(
    name: string,
    value: string,
    { expires, ...options }: CookieOption = {}
  ): void {
    const newOptions: CookieOption = {
      ...options,
      path: "/",
      expires,
    };

    if (newOptions.expires instanceof Date) {
      newOptions.expires = newOptions.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);

    Object.entries(newOptions).forEach(([key, optionValue]) => {
      updatedCookie += "; " + key;

      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    });

    document.cookie = updatedCookie;
  }

  public static generate(
    Name: string,
    Value: string,
    Expires?: Date,
    Path?: string,
    Domain?: string,
    Secure?: boolean
  ) {
    return (
      Name +
      "=" +
      escape(Value) +
      (Expires !== undefined ? "; expires=" + Expires.toUTCString() : "") +
      (Path !== undefined ? "; path=" + escape(Path) : "") +
      (Domain !== undefined ? "; domain=" + escape(Domain) : "") +
      (Secure !== undefined && Secure ? +"; secure" : "")
    );
  }
}
