import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { withRouter, Link } from "react-router-dom";
import { throws } from "assert";
// import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  name: string;
  items: any[];
}

interface StateInterface {}

class Radio extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="radio">
          {this.props.items.map((input: string, index: number) => {
            return (
              <>
                <input
                  className="visually-hidden"
                  type="radio"
                  name={this.props.name}
                  id={`${this.props.name}-${index + 1}`}
                  checked={index === 0 ? true : false}
                />
                <label tabIndex={0} htmlFor={`${this.props.name}-${index + 1}`}>
                  {input}
                </label>
              </>
            );
          })}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Radio)
);
