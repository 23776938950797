import React, { ReactElement } from "react";
import * as Type from "@interface/index";
import moment from "moment-timezone";

const ScheduleTableDayLayout = (props): ReactElement => {
  const startTime = moment(props.currentDate)
    .hour(+props.scheduleWizard.startHour)
    .minute(+props.scheduleWizard.startMinute);

  const periodCount = +props.scheduleWizard.dayPeriodCount;
  const periodLength = +props.scheduleWizard.dayPeriodLength;

  const cells = [];

  for (let i = 0; i < periodCount; i++) {
    cells.push(
      <div className="schedule__table-bg">
        <p className="schedule__table-bg-time">
          {startTime.add(i > 0 ? periodLength : 0, "minutes").format("HH:mm")}
          <span>МСК</span>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="schedule__table-bg-wrapper">
        {cells.map((item: any, index: number) => {
          return <React.Fragment key={index}>{item}</React.Fragment>;
        })}
      </div>
    </>
  );
};

export default ScheduleTableDayLayout;
