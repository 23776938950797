import React, {
  useState,
  useEffect,
  ReactElement,
  lazy,
  Suspense,
} from "react";
import { useSelector } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Helmet } from "react-helmet";
import Cookie from "../../utils/Cookie";

// import Style from "./Dashboard.module";
import AboutExhibitionV2 from "@pages/dashboard/components/AboutExhibitionV2";
import Prizes from "@pages/dashboard/components2.0/Prizes/Prizes";
import Header from "@pages/dashboard/components2.0/Header";
import Speakers from "@pages/dashboard/components2.0/Speakers";
// const Speakers = React.lazy(
//   () => import("@pages/dashboard/components2.0/Speakers")
// );
import ThreeWeeksOverview from "@pages/dashboard/components2.0/ThreeWeeksOverview";
import Schedule from "@pages/dashboard/components2.0/Schedule/Schedule";
import Stands from "@pages/dashboard/components2.0/Stands/Stands";
import Price from "@pages/dashboard/components/Price";
import { Rating } from "@pages/dashboard/components";
import Reviews from "@pages/dashboard/components2.0/Reviews/Reviews";
import AboutNetworking from "@pages/dashboard/components/AboutNetworking";
import { TariffArchive } from "./components/Price/TariffArchive";
import moment from "moment";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  anchor: string;
}

// const Slider = lazy(() => import("./components/Slider"));
// const AboutNetworking = lazy(() => import("./components/AboutNetworking"));
// const Prizes = lazy(() => import("./components/Prizes"));
// const Survey = lazy(() => import("./components/Survey"));
// const ArabEast = lazy(() => import("./components/ArabEast"));
// const AboutExhibition = lazy(() => import("./components/AboutExhibition"));
// const About = lazy(() => import("./components/About"));
// const Dynamic = lazy(() => import("./components/Dynamic"));
// const Program = lazy(() => import("./components/Program"));
// const Secret = lazy(() => import("./components/Secret"));
// const RatingMaintenance = lazy(() => import("./components/RatingMaintenance"));

const DashboardPage = (): ReactElement => {
  const isShowModal = Cookie.get("isShowModalInTouch") === "true";
  const authorized = useSelector((state) => state.Auth.status);
  const now = moment().tz("Europe/Moscow").format();
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );
  const isArchive = now > moment(endWorkshop).tz("Europe/Moscow").format();

  useEffect(() => {
    if (!isShowModal && authorized === "authorized") {
      Middleware.Ui.Modal.open("InTouch");
      Cookie.set("isShowModalInTouch", "true", {
        expires: new Date("Mar 01 2032 18:00:00"),
      });
    }

    if (authorized === "unauthorized") {
      Cookie.set("isShowModalInTouch", "false", {
        expires: new Date("Mar 01 2032 18:00:00"),
      });
    }
  }, [authorized]);

  return (
    <>
      <Helmet>
        <title>ОТМ: SUMMER 2025</title>
      </Helmet>
      <Header />
      <main>
        <Speakers />
        <ThreeWeeksOverview />
        <Schedule />
        <Stands />
        <Prizes />
        {isArchive ? <TariffArchive /> : <Price />}
        <Reviews />
        <Rating />
        <AboutNetworking />
      </main>
    </>
  );
};

export default DashboardPage;
