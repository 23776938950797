import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {
  containerMod?: string;
}

interface StateInterface {}

class Header extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div
          className={`stand-header ${
            this.props.Store.Stand.Selected.data?.additionalImage ? "" : "stand-header--no-mobile"
          } ${this.props.Store.Stand.Selected.data?.mainImage ? "" : "stand-header--no-desktop"}`}
        >
          {/* <video
            className="stand-header__video"
            autoPlay={true}
            loop={true}
            muted={true}
            poster={require("@public/images/stand-bg.jpg")}
          >
            <source
              src="https://profi.travel/uploads/workshops/projects/otm2021/video/video2.mp4"
              type="video/mp4"
            />
          </video> */}

          {this.props.Store.Stand.Selected.data?.mainVideoPreview &&
          this.props.Store.Stand.Selected.data?.mainImage ? (
            <img
              className="stand-header__image"
              src={this.props.Store.Stand.Selected.data?.mainVideoPreview}
              alt=""
            />
          ) : (
            <></>
          )}

          {this.props.Store.Stand.Selected.data?.mainVideoId ? (
            <>
              <Link
                className="stand-header__video-link"
                to="#"
                onClick={() =>
                  Middleware.Ui.Modal.open("StandVideo", {
                    link: this.props.Store.Stand.Selected.data?.mainVideoId,
                  })
                }
              />
            </>
          ) : (
            <></>
          )}

          <div
            className="stand-header__bg stand-header__bg--mobile"
            style={{
              backgroundImage: `url(${this.props.Store.Stand.Selected.data?.additionalImage})`,
            }}
          />
          <div
            className="stand-header__bg stand-header__bg--desktop"
            style={{
              backgroundImage: `url(${this.props.Store.Stand.Selected.data?.mainImage})`,
            }}
          />
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Header)
);
