import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class Event {
  public static async load(event_id: number) {
    useDispatch(Action.Event.setStatus("loading"));
    try {
      const response = await new Model.Event().get(event_id);
      useDispatch(Action.Event.setData(response.data));
      useDispatch(Action.Event.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async count() {
    try {
      const response = await new Model.Event().count();
      useDispatch(Action.Event.setCount(response.data));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
