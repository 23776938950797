import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReviewCard from "./ReviewCard";

const reviewsList = [
  {
    imageSrc: "review-alexandr.png",
    name: "Александр Стахурский",
    subtitle: "ООО «ТАГАНКА ТРЭВЕЛ»",
    description:
      "«В 2024 году впервые принял участие в онлайн-интенсиве “Учись сейчас – зарабатывай зимой” и хочу сказать, что он просто замечательный. Очень интересный материал, особенно по отельной и курортной базе, хорошо рассказывающие спикеры и, самое главное, отличное качество трансляции. Если меня спросят, порекомендую ли я этот онлайн-интенсив для повышения квалификации, однозначно отвечу да».",
  },
  {
    imageSrc: "review-veronika.png",
    name: "Вероника Задорожная",
    subtitle: "ИП «BaQyt Tour»",
    description:
      "«Для меня интенсив оказался очень полезным и информативным. Круг партнеров очень сильно расширился, что полезно и для продаж, и для общего развития в сфере туризма.\nЯ всем своим коллегам рассказала и рекомендовала  Profi.Travel, поделилась впечатлениями об интенсиве и контактами с новыми партнерами».",
  },
];

const Reviews: React.FC = () => {
  const location = useLocation();
  const ref = useRef(null);
  const workshopTitle: string = useSelector(
    (state: any) => state.Main.Workshop.data.title
  );

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "reviews-anchor" && ref) {
      console.log("SCROLL EVENT");
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref]);

  return (
    <section
      className="bg-dark-gray-500 py-[54px] md:py-16 lg:py-[100px] 2xl:py-[120px] 3xl:py-[140px]"
      id="reviews-anchor"
      ref={ref}
    >
      <div className="cont">
        <div className="text-white flex flex-col gap-8 lg:flex-row lg:gap-[58px] justify-between">
          <p className="title-middle max-w-[430px] xl:max-w-full no-wrap">
            Отзывы о прошлом онлайн{"\u2011"}интенсиве
          </p>
          <div className="flex flex-col gap-4 lg:text-18 max-w-[456px] xl:max-w-[544px] 2xl:max-w-[624px] 3xl:max-w-[704px] 4xl:max-w-[743px]">
            <p>
              В августе 2024 года команда Profi.Travel провела интенсив по
              продаже зарубежного турпродукта в рамках выставки{" "}
              <a
                className="text-red-500 hover:underline"
                href="https://otm-winter-2024.profi.travel/"
                target="_blanc"
              >
                ОТМ: Winter 24/25.
              </a>
            </p>
            <p>
              За три недели участники прослушали более 60 онлайн-презентаций,
              специэфиров, мастер-классов. В итоговом опросе они поделились
              положительными отзывами о мероприятии.
            </p>
          </div>
        </div>
        <div className="reviews__list">
          {reviewsList.map((item, index) => (
            <ReviewCard
              key={index}
              imageSrc={item.imageSrc}
              name={item.name}
              subtitle={item.subtitle}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
