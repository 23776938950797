import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class DashboardPage extends React.Component<PropsInterface, StateInterface> {
  public componentDidMount(): void {
    document.title = "Login" + " | " + process.env.SITE_NAME;
    this.props.Dispatch(Middleware.Ui.Footer.hide());
  }

  public render() {
    return (
      <>
        <div className="auth auth--pwd-changed">
          <div className="auth__wrapper">
            <Link className="auth__close" />
            <div className="auth__header">
              <h1>Пароль изменен!</h1>
              <div className="auth__header-desc">
                Отлично! Теперь вам доступны закрытые функции онлайн-выставки.
              </div>
            </div>
            <div className="auth__body">
              <Link className="auth__btn auth__btn--red">Клёво!</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(DashboardPage);
