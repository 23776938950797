import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Model from "@models/index";
import { Method } from "@models/ModelPrototype";
import InputComponent from "./components/input";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  fields: { [key: number]: { name: string; type: string; value: any; hash: string } };
  method: Method;
  url: string;
  inputs: any[];
  hash: number;
}

class TestPage extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      fields: {},
      method: Method.GET,
      url: "",
      inputs: [],
      hash: 1,
    };
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }
  public componentDidMount(): void {
    document.title = "Test API" + " | " + process.env.SITE_NAME;
  }

  private onSubmitHandler(event: React.FormEvent<HTMLFormElement>): void {
    console.log("Method: ", this.state.method);
    console.log("Url: ", this.state.url);

    const data: { [key: string]: number | string } = {};
    Object.keys(this.state.fields).forEach((key: any) => {
      data[this.state.fields[key].name] =
        this.state.fields[key].type === "number"
          ? Number(this.state.fields[key].value)
          : this.state.fields[key].value;
    });
    console.log("Data: ", data);

    new Model.Test().initializeRequest(this.state.url, this.state.method, data).then((response) => {
      console.log(response);
    });
    event.preventDefault();
  }

  public render() {
    return (
      <>
        <form onSubmit={this.onSubmitHandler}>
          <select
            name="method"
            defaultValue={Method.GET}
            onChange={(e) => {
              this.setState({ method: e.target.value });
            }}
          >
            <option value={Method.GET}>GET</option>
            <option value={Method.POST}>POST</option>
            <option value={Method.PUT}>PUT</option>
            <option value={Method.DELETE}>DELETE</option>
          </select>
          <input
            type="text"
            name="url"
            value={this.state.url}
            onChange={(e) => {
              this.setState({ url: e.target.value });
            }}
            placeholder="/profile/1"
            required={true}
          />
          {this.state.inputs.map((component: any, index: number) => {
            return <div key={index}>{component}</div>;
          })}
          <div>
            <Link
              to="#"
              onClick={() => {
                const inputs = this.state.inputs;
                inputs.push(
                  <InputComponent
                    hash={this.state.hash}
                    onChange={(item) => {
                      this.setState({
                        fields: { ...this.state.fields, ...{ [item.hash]: item } },
                      });
                    }}
                  />
                );
                this.setState({ hash: this.state.hash + 1 });
              }}
            >
              Добавить поле
            </Link>
          </div>

          <button type="submit">Отправить запрос</button>
        </form>
      </>
    );
  }
}

export default connect(
  (Store: any) => {
    return { Store };
  },
  (Dispatch: (action: any) => void) => {
    return { Dispatch: (action: any) => Dispatch(action) };
  }
)(TestPage);
