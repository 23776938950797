import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import Calendar from "./Calendar";
import moment, { Moment } from "moment-timezone";
import * as Model from "@models/index";

interface PropsInterface extends Type.ComponentInterface {
  duration: number;
  onSubmit: any;
}

interface StateInterface {
  isTimeZoneShowed: boolean;
  date: Moment | null;
  times: any[];
  selected: Moment | null;
  currentDate: Moment;
}

class Step2 extends React.Component<PropsInterface, StateInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      isTimeZoneShowed: false,
      date: null,
      times: [],
      selected: null,
      currentDate: moment(),
    };
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.generateTime = this.generateTime.bind(this);
  }

  public componentDidMount() {
    this.setState({ currentDate: moment(this.props.Store.Main.Time.current) });
  }

  private onSelectHandler(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      [event.target.name]: this.state.times[Number(event.target.value)]?.time.clone(),
    });
  }

  private generateTime() {
    if (this.state.date) {
      new Model.User2.Calendar()
        .times(Number(this.props.match.params.user_id), {
          day: this.state.date?.format("YYYY-MM-DD"),
          duration: `PT${this.props.duration}M`,
        })
        .then((response: any) => {
          const times: any[] = [];
          response.data.forEach((Time: any) => {
            times.push({
              time: moment(Time.startTime).tz("Europe/Moscow"),
              available: Time.available,
            });
          });
          this.setState({ times });
        });
    }
  }

  private onSubmitHandler(data: any): void {
    this.props.onSubmit({
      step: 3,
      datetime: this.state.selected,
    });
  }

  public render() {
    return (
      <>
        <form className="event-create-step-2" onSubmit={this.onSubmitHandler}>
          <div className="event-create-step-2__content">
            <div className="event-create-step-2__title">
              Шаг 2 <span>/ 3</span>
            </div>
            <div className="event-create-step-2__desc">Выберите день и время</div>
          </div>
          <div className="event-create-step-2__calendar">
            <Calendar
              onSubmit={(date: Moment) =>
                this.setState({ date, times: [], selected: null }, this.generateTime)
              }
            />
            <input
              className="visually-hidden"
              type="text"
              name="date"
              value={this.state.date?.format("YYYY-MM-DD HH:mm")}
              required={true}
            />
          </div>
          <div className="event-create-step-2__timezone">
            <div className="event-create-step-2__timezone-wrapper">
              <div
                className="event-create-step-2__timezone-trigger"
                onClick={() => this.setState({ isTimeZoneShowed: !this.state.isTimeZoneShowed })}
              >
                <span>MSK (gmt+3)</span>
                <span>{this.state.currentDate.tz("Europe/Moscow").format("HH:mm")}</span>
                {/* <span>Московское время:</span>
                <span>
                  16:00 <img src={require("@public/images/event-calendar.svg")} alt="" />
                </span> */}
              </div>
              {/* <div
                className={`event-create-step-2__timezone-list ${
                  this.state.isTimeZoneShowed ? "event-create-step-2__timezone-list--open" : ""
                }`}
              >
                <input className="visually-hidden" type="radio" name="timerange" id="timerange1" />
                <label htmlFor="timerange1">
                  <span>Московское время</span>
                  <span>16:00</span>
                </label>
                <input className="visually-hidden" type="radio" name="timerange" id="timerange2" />
                <label htmlFor="timerange2">
                  <span>Время собеседника</span>
                  <span>18:00</span>
                </label>
              </div> */}
            </div>
          </div>
          <div className="event-create-step-2__time">
            <div className="event-create-step-2__time-list">
              <input
                className="visually-hidden"
                type="text"
                name="time"
                value={this.state.selected?.format("YYYY-MM-DD HH:mm")}
                required={true}
              />
              {this.state.times.map((time: Moment, index: number) => {
                return (
                  <div className="event-create-step-2__time-item" key={index}>
                    <input
                      className="visually-hidden"
                      type="radio"
                      name="selected"
                      value={index}
                      id={`time-${index}`}
                      onChange={this.onSelectHandler}
                      checked={
                        this.state.selected?.format("DD-MM-YYYY HH:mm") ===
                        time.time.format("DD-MM-YYYY HH:mm")
                      }
                      disabled={!time.available}
                    />
                    <label htmlFor={`time-${index}`}>{time.time.format("HH:mm")}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="event-create-step-2__final">
            <div className="event-create-step-2__final-title">Время встречи</div>
            <div className="event-create-step-2__final-time">
              {this.state.date?.format("D MMMM")}
              <span>{this.state.selected?.format("HH:mm")}</span>
            </div>
            <div className="event-create-step-2__final-buttons">
              <button
                type="submit"
                className={`event-create-step-2__final-btn  btn btn--red ${
                  this.state.selected ? "" : "disabled"
                }`}
              >
                Подтвердить
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Step2)
);
