import React from "react";
import * as Type from "@interface/index";
import { Link } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import { Input, InputCompanyType, InputAutocomplete, Checkbox } from "@components/form/index";
import Office from "./components/Office";
import Connector from "@helpers/Connector";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  company_id: number;
  step: number;
  // * step 1
  name: string;
  types: any[];
  about: string;
  // * step 2
  directions: any[];
  // * step 3
  specializations: any[];
  // * step 4
  avatar: string | null;
  // * step 5
  offices: any;
  website: string;
  facebook: string;
  instagram: string;
  vkontakte: string;
  youtube: string;
  tiktok: string;
  telegram: string;
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  step5: boolean;
  companyExists: boolean;
}

export default Connector(
  class CompanyCreatePage extends React.Component<PropsInterface, StateInterface> {
    public constructor(props: PropsInterface) {
      super(props);
      this.state = {
        step: 1,
        // * step 1
        name: "",
        types: [],
        about: "",
        // * step 2
        directions: [],
        // * step 3
        specializations: [],
        // * step 4
        avatar: null,
        // * step 5
        offices: {
          sdfg: {
            name: "",
            email: "",
            phone: "",
            address: "",
            country_id: null,
            city_id: null,
            username: "",
            userposition: "",
          },
        },
        website: "",
        facebook: "",
        instagram: "",
        vkontakte: "",
        youtube: "",
        tiktok: "",
        telegram: "",
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        companyExists: false,
      };
      this.onSubmitHandler = this.onSubmitHandler.bind(this);
      this.onChangeHandler = this.onChangeHandler.bind(this);
      this.onCheckHandler = this.onCheckHandler.bind(this);
      this.onFileSelectHandler = this.onFileSelectHandler.bind(this);
      this.onOfficeChange = this.onOfficeChange.bind(this);
    }

    public componentDidMount() {
      Middleware.Location.Country.load();
      Middleware.Company.Type.load();
      Middleware.Company.Direction.load();
      Middleware.Company.Specialization.load();

      this.selectCompany();
    }

    public selectCompany() {
      this.setState({ company_id: Number(this.props.match.params.company_id || 0) }, () => {
        if (this.props.match.params.company_id && Number(this.props.match.params.company_id)) {
          Middleware.Company.Current.load(Number(this.props.match.params.company_id), true).then(
            (Company) => {
              this.setState({
                name: Company.title || "",
                types: Company.types || [],
                about: Company.description || "",
                directions: Company.directions || [],
                specializations: Company.specializations || [],
                avatar: Company.companyImage || null,
                website: Company.website || "",
                facebook: Company.networkingProfile.facebook || "",
                instagram: Company.networkingProfile.instagram || "",
                vkontakte: Company.networkingProfile.vkontakte || "",
                youtube: Company.networkingProfile.youtube || "",
                tiktok: Company.networkingProfile.tiktok || "",
                telegram: Company.networkingProfile.telegram || "",
              });
            }
          );
        }
      });
    }

    private onOfficeChange(Office: any) {
      const offices = this.state.offices;
      offices[Office.hash] = {
        name: Office.name,
        email: Office.email,
        phone: Office.phone,
        address: Office.address,
        country_id: Office.country_id,
        city_id: Office.city_id,
        username: Office.username,
        userposition: Office.userposition,
      };
      this.setState({ offices });
    }

    private onSubmitHandler(event?: any): void {
      const data: any = {
        name: this.state.name,
        types: this.state.types,
        about: this.state.about,
        creator: this.props.Store.Auth.data.id,
        website: this.state.website,
      };

      if (this.state.company_id) {
        data.company_id = this.state.company_id;
      }

      if (this.state.step2) {
        data.directions = this.state.directions;
      }
      if (this.state.step3) {
        data.specializations = this.state.specializations;
      }
      if (this.state.step4) {
        data.avatar = this.state.avatar;
      }
      if (this.state.step5) {
        const offices: any[] = [];
        Object.keys(this.state.offices).map((hash: any) => {
          if (
            this.state.offices[hash].name.length &&
            this.state.offices[hash].email.length &&
            this.state.offices[hash].phone.length &&
            this.state.offices[hash].address.length &&
            this.state.offices[hash].country_id.length &&
            this.state.offices[hash].city_id.length &&
            this.state.offices[hash].username.length &&
            this.state.offices[hash].userposition.length
          ) {
            offices.push(this.state.offices[hash]);
          }
        });
        data.offices = offices;

        data.website = this.state.website;

        data.socials = {
          facebook: this.state.facebook,
          instagram: this.state.instagram,
          vkontakte: this.state.vkontakte,
          youtube: this.state.youtube,
          tiktok: this.state.tiktok,
          telegram: this.state.telegram,
        };
      }

      console.log(data);
      Middleware.Company.Current.create(data).then(() =>
        Middleware.Profile.load().then(() => {
          this.props.history.push("/service/networking/enabled");
        })
      );
    }

    private onChangeHandler(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
      this.setState({ [event.target.name]: event.target.value });
    }

    private onCheckHandler(
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ): void {
      const list = this.state[event.target.name];
      if (list.includes(event.target.value)) {
        list.splice(list.indexOf(event.target.value), 1);
      } else {
        list.push(event.target.value);
      }
      this.setState({ [event.target.name]: list });
    }

    private onFileSelectHandler(event: any) {
      const selectedFile = event.target.files[0];
      const fr = new FileReader();

      fr.onload = (event) => {
        const image = new Image();
        image.src = event.target?.result;
        image.onload = () => {
          const height = image.naturalWidth || image.width;
          const width = image.naturalHeight || image.height;
          console.log(`Image [width: ${width} | height: ${height}]`);
          if (width >= 250 && width <= 2000 && height >= 250 && height <= 2000) {
            this.setState({ avatar: event.target?.result });
          } else {
            Middleware.Ui.Alert.open("Default", {
              text: "Аватар должен быть в ширину и высоту больше 200px и меньше 1280px",
            });
          }
        };
      };

      fr.readAsDataURL(selectedFile);
    }

    private renderStep(step: number) {
      switch (step) {
        case 1:
          return (
            <div className="company-create__block">
              <form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ step1: true, step: 2 });
                  console.log("state", this.state);
                }}
              >
                <div className="form__header">
                  <div className="company-create__block-title">
                    Шаг 1 <span>/ 5</span>
                  </div>
                  <div className="company-create__block-desc">
                    Укажите основную информацию компании
                  </div>
                  <div className="company-create__block-req">Обязательный пункт</div>
                </div>
                <div className="form__body">
                  <div className="form__group">
                    <InputAutocomplete
                      name="name"
                      type="company"
                      selected={this.state.company_id}
                      placeholder="Компания"
                      value={this.state.name}
                      onChange={(e: any) => {
                        // this.onChangeHandler(e)
                        this.setState({
                          name: e.target.value,
                          companyExists: false,
                        });
                      }}
                      required={true}
                      withCompanyStatus={true}
                      onSelect={(event: any) => {
                        if (!event.target.networkingStatus && !event.target.currentWorkshopStatus) {
                          // Юзер уходит создавать компанию
                          this.selectCompany();
                          this.props.history.push(`/company/create/${event.target.value}`);
                        } else if (
                          event.target.networkingStatus &&
                          !event.target.currentWorkshopStatus
                        ) {
                          // Юзер начинает управлять компанией
                          Middleware.Company.Employee.invite(event.target.value).then(
                            (response: any) => {
                              this.selectCompany();
                              this.props.history.push(`/company/${event.target.value}/edit`);
                            }
                          );
                        } else if (
                          event.target.networkingStatus &&
                          event.target.currentWorkshopStatus
                        ) {
                          // Юзер присоединяется к компании
                          Middleware.Company.Employee.invite(event.target.value).then(
                            (response: any) => {
                              Middleware.Profile.load().then(() => {
                                this.props.history.push("/service/networking/enabled");
                              });
                            }
                          );
                        }
                        console.log(event);
                      }}
                    />
                    {this.state.companyExists ? (
                      <>
                        <div className="company-create__company-exist">
                          Компания с таким названием уже зарегистрирована в нетворкинге! Перейдите в{" "}
                          <Link to="/cabinet/profile">профиль</Link> и отправьте заявку на
                          присоединение к компании
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <InputCompanyType
                      name="types"
                      value={this.state.types}
                      onChange={this.onChangeHandler}
                      required={true}
                    />
                    <div className="textarea">
                      <textarea
                        className="textarea__input"
                        name="about"
                        id=""
                        placeholder="О компании*"
                        onChange={this.onChangeHandler}
                        required={true}
                        maxLength={500}
                        value={this.state.about}
                      />
                      <div className="textarea__counter">
                        {500 - this.state.about?.length} знаков
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__footer">
                  <button type="submit" className="btn btn--red">
                    Дальше
                  </button>
                </div>
              </form>
            </div>
          );
        case 2:
          return (
            <div className="company-create__block">
              <form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ step2: true, step: 3 });
                }}
              >
                <div className="form__header">
                  <div className="company-create__block-title">
                    Шаг 2 <span>/ 5</span>
                  </div>
                  <div className="company-create__block-desc">
                    С какими направлениями работает компания?
                  </div>
                  <div className="company-create__block-req">
                    Этот пункт вы можете заполнить потом
                  </div>
                </div>
                <div className="form__body">
                  <div className="form__group">
                    <div className="company-create__step-3-disclaimer">
                      Отметьте один или несколько пунктов, если компания специализируется на
                      определенных направлениях.
                      <ul>
                        <li>
                          Если вы работаете со всеми направлениями, введите «Все направления» в окне
                          поиска{" "}
                        </li>
                        <li>Если у компании нет специализации на направлениях, нажмите «Дальше»</li>
                      </ul>
                    </div>
                    <div className="form__autocomplete-tags">
                      {this.props.Store.Company.Direction.list.map((Item: any, index: number) => {
                        if (this.state.directions.includes(Item.id)) {
                          return (
                            <Link
                              to="#"
                              className="form__autocomplete-tag"
                              onClick={() => {
                                const directions = this.state.directions;
                                const id = this.state.directions.indexOf(Item.id);
                                if (id >= 0) {
                                  directions.splice(id, 1);
                                }
                                this.setState({ directions });
                              }}
                            >
                              {Item.name}
                            </Link>
                          );
                        }
                      })}
                    </div>
                    <InputAutocomplete
                      name="directions"
                      type="custom"
                      list={this.props.Store.Company.Direction.list}
                      placeholder="Начните вводить название"
                      clearOnSelect={true}
                      onSelect={(event: any) => {
                        const directions = this.state.directions;
                        if (
                          !directions.includes(event.target.value) &&
                          event.target.value !== null
                        ) {
                          directions.push(event.target.value);
                        }
                        this.setState({ directions });
                      }}
                    />
                  </div>
                </div>
                <div className="form__footer">
                  <button type="submit" className="btn btn--red">
                    Дальше
                  </button>
                  <button
                    type="button"
                    className="btn btn--grey-2"
                    onClick={() => {
                      this.setState({ step: this.state.step - 1 });
                    }}
                  >
                    Назад
                  </button>

                  <Link to="#" className="btn btn--skip" onClick={() => this.setState({ step: 3 })}>
                    Заполнить позже
                  </Link>
                </div>
              </form>
            </div>
          );
        case 3:
          return (
            <div className="company-create__block">
              <form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ step3: true, step: 4 });
                }}
              >
                <div className="form__header">
                  <div className="company-create__block-title">
                    Шаг 3 <span>/ 5</span>
                  </div>
                  <div className="company-create__block-desc">Укажите специализацию компании</div>
                  <div className="company-create__block-req">
                    Этот пункт вы можете заполнить потом
                  </div>
                </div>
                <div className="form__body">
                  <div className="form__group">
                    <div className="company-create__step-3-disclaimer">
                      Отметьте один или несколько пунктов, если данные виды туризма занимают
                      существенную долю в ваших продажах. Если у компании нет специализации, нажмите
                      «Дальше»
                    </div>
                    <div className="checkbox-list">
                      {this.props.Store.Company.Specialization.list.map(
                        (Item: any, index: number) => {
                          return (
                            <Checkbox
                              key={index}
                              name="specializations"
                              placeholder={Item.name}
                              value={Item.id}
                              hiddenInput={true}
                              checked={this.state.specializations.includes(Item.id)}
                              onChange={this.onCheckHandler}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__footer">
                  <button type="submit" className="btn btn--red">
                    Дальше
                  </button>
                  <button
                    type="button"
                    className="btn btn--grey-2"
                    onClick={() => {
                      this.setState({ step: this.state.step - 1 });
                    }}
                  >
                    Назад
                  </button>

                  <Link to="#" className="btn btn--skip" onClick={() => this.setState({ step: 4 })}>
                    Заполнить позже
                  </Link>
                </div>
              </form>
            </div>
          );
        case 4:
          return (
            <div className="company-create__block">
              <form
                className="form company-create__step-4-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.setState({ step4: true, step: 5 });
                }}
              >
                <div className="form__header">
                  <div className="company-create__block-title">
                    Шаг 4 <span>/ 5</span>
                  </div>
                  <div className="company-create__block-desc">Загрузите логотип компании</div>
                  <div className="company-create__block-req">
                    Этот пункт вы можете заполнить потом
                  </div>
                </div>
                <div className="form__body">
                  <div className="form__group">
                    <div className="company-create__avatar">
                      {this.state.avatar ? (
                        <img src={this.state.avatar} />
                      ) : (
                        <>
                          <div>Загрузить логотип</div>
                          {/* <div>минимум 250x250 px</div> */}
                        </>
                      )}

                      <input
                        type="file"
                        name="avatar"
                        id="avatar"
                        required={this.state.avatar === null ? false : true}
                        onChange={this.onFileSelectHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__footer">
                  <button type="submit" className="btn btn--red">
                    Дальше
                  </button>
                  <button
                    type="button"
                    className="btn btn--grey-2"
                    onClick={() => {
                      this.setState({ step: this.state.step - 1 });
                    }}
                  >
                    Назад
                  </button>

                  <Link to="#" className="btn btn--skip" onClick={() => this.setState({ step: 5 })}>
                    Заполнить позже
                  </Link>
                </div>
              </form>
            </div>
          );
        case 5:
          return (
            <div className="company-create__block">
              <form
                className="form company-create__step-5-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  this.setState({ step5: true, step: 6 }, () => this.onSubmitHandler(event));
                }}
              >
                <div className="form__header">
                  <div className="company-create__block-title">
                    Шаг 5 <span>/ 5</span>
                  </div>
                  <div className="company-create__block-desc">Укажите контактные данные офиса</div>
                  <div className="company-create__block-req">
                    Этот пункт вы можете заполнить потом
                  </div>
                </div>
                <div className="form__body">
                  {Object.keys(this.state.offices).map((hash: string) => {
                    return <Office hash={hash} key={hash} onChange={this.onOfficeChange} />;
                  })}

                  <div className="form__group form__group--span-2">
                    <button
                      className="btn btn--new-field"
                      type="button"
                      onClick={() => {
                        const offices = this.state.offices;
                        offices[Math.random().toString(36).substr(2, 5)] = {
                          officename: "",
                          officeemail: "",
                          officephone: "",
                          officeaddress: "",
                          officecountry_id: null,
                          officecity_id: null,
                          contactname: "",
                          contactposition: "",
                        };
                        this.setState({ offices });
                      }}
                    >
                      {/* <span /> */}
                      Добавить офис
                    </button>
                  </div>
                  <div className="form__group form__group--span-2">
                    Укажите ссылки на официальные аккаунты компании в соцсетях
                  </div>
                  <div className="form__group form__group--links">
                    <Input
                      type="text"
                      name="website"
                      placeholder="Веб-сайт"
                      value={this.state.website}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="facebook"
                      placeholder="Facebook"
                      value={this.state.facebook}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="instagram"
                      placeholder="Instagram"
                      value={this.state.instagram}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="vkontakte"
                      placeholder="Vk.com"
                      value={this.state.vkontakte}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="youtube"
                      placeholder="Youtube"
                      value={this.state.youtube}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="tiktok"
                      placeholder="Tik-tok"
                      value={this.state.tiktok}
                      onChange={this.onChangeHandler}
                    />
                    <Input
                      type="text"
                      name="telegram"
                      placeholder="Telegram"
                      value={this.state.telegram}
                      onChange={this.onChangeHandler}
                    />
                  </div>
                </div>
                <div className="form__footer">
                  <button type="submit" className="btn btn--red">
                    Дальше
                  </button>
                  <button
                    type="button"
                    className="btn btn--grey-2"
                    onClick={() => {
                      this.setState({ step: this.state.step - 1 });
                    }}
                  >
                    Назад
                  </button>

                  <Link to="#" className="btn btn--skip" onClick={this.onSubmitHandler}>
                    Заполнить позже
                  </Link>
                </div>
              </form>
            </div>
          );
      }
    }

    public render() {
      return (
        <>
          <div className="company-create">
            <div className="company-create__grid">
              <h1 className="company-create__title">Добавить компанию</h1>

              {this.renderStep(this.state.step)}
            </div>
          </div>
        </>
      );
    }
  }
);
