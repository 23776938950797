import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {
  children: any;
}

interface StateInterface {}

class Questions extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="stand-questions stand__block">
          <div className="stand-questions__q-form">
            <div className="stand-questions__q-form-header">
              <div className="stand-questions__q-form-title">Задать вопрос</div>
              <div className="stand-questions__q-form-desc">
                Сделайте запрос информации на этом стенде. Вам ответят эксперты Национального
                агентства по туризму республики Беларусь.
              </div>
            </div>
            <div className="stand-questions__q-form-body">
              <div className="stand-questions__q-form-disclaimer">
                Задайте вопрос в поле ниже. Он будет опубликован на этом стенде.
              </div>
              <textarea
                className="textarea"
                name="question-1"
                id="question-1"
                placeholder="Напишите вопрос"
              />
              <button className="btn btn--red" type="submit">
                Отправить
              </button>
            </div>
          </div>
          <hr />
          <div className="stand-questions__q-list">
            <div className="stand-questions__q-list-title">Вопросы</div>
            {this.props.children}
          </div>
          <Link className="stand-questions__more-link" to="#">
            Показать вопросы и ответы
            <img src={require("@public/images/stand-arrow.svg")} alt="" />
          </Link>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Questions)
);
