import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class Event extends ModelPrototype {
  public async list(): Promise<any> {
    try {
      return this.withUrl(`cabinet/event/list`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async get(id: number): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/${id}`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async update(id: number, data: any = {}): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/${id}`).setMethod(Method.PUT).request(data);
    } catch (Exception) {
      throw Exception;
    }
  }

  public async delete(id: number): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/${id}`).setMethod(Method.DELETE).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async subscribe(id: number): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/${id}/subscribe`).setMethod(Method.PUT).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async unsubscribe(id: number): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/${id}/unsubscribe`).setMethod(Method.PUT).request();
    } catch (Exception) {
      throw Exception;
    }
  }

  public async calendar(): Promise<any> {
    try {
      return this.withUrl(`cabinet/events/calendar`).setMethod(Method.GET).request();
    } catch (Exception) {
      throw Exception;
    }
  }
}
