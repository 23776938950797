import { PreloaderPage } from "@components/preloader";
import React from "react";
import { useSelector } from "react-redux";

interface UnauthorizedRouteProps {
  children: React.ReactNode;
}

export const UnauthorizedRoute = ({
  children,
}: UnauthorizedRouteProps): JSX.Element => {
  const authStatus = useSelector((state: any) => state.Auth.status);

  if (authStatus === "loading") {
    return <PreloaderPage />;
  }

  if (authStatus === "authorized") {
    return <></>;
  }

  return <> {children} </>;
};
