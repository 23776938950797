import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import moment from "moment-timezone";
import * as Model from "@models/index";
import Calendar from "./components/calendar";
import * as Middleware from "@middlewares/index";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {
  events: any[];
  selected: any;
  dates: any[];
  width: number;
}

class EventsCalendarPage extends React.Component<
  PropsInterface,
  StateInterface
> {
  private mediaQuery: MediaQueryList = window.matchMedia("(min-width: 1600px)");
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      events: [],
      selected:
        moment().tz("Europe/Moscow") <
        moment("2021-10-11T10:00:00+03:00").tz("Europe/Moscow")
          ? moment("2021-10-11T10:00:00+03:00").tz("Europe/Moscow")
          : moment().tz("Europe/Moscow"),
      dates: [],
      width: 1600,
    };
    this.onResize = this.onResize.bind(this);
    this.previousDay = this.previousDay.bind(this);
    this.nextDay = this.nextDay.bind(this);
    this.loadEventsList = this.loadEventsList.bind(this);
  }

  public componentWillMount() {
    this.onResize();
  }

  public componentDidMount() {
    this.mediaQuery.addEventListener("change", this.onResize);
    this.loadEventsList();
    this.makeEventColumns();
  }

  public componentWillUnmount() {
    this.mediaQuery.removeEventListener("resize", this.onResize);
  }

  public onResize() {
    this.setState({ width: window.innerWidth });
  }
  public loadEventsList() {
    new Model.User2.Event()
      .calendar({
        dateStart: moment(this.state.selected)
          .tz("Europe/Moscow")
          .format("YYYY-MM-DD"),
        dateEnd: moment(this.state.selected)
          .tz("Europe/Moscow")
          .add(6, "days")
          .format("YYYY-MM-DD"),
      })
      .then((response) => {
        this.setState({ events: response.data });
      });
  }

  public makeEventColumns() {
    const dates = [];
    for (let i = 0; i < 6; i++) {
      dates.push(
        moment(this.state.selected)
          .tz("Europe/Moscow")
          .startOf("day")
          .add(i, "days")
      );
    }
    this.setState({ dates });
  }

  public previousDay() {
    this.setState(
      {
        selected: moment(this.state.selected)
          .tz("Europe/Moscow")
          .subtract(this.state.width < 1600 ? 4 : 6, "days"),
      },
      () => {
        this.loadEventsList();
        this.makeEventColumns();
      }
    );
  }

  public nextDay() {
    this.setState(
      {
        selected: moment(this.state.selected)
          .tz("Europe/Moscow")
          .add(this.state.width < 1600 ? 4 : 6, "days"),
      },
      () => {
        this.loadEventsList();
        this.makeEventColumns();
      }
    );
  }

  public render() {
    const netwDays = this.props.Store.Networking?.Days.data;

    return (
      <div className="calendar-page">
        <div className="calendar-page__header">
          <h1 className="cabinet__title">Календарь</h1>
          <div className="calendar-page__header-today">
            Сегодня:{" "}
            {moment().tz("Europe/Moscow").locale("ru").format("DD MMMM")}
          </div>
          <div className="calendar-page__header-week">
            <Link
              to="#"
              className="calendar-page__header-week-arrow calendar-page__header-week-arrow--prev"
              onClick={this.previousDay}
            />
            {this.state.dates[0]?.locale("ru").format("DD MMMM")}
            {" - "}
            {this.state.dates[this.state.width < 1600 ? 3 : 5]
              ?.locale("ru")
              .format("DD MMMM")}
            <Link
              to="#"
              className="calendar-page__header-week-arrow calendar-page__header-week-arrow--next"
              onClick={this.nextDay}
            />
          </div>

          {netwDays?.length ? (
            <Link
              className="calendar-page__meet-request"
              to="/cabinet/events"
              onClick={(e) => {
                if (!this.props.Store.Profile?.data?.useNetworking) {
                  e.preventDefault();
                  Middleware.Ui.Modal.open("EnterNetworking");
                }
              }}
            >
              <span className="calendar-page__meet-request-icon" />
              Запросы на встречу
            </Link>
          ) : null}
        </div>
        <div className="calendar-page__body">
          <Calendar
            Events={this.state.events}
            Dates={this.state.dates}
            Refresh={this.loadEventsList}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(EventsCalendarPage)
);
