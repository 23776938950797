/* eslint-disable @typescript-eslint/indent */
import ModelPrototype, { Method } from "@models/ModelPrototype";

export default class NetworkingExponent extends ModelPrototype {
  public async page(
    page: number,
    filters?: { specializations?: number[]; types?: number[]; direction?: number; city?: number[] }
  ): Promise<{ data: any }> {
    try {
      return this.withUrl(`networking/exponent/list`)
        .setMethod(Method.GET)
        .request({
          type: 3,
          page,
          ...(filters
            ? {
                ...(filters.specializations !== undefined
                  ? { specializationList: filters.specializations }
                  : {}),
                ...(filters.types !== undefined ? { typeList: filters.types } : {}),
                ...(filters.direction !== undefined ? { direction: filters.direction } : {}),
                ...(filters.city !== undefined ? { cityId: filters.city } : {}),
              }
            : {}),
        });
    } catch (Exception) {
      throw Exception;
    }
  }
}
