import React, { ReactElement, useEffect, useState } from "react";
import * as Type from "@interface/index";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import * as Middleware from "@middlewares/index";
import {
  Input,
  Select,
  InputCompanyType,
  InputAutocomplete,
  Modificator,
} from "@components/form/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

Middleware.Location.Country.load();
Middleware.Company.Type.load();

const confirmProfile = (): ReactElement => {
  const history = useHistory();
  const preUrlStatus = useSelector((state: any) => state.Auth.preurl_status);
  const preUrl = useSelector((state: any) => state.Auth.preurl);
  const countryList = useSelector((state: any) => state.Location.Country.list);
  const profileData = useSelector((state: any) => state.Profile.data);

  const [contacts, setContacts] = useState<{
    firstname: string;
    lastname: string;
    country_id: number | null;
    city_id: number | null;
    phone: string;
    worktype: number;
    companyname: string;
    companytypes: any[];
    position: string;
  }>({
    firstname: "",
    lastname: "",
    country_id: null,
    city_id: null,
    phone: "",
    worktype: 1,
    companyname: "",
    companytypes: [],
    position: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    Middleware.Profile.load().then((profile) => {
      setContacts({
        firstname: profile.firstName || "",
        lastname: profile.lastName || "",
        country_id: profile.networkingProfile?.country || null,
        city_id: profile.networkingProfile?.city || null,
        phone: profile.phone || "",
        worktype: profile.networkingProfile?.employmentType || 1,
        companyname: profile.workName || "",
        companytypes: profile.networkingProfile?.companyTypes || [],
        position: profile.position || "",
      });
    });
  }, [dispatch]);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setContacts({ ...contacts, [event.target.name]: event.target.value });
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    Middleware.Profile.update({
      ...profileData,

      firstname: contacts.firstname,
      lastname: contacts.lastname,
      country_id: contacts.country_id,
      city_id: contacts.city_id,
      phone: contacts.phone,
      worktype: contacts.worktype,
      companyname: contacts.companyname,
      companytypes: contacts.companytypes,
      position: contacts.position,
    }).then(() => {
      if (profileData.useNetworking) {
        history.push({
          pathname: "/auth/networking",
        });
        return;
      } else {
        history.push({
          pathname: preUrl,
        });
      }
    });
  };

  return (
    <>
      <div className="auth">
        <div className="auth__wrapper">
          <form className="form" onSubmit={onSubmitHandler}>
            <div className="form__header">
              <h1>Это ваши контактные данные?</h1>
              <div className="form__header-desc">
                Пожалуйста, убедитесь, что вся контактная информация актуальна&nbsp;&mdash;
                по&nbsp;указанному адресу электронной почты и&nbsp;телефону вы&nbsp;будете получать
                уведомления об&nbsp;эфирах, новых встречах и&nbsp;визитках (если ваш профиль
                участвует в&nbsp;нетворкинге)
              </div>
            </div>
            <div className="form__body">
              <div className="form__group form__group--col-2">
                <Input
                  type="text"
                  name="firstname"
                  placeholder="Имя"
                  value={contacts.firstname}
                  required={true}
                  onChange={onChangeHandler}
                />
                <Input
                  type="text"
                  name="lastname"
                  placeholder="Фамилия"
                  required={true}
                  value={contacts.lastname}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="form__group">
                <Select
                  name="country_id"
                  value={contacts.country_id}
                  onChange={onChangeHandler}
                  options={countryList}
                  required={true}
                  placeholder="*Страна"
                />
                {contacts.country_id === null ? (
                  <></>
                ) : (
                  <>
                    <InputAutocomplete
                      name="city_id"
                      type="city"
                      selected={contacts.city_id}
                      country_id={contacts.country_id}
                      placeholder="Город"
                      onSelect={onChangeHandler}
                      required={true}
                    />
                  </>
                )}
              </div>
              <div className="form__group">
                {/* <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={"Телефон"}
                  value={contacts.phone}
                  onChange={onChangeHandler}
                  modificator={Modificator.PhoneWithPrefix}
                  required={true}
                /> */}
                <PhoneInput
                  country={"ru"}
                  placeholder={"Телефон"}
                  value={contacts.phone}
                  onChange={(phone) => {
                    setContacts({ ...contacts, phone });
                  }}
                  containerClass={"phone-input"}
                  inputProps={{
                    name: "phone",
                    required: true,
                    pattern: ".{7,}",
                  }}
                />
              </div>
              <hr />

              <div className="form__group">
                <div className="radio">
                  <input
                    className="visually-hidden"
                    type="radio"
                    name="worktype"
                    id="jobtype-1"
                    onChange={() => setContacts({ ...contacts, worktype: 1 })}
                    checked={contacts.worktype === 1}
                  />
                  <label tabIndex={0} htmlFor="jobtype-1">
                    Работаю в компании
                  </label>
                  <input
                    className="visually-hidden"
                    type="radio"
                    name="worktype"
                    id="jobtype-2"
                    value={2}
                    onChange={() => setContacts({ ...contacts, worktype: 2 })}
                    checked={contacts.worktype === 2}
                  />
                  <label htmlFor="jobtype-2">Фрилансер, самозанятый</label>
                </div>
              </div>
              <div className="form__group">
                {contacts.worktype !== 1 ? (
                  <></>
                ) : (
                  <>
                    <InputAutocomplete
                      value={contacts.companyname}
                      name="companyname"
                      type="company"
                      placeholder="Компания"
                      onChange={onChangeHandler}
                      required={true}
                    />
                    <InputCompanyType
                      name="companytypes"
                      value={contacts.companytypes}
                      onChange={onChangeHandler}
                      required={true}
                    />
                  </>
                )}

                <Input
                  type="text"
                  name="position"
                  placeholder={contacts.worktype !== 1 ? "Ваша профессия" : "Ваша должность"}
                  onChange={onChangeHandler}
                  value={contacts.position}
                  required={true}
                />
              </div>
            </div>
            <div className="form__footer">
              <button type="submit" className="btn btn--red">
                Подтвердить
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default confirmProfile;
