import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, withRouter } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class CompanyEmployeesInbox extends React.Component<PropsInterface, StateInterface> {
  public render() {
    return (
      <>
        <div className="company-employees__inbox">
          {this.props.Store.Company.Employee.list
            ?.filter((Employee: any) => Employee.userRequestStatus === 0)
            ?.map((Employee: any, index: number) => {
              return (
                // <>
                // {/* {index !== 0 ? <hr className="company-employees__inbox-divider" /> : <></>} */}
                <div className="company-employees__inbox-item" key={Employee.id}>
                  <div className="company-employees__inbox-item-body">
                    <div className="company-employees__inbox-item-user">
                      <div className="company-employees__inbox-item-img">
                        <img src={Employee.userImage} alt="" />
                      </div>
                      <div className="company-employees__inbox-item-name">
                        {Employee.firstName + " " + Employee.lastName}
                      </div>
                      <div className="company-employees__inbox-item-desc">{Employee.position}</div>
                    </div>
                    <div className="company-employees__inbox-item-contacts">
                      <a href={`mailto:${Employee.email}`}>{Employee.email}</a>
                      {/* <a href="tel:+79005000000">+7 900 500-00-00</a> */}
                    </div>
                  </div>
                  <hr />
                  <div className="company-employees__inbox-item-buttons">
                    <Link
                      className="btn btn--red"
                      to="#"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log("AGRHRHHH!! DEBUG!!!!!!");
                        Middleware.Company.Employee.accept(
                          Number(this.props.match.params.company_id),
                          Employee.id
                        );
                      }}
                    >
                      Добавить в компанию
                    </Link>
                    <Link
                      className="btn btn--grey"
                      to="#"
                      onClick={(event) => {
                        event.preventDefault();
                        console.log("AGRHRHHH!! DEBUG!!!!!!");
                        Middleware.Company.Employee.reject(
                          Number(this.props.match.params.company_id),
                          Employee.id
                        );
                      }}
                    >
                      Отклонить
                    </Link>
                  </div>
                </div>
                // </>
              );
            })}
          {/* // <div className="company-employees__inbox-item">
          //   <div className="company-employees__inbox-item-body">
          //     <div className="company-employees__inbox-item-user">
          //       <div className="company-employees__inbox-item-img">
          //         <img src={require("@public/images/avatar.png")} alt="" />
          //       </div>
          //       <div className="company-employees__inbox-item-name">Евгения Скакун</div>
          //       <div className="company-employees__inbox-item-desc">
          //         Директор Представительства Польской туристической организации в Москве
          //       </div>
          //     </div>
          //     <div className="company-employees__inbox-item-contacts">
          //       <a href="mailto:sofi@compain.ru">sofi@compain.ru</a>
          //       <a href="tel:+79005000000">+7 900 500-00-00</a>
          //     </div>
          //   </div>
          //   <hr />
          //   <div className="company-employees__inbox-item-buttons">
          //     <Link className="btn btn--red" to="#">
          //       Добавить в компанию
          //     </Link>
          //     <Link className="btn btn--grey" to="#">
          //       Отклонить
          //     </Link>
          //   </div>
          // </div>
          // <hr className="company-employees__inbox-divider" />
          // <div className="company-employees__inbox-item">
          //   <div className="company-employees__inbox-item-body">
          //     <div className="company-employees__inbox-item-user">
          //       <div className="company-employees__inbox-item-img">
          //         <img src={require("@public/images/avatar.png")} alt="" />
          //       </div>
          //       <div className="company-employees__inbox-item-name">Евгения Скакун</div>
          //       <div className="company-employees__inbox-item-desc">
          //         Директор Представительства Польской туристической организации в Москве
          //       </div>
          //     </div>
          //     <div className="company-employees__inbox-item-contacts">
          //       <a href="mailto:sofi@compain.ru">sofi@compain.ru</a>
          //       <a href="tel:+79005000000">+7 900 500-00-00</a>
          //     </div>
          //   </div>
          //   <hr />
          //   <div className="company-employees__inbox-item-buttons">
          //     <Link className="btn btn--red" to="#">
          //       Добавить в компанию
          //     </Link>
          //     <Link className="btn btn--grey" to="#">
          //       Отклонить
          //     </Link>
          //   </div>
          // </div> */}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(CompanyEmployeesInbox)
);
