import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import PreloaderComponent from "@components/preloader/PreloaderComponent";
import Tariff from "./Price/Tariff";
import { useCurrentTariff } from "@hooks/useCurrentTariff";

interface Price {
  rub: number;
  loyalty: number;
}

interface Description {
  text: string;
  description: string;
}

export interface ITariff {
  id: number;
  name: string;
  type: number;
  price: Price;
  newPrice: Price;
  description: Description[];
}

const Price: React.FC = () => {
  const location = useLocation();
  const ref = useRef(null);
  const tariffs: ITariff[] = useSelector(
    (state: any) => state.Main?.Workshop?.data?.tariffs
  );
  const authStatus = useSelector((state: any) => state.Auth?.status);
  const promoDate = useSelector(
    (state: any) => state.Main?.Workshop?.data.promoDate
  );
  const now = moment().tz("Europe/Moscow").format();

  const currentTariff = useCurrentTariff();
  const currentTariffType = currentTariff ? currentTariff?.type : 0;
  const currentTariffName = currentTariff ? currentTariff?.name : "";
  // Заменить endWorkshop на status loaded, когда буду получать данные с сервера
  const endWorkshop = useSelector(
    (state: any) => state.Main.Workshop.data?.endAt
  );

  useEffect(() => {
    const anchor = new URLSearchParams(location.search).get("anchor");
    if (anchor === "price-anchor" && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, ref.current]);

  if (tariffs.length < 1) {
    return null;
  }

  /* тарифы доступные для покупки во время проведения выставки */
  const liveTariffs = tariffs.filter((tariff) =>
    [1, 2, 3].includes(tariff.type)
  );
  const maxTariff = liveTariffs.sort((a, b) => b.type - a.type)[0];

  const maxTariffBenefits = maxTariff.description;

  function addMaxTariffBenefits(currentTariffBenefits: Description[]) {
    return maxTariffBenefits
      .filter((item) => {
        return !currentTariffBenefits
          .map((item) => item.text)
          .includes(item.text);
      })
      .map((item) => ({ ...item, isMissing: true }));
  }

  return (
    <section className="price bg-white" id="price-anchor" ref={ref}>
      <div className="cont price__cont">
        {!endWorkshop ? (
          <div className="price__preloader">
            <PreloaderComponent />
          </div>
        ) : (
          <>
            <div className="price__header">
              <h2 className="price__title">Тарифы на ОТМ интенсив</h2>
            </div>
            <div className={`price__list`}>
              {tariffs?.map((tariff) => {
                return (
                  <Tariff
                    authStatus={authStatus}
                    currentTariffName={currentTariffName}
                    currentTariff={currentTariffType}
                    key={tariff.id}
                    now={now}
                    promoDate={promoDate}
                    tariff={tariff}
                    benefits={[
                      ...tariff.description,
                      ...addMaxTariffBenefits(tariff.description),
                    ]}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Price;
