import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import * as Model from "@models/index";
import * as Middleware from "@middlewares/index";

import Style from "./Status.module.scss";

interface IProps {
  useNetworking?: boolean;
}

const Status = ({ useNetworking }: IProps): ReactElement => {
  const history = useHistory();
  const netwDays = useSelector((state) => state.Networking?.Days.data);

  return (
    <div className={`${Style.block} cabinet__block`}>
      <div className={Style.title}>
        Доступ в <span>Нетворкинг</span> открыт!
      </div>
      <div className={Style.description}>
        <p>
          Вы можете {netwDays?.length ? "назначать встречи, " : null}{" "}
          обмениваться контактами с другими участниками системы Делового
          общения.
        </p>
        <p>
          Входящие и Исходящие запросы будут отображаться в разделе Визитки.
        </p>
      </div>
      <div className={Style.footer}>
        <div className={Style.buttons}>
          <Link to="/networking" className="btn btn--medium btn--red">
            Перейти в Нетворкинг
          </Link>
          <button
            className="btn btn--medium btn--dark-grey"
            onClick={() => {
              new Model.Profile().setNetworkingStatus(false).then(() => {
                Middleware.Profile.load();
                history.push("/cabinet/networking");
              });
            }}
          >
            Отключить опцию
          </button>
        </div>
        <div className={Style.note}>
          Ваш доступ к вебинарам, стендам и рейтингу Loyalty сохранится.
        </div>
      </div>
    </div>
  );
};

export default Status;
