import * as Action from "@actions/index";
import * as Model from "@models/index";
import { useDispatch } from "@helpers/Store";
import * as Middleware from "@middlewares/index";

export default class UserCutaway {
  public static async list() {
    useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().list();
      useDispatch(Action.User.Cutaway.setList(response.data));
      useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async incoming() {
    useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().list({ type: "incoming" });
      useDispatch(Action.User.Cutaway.setIncomingList(response.data));
      useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async outgoing() {
    useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().list({ type: "outgoing" });
      useDispatch(Action.User.Cutaway.setOutgoingList(response.data));
      useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }

  public static async reject(cutaway_id: number) {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Отклоняем запрос визитки...",
      component: (c: any) => (ProgressAlert = c),
    });
    // useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().update(cutaway_id, { status: 2 });
      UserCutaway.incoming();
      ProgressAlert.setText("Запрос визитки отклонен");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      // useDispatch(Action.User.Cutaway.setOutgoingList(response.data));
      // useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при отклонении запроса на визитку");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
    }
  }

  public static async accept(cutaway_id: number) {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Подтверждаем отправку визитки...",
      component: (c: any) => (ProgressAlert = c),
    });
    // useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().update(cutaway_id, { status: 1 });
      UserCutaway.list();
      UserCutaway.incoming();
      ProgressAlert.setText("Визитка отправлена");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      // useDispatch(Action.User.Cutaway.setOutgoingList(response.data));
      // useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при отправке визитки!");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      throw Exception;
    }
  }

  public static async invite(user_id: number) {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Запрашиваем визитку...",
      component: (c: any) => (ProgressAlert = c),
    });
    // useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().invite(user_id);
      ProgressAlert.setText("Запрос визитки отправлен");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      // useDispatch(Action.User.Cutaway.setOutgoingList(response.data));
      // useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при отправке запроса визитки!");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      throw Exception;
    }
  }

  public static async delete(cutaway_id: number) {
    let ProgressAlert: any;
    Middleware.Ui.Alert.open("Process", {
      text: "Удаляем запрос визитки...",
      component: (c: any) => (ProgressAlert = c),
    });
    // useDispatch(Action.User.Cutaway.setStatus("loading"));
    try {
      const response = await new Model.User2.Cutaway().delete(cutaway_id);
      ProgressAlert.setText("Запрос визитки удален");
      ProgressAlert.setStatus("success");
      ProgressAlert.startTimer();
      // useDispatch(Action.User.Cutaway.setOutgoingList(response.data));
      // useDispatch(Action.User.Cutaway.setStatus("loaded"));
      return response.data;
    } catch (Exception) {
      ProgressAlert.setText("Ошибка при удалении запроса на визитку");
      ProgressAlert.setStatus("danger");
      ProgressAlert.startTimer();
      throw Exception;
    }
  }

  public static async counter(type: string) {
    try {
      const response = await new Model.User2.Cutaway().counter(type);

      switch (type) {
        case "incoming":
          useDispatch(Action.User.Cutaway.incomingCounter(response.data));
          return response.data;
        case "outgoing":
          useDispatch(Action.User.Cutaway.outgoingCounter(response.data));
          return response.data;

        default:
          break;
      }

      return response.data;
    } catch (Exception) {
      throw Exception;
    }
  }
}
