import { Main as MainAction } from "@actions/index";
import { Main } from "@models/index";
import { useDispatch } from "@helpers/Store";

export default class WorkshopNews {
  public static async get() {
    try {
      const response = await new Main.WorkshopNews().get();

      useDispatch(MainAction.WorkshopNews.setData(response.data.result));
      return response.data;
    } catch (Exception) { }
  }
}
