import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import { Link, withRouter } from "react-router-dom";
import * as Middleware from "@middlewares/index";
import { userCutawayStatus } from "@helpers/UserCutawayStatus";

interface PropsInterface extends Type.ComponentInterface {
  title: string;
  data: any;
}

interface StateInterface {
  showedPartners: number;
}

class Partners extends React.Component<PropsInterface, StateInterface> {
  constructor(Props: PropsInterface) {
    super(Props);
    this.state = {
      title: this.props.title,
      data: this.props.data,
      showedPartners: 8,
    };
  }
  public render() {
    return (
      <>
        {this.props.data.length > 0 ? (
          <>
            <div className="stand-partners">
              <div className="stand-partners__title">{this.props.title}</div>
              <div className="stand-partners__grid">
                {this.props.data.map((User: any, index: number) => {
                  return (
                    <>
                      {index < this.state.showedPartners ? (
                        <>
                          <Link
                            to={`/company/stand/${User.id}`}
                            key={index}
                            target="_blank"
                            className="stand-partners__item"
                          >
                            <div>
                              <div className="stand-partners__item-logo">
                                {User.logo ? (
                                  <img src={User.logo} alt="" />
                                ) : (
                                  <img
                                    src={require("@public/images/defaultAvatarCompany.svg")}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="stand-partners__item-title">{User.title}</div>
                            </div>
                            <div className="stand-partners__item-buttons">
                              {User.users?.length > 0 ? (
                                <Link
                                  to={`/company/stand/${User.id}`}
                                  className="stands-item__contacts"
                                  target="_blank"
                                >
                                  <div className="stands-item__contacts-list">
                                    {User.users.map((user: any, index: number) => {
                                      return index < 2 ? (
                                        <div key={index} className="stands-item__contacts-img">
                                          <img src={user.image} alt={user.fullname} />
                                        </div>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  </div>
                                  <Link
                                    to={`/user/${User.id}/event/create`}
                                    className="stands-item__contacts-link"
                                    target="_blank"
                                  >
                                    Назначить встречу
                                  </Link>
                                </Link>
                              ) : (
                                <></>
                              )}
                              <Link
                                className="stands-item__btn btn btn--stand btn--medium"
                                to={`/company/stand/${User.id}`}
                                target="_blank"
                              >
                                Изучить стенд
                              </Link>
                            </div>
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </div>
              {this.state.showedPartners < this.props.data.length ? (
                <>
                  <Link
                    to="#"
                    onClick={() => {
                      this.setState({ showedPartners: this.props.data.length });
                    }}
                    className="stand-partners__more-link"
                  >
                    Показать всех партнеров
                    <img src={require("@public/images/stand-arrow.svg")} alt="" />
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(Partners)
);
