import React from "react";
import { connect } from "react-redux";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { Link, Switch, withRouter } from "react-router-dom";
import Router from "@helpers/router";
import { ComponentInterface } from "@interface/index";

import * as Pages from ".";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

class RouterComponent extends React.Component<PropsInterface, StateInterface, ComponentInterface> {
  public render() {
    return (
      <>
        <div className="company-employees">
          <h1 className="company-cabinet__title">Сотрудники компании</h1>
          <div className="company-employees__tabs-block">
            <div className="company-employees__tabs-nav">
              <Link
                className={`company-employees__tabs-nav-item ${
                  this.props.location.pathname.startsWith("/company/cabinet/employees/inbox")
                    ? "company-employees__tabs-nav-item--active"
                    : ""
                }`}
                to="/company/cabinet/employees/inbox"
              >
                Новые запросы
              </Link>
              <Link
                className={`company-employees__tabs-nav-item ${
                  this.props.location.pathname.startsWith("/company/cabinet/employees/declined")
                    ? "company-employees__tabs-nav-item--active"
                    : ""
                }`}
                to="/company/cabinet/employees/declined"
              >
                Отклоненные
              </Link>
            </div>
            <div className="company-cabinet__block company-employees__tabs-body">
              <Switch>
                <Router
                  path="/company/cabinet/employees/inbox"
                  exact={true}
                  component={Pages.Inbox}
                />
                <Router
                  path="/company/cabinet/employees/declined"
                  exact={true}
                  component={Pages.Declined}
                />
              </Switch>
            </div>
          </div>
          <div className="company-cabinet__block">
            <div className="company-employees__approved">
              <div className="company-employees__approved-title">Подтвержденные</div>
              <div className="company-employees__approved-list">
                <div className="company-employees__approved-item">
                  <div className="company-employees__approved-item-body">
                    <div className="company-employees__approved-item-img">
                      <img src={require("@public/images/avatar.png")} alt="" />
                    </div>
                    <div className="company-employees__approved-item-name">
                      Евгения Скакун <img src={require("@public/images/employee-eye.svg")} alt="" />
                    </div>
                    <div className="company-employees__approved-item-position">
                      Куратор отдела производства
                    </div>
                    <div className="company-employees__approved-item-role">Администратор</div>
                  </div>
                  <div className="company-employees__approved-item-buttons">
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--visibility">
                      <img src={require("@public/images/employee-eye-closed.svg")} alt="" />
                      Скрыть на стенде
                    </div>
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--role">
                      Разжаловать администратора
                    </div>
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--delete">
                      Удалить
                    </div>
                  </div>
                </div>
                <hr />
                <div className="company-employees__approved-item">
                  <div className="company-employees__approved-item-body">
                    <div className="company-employees__approved-item-img">
                      <img src={require("@public/images/avatar.png")} alt="" />
                    </div>
                    <div className="company-employees__approved-item-name">
                      Евгения Скакун{" "}
                      <img src={require("@public/images/employee-eye-closed.svg")} alt="" />
                    </div>
                    <div className="company-employees__approved-item-position">
                      Куратор отдела производства
                    </div>
                    <div className="company-employees__approved-item-role"></div>
                  </div>
                  <div className="company-employees__approved-item-buttons">
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--visibility">
                      <img src={require("@public/images/employee-eye.svg")} alt="" />
                      Отобразить на стенде
                    </div>
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--role">
                      Назначить администратора
                    </div>
                    <div className="company-employees__approved-item-btn company-employees__approved-item-btn--delete">
                      Удалить
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (Store: any) => {
      return { Store };
    },
    (Dispatch: (action: any) => void) => {
      return { Dispatch: (action: any) => Dispatch(action) };
    }
  )(RouterComponent)
);
