import React, { ReactElement, useEffect, useState } from "react";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import { useSelector } from "react-redux";
import RegStep1 from "./components/RegStep1";
import RegStep2 from "./components/RegStep2";
import RegStep3 from "./components/RegStep3";
import RegStep4 from "./components/RegStep4";
import RegStep5 from "./components/RegStep5";
import RegStep6 from "./components/RegStep6";
import { ContactsValidation } from "@components/cabinet";

const NetworkingPage = (): ReactElement => {
  const profile = useSelector((state: any) => state.Profile.data);

  const isContactsValid = useSelector(
    (state: any) => state.Profile.isContactsValid
  );
  const languagesList = useSelector((state: any) => state.Language.list);
  const companyTypesList = useSelector((state: any) => state.Company.Type.list);
  const companyDirectionsList = useSelector(
    (state: any) => state.Company.Direction.list
  );
  const companySpecializationsList = useSelector(
    (state: any) => state.Company.Specialization.list
  );

  const company = useSelector((state: any) => state.Profile.data.company);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const networkingProfile = useSelector(
    (state: any) => state.Profile.data?.networkingProfile
  );
  const employmentType = networkingProfile?.employmentType;
  const maxStep = employmentType === 2 ? 5 : 6;
  // const [networkingProfile, setNetworkingProfile] = useState({
  //   interestCompanyTypes: profile?.networkingProfile?.interestCompanyTypes,
  //   languages: profile?.networkingProfile?.languages,
  //   directions: profile?.networkingProfile?.directions,
  //   specializations: profile?.networkingProfile?.specializations,
  //   about: profile?.networkingProfile?.about,
  // });

  const profileReload = async (): (() => void) => {
    await Middleware.Profile.load();
  };

  const updateNetworkingProfile = (networkingProfile: {
    interestCompanyTypes: number[];
    languages: number[];
    directions: number[];
    specializations: number[];
    about: string;
  }) => {
    Middleware.Profile.updateAdditional(networkingProfile).then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  useEffect(() => {
    if (profile?.networkingFillStatus === 2) {
      setCurrentStep(6);
    }
  }, [profile]);

  return (
    <>
      <div className="cabinet__layout-col">
        <ContactsValidation />
        {currentStep === 1 ? (
          <RegStep1
            companyTypesList={companyTypesList}
            currentStep={currentStep}
            maxStep={maxStep}
            networkingProfile={networkingProfile}
            required={true}
            setCurrentStep={setCurrentStep}
            updateNetworkingProfile={updateNetworkingProfile}
          />
        ) : currentStep === 2 ? (
          <RegStep2
            currentStep={currentStep}
            languagesList={languagesList}
            maxStep={maxStep}
            networkingProfile={networkingProfile}
            required={true}
            setCurrentStep={setCurrentStep}
            updateNetworkingProfile={updateNetworkingProfile}
          />
        ) : currentStep === 3 ? (
          <RegStep3
            companyDirectionsList={companyDirectionsList}
            currentStep={currentStep}
            maxStep={maxStep}
            networkingProfile={networkingProfile}
            required={false}
            setCurrentStep={setCurrentStep}
            updateNetworkingProfile={updateNetworkingProfile}
          />
        ) : currentStep === 4 ? (
          <RegStep4
            companySpecializationsList={companySpecializationsList}
            currentStep={currentStep}
            maxStep={maxStep}
            networkingProfile={networkingProfile}
            required={false}
            setCurrentStep={setCurrentStep}
            updateNetworkingProfile={updateNetworkingProfile}
          />
        ) : currentStep === 5 ? (
          <RegStep5
            currentStep={currentStep}
            employmentType={employmentType}
            isContactsValid={isContactsValid}
            maxStep={maxStep}
            networkingProfile={networkingProfile}
            required={false}
            setCurrentStep={setCurrentStep}
            updateNetworkingProfile={updateNetworkingProfile}
          />
        ) : currentStep === 6 ? (
          <RegStep6
            company={company}
            currentStep={currentStep}
            isContactsValid={isContactsValid}
            profileReload={profileReload}
            required={true}
            setCurrentStep={setCurrentStep}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="cabinet__layout-col"></div>
    </>
  );
};

export default NetworkingPage;
