import React from "react";
import * as Type from "@interface/index";
import * as Middleware from "@middlewares/index";
import Connector from "@helpers/Connector";
import { Link } from "react-router-dom";

interface PropsInterface extends Type.ComponentInterface {}

interface StateInterface {}

export default Connector(
  class NetworkingPage extends React.Component<PropsInterface, StateInterface> {
    public componentDidMount() {
      document.title = "«ОТМ: SUMMER 2024» Networking";
    }

    public render() {
      return (
        <>
          <main className="netw-soon">
            <div className="netw-soon__header">
              <div className="cont">
                <h1 className="netw-soon__title">
                  «{this.props.Store.Main.Workshop.data.title}» Networking
                </h1>
              </div>
            </div>
            <div className="netw-soon__body">
              <div className="cont">
                <div className="netw-soon__main">
                  <div className="netw-soon__main-content">
                    <div className="netw-soon__main-title">
                      Добро пожаловать в нетворкинг на Online Travel Mart!
                    </div>
                    <div className="netw-soon__main-desc">
                      Совсем скоро в этом разделе появятся карточки участников
                      системы делового общения: представителей направлений,
                      туристических компаний и организаций. Вы сможете назначать
                      встречи и проводить их в видеоформате, обмениваться
                      визитками и сообщениями.
                    </div>
                  </div>

                  {this.props.Store.Auth.status !== "authorized" ? (
                    <Link to="/auth/login" className="netw-soon__main-img">
                      <img
                        src={require("@public/images/netw-soon-main.png")}
                        alt=""
                      />
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="netw-soon__main-img"
                      onClick={() =>
                        Middleware.Ui.Modal.open("NetworkingSoon", {
                          callback: "",
                        })
                      }
                    >
                      <img
                        src={require("@public/images/netw-soon-main.png")}
                        alt=""
                      />
                    </Link>
                  )}
                </div>
                <div className="netw-soon__tagline">
                  <div className="netw-soon__tagline-text">
                    Проводите встречи, обменивайтесь визитками и{" "}
                    <span>заключайте новые контракты!</span>
                  </div>
                  <div className="netw-soon__tagline-icon">
                    <img
                      src={require("@public/images/netw-soon-tagline.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="netw-soon__cards">
                  <div className="netw-soon__cards-content">
                    <div className="netw-soon__cards-title">
                      Ключевые участники нетворкинга
                    </div>

                    {this.props.Store.Auth.status !== "authorized" ? (
                      <Link to="/auth/login" className="netw-soon__cards-desc">
                        Скоро открытие раздела{" "}
                        <div className="netw-soon__cards-arrow" />
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        className="netw-soon__cards-desc "
                        onClick={() =>
                          Middleware.Ui.Modal.open("NetworkingSoon", {
                            callback: "",
                          })
                        }
                      >
                        Скоро открытие раздела
                        <div className="netw-soon__cards-arrow" />
                      </Link>
                    )}
                  </div>
                  <ul className="netw-soon__cards-list">
                    {[
                      "Туристические фирмы",
                      "Туроператоры",
                      "Объекты размещения",
                      "Турагентства",
                      "Транпспорт",
                      "Объекты показа",
                    ].map((item: string, index: number) => {
                      return (
                        <li key={index} className="netw-soon__cards-item">
                          {this.props.Store.Auth.status !== "authorized" ? (
                            <Link to="/auth/login">{item}</Link>
                          ) : (
                            <Link
                              to="#"
                              onClick={() =>
                                Middleware.Ui.Modal.open("NetworkingSoon", {
                                  callback: "",
                                })
                              }
                            >
                              {item}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }
  }
);
